import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InterviewComponent } from './interview/interview.component';
import { UserComponent } from './user/user.component';
import { ParameterComponent } from './parameter/parameter.component';
import { ProfileComponent } from './profile/profile.component';
import { SavedInterviewsComponent } from './saved-interviews/saved-interviews.component';
import { IndexComponent } from './index/index.component';
import { AuthGuardHelper } from './_helpers/auth.guard';

const routes: Routes = [
  { path: 'acesso', component: LoginComponent },
  { 
    path: '', 
    canActivate: [AuthGuardHelper], 
    runGuardsAndResolvers: 'always',
    children: [
      { 
        path: '', 
        component: IndexComponent,
        children: [
          { path: '', redirectTo: 'historico', pathMatch: 'full'},
          { path: 'cadastros', component: RegistrationComponent },
          { path: 'entrevista', component: InterviewComponent },
          { path: 'usuarios', component: UserComponent },
          { path: 'historico', component: SavedInterviewsComponent },
          { path: 'perfil', component: ProfileComponent },
          { path: 'parametros', component: ParameterComponent },
          { path: '**', component: PageNotFoundComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
