import { Herd } from './herd';

export class Farm {

  public id: number;

  public name: string;

  public herd: Array<Herd>;

  public customerId: number;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.herd = this.herd ? input.herd.map((herd: Herd) => new Herd().deserialize(herd)) : this.herd;

    return this;
  }

}
