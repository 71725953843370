<h2 class="text-center my-4">Ponderação de índices - {{interviewCycle.breedName}} ({{interviewCycle.proofName}})</h2>
<div class="border rounded p-4 bg-white col-12">
    <!-- <div class="mb-2">
        <h3 class="p-2 mr-5">Características</h3>
        <fa-icon (click)="OpenModalTraits()" class="btn btn-submit  m-4" [icon]="iconAdd" title="Adicionar características"></fa-icon>
    </div> -->
    <div *ngIf="traitsGroups.length > 0 && isTraitsLoaded; else listEmpty">
        <div *ngFor="let traitGroup of traitsGroups; let tg = index;" class="col-12">
            <h3 class="p-2 mr-5">{{traitGroup.name}}</h3>
            <div *ngFor="let trait of traitGroup.trait; let t = index;">
                <div class="row bg-light border p-1 rounded m-1">
                    <div class="col-8 m-0 py-1 px-0 text-left">
                        <label class="p-1 m-0" *ngIf="!trait.isHighlighting">{{trait.description}} ({{trait.display}})</label>
                        <label class="p-1 m-0 font-weight-bold" *ngIf="trait.isHighlighting">{{trait.description}} ({{trait.display}})</label>
                    </div>
                    <div class="col-2 m-0 py-1 px-0">
                        <input 
                            type="text" 
                            class="form-control text-right"
                            id="trait_{{trait.id}}"
                            name="trait_{{trait.display}}"
                            mask="000" 
                            placeholder="000"
                            [(ngModel)]="trait.weightingValue" 
                            (change)="updatWeightingTotalValue()"/>
                    </div>
                    <!-- <div class="col-2 m-0 py-1 px-2">
                        <button class="btn-danger badge badge-secondary rounded border m-1 p-2" (click)="removeTrait(tg, t)">X</button>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row rounded p-1 m-3 col-12">
            <div class="col-8 m-0 py-1 px-0 text-left">
                <h3>Total</h3>
            </div>
            <div class="col-2 m-0 py-1 px-0 text-center">
                <h3 *ngIf="!invalidWeightingValueTotal">{{weightingValueTotal}}</h3>
                <h3 class="text-danger" *ngIf="invalidWeightingValueTotal">{{weightingValueTotal}}</h3>
            </div>
            <div class="col-2 m-0 py-1 px-0"></div>
        </div>
    </div>

    <div *ngIf="!isTraitsLoaded" class="text-center">
        <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
        <p class="text-center w-100">Buscando características...</p>
    </div>

    <ng-template #listEmpty>
        <div *ngIf="isTraitsLoaded" class="d-flex align-items-center" style="height: calc(100% - 90px);">
            <p class="text-center w-100">Nenhuma característica encontrada.</p>
        </div>
    </ng-template>

    <!-- <ng-template #noSelectedTraitsGroups>
        <div class="form-row">
            <div class="col-12">
                <p class="w-100">Nenhuma característica selecionada.</p>
            </div>
        </div>
    </ng-template> -->
</div>