import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Interview } from 'src/_models/interview';
import { NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalBonusProgramComponent } from '../modal-bonus-program/modal-bonus-program.component';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { BonusProgramm } from 'src/_models/bonus-program';
import { HerdProblem } from 'src/_models/herd-problem';
import { ModalHerdProblemComponent } from '../modal-herd-problem/modal-herd-problem.component';

@Component({
  selector: 'app-data-collect',
  templateUrl: './data-collect.component.html',
  styleUrls: ['./data-collect.component.scss']
})
export class DataCollectComponent implements OnInit {

  @Input() public interview: Interview;
  @Output() updateInterview = new EventEmitter();
  @Output() updateInterviewPrimary = new EventEmitter();
  @Output() updateInterviewSecondary = new EventEmitter();

  public herdGoalMaleList: Array<any>;
  public herdGoalFemaleList: Array<any>;

  public modalOptions: NgbModalOptions;

  public iconAdd = faPlus;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.herdGoalMaleList = [{id: 1, name: 'Abate'}, {id: 2, name: 'Venda ao desmame'}, {id: 3, name: 'Produção de touros'}];
    this.herdGoalFemaleList = [{id: 1, name: 'Abate'}, {id: 2, name: 'Venda ao desmame'}, {id: 3, name: 'Produção de matrizes'}];
  }

  // QUESTAO 1: ORIGEM DAS FEMEAS PARA REPRODUCAO
  public changeFemaleOrigin(e: any) {
    if (e.target.checked) {
      this.interview.femaleOrigin += Number(e.target.value);
    } else {
      this.interview.femaleOrigin = this.interview.femaleOrigin > 0 ? this.interview.femaleOrigin - Number(e.target.value) : 0;
    }
    this.updateInterview.emit();
  }

  public updateAllBreedsSuggestions() {
    this.updateInterviewBreedGoals();
    this.updateInterview.emit();
  }

  private updateInterviewBreedGoals() {
    if (this.interview.breedOptions === 1) {
      this.interview.interviewBreedGoal[1].sellingGoalsMale = 0;
      this.interview.interviewBreedGoal[1].sellingGoalsFemale = 0;
      this.interview.interviewBreedGoal[1].confinementTypeMale = 0;
      this.interview.interviewBreedGoal[1].usesCastration = 0;
      this.interview.interviewBreedGoal[1].confinementTypeFemale = 0;
      this.interview.interviewBreedGoal[1].maxTaurean = 0;
      this.interview.interviewBreedGoal[1].maxZebu = 0;
    }
  }

  public updatePrimaryBreedSuggestion() {
    if (Number(this.interview.interviewBreedGoal[0].sellingGoalsMale) !== 1) {
      this.interview.interviewBreedGoal[0].confinementTypeMale = 0;
      this.interview.interviewBreedGoal[0].usesCastration = 0;
    }
    if (Number(this.interview.interviewBreedGoal[0].sellingGoalsFemale) !== 1) {
      this.interview.interviewBreedGoal[0].confinementTypeFemale = 0;
    }
    this.updateInterviewPrimary.emit();
  }

  public updateSecondaryBreedSuggestion() {
    if (Number(this.interview.interviewBreedGoal[1].sellingGoalsMale) !== 1) {
      this.interview.interviewBreedGoal[1].confinementTypeMale = 0;
      this.interview.interviewBreedGoal[1].usesCastration = 0;
    }
    if (Number(this.interview.interviewBreedGoal[1].sellingGoalsFemale) !== 1) {
      this.interview.interviewBreedGoal[1].confinementTypeFemale = 0;
    }
    this.updateInterviewSecondary.emit();
  }

  // QUESTAO 4: PROGRAMAS DE BONIFICACAO
  public openBonusProgram() {
    const modalRef = this.modalService.open(ModalBonusProgramComponent, { centered: true });
    modalRef.componentInstance.bonus = this.interview.bonusProgram;
    modalRef.result.then((result) => {
      this.interview.bonusProgram = new Array<BonusProgramm>();
      this.interview.bonusProgram.push(...result);
      this.updateAllBreedsSuggestions();
    }, (reason) => {
      console.log(`Dispensou ${this.getDismissReason(reason)}`);
    });
  }

  public removeBonusProgram(idx: number) {
    console.log(idx);
    this.interview.bonusProgram.splice(idx, 1);
  }

  // QUESTAO 5: PROBLEMAS DO REBANHO
  public openHerdProblem() {
    const modalRef = this.modalService.open(ModalHerdProblemComponent, { centered: true });
    modalRef.componentInstance.list = this.interview.herdProblem;
    modalRef.result.then((result) => {
      this.interview.herdProblem = new Array<HerdProblem>();
      this.interview.herdProblem.push(...result);
    }, (reason) => {
      console.log(`Dispensou ${this.getDismissReason(reason)}`);
    });
  }

  public removeHerdProblem(idx: number) {
    console.log(idx);
    this.interview.herdProblem.splice(idx, 1);
  }

  // ======================================================================================================
  // METODOS PRIVADOS
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'apertando ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'clicando fora do modal';
    } else {
      return  `com: ${reason}`;
    }
  }
}
