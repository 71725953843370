import { Component, OnInit, Input } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import { InterviewCycle } from 'src/_models/interview-cycle';
import { DosesDistributionByBreed } from 'src/_models/doses-distribution-by-breed';
import { ReproductiveManagementService } from '@app/_services/reproductive-management.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-breed-distribution]',
  templateUrl: './breed-distribution.component.html',
  styleUrls: ['./breed-distribution.component.scss']
})
export class BreedDistributionComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;
  @Input() interviewCycle: InterviewCycle[];
  @Input() dosesDistributionByBreed: DosesDistributionByBreed;

  constructor(public reproductiveManagementService: ReproductiveManagementService) { }

  ngOnInit() {
      this.reproductiveManagementService.heifersBeginningBreed1Percent = 100;
      this.reproductiveManagementService.heifersMiddleBreed1Percent = 100;
      this.reproductiveManagementService.heifersEndBreed1Percent = 100;
      this.reproductiveManagementService.cowsBeginningBreed1Percent = 100;
      this.reproductiveManagementService.cowsMiddleBreed1Percent = 100;
      this.reproductiveManagementService.cowsEndBreed1Percent = 100;

      this.reproductiveManagementService.heifersBeginningBreed2Percent = 0;
      this.reproductiveManagementService.heifersMiddleBreed2Percent = 0;
      this.reproductiveManagementService.heifersEndBreed2Percent = 0;
      this.reproductiveManagementService.cowsBeginningBreed2Percent = 0;
      this.reproductiveManagementService.cowsMiddleBreed2Percent = 0;
      this.reproductiveManagementService.cowsEndBreed2Percent = 0;

      this.dosesDistributionByBreed = this.reproductiveManagementService.dosesDistributionByBreed = new DosesDistributionByBreed();
  }

  get breed1() { return this.interviewCycle[0].breedName; }
  get breed2() { return this.interviewCycle[1].breedName; }

  get breedDistributionHeifersEM1Breed1 () {
    this.reproductiveManagement.breedDistributionHeifersBeginningBreed1 = this.reproductiveManagementService.breedDistributionHeifersEM1Breed1;
    return this.reproductiveManagement.breedDistributionHeifersBeginningBreed1;
  }
  get breedDistributionHeifersEM2Breed1 () {
    this.reproductiveManagement.breedDistributionHeifersMiddleBreed1 = this.reproductiveManagementService.breedDistributionHeifersEM2Breed1;
    return this.reproductiveManagement.breedDistributionHeifersMiddleBreed1;
  }
  get breedDistributionHeifersEM3Breed1 () {
    this.reproductiveManagement.breedDistributionHeifersEndBreed1 = this.reproductiveManagementService.breedDistributionHeifersEM3Breed1;
    return this.reproductiveManagement.breedDistributionHeifersEndBreed1;
  }
  get breedDistributionCowsEM1Breed1 () {
    this.reproductiveManagement.breedDistributionCowsBeginningBreed1 = this.reproductiveManagementService.breedDistributionCowsEM1Breed1;
    return this.reproductiveManagement.breedDistributionCowsBeginningBreed1;
  }
  get breedDistributionCowsEM2Breed1 () {
    this.reproductiveManagement.breedDistributionCowsMiddleBreed1 = this.reproductiveManagementService.breedDistributionCowsEM2Breed1;
    return this.reproductiveManagement.breedDistributionCowsMiddleBreed1;
  }
  get breedDistributionCowsEM3Breed1 () {
    this.reproductiveManagement.breedDistributionCowsEndBreed1 = this.reproductiveManagementService.breedDistributionCowsEM3Breed1;
    return this.reproductiveManagement.breedDistributionCowsEndBreed1;
  }

  get breedDistributionHeifersEM1Breed2 () {
    this.reproductiveManagement.breedDistributionHeifersBeginningBreed2 = this.reproductiveManagementService.breedDistributionHeifersEM1Breed2;
    return this.reproductiveManagement.breedDistributionHeifersBeginningBreed2;
  }
  get breedDistributionHeifersEM2Breed2 () {
    this.reproductiveManagement.breedDistributionHeifersMiddleBreed2 = this.reproductiveManagementService.breedDistributionHeifersEM2Breed2;
    return this.reproductiveManagement.breedDistributionHeifersMiddleBreed2;
  }
  get breedDistributionHeifersEM3Breed2 () {
    this.reproductiveManagement.breedDistributionHeifersEndBreed2 = this.reproductiveManagementService.breedDistributionHeifersEM3Breed2;
    return this.reproductiveManagement.breedDistributionHeifersEndBreed2;
  }
  get breedDistributionCowsEM1Breed2 () {
    this.reproductiveManagement.breedDistributionCowsBeginningBreed2 = this.reproductiveManagementService.breedDistributionCowsEM1Breed2;
    return this.reproductiveManagement.breedDistributionCowsBeginningBreed2;
  }
  get breedDistributionCowsEM2Breed2 () {
    this.reproductiveManagement.breedDistributionCowsMiddleBreed2 = this.reproductiveManagementService.breedDistributionCowsEM2Breed2;
    return this.reproductiveManagement.breedDistributionCowsMiddleBreed2;
  }
  get breedDistributionCowsEM3Breed2 () {
    this.reproductiveManagement.breedDistributionCowsEndBreed2 = this.reproductiveManagementService.breedDistributionCowsEM3Breed2;
    return this.reproductiveManagement.breedDistributionCowsEndBreed2;
  }

  get breedDistributionTotalEM1Breed1 () {
    this.reproductiveManagement.breedDistributionTotalBeginningBreed1 = this.reproductiveManagementService.breedDistributionTotalEM1Breed1;
    return this.reproductiveManagement.breedDistributionTotalBeginningBreed1;
  }
  get breedDistributionTotalEM1Breed2 () {
    this.reproductiveManagement.breedDistributionTotalBeginningBreed2 = this.reproductiveManagementService.breedDistributionTotalEM1Breed2;
    return this.reproductiveManagement.breedDistributionTotalBeginningBreed2;
  }
  get breedDistributionTotalEM2Breed1 () {
    this.reproductiveManagement.breedDistributionTotalMiddleBreed1 = this.reproductiveManagementService.breedDistributionTotalEM2Breed1;
    return this.reproductiveManagement.breedDistributionTotalMiddleBreed1;
  }
  get breedDistributionTotalEM2Breed2 () {
    this.reproductiveManagement.breedDistributionTotalMiddleBreed2 = this.reproductiveManagementService.breedDistributionTotalEM2Breed2;
    return this.reproductiveManagement.breedDistributionTotalMiddleBreed2;
  }
  get breedDistributionTotalEM3Breed1 () {
    this.reproductiveManagement.breedDistributionTotalEndBreed1 = this.reproductiveManagementService.breedDistributionTotalEM3Breed1;
    return this.reproductiveManagement.breedDistributionTotalEndBreed1;
  }
  get breedDistributionTotalEM3Breed2 () {
    this.reproductiveManagement.breedDistributionTotalEndBreed2 = this.reproductiveManagementService.breedDistributionTotalEM3Breed2;
    return this.reproductiveManagement.breedDistributionTotalEndBreed2;
  }

  get breedDistributionTotalBreed1 () {
    this.reproductiveManagement.totalDosesByBreedBreed1 = this.reproductiveManagementService.breedDistributionTotalBreed1;
    return this.reproductiveManagement.totalDosesByBreedBreed1;
  }
  get breedDistributionTotalBreed2 () {
    this.reproductiveManagement.totalDosesByBreedBreed2 = this.reproductiveManagementService.breedDistributionTotalBreed2;
    return this.reproductiveManagement.totalDosesByBreedBreed2;
  }

}
