export enum BreedEnum {
    BRAHMAN = 1,
    GUZERA = 2,
    INDUBRASIL = 3,
    NELORE = 4,
    NELORE_MOCHO = 5,
    TABAPUA = 6,
    SINDI = 7,
    ANGUS = 8,
    BONSMARA = 9,
    BRAFORD = 10,
    BRANGUS = 11,
    BRAUNVIEH = 12,
    CANCHIM = 13,
    CARACU = 14,
    CHAROLES = 15,
    CHAROLES_MOCHO = 16,
    DEVON = 17,
    LIMOUSIN = 19,
    MARCHIGIANA = 20,
    PIEMONTES = 21,
    HEREFORD = 22,
    RED_ANGUS = 23,
    RED_BRANGUS = 24,
    SANTA_GERTRUDIS = 25,
    SENEPOL = 27,
    SIMBRASIL = 28,
    SIMENTAL = 29,
    SIMENTAL_MOCHO = 50,
    SIMENTAL_LEITEIRO = 51,
    WAGYU = 56,
    CARACU_MOCHO = 87,
    CRUZA_ANGUS = 105,
    CRUZA_HEREFORD = 106,
    CRUZA_SINTETICA = 116,
    ZEBU_MESTICA = 122
  }
