<h3 class="p-2 mb-2">5 - Taxa esperada de prenhez por IATF</h3>
<div class="row">
  <div class="col-12">
    <div class="form-row">
      <div class="form-group">
        <table class="table borderless">
          <thead>
            <tr>
              <th></th>
              <th>
                <label class="p-2 m-0">1ª IATF</label>
              </th>
              <th>
                <label class="p-2 m-0">2ª IATF</label>
              </th>
              <th>
                <label class="p-2 m-0">3ª IATF</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <label class="p-2 m-0">Novilhas</label>
              </th>
              <td>
                <input appOnlyDigits class="form-control text-right"
                  [(ngModel)]="reproductiveManagement.pregnancyRate1IATFHeifers"
                  (keyup) = "pregnancyRate1IATFHeifersChange()"
                  type="text" placeholder="0" />
              </td>
              <td>
                <input appOnlyDigits class="form-control text-right"
                  [(ngModel)]="reproductiveManagement.pregnancyRate2IATFHeifers"
                  (keyup) = "pregnancyRate2IATFHeifersChange()"
                  type="text" placeholder="0"
                  [disabled]="reproductiveManagement.maxIATFsPerHeifer < 2" />
              </td>
              <td>
                <input appOnlyDigits class="form-control text-right"
                  [(ngModel)]="reproductiveManagement.pregnancyRate3IATFHeifers"
                  type="text" placeholder="0"
                  [disabled]="reproductiveManagement.maxIATFsPerHeifer < 3" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0">Vacas</label>
              </th>
              <td>
                <input appOnlyDigits class="form-control text-right"
                  [(ngModel)]="reproductiveManagement.pregnancyRate1IATFCows"
                  (keyup) = "pregnancyRate1IATFCowsChange()"
                  type="text" placeholder="0" />
              </td>
              <td>
                <input appOnlyDigits class="form-control text-right"
                  [(ngModel)]="reproductiveManagement.pregnancyRate2IATFCows"
                  (keyup) = "pregnancyRate2IATFCowsChange()"
                  type="text" placeholder="0"
                  [disabled]="reproductiveManagement.maxIATFsPerCow < 2" />
              </td>
              <td>
                <input appOnlyDigits class="form-control text-right"
                  [(ngModel)]="reproductiveManagement.pregnancyRate3IATFCows"
                  type="text" placeholder="0"
                  [disabled]="reproductiveManagement.maxIATFsPerCow < 3" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
