import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-pregnancy-rate]',
  templateUrl: './pregnancy-rate.component.html',
  styleUrls: ['./pregnancy-rate.component.scss']
})
export class PregnancyRateComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;

  @Output() pregnancyRate1IATFHeifersEmitter = new EventEmitter();
  @Output() pregnancyRate2IATFHeifersEmitter = new EventEmitter();
  @Output() pregnancyRate1IATFCowsEmitter = new EventEmitter();
  @Output() pregnancyRate2IATFCowsEmitter = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  pregnancyRate1IATFHeifersChange = () => this.pregnancyRate1IATFHeifersEmitter.emit();

  pregnancyRate1IATFCowsChange = () => this.pregnancyRate1IATFCowsEmitter.emit();

  pregnancyRate2IATFHeifersChange = () => this.pregnancyRate2IATFHeifersEmitter.emit();

  pregnancyRate2IATFCowsChange = () => this.pregnancyRate2IATFCowsEmitter.emit();

}
