<h3 class="p-2 mb-2">2 - Número de IATF's</h3>
<div class="row">
    <div class="col-12">
        <div class="form-row">
        <div class="form-group row w-100">
            <label class="col-form-label col-12 col-sm-9 p-2" for="maxIATFsPerHeifer">Número máximo de IATF por novilha</label>
            <div class="col-12 col-sm-3 p-1">
            <kendo-numerictextbox
                [step]="1"
                [min]="1"
                [max]="3"
                [decimals]="0"
                [autoCorrect]="true"
                [format]="'n0'"
                class="form-control"
                id="maxIATFsPerHeifer"
                [(ngModel)]="reproductiveManagement.maxIATFsPerHeifer"
                (valueChange)="maxIATFsPerHeifer()"
                placeholder="1">
            </kendo-numerictextbox>
            </div>
        </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-row">
        <div class="form-group row w-100">
            <label class="col-form-label col-12 col-sm-9 p-2" for="maxIATFsPerCow">Número máximo de IATF por vaca</label>
            <div class="col-12 col-sm-3 p-1">
            <kendo-numerictextbox
                [step]="1"
                [min]="1"
                [max]="3"
                [decimals]="0"
                [autoCorrect]="true"
                [format]="'n0'"
                class="form-control"
                id="maxIATFsPerCow"
                [(ngModel)]="reproductiveManagement.maxIATFsPerCow"
                (valueChange)="maxIATFsPerCowChange()"
                placeholder="1">
            </kendo-numerictextbox>
            </div>
        </div>
        </div>
    </div>
</div>
