import { City } from './city';
import { Person } from './person';

export class Address {

  public id: number;

  public street: string;

  public number: string;

  public complement: string;

  public postalCode: string;

  public cityId: number;

  public personId: number;

  public city: City;

  public isDefault: boolean;

  constructor () {
    if (!this.city) { this.city = new City(); }
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
