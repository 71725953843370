export class AnalyticSelectResultPresentation {

    public id: number;

    public traitGroupId: number;

    public traitId: number;

    public traitGroupName: string;

    public traitName: string;

    public acronym: string;

    public isTraitGroup: boolean;

    public weighting: number;

    public filterPercentSignal: string;

    public filterPercentValue: number;

    public filterDepSignal: string;

    public filterDepValue: number;

    public filterAccValue: number;

    public bullAverage: number;

    public schemeAverage: number;

    public accAverage: number;

    public percent: number;

    public sequence: number;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
