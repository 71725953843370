import { Component, OnInit } from '@angular/core';
import { ApiService } from '@app/_services/api.service';
import { User } from 'src/_models/user';
import { Router } from '@angular/router';
import { faSignOutAlt, faUser, faHome, faFileAlt, faUsers, faEdit, faUserPlus, faBars } from '@fortawesome/free-solid-svg-icons';
import { Person } from 'src/_models/person';
import { MsalService } from '@azure/msal-angular';
import { LoginInfo } from 'src/_models/login-info';
import { UserId } from 'src/_models/user-token';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  currentUser: User;
  iconSignOut = faSignOutAlt;
  iconMenu = faBars;
  faUser = faUser;
  faFileAlt = faFileAlt;
  faUsers = faUsers;
  faUserPlus = faUserPlus;
  faHome = faHome;
  faEdit = faEdit;
  showMenu = false;
  Permission = false;
  claims: any;
  currentUserSubject: BehaviorSubject<UserId>;
  isLoggedIn = false;
  logingInfo: LoginInfo;

  adminEmails: string[] = [
    'rodrigo.alves@altagenetics.com',
    'rodrigo.alves1@altagenetics.com',
    'flavia.santana@altagenetics.com',
    'luiza.mangucci@altagenetics.com',
    'fernando.prado@altagenetics.com',
    'isabella.noronha@altagenetics.com',
    'isabela.caldato@altagenetics.com'
  ];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private msalService: MsalService) { }

  ngOnInit() {
    this.currentUser = new User();
    this.currentUser.person = new Person();
    this.logingInfo = new LoginInfo();
    this.setClaims();
    this.login();
  }

  login() {
    this.apiService.login(this.getLogingInfo()).subscribe((userToken: UserId) => {
      localStorage.setItem('currentUser', JSON.stringify(userToken));
      sessionStorage.setItem('currentUser', JSON.stringify(userToken));
      this.apiService.GetUser().subscribe((data: User) => {
        this.currentUser = data;
      }, (error) => console.error(error));
    }, (error) => console.error(error));
  }

  getLogingInfo(): LoginInfo {
    this.logingInfo.email = this.getEmail();
    this.logingInfo.password = ``;
    return this.logingInfo;
  }

  logout() {
    localStorage.removeItem('currentUser');
    sessionStorage.removeItem('currentUser');
    this.msalService.logout();
  }

  setClaims() {
    const account = this.msalService.getAccount();
    this.claims = account ? account.idTokenClaims : undefined;
  }

  getEmail() {
    if (this.claims) {
      return this.claims.emails[0];
    }
  }

  isAdminUser(): boolean {
    return this.adminEmails.includes(this.currentUser.email);
  }
}