import { HerdProblem } from 'src/_models/herd-problem';
import { BonusProgramm } from 'src/_models/bonus-program';
import { InterviewCycle } from 'src/_models/interview-cycle';
import { InterviewBreedGoal } from './../../_models/interview-breed-goal';
import { Interview } from 'src/_models/interview';
import { Injectable } from '@angular/core';
import { Herd } from 'src/_models/herd';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import * as internal from 'assert';
import { DataResultIterator } from '@progress/kendo-angular-grid/dist/es2015/data/data.collection';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  constructor() { }

  public interview: Interview

  public cloneInterview(interview: any): Interview {

    const result:Interview = new Interview();

    result.bonusProgram = interview.bonusProgram? interview.bonusProgram : result.bonusProgram;
     result.breedOptions = interview.breedOptions? interview.breedOptions : result.breedOptions;
     result.breedOptionsMatting = interview.breedOptionsMatting? interview.breedOptionsMatting : result.breedOptionsMatting;
    // result.currentInterviewCycle = interview.currentInterviewCycle ? interview.currentInterviewCycle : result.currentInterviewCycle;
     result.interviewBreedGoal = interview.interviewBreedGoal ? interview.interviewBreedGoal : result.interviewBreedGoal;
    //  result.interviewCycle = [];
    //  result.interviewCycle.push(new InterviewCycle());



     result.interviewCycle = interview.interviewCycle; //? interview.interviewCycle : result.interviewCycle;


    result.reproductiveManagement = interview.reproductiveManagement ? interview.reproductiveManagement : result.reproductiveManagement;


    return result;
  }

  public initializeInterview(interview: Interview): Interview {
    // interview.interviewDate = interview.interviewDate ? interview.interviewDate : new Date();
    // interview.femaleOrigin = interview.femaleOrigin ? interview.femaleOrigin : 0;
    // interview.breedOptions = interview.breedOptions ? interview.breedOptions : 1;
    // interview.breedOptionsMatting = interview.breedOptionsMatting ? interview.breedOptionsMatting : 0;
    // //interview.currentInterviewCycle = interview.currentInterviewCycle ? interview.currentInterviewCycle : 0;

    // interview.herd = interview.herd ? interview.herd : new Herd();
    // interview.reproductiveManagement = interview.reproductiveManagement ? interview.reproductiveManagement : new ReproductiveManagement();

    //   if (!interview.interviewBreedGoal) {
    //     interview.interviewBreedGoal = new Array<InterviewBreedGoal>();
    //     interview.interviewBreedGoal.push(new InterviewBreedGoal());
    //     interview.interviewBreedGoal.push(new InterviewBreedGoal());
    //   }

    // //interview.interviewCycle = interview.interviewCycle ? interview.interviewCycle : new Array<InterviewCycle>();
    // interview.bonusProgram = interview.bonusProgram ? interview.bonusProgram : new Array<BonusProgramm>();
    // interview.herdProblem = interview.herdProblem ? interview.herdProblem : new Array<HerdProblem>();

    return interview;
  }

}
