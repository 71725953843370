<h2 class="text-center my-4">Quantidade de doses por touro - {{interviewCycle.breedName}} ({{interviewCycle.proofName}})</h2>
<div class="border rounded p-4 bg-white col-12">
    <div class="col-12 text-left">
        <ng-template [ngIf]="selectedBulls.length > 0">
            <kendo-grid
                [kendoGridBinding]="selectedBulls"
                [data]="gridView"
                [selectable]="selectableSettings"
                [kendoGridSelectBy]="selectedCallback"
                [selectedKeys]="gridSelection"
                (cellClick)="cellClickHandler($event)"
                (cellClose)="cellCloseHandler($event)"
                style="max-height: calc(100% - 90px); width: 100%;">
                <kendo-grid-column 
                    media="xs" 
                    title="Distribuição de doses">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <dl>
                            <dt>Touro</dt>
                            <dd>{{ dataItem.shortName }}</dd>
    
                            <dt>Doses (Vacas)</dt>
                            <dd>{{ dataItem.cowsDoses }}</dd>
    
                            <dt>Doses (Novilhas)</dt>
                            <dd>{{ dataItem.heifersDoses }}</dd>
    
                            <dt>Preço</dt>
                            <dd>{{ dataItem.pricePerDose }}</dd>
    
                            <dt>Total</dt>
                            <dd>{{ dataItem.total }}</dd>
                        </dl>
                    </ng-template>
                </kendo-grid-column>
    
                <!-- columns for mid-size devices -->
                <kendo-grid-column
                    media="sm"
                    field="shortName"
                    title="Touro"
                    width="250"
                    [style]="{'color': '#000', 'font-weight': 'bold'}"
                    [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                    [footerStyle]="{'font-weight': 'bold', 'line-height': '1em', 'color': '#000'}">
                    <ng-template kendoGridFooterTemplate>Total</ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                    media="sm"
                    field="cowsDoses"
                    title="Doses (Vacas)"
                    width="150"
                    editor="numeric"
                    [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                    [footerStyle]="{'font-weight': 'bold', 'line-height': '1em', 'color': '#000'}">
                    <ng-template kendoGridFooterTemplate>{{ totalCowDoses | number }}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                    media="sm"
                    field="heifersDoses"
                    title="Doses (Novilhas)"
                    width="150"
                    editor="numeric"
                    [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                    [footerStyle]="{'font-weight': 'bold', 'line-height': '1em', 'color': '#000'}">
                    <ng-template kendoGridFooterTemplate>{{ totalHeifersDoses | number }}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                    media="sm"
                    field="pricePerDose"
                    title="Preço"
                    width="100"
                    editor="numeric"
                    [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                    [footerStyle]="{'font-weight': 'bold', 'line-height': '1em', 'color': '#000'}">
                    <ng-template kendoGridFooterTemplate>{{ totalPricePerDose | currency:'BRL':symbol }}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                    media="sm"
                    field="total"
                    title="Total"
                    width="100"
                    class="font-weight-bold"
                    [style]="{'color': '#000', 'font-weight': 'bold'}"
                    [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                    [footerStyle]="{'font-weight': 'bold', 'line-height': '1em', 'color': '#000'}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.total | currency:'BRL':symbol }}
                    </ng-template>
                    <ng-template kendoGridFooterTemplate>
                        {{ totalPrice | currency:'BRL':symbol }}
                    </ng-template>
                </kendo-grid-column>
    
            </kendo-grid>
    
        </ng-template>
    </div>
    
    <div class="col-12 py-5">
        <div class="col-12 bg-light border mb-3">
            <h3 class="p-2 m-0 text-center">Quantidade Doses Necessárias</h3>
        </div>
        <table class="col-12">
            <tr>
                <th>
                    <div class="row col-12 mb-1">
                        <div class="col-6 p-1 border text-center">
                            <label class="p-1 m-0 font-weight-bold">Vacas</label>
                        </div>
                        <div class="col-6 p-1 border text-center">
                            <label class="p-1 m-0 font-weight-bold">{{ quantityNecessaryDosesCows }}</label>
                        </div>
                    </div>
                </th>
            </tr>
            <tr>
                <th>
                    <div class="row col-12 mb-1">
                        <div class="col-6 p-1 border text-center">
                            <label class="p-1 m-0 font-weight-bold">Novilhas</label>
                        </div>
                        <div class="col-6 p-1 border text-center">
                            <label class="p-1 m-0 font-weight-bold">{{ quantityNecessaryDosesHeifers }}</label>
                        </div>
                    </div>
                </th>
            </tr>
            <tr>
                <th>
                    <div class="row col-12 mb-1">
                        <div class="col-6 p-1 border text-center">
                            <label class="p-1 m-0 font-weight-bold">Total</label>
                        </div>     
                        <div class="col-6 p-1 border text-center">
                            <label class="p-1 m-0 font-weight-bold">{{ quantityNecessaryDosesTotal }}</label>
                        </div>
                    </div>
                </th>
            </tr>
        </table>
    </div>

</div>