import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import 'rxjs/add/operator/finally';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, UploadInterceptor } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { IndexComponent } from './index/index.component';
import { AddressComponent } from './address/address.component';
import { EmailComponent } from './email/email.component';
import { PhoneComponent } from './phone/phone.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FarmComponent } from './farm/farm.component';
import { HerdComponent } from './herd/herd.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InterviewComponent } from './interview/interview.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalAccessRequestComponent } from './modal-access-request/modal-access-request.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BasicInformationComponent } from './interview/basic-information/basic-information.component';
import { DataCollectComponent } from './interview/data-collect/data-collect.component';
import { UserComponent } from './user/user.component';
import { ProfileComponent } from './profile/profile.component';
import { SavedInterviewsComponent } from './saved-interviews/saved-interviews.component';
import { ParameterComponent } from './parameter/parameter.component';
import { ByPropertyPipe } from './_pipes/by-property.pipe';
import { ModalBonusProgramComponent } from './interview/modal-bonus-program/modal-bonus-program.component';
import { ModalHerdProblemComponent } from './interview/modal-herd-problem/modal-herd-problem.component';
import { BreedProofSelectionComponent } from './interview/breed-proof-selection/breed-proof-selection.component';
import { ReproductiveManagementComponent } from './interview/reproductive-management/reproductive-management.component';
import { QuantityToInseminateComponent } from './interview/reproductive-management/quantity-to-inseminate/quantity-to-inseminate.component';
import { MaxIatfComponent } from './interview/reproductive-management/max-iatf/max-iatf.component';
import { ReadyToIatfComponent } from './interview/reproductive-management/ready-to-iatf/ready-to-iatf.component';
import { CalvingDistributionComponent } from './interview/reproductive-management/calving-distribution/calving-distribution.component';
import { PregnancyRateComponent } from './interview/reproductive-management/pregnancy-rate/pregnancy-rate.component';
import { DatePipe } from '@angular/common';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { PersonComponent } from './person/person.component';
import { ModalTraitsComponent } from './interview/modal-traits/modal-traits.component';
import { WeightingComponent } from './interview/weighting/weighting.component';
import { FilterComponent } from './interview/filter/filter.component';
import { BullSelectionComponent } from './interview/bull-selection/bull-selection.component';
import { DosesDistributionComponent } from './interview/doses-distribution/doses-distribution.component';
import { SelectResultPresentationComponent } from './interview/select-result-presentation/select-result-presentation.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { HerdFormComponent } from './herd/herd-form/herd-form.component';
import { IatfDistributionComponent } from './interview/reproductive-management/iatf-distribution/iatf-distribution.component';
// tslint:disable-next-line:max-line-length
import { IatfDistributionPerSeasonComponent } from './interview/reproductive-management/iatf-distribution-per-season/iatf-distribution-per-season.component';
// tslint:disable-next-line:max-line-length
import { PregnancyIatfDistributionPerSeasonComponent } from './interview/reproductive-management/pregnancy-iatf-distribution-per-season/pregnancy-iatf-distribution-per-season.component';
import { AveragePregnancyRateComponent } from './interview/reproductive-management/average-pregnancy-rate/average-pregnancy-rate.component';
import { BreedDistributionComponent } from './interview/reproductive-management/breed-distribution/breed-distribution.component';
import { PregnancyDistributionPerBreedComponent } from './interview/reproductive-management/pregnancy-distribution-per-breed/pregnancy-distribution-per-breed.component';
import { OnlyDigitsDirective } from './_directives/only-digits.directive';
import '@progress/kendo-angular-intl/locales/pt/all';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { ManagersComponent } from './managers/managers.component';
import { Configuration } from 'msal';
import { msalConfig, msalAngularConfig, loginRequest } from './_authorization/auth.config';
import { MsalModule, MsalInterceptor, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalService, 
          MsalAngularConfiguration, MsalGuard, BroadcastService } from '@azure/msal-angular';
import { AuthGuardHelper } from './_helpers/auth.guard';
registerLocaleData(pt, 'pt');

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALConfigFactory(): Configuration {
  return msalConfig;
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return msalAngularConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    PageNotFoundComponent,
    IndexComponent,
    AddressComponent,
    EmailComponent,
    PhoneComponent,
    FarmComponent,
    HerdComponent,
    InterviewComponent,
    ModalAccessRequestComponent,
    ForgotPasswordComponent,
    BasicInformationComponent,
    DataCollectComponent,
    UserComponent,
    ProfileComponent,
    SavedInterviewsComponent,
    ParameterComponent,
    ByPropertyPipe,
    ModalBonusProgramComponent,
    ModalHerdProblemComponent,
    BreedProofSelectionComponent,
    ReproductiveManagementComponent,
    QuantityToInseminateComponent,
    MaxIatfComponent,
    ReadyToIatfComponent,
    CalvingDistributionComponent,
    PregnancyRateComponent,
    PersonComponent,
    ModalTraitsComponent,
    WeightingComponent,
    FilterComponent,
    BullSelectionComponent,
    DosesDistributionComponent,
    SelectResultPresentationComponent,
    HerdFormComponent,
    IatfDistributionComponent,
    IatfDistributionPerSeasonComponent,
    PregnancyIatfDistributionPerSeasonComponent,
    AveragePregnancyRateComponent,
    BreedDistributionComponent,
    PregnancyDistributionPerBreedComponent,
    OnlyDigitsDirective,
    ManagersComponent
  ],
  entryComponents: [
    ModalAccessRequestComponent,
    ForgotPasswordComponent,
    ModalBonusProgramComponent,
    ModalHerdProblemComponent,
    ModalTraitsComponent
  ],
  imports: [
    MsalModule,
    BrowserModule,
    HttpClientModule,
    FontAwesomeModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    GridModule,
    DropDownsModule,
    NgbModalModule,
    NotificationModule,
    DialogModule,
    LayoutModule,
    ChartsModule,
    RouterModule
  ],
  providers: [
    DatePipe,
    { 
      provide: LOCALE_ID, useValue: 'pt' 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService,
    AuthGuardHelper,
    BroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
