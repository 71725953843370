import { Trait } from './trait';

export class TraitGroup {

    public id: number;

    public name: string;

    public trait: Array<Trait>;

    public checked: boolean;

    deserialize(input: any): this {
      Object.assign(this, input);

      this.trait = this.trait ? input.trait.map((trait: Trait) => new Trait().deserialize(trait)) : this.trait;

      return this;
    }
}
