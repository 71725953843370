<h3 class="p-2 mb-2">10 - Distribuição das prenhezes de {{breed}} de IATF por época</h3>
<div class="row">
  <div class="col-12">
    <div class="form-row">
      <div class="form-group">
        <table class="table borderless">
          <thead>
            <tr>
              <th></th>
              <th>
                <label class="p-2 m-0">Início EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Meio EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Final EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Total</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <label class="p-2 m-0">Novilhas</label>
              </th>
              <td>
                <input class="form-control text-right" type="text" placeholder="0" disabled [value]="pregnancyDistributionHeifersEM1" name="pregnancyDistributionHeifersEM1" />
              </td>
              <td>
                <input class="form-control text-right" type="text" placeholder="0" disabled [value]="pregnancyDistributionHeifersEM2" name="pregnancyDistributionHeifersEM2" />
              </td>
              <td>
                <input class="form-control text-right" type="text" placeholder="0" disabled [value]="pregnancyDistributionHeifersEM3" name="pregnancyDistributionHeifersEM3" />
              </td>
              <td>
                <input class="form-control text-right font-weight-bold" type="text" placeholder="0" disabled [value]="pregnancyDistributionHeifersTotal" name="pregnancyDistributionHeifersTotal" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0">Vacas</label>
              </th>
              <td>
                <input class="form-control text-right" type="text" placeholder="0" disabled [value]="pregnancyDistributionCowsEM1" name="pregnancyDistributionCowsEM1" />
              </td>
              <td>
                <input class="form-control text-right" type="text" placeholder="0" disabled [value]="pregnancyDistributionCowsEM2" name="pregnancyDistributionCowsEM2" />
              </td>
              <td>
                <input class="form-control text-right" type="text" placeholder="0" disabled [value]="pregnancyDistributionCowsEM3" name="pregnancyDistributionCowsEM3" />
              </td>
              <td>
                <input class="form-control text-right font-weight-bold" type="text" placeholder="0" disabled [value]="pregnancyDistributionCowsTotal" name="pregnancyDistributionCowsTotal" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0"></label>
              </th>
              <td>
                <input class="form-control text-right font-weight-bold" type="text" placeholder="0" disabled [value]="pregnancyDistributionEM1Total" name="pregnancyDistributionEM1Total" />
              </td>
              <td>
                <input class="form-control text-right font-weight-bold" type="text" placeholder="0" disabled [value]="pregnancyDistributionEM2Total" name="pregnancyDistributionEM2Total" />
              </td>
              <td>
                <input class="form-control text-right font-weight-bold" type="text" placeholder="0" disabled [value]="pregnancyDistributionEM3Total" name="pregnancyDistributionEM3Total" />
              </td>
              <td>
                <input class="form-control text-right font-weight-bold" type="text" placeholder="0" disabled [value]="pregnancyDistributionTotal" name="pregnancyDistributionTotal" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
