<h3 class="p-2 mb-2">8 - Distribuição das prenhezes de IATF por época</h3>
<div class="row">
  <div class="col-12">
    <div class="form-row">
      <div class="form-group">
        <table class="table borderless">
          <thead>
            <tr>
              <th></th>
              <th>
                <label class="p-2 m-0">Início EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Meio EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Final EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Total</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <label class="p-2 m-0">Novilhas</label>
              </th>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonHeifersBeginningEM" 
                  name="pregnancyIatfDistributionPerSeasonHeifersBeginningEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonHeifersMiddleEM" 
                  name="pregnancyIatfDistributionPerSeasonHeifersMiddleEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonHeifersEndEM" 
                  name="pregnancyIatfDistributionPerSeasonHeifersEndEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonHeifersTotal" 
                  name="pregnancyIatfDistributionPerSeasonHeifersTotal" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0">Vacas</label>
              </th>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonCowsBeginningEM" 
                  name="pregnancyIatfDistributionPerSeasonCowsBeginningEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonCowsMiddleEM" 
                  name="pregnancyIatfDistributionPerSeasonCowsMiddleEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonCowsEndEM" 
                  name="pregnancyIatfDistributionPerSeasonCowsEndEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonCowsTotal" 
                  name="pregnancyIatfDistributionPerSeasonCowsTotal" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0"></label>
              </th>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonTotalBeginningEM" 
                  name="pregnancyIatfDistributionPerSeasonTotalBeginningEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonTotalMiddleEM" 
                  name="pregnancyIatfDistributionPerSeasonTotalMiddleEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonTotalEndEM" 
                  name="pregnancyIatfDistributionPerSeasonTotalEndEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="pregnancyIatfDistributionPerSeasonTotal" 
                  name="pregnancyIatfDistributionPerSeasonTotal" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
