export class BullAttendedFilter {

  public bullId: number;

  public column: string;

  constructor () {
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
