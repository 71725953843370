import { Suggestion } from 'src/_models/suggestion';
import { Interview } from 'src/_models/interview';
import { Question } from 'src/_models/question';

export class InterviewSuggestions {

  private questionsMap: Map<number, Function>;
  private index: number;

  constructor () {
    this.questionsMap = new Map<number, Function>();
    this.questionsMap.set(0, origin);
    this.questionsMap.set(1, breedOptionsMatting);
    this.questionsMap.set(2, maxZebuine);
    this.questionsMap.set(3, maxTaurine);
    this.questionsMap.set(4, herdGoalMale);
    this.questionsMap.set(5, confinementTypeMale);
    this.questionsMap.set(6, usesCastration);
    this.questionsMap.set(7, herdGoalFemale);
    this.questionsMap.set(8, confinementTypeFemale);

  }

  public validateSuggestions(suggestions: Array<Suggestion>, interview: Interview, breedGoalIndex: number): string[] {
    const breeds = new Array<string>();
    let isQuestionValid = true;
    let isSuggestionValid = true;
    this.index = breedGoalIndex;
    console.info(breedGoalIndex);

    // PERCORRE AS SUGESTOES VALIDANDO AS QUESTOES ENQUANTO O RETORNO FOR NEGATIVO
    // OU SEJA, ENQUANTO NAO ENCONTRAR UMA SUGESTAO 100% VALIDA, NAO PARA DE PROCURAR
    suggestions.every(suggestion => {

      // PERCORRE AS QUESTOES ENQUANTO O RETORNO FOR POSITIVO
      // OU SEJA, SE ENCONTRAR UMA QUESTAO QUE NAO E VALIDA, PASSA PARA A PROXIMA SUGESTAO
      suggestion.questions.every(question => {
        console.group(this.questionsMap.get(question.question).name);
        isQuestionValid = this.questionsMap.get(question.question)(interview, question, this.index);
        console.groupEnd();
        return isQuestionValid;
      });
      if (isQuestionValid) {
        breeds.push(...suggestion.suggestedBreeds.map(s => s.name));
        isSuggestionValid = true;
        // console.log('suggestion: ', suggestion);
      }
      return isSuggestionValid;
    });
    return breeds;
  }
}

function origin(interview: Interview, question: Question, index: number): boolean  {
  console.log('interview.femaleOrigin === Number(question.value): ', interview.femaleOrigin === Number(question.value));
  return Number(interview.femaleOrigin) === Number(question.value);
}

function breedOptionsMatting(interview: Interview, question: Question, index: number): boolean  {
  console.log('interview.breedOptionsMatting === Number(question.value): ', interview.breedOptionsMatting === Number(question.value));
  return Number(interview.breedOptionsMatting) === Number(question.value);
}

function maxZebuine(interview: Interview, question: Question, index: number): boolean  {
  const signal = returnSignal(question.signal);
  let sentence = interview.interviewBreedGoal[index].maxZebu + ' ' + signal + ' ' + question.value;
  if (question.value2 && Number(question.value2) > 0) {
    const signal2 = returnSignal(question.signal2);
    sentence += ' && ' + interview.interviewBreedGoal[index].maxZebu + ' ' + signal2 + ' ' + question.value2;
  }
  console.log('sentence: ', sentence);
  // tslint:disable-next-line:no-eval
  return eval(sentence);
}

function maxTaurine(interview: Interview, question: Question, index: number): boolean  {
  console.log('maxTaurine');
  const signal = returnSignal(question.signal);
  let sentence = interview.interviewBreedGoal[index].maxTaurean + ' ' + signal + ' ' + question.value;
  if (question.value2 && Number(question.value2) > 0) {
    const signal2 = returnSignal(question.signal2);
    sentence += ' && ' + interview.interviewBreedGoal[index].maxTaurean + ' ' + signal2 + ' ' + question.value2;
  }
  console.log('sentence: ', sentence);
  // tslint:disable-next-line:no-eval
  return eval(sentence);
}

function herdGoalMale(interview: Interview, question: Question, index: number): boolean  {
  console.log('interview.interviewBreedGoal[', index, '].sellingGoalsMale === Number(question.value): ', interview.interviewBreedGoal[index].sellingGoalsMale === Number(question.value));
  // tslint:disable-next-line:triple-equals
  if (Number(interview.interviewBreedGoal[index].sellingGoalsMale) === 3) {
    return true;
  }
  return Number(interview.interviewBreedGoal[index].sellingGoalsMale) === Number(question.value);
}

function confinementTypeMale(interview: Interview, question: Question, index: number): boolean  {
  console.log('interview.interviewBreedGoal[', index, '].confinementTypeMale === Number(question.value): ', interview.interviewBreedGoal[index].confinementTypeMale === Number(question.value));
  // tslint:disable-next-line:triple-equals
  if (Number(interview.interviewBreedGoal[index].sellingGoalsMale) === 3) {
    return true;
  }
  if ((Number(interview.interviewBreedGoal[index].confinementTypeMale) === 1 ||
      Number(interview.interviewBreedGoal[index].confinementTypeMale) === 2) &&
      Number(question.value) === 4) {
    return true;
  }
  return Number(interview.interviewBreedGoal[index].confinementTypeMale) === Number(question.value);
}

function usesCastration(interview: Interview, question: Question, index: number): boolean  {
  console.log('interview.interviewBreedGoal[', index, '].usesCastration === Number(question.value): ', interview.interviewBreedGoal[index].usesCastration === Number(question.value));
  // tslint:disable-next-line:triple-equals
  if (Number(interview.interviewBreedGoal[index].sellingGoalsMale) === 3) {
    return true;
  }
  return Number(interview.interviewBreedGoal[index].usesCastration) === Number(question.value);
}

function herdGoalFemale(interview: Interview, question: Question, index: number): boolean  {
  console.log('interview.interviewBreedGoal[', index, '].sellingGoalsFemale === Number(question.value): ', interview.interviewBreedGoal[index].sellingGoalsFemale === Number(question.value));
  // tslint:disable-next-line:triple-equals
  if (Number(interview.interviewBreedGoal[index].sellingGoalsFemale) !== 3
      && Number(interview.interviewBreedGoal[index].sellingGoalsFemale) > 0) {
    return true;
  }
  return Number(interview.interviewBreedGoal[index].sellingGoalsFemale) === Number(question.value);
}

function confinementTypeFemale(interview: Interview, question: Question, index: number): boolean  {
  console.log('interview.interviewBreedGoal[', index, '].confinementTypeFemale === Number(question.value): ', interview.interviewBreedGoal[index].confinementTypeFemale === Number(question.value));
  // tslint:disable-next-line:triple-equals
  if (Number(interview.interviewBreedGoal[index].sellingGoalsFemale) !== 3
      && Number(interview.interviewBreedGoal[index].sellingGoalsFemale) > 0) {
    return true;
  }
  return Number(interview.interviewBreedGoal[index].confinementTypeFemale) === Number(question.value);
}

function returnSignal(index: number) {
  switch (index) {
    case 1:
      return '==';
    case 2:
      return '<';
    case 3:
      return '>';
    case 4:
      return '<=';
    case 5:
      return '>=';
    default:
      return '==';
  }
}

