import { Breed } from './breed';
import { Question } from './question';
import { BreedSelection } from './breed-selection';

export class Suggestion {

  public id: number;

  public description: string;

  public questions: Array<Question>;

  public baseBreeds: Array<Breed>;

  public suggestedBreeds: Array<BreedSelection>;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.questions = this.questions ? input.farm.map((questions: Question) => new Question().deserialize(questions)) : this.questions;
    this.baseBreeds = this.baseBreeds ? input.farm.map((baseBreeds: Breed) => new Breed().deserialize(baseBreeds)) : this.baseBreeds;
    this.suggestedBreeds = this.suggestedBreeds ? input.suggestedBreeds.map(
      (suggestedBreeds: BreedSelection) => new BreedSelection().deserialize(suggestedBreeds)) : this.suggestedBreeds;

    return this;
  }

}
