
  <h3 class="p-2 mb-2">1 - Quantidades</h3>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <div class="form-group row w-100">
          <label class="col-form-label col-12 col-sm-9 p-2" for="nbrOfHeifersToInseminate">Quantidade de novilhas a
            inseminar</label>
          <div class="col-12 col-sm-3 p-1">
            <input type="text" [(ngModel)]="reproductiveManagement.nbrOfHeifersToInseminate" appOnlyDigits
              class="form-control text-right" id="nbrOfHeifersToInseminate" placeholder="0" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-row">
        <div class="form-group row w-100">
          <label class="col-form-label col-12 col-sm-9 p-2" for="nbrOfCowsToInseminate">Quantidade de vacas a
            inseminar</label>
          <div class="col-12 col-sm-3 p-1">
            <input type="text" [(ngModel)]="reproductiveManagement.nbrOfCowsToInseminate" appOnlyDigits
              class="form-control text-right" id="nbrOfCowsToInseminate" placeholder="0" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-row">
        <div class="form-group row w-100">
          <label class="col-form-label col-12 col-sm-9 p-2" for="nbrTotalToInsaminate">Quantidade de matrizes a
            inseminar</label>
          <div class="col-12 col-sm-3 p-1">
            <input type="text" disabled [value]="nbrTotalToInsaminate" appOnlyDigits
              class="form-control text-right font-weight-bold"
              id="nbrTotalToInsaminate" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>
  </div>
