import { Component, OnInit, Input } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import { ReproductiveManagementService } from '@app/_services/reproductive-management.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-average-pregnancy-rate]',
  templateUrl: './average-pregnancy-rate.component.html',
  styleUrls: ['./average-pregnancy-rate.component.scss']
})
export class AveragePregnancyRateComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;

  constructor(public reproductiveManagementService: ReproductiveManagementService) { }

  ngOnInit() {
  }

  get pregnancyRateHeifers () {
    this.reproductiveManagement.averagePregnancyRateHeifers =
      (this.reproductiveManagementService.pregnancyIatfDistributionPerSeasonHeifersTotal / this.reproductiveManagement.nbrOfHeifersToInseminate) * 100 || 0;
    return this.reproductiveManagement.averagePregnancyRateHeifers.toFixed(2);
  }

  get pregnancyRateCows () {
    this.reproductiveManagement.averagePregnancyRateCows =
      (this.reproductiveManagementService.pregnancyIatfDistributionPerSeasonCowsTotal / this.reproductiveManagement.nbrOfCowsToInseminate) * 100 || 0;
    return this.reproductiveManagement.averagePregnancyRateCows.toFixed(2);
  }

  get averagePregnancyRate () {
    this.reproductiveManagement.averagePregnancyRateAverage =
      (this.reproductiveManagementService.pregnancyIatfDistributionPerSeasonTotal / this.reproductiveManagementService.iatfDistributionPerSeasonTotalTotal) * 100 || 0;
    return this.reproductiveManagement.averagePregnancyRateAverage.toFixed(2);
  }

  get pregnancyRateTotal () {
    const totalToInseminate = Number(this.reproductiveManagement.nbrOfHeifersToInseminate || 0) + Number(this.reproductiveManagement.nbrOfCowsToInseminate || 0);
    this.reproductiveManagement.averagePregnancyRateTotal =
      (this.reproductiveManagementService.pregnancyIatfDistributionPerSeasonTotal / totalToInseminate) * 100 || 0;
    return this.reproductiveManagement.averagePregnancyRateTotal.toFixed(2);
  }

}
