import { Component, Injectable, OnInit, ViewEncapsulation } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { b2cPolicies, isIE } from './_authorization/auth.config';
import { CryptoUtils, Logger } from 'msal';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, concat, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  constructor(private broadcastService: BroadcastService, private authService: MsalService) {
    this.broadcastService.subscribe('msal:loginSuccess', (success) => {
      console.log(success.idToken.claims);
  
      // We need to reject id tokens that were not issued with the default sign-in policy.
      // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
      // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
        // if (success.idToken.claims['acr'] !== b2cPolicies.names.signUpSignIn) {
        //   window.alert("Password has been reset successfully. \nPlease sign-in with your new password");
        //   return this.authService.logout()
        // }
  
        // console.log('login succeeded. id token acquired at: ' + new Date().toString());
        // console.log(success);
      });
  
    this.broadcastService.subscribe('msal:loginFailure', (error) => {
        console.log('login failed');
        console.log(error);
  
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
            if (isIE) {
              this.authService.loginRedirect(b2cPolicies.authorities.resetPassword);
            } else {
              this.authService.loginPopup(b2cPolicies.authorities.resetPassword);
            }
          }
      });
  
      // redirect callback for redirect flow (IE)
    this.authService.handleRedirectCallback((authError, response) => {
        if (authError) {
          console.error('Redirect Error: ', authError.errorMessage);
          return;
        }
  
        console.log('Redirect Success: ', response);
      });
  
    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
        console.log('MSAL Logging: ', message);
      }, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false
      }));
    }
  }
  
  @Injectable()
  export class UploadInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (req.url === 'uploadSaveUrl') {
        const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
          type: HttpEventType.UploadProgress,
          loaded: x,
          total: 100
        }).pipe(delay(1000)));
  
        const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
        events.push(success);
  
        return concat(...events);
      }
  
      if (req.url === 'uploadRemoveUrl') {
          return of(new HttpResponse({ status: 200 }));
      }
  
      return next.handle(req);
    }
  }

//   @Injectable()
//   export class UploadInterceptor implements HttpInterceptor {
//     intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
//       if (req.url === 'removeUrl') {
//           return of(new HttpResponse({ status: 200 }));
//       }
  
//       return next.handle(req);
//     }
// }
