import { Component, OnInit, Input } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import { ReproductiveManagementService } from '@app/_services/reproductive-management.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-pregnancy-iatf-distribution-per-season]',
  templateUrl: './pregnancy-iatf-distribution-per-season.component.html',
  styleUrls: ['./pregnancy-iatf-distribution-per-season.component.scss']
})
export class PregnancyIatfDistributionPerSeasonComponent implements OnInit {

  @Input() rm: ReproductiveManagement;

  constructor(public rmService: ReproductiveManagementService) { }

  ngOnInit() {
  }

  // PREGNANCY IATF DISTRIBUTION PER SEASON - HEIFERS
  get pregnancyIatfDistributionPerSeasonHeifersBeginningEM () {
    this.rm.pregnancyIatfDistributionPerSeasonHeifersBeginningEM = this.rmService.pregnancyIatfDistributionPerSeasonHeifersBeginningEM;
    return this.rm.pregnancyIatfDistributionPerSeasonHeifersBeginningEM;
  }
  get pregnancyIatfDistributionPerSeasonHeifersMiddleEM () {
    this.rm.pregnancyIatfDistributionPerSeasonHeifersMiddleEM = this.rmService.pregnancyIatfDistributionPerSeasonHeifersMiddleEM;
    return this.rm.pregnancyIatfDistributionPerSeasonHeifersMiddleEM;
  }
  get pregnancyIatfDistributionPerSeasonHeifersEndEM () {
    this.rm.pregnancyIatfDistributionPerSeasonHeifersEndEM = this.rmService.pregnancyIatfDistributionPerSeasonHeifersEndEM;
    return this.rm.pregnancyIatfDistributionPerSeasonHeifersEndEM;
  }

  // PREGNANCY IATF DISTRIBUTION PER SEASON - COWS
  get pregnancyIatfDistributionPerSeasonCowsBeginningEM () {
    this.rm.pregnancyIatfDistributionPerSeasonCowsBeginningEM = this.rmService.pregnancyIatfDistributionPerSeasonCowsBeginningEM;
    return this.rm.pregnancyIatfDistributionPerSeasonCowsBeginningEM;
  }
  get pregnancyIatfDistributionPerSeasonCowsMiddleEM () {
    this.rm.pregnancyIatfDistributionPerSeasonCowsMiddleEM = this.rmService.pregnancyIatfDistributionPerSeasonCowsMiddleEM;
    return this.rm.pregnancyIatfDistributionPerSeasonCowsMiddleEM;
  }
  get pregnancyIatfDistributionPerSeasonCowsEndEM () {
    this.rm.pregnancyIatfDistributionPerSeasonCowsEndEM = this.rmService.pregnancyIatfDistributionPerSeasonCowsEndEM;;
    return this.rm.pregnancyIatfDistributionPerSeasonCowsEndEM;
  }

  // PREGNANCY IATF DISTRIBUTION PER SEASON - COL TOTAL
  get pregnancyIatfDistributionPerSeasonTotalBeginningEM() {
    this.rm.pregnancyIatfDistributionPerSeasonTotalBeginningEM = this.rmService.pregnancyIatfDistributionPerSeasonTotalBeginningEM;
    return this.rm.pregnancyIatfDistributionPerSeasonTotalBeginningEM;
  }
  get pregnancyIatfDistributionPerSeasonTotalMiddleEM() {
    this.rm.pregnancyIatfDistributionPerSeasonTotalMiddleEM = this.rmService.pregnancyIatfDistributionPerSeasonTotalMiddleEM;
    return this.rm.pregnancyIatfDistributionPerSeasonTotalMiddleEM;
  }
  get pregnancyIatfDistributionPerSeasonTotalEndEM() {
    this.rm.pregnancyIatfDistributionPerSeasonTotalEndEM = this.rmService.pregnancyIatfDistributionPerSeasonTotalEndEM;
    return this.rm.pregnancyIatfDistributionPerSeasonTotalEndEM;
  }

  // // PREGNANCY IATF DISTRIBUTION PER SEASON - ROW TOTAL
  get pregnancyIatfDistributionPerSeasonHeifersTotal() {
    this.rm.pregnancyIatfDistributionPerSeasonHeifersTotal = this.rmService.pregnancyIatfDistributionPerSeasonHeifersTotal;
    return this.rm.pregnancyIatfDistributionPerSeasonHeifersTotal;
  }
  get pregnancyIatfDistributionPerSeasonCowsTotal() {
    this.rm.pregnancyIatfDistributionPerSeasonCowsTotal = this.rmService.pregnancyIatfDistributionPerSeasonCowsTotal;
    return this.rm.pregnancyIatfDistributionPerSeasonCowsTotal;
  }
  get pregnancyIatfDistributionPerSeasonTotal() {
    this.rm.pregnancyIatfDistributionPerSeasonTotal = this.rmService.pregnancyIatfDistributionPerSeasonTotal;
    return this.rm.pregnancyIatfDistributionPerSeasonTotal;
  }

}
