export class BonusProgramm {

  public id: number;

  public description: string;

  public checked: boolean;

  public active: boolean;

  public otherProgram: string;

  public static getPrograms (): Array<BonusProgramm> {
    return listPrograms(); // BUSCAR NO SERVICE
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

function listPrograms(): Array<BonusProgramm> {
  const programs = <Array<BonusProgramm>> [
    {id : 1, description : 'Angus / Brangus', checked : false, active : true },
    {id : 2, description : 'Boi no ponto', checked : false, active : true },
    {id : 3, description : 'Bonsmara', checked : false, active : true },
    {id : 4, description : 'Britânicas', checked : false, active : true },
    {id : 5, description : 'Charolês Beef', checked : false, active : true },
    {id : 6, description : 'Cota Hilton', checked : false, active : true },
    {id : 7, description : 'Hereford / Braford', checked : false, active : true },
    {id : 8, description : 'Novilho Precoce', checked : false, active : true },
    {id : 9, description : 'Rubia Galega', checked : false, active : true },
    {id : 10, description : 'Wagyu', checked : false, active : true },
    {id : 11, description : 'Outro', checked : false, active : true }
  ];
  return programs;
}
