<div>
    <h2 class="text-center my-4">Escolha de raça(s) e avaliação(ões)</h2>
    <div class="form-row border rounded p-4 bg-white">
        <div class="form-row col-12">
            <div class="alert alert-primary w-100">
              <b class="d-inline-flex w-25">Raça base do rebanho: </b>
              <span class="d-inline-flex w-75" *ngIf="interview.herd.breed">{{ interview.herd.breed.name }}</span>
              <!-- <span class="d-inline-flex w-75" *ngIf="interview.herd.breed">{{primaryBreedSuggestion.join(', ')}}</span> -->
            </div>
            <div class="col-12" *ngIf="interview.breedOptions != 1 || isCrossedBaseBreed()">
                <p class="font-weight-bold">Raça primária</p>
                <div *ngIf="primaryBreedSuggestion.length > 0"  class="alert alert-success w-100">
                  <b class="d-inline-flex w-25">Sugestão de raça primária: </b>
                  <span class="d-inline-flex w-75">{{primaryBreedSuggestion.join(', ')}}</span>
                </div>
            </div>
            <div class="form-group col-12 col-md-6 col-lg-3">
                <label for="breedTypePrimary" class="font-weight-bold">Categoria</label>
                <select id="breedTypePrimary" name="breedTypePrimary" class="form-control" (change)="changePrimaryBreedType($event.target.value)">
                    <option [value]="null" disabled>Selecione...</option>
                    <option *ngFor="let u of breedTypeList" [value]="u.breedTypeId" >{{u.breedTypeName}}</option>
                </select>
            </div>
            <div class="form-group col-12 col-md-6 col-lg-3">
                <label for="breedPrimary" class="font-weight-bold">Raça</label>
                <select id="breedPrimary" name="breedPrimary" class="form-control" (change)="changePrimaryBreed($event.target.value)">
                    <option [value]="null">Selecione...</option>
                    <option *ngFor="let b of primaryBreedsList" [value]="b.id">{{b.name}}</option>
                </select>
            </div>
            <div class="form-group col-12 col-lg-6">
                <label for="proofPrimary" class="font-weight-bold">Prova</label>
                <select id="proofPrimary" name="proofPrimary" class="form-control" (change)="changeBreedProof($event.target.value, 0)">
                  <option [value]="null" selected disabled>Selecione...</option>
                  <option *ngFor="let b of primaryProofList" [value]="b.proofId">{{b.proofName}}</option>
                </select>
                <!-- <kendo-multiselect id="proofPrimary" name="proofPrimary"
                    class="form-control"
                    [data]="primaryProofList"
                    [autoClose]="false"
                    (valueChange)="changePrimaryBreedProof($event)"
                    [textField]="'proofName'"
                    [valueField]="'proofId'"
                    placeholder="Selecione...">
                    <ng-template kendoMultiSelectNoDataTemplate>
                        <p>Selecione uma raça...</p>
                    </ng-template>
                </kendo-multiselect> -->
                <small class="text-danger" *ngIf="!interview.interviewCycle || interview.interviewCycle.length == 0">Informe a(s) prova(s)</small>
            </div>
        </div>

        <hr class="w-100 divider mb-4" *ngIf="interview.breedOptions === 2" />

        <div class="form-row col-12" *ngIf="interview.breedOptions === 2" >
            <div class="col-12">
                <p class="font-weight-bold">Raça secundária</p>
                <div *ngIf="secondaryBreedSuggestion.length > 0" class="alert alert-warning w-100">
                  <b class="d-inline-flex w-25">Sugestão de raça secundária: </b>
                  <span class="d-inline-flex w-75">{{secondaryBreedSuggestion.join(', ')}}</span>
                </div>
            </div>
            <div class="form-group col-3">
                <label for="breedTypeSecondary" class="font-weight-bold">Categoria</label>
                <select id="breedTypeSecondary" name="breedTypeSecondary" class="form-control" (change)="changeSecondaryBreedType($event.target.value)">
                    <option [value]="null" selected disabled>Selecione...</option>
                    <option *ngFor="let u of breedTypeList" [value]="u.breedTypeId" >{{u.breedTypeName}}</option>
                </select>
            </div>
            <div class="form-group col-3">
                <label for="breedSecondary" class="font-weight-bold">Raça</label>
                <select id="breedSecondary" name="breedSecondary" class="form-control" (change)="changeSecondaryBreed($event.target.value)">
                    <option [value]="null" selected>Selecione...</option>
                    <option *ngFor="let b of secondaryBreedsList" [value]="b.id">{{b.name}}</option>
                </select>
            </div>
            <div class="form-group col-6">
                <label for="proofSecondary" class="font-weight-bold">Prova</label>
                <select id="proofSecondary" name="proofSecondary" class="form-control" (change)="changeBreedProof($event.target.value, 1)">
                  <option [value]="null" selected disabled>Selecione...</option>
                  <option *ngFor="let b of secondaryProofList" [value]="b.proofId">{{b.proofName}}</option>
                </select>
                <!-- <kendo-multiselect id="proofSecondary" name="proofSecondary"
                    class="form-control"
                    style="border: 1px solid #ced4da; border-radius: 0.25rem;"
                    [data]="secondaryProofList"
                    [autoClose]="false"
                    (valueChange)="changeSecondaryBreedProof($event, 2)"
                    [textField]="'proofName'"
                    [valueField]="'proofId'"
                    placeholder="Selecione...">
                    <ng-template kendoMultiSelectNoDataTemplate>
                        <p>Selecione uma raça...</p>
                    </ng-template>
                </kendo-multiselect> -->
                <small class="text-danger" *ngIf="!interview.interviewCycle || interview.interviewCycle.length == 0">Informe a(s) prova(s)</small>
            </div>
        </div>
    </div>
</div>
