<h2 class="text-center my-4">Classificação e seleção de touros - {{interviewCycle.breedName}} ({{interviewCycle.proofName}})</h2>
<div class="border rounded p-4 bg-white col-12">
    <ng-template [ngIf]="bulls.length > 0 && isBullsLoaded" [ngIfElse]="listEmpty">
        <kendo-grid
            [kendoGridBinding]="bulls"
            [data]="gridView"
            [sortable]="{allowUnsort: 'allowUnsort', mode: 'multiple'}"
            [sort]="bullSort"
            (sortChange)="bullSortChange($event)"
            [selectable]="selectableSettings"
            [rowSelected]="isRowSelected"
            [kendoGridSelectBy]="selectedCallback"
            [selectedKeys]="gridSelection"
            (selectedKeysChange)="onSelectedKeysChange()"
            (cellClick)="cellClickHandler($event)"
            style="max-height: calc(100% - 90px);"
            [height]="600">
            <kendo-grid-column
                media="xs"
                title="Classificação e seleção de touros"
                width="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <input type="checkbox"/>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <dl>
                        <dt>Touro</dt>
                        <dd>{{ dataItem.shortName }}</dd>

                        <dt>Registro</dt>
                        <dd>{{ dataItem.regNumber }}</dd>

                        <dt>Índice</dt>
                        <dd>{{ dataItem.index }}</dd>

                        <dt>Estoque</dt>
                        <dd>{{ dataItem.doses }}</dd>

                        <dt>Filtro(s)</dt>
                        <dd>{{ dataItem.filters }}</dd>

                        <dt>ConceptPlus</dt>
                        <dd>{{ dataItem.conceptPlus }}</dd>

                        <dt>Raça</dt>
                        <dd>{{ dataItem.breed.name }}</dd>

                        <dt>Selo (PN)</dt>
                        <dd>{{ dataItem.calvingEase }}</dd>

                        <ng-template *ngFor="let c of columns">
                            <dt>{{ c.title }}</dt>
                            <dd>{{ c.field }}</dd>
                        </ng-template>
                    </dl>
                </ng-template>
            </kendo-grid-column>

            <!-- columns for mid-size devices -->
            <kendo-grid-checkbox-column
                width="110"
                media="sm"
                class="text-center"
                [locked]="true"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                [footerStyle]="{'font-weight': 'bold','color': '#000', 'line-height': '1em'}">
                <ng-template kendoGridFooterTemplate>Total: {{ bulls.length }}</ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column
                media="sm"
                field="shortName"
                title="Touro"
                [locked]="true"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                width="250">
            </kendo-grid-column>
            <kendo-grid-column
                media="sm"
                field="regNumber"
                title="Registro"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                width="130">
            </kendo-grid-column>
            <kendo-grid-column
                media="sm"
                field="index"
                title="Índice"
                *ngIf="isColumnHidden()"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                width="100">
            </kendo-grid-column>
            <kendo-grid-column
                media="sm"
                field="doses"
                title="Estoque"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                width="120">
            </kendo-grid-column>
            <kendo-grid-column
                media="sm"
                field="filters"
                title="Filtro(s)"
                *ngIf="isColumnHidden()"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                width="110">
            </kendo-grid-column>
            <kendo-grid-column
                media="sm"
                field="conceptPlus"
                title="ConceptPlus"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                width="140">
            </kendo-grid-column>
            <!-- <kendo-grid-column
                media="sm"
                *ngFor="let c of columns"
                field="{{ c.field }}"
                title="{{ c.title }}"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                width="150">
            </kendo-grid-column> -->
            <kendo-grid-column
                media="sm"
                *ngFor="let c of columns"
                field="{{ c.field }}"
                title="{{ c.title }}"
                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                width="120">
                <ng-template *ngIf="isFilterColumn(c.title)" kendoGridCellTemplate let-dataItem>
                    <span
                        class="whole-cell"
                        [style.backgroundColor]="highlightGridCell(c.title, dataItem.id, dataItem[c.field])"
                        [style.color]="gridTextCellColor()">
                        {{ dataItem[c.field] }}
                    </span>
                </ng-template>
            </kendo-grid-column>

        </kendo-grid>

    </ng-template>

    <div *ngIf="!isBullsLoaded" class="text-center">
        <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
        <p class="text-center w-100">Buscando touros...</p>
    </div>

    <ng-template #listEmpty>
        <div *ngIf="isBullsLoaded" class="d-flex align-items-center" style="height: calc(100% - 90px);">
            <p class="text-center w-100">Nenhum touro encontrado.</p>
        </div>
    </ng-template>

    <div class="border rounded p-4 bg-white col-12 mt-2">
        <div class="row px-2">
            <div class="col-6 m-0 p-2 text-left">
                <div class="custom-control custom-checkbox">
                    <input #removeBullsWithoutInventory
                        class="form-check-input"
                        type="checkbox"
                        id="removeBullsWithoutInventory"
                        checked="true"
                        (change)="removeBulls($event.target.checked, $event.target.id)">
                    <label class="form-check-label font-weight-bold large">Remover touros que não tem estoque</label>
                </div>
                <div class="custom-control custom-checkbox">
                    <input #removeBullsNotAttendedFilters
                        class="form-check-input"
                        type="checkbox"
                        checked="true"
                        id="removeBullsNotAttendedFilters"
                        (change)="removeBulls($event.target.checked, $event.target.id)">
                    <label class="form-check-label font-weight-bold large">Remover touros que não atenderam aos filtros</label>
                </div>
            </div>

            <div class="col-4 m-0 p-2 text-right">
                <button class="btn btn-submit mr-3" (click)="exportSelectedBulls()">Exportar selecionados</button>
                <button class="btn btn-submit" (click)="exportAllBulls()" >Exportar todos</button>
            </div>

            <div class="col-2 m-0 p-2 text-right">
                <button class="btn btn-submit" type="submit" (click)="compareBulls($event)">Comparar Touros</button>
            </div>
        </div>
    </div>

    <div kendoDialogContainer></div>
</div>
