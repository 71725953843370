import { FilterQuestion } from './filter-question';
import { FilterSuggestionHasTrait } from './filter-suggestion-has-trait';

export class FilterSuggestion {

    public id: number;

    public description: string;

    public filterSuggestionHasTrait: Array<FilterSuggestionHasTrait>;

    public filterQuestion: Array<FilterQuestion>;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.filterQuestion =
            this.filterQuestion ?
                input.filterQuestion
                .map((w: FilterQuestion) => new FilterQuestion()
                .deserialize(w)) : this.filterQuestion;

        this.filterSuggestionHasTrait =
            this.filterSuggestionHasTrait ?
                input.filterSuggestionHasTrait
                .map((w: FilterSuggestionHasTrait) => new FilterSuggestionHasTrait()
                .deserialize(w)) : this.filterSuggestionHasTrait;

        return this;
    }
}
