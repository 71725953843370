import { Breed } from './breed';

export class Herd {

  public id: number;

  public name: string;

  public numberOfCows: number;

  public numberOfHeifers: number;

  public goals: string;

  public farmId: number;

  public breedId: number;

  public breed: Breed;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
