<div class="border rounded p-4 mb-4 bg-light">
  <div>
    <h4 class="d-inline-block mb-2">Rebanho</h4>
    <fa-icon (click)="AddHerd()" class="btn btn-submit float-right" [icon]="iconAdd" title="Adicionar rebanho"></fa-icon>
  </div>
  <div *ngIf="farm != null">
    <div class="my-2" *ngFor="let herd of editFarm.herd?.slice()?.reverse(); let i = index;" >
      <hr *ngIf="i > 0" class="w-100 divider mb-4" />
      <div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="name_{{herd.name}}">Nome</label>
            <input type="text" [(ngModel)]="herd.name" class="form-control" id="id_{{herd.id}}" placeholder="Nome do rebanho" autocomplete="off" />
            <small class="text-danger" *ngIf="!herd.name">Informe o nome do rebanho</small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-4">
            <div class="form-row">
              <div class="form-group col-6">
                <label for="numberOfCows_{{herd.id}}">Nr. de vacas</label>
                <input type="text" [(ngModel)]="herd.numberOfCows" class="form-control" id="id_numberOfCows_{{herd.id}}" placeholder="0" autocomplete="off" tabindex="0" />
                <!-- <small class="text-danger" *ngIf="herd.numberOfCows <= 0">O número de vacas não pode ser 0</small> -->
              </div>
              <div class="form-group col-6">
                <label for="numberOfHeifers_{{herd.id}}">Nr. de novilhas</label>
                <input type="text" [(ngModel)]="herd.numberOfHeifers" class="form-control" id="id_numberOfHeifers_{{herd.id}}" placeholder="0" autocomplete="off" tabindex="1" />
                <!-- <small class="text-danger" *ngIf="herd.numberOfHeifers <= 0">O número de novilhas não pode ser 0</small> -->
              </div>
              <small class="text-danger" *ngIf="herd.numberOfCows + herd.numberOfHeifers <= 0">O número de vacas e novilhas não pode ser 0</small>
            </div>
          </div>
          <div class="form-group col-8">
            <div class="form-row">
              <!-- <div class="form-group col-6">
                <label for="breedType_{{herd.breed.breedTypeId}}">Categoria</label>
                  <select id="breedType_{{herd.breed.breedTypeId}}" name="breedType_{herd.breed.breedTypeId}}" class="form-control" (change)="ChangeBreedType($event.target.value, i)" [(ngModel)]="herd.breed.breedTypeId">
                    <option value="" disabled>Selecione...</option>
                    <option *ngFor="let bt of breedTypeList" value={{bt.id}}>{{bt.name}}</option>
                  </select>
                <small class="text-danger" *ngIf="!herd.breed.breedTypeId">Informe a categoria</small>
              </div> -->
              <div class="form-group col-6">
                <label for="breed_{{herd.breed.id}}">Raça</label>
                <select id="breed_{{herd.breed.id}}" name="breed_{{herd.breed.id}}" class="form-control" (change)="ChangeBreed($event.target.value, i)" [(ngModel)]="herd.breed.id">
                  <option value="" disabled>Selecione...</option>
                  <option *ngFor="let b of breedList" value={{b.id}}>{{b.name}}</option>
                </select>
                <small class="text-danger" *ngIf="!herd.breed.id">Informe a raça</small>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
              <label for="goals_{{herd.id}}">Objetivo</label>
              <textarea [(ngModel)]="herd.goals" rows="4" class="form-control" id="id_goals_{{herd.id}}" placeholder="Objetivo do rebanho" autocomplete="off"></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 m-0">
            <fa-icon (click)="RemoveHerd(i)" class="btn btn-delete float-right" [icon]="iconTrash" title="Remover rebanho"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
