import { ProofSelection } from './proof-selection';

export class BreedSelection {

    public id: number;

    public name: string;

    // public proofs: Array<ProofSelection>;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
