import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import emailMask from 'text-mask-addons/dist/emailMask';
import { Person } from 'src/_models/person';
import { Email } from 'src/_models/email';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { DialogConfirmService } from '@app/_services/dialog-confirm.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, OnChanges {

  @Input() person: any;

  public editPerson: Person;
  public emailMask = emailMask;
  public emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  public iconTrash = faTrashAlt;
  public iconAdd = faPlus;

  constructor(private dialogConfirmService: DialogConfirmService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.CopyPerson();
  }

  ngOnInit() {
    this.CopyPerson();
    this.CreateNewPerson();
  }

  private CopyPerson() {
    this.editPerson = Object.assign({}, this.person);
  }

  private CreateNewPerson() {
    if (!this.editPerson || !this.editPerson.id) {
      this.editPerson = new Person();
      this.editPerson.email = new Array<Email>();
    }
  }

  public AddEmail() {
    if (!this.editPerson.email) {
      this.editPerson.email = new Array<Email>();
    }
    this.editPerson.email.push(new Email());
    this.SetDefaultEmail();
  }

  public RemoveEmail(index: number) {
    const dialogTitle = `Remover email`;
    const dialogContent =  `Deseja remover este email ?`;
    this.dialogConfirmService.Confirm(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
          if (action === `Sim`) {
            this.editPerson.email.reverse().splice(index, 1);
            this.editPerson.email.reverse();
            this.SetDefaultEmail();
          }
        }
    });
  }

  public ChangeDefaultEmail(index: number) {
      if (this.editPerson.email.length > 1) {
        this.editPerson.email.reverse();
        for (let i = 0; i < this.editPerson.email.length; i++) {
          this.editPerson.email[i].isDefault = false;
        }
        this.editPerson.email[index].isDefault = true;
        this.editPerson.email.reverse();
    } else {
      this.SetDefaultEmail();
    }
  }

  public SetDefaultEmail() {
    if (this.editPerson.email.length === 1) {
      this.editPerson.email[0].isDefault = true;
    }
  }
}
