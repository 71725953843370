import { Component, OnInit, Input } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import { ReproductiveManagementService } from '@app/_services/reproductive-management.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-pregnancy-distribution-per-breed]',
  templateUrl: './pregnancy-distribution-per-breed.component.html',
  styleUrls: ['./pregnancy-distribution-per-breed.component.scss']
})
export class PregnancyDistributionPerBreedComponent implements OnInit {

  @Input() rm: ReproductiveManagement;
  @Input() breed: string;
  @Input() breedNumber: number;

  constructor(public rmService: ReproductiveManagementService) { }

  ngOnInit() {
  }

  // HEIFERS
  get pregnancyDistributionHeifersEM1 () {
    if (this.breedNumber == 0) {
      const percent = this.rmService.heifersBeginningBreed1Percent / 100;
      this.rm.pregnancyDistributionBreed1HeifersBeginningEM = Math.round((this.rmService.heifersIATFDistributionBeginning * (this.rm.pregnancyRate1IATFHeifers / 100)) * percent) || 0;
      return this.rm.pregnancyDistributionBreed1HeifersBeginningEM;
    } else {
      const percent = this.rmService.heifersBeginningBreed2Percent / 100;
      this.rm.pregnancyDistributionBreed2HeifersBeginningEM = Math.round((this.rmService.heifersIATFDistributionBeginning * (this.rm.pregnancyRate1IATFHeifers / 100)) * percent) || 0;
      return this.rm.pregnancyDistributionBreed2HeifersBeginningEM;
    }
  }
  get pregnancyDistributionHeifersEM2 () {
    if (this.breedNumber == 0) {
      const percent = this.rmService.heifersMiddleBreed1Percent / 100;
      if (this.rm.maxIATFsPerHeifer == 1) {
        this.rm.pregnancyDistributionBreed1HeifersMiddleEM =  Math.round((this.rmService.heifersIATFDistributionMiddle2 * (this.rm.pregnancyRate1IATFHeifers / 100)) * percent) || 0;
      } else {
        this.rm.pregnancyDistributionBreed1HeifersMiddleEM =  Math.round((this.rmService.heifersIATFDistributionMiddle2 * (this.rm.pregnancyRate1IATFHeifers / 100) + this.rmService.heifersIATFDistributionMiddle1 * (this.rm.pregnancyRate2IATFHeifers / 100)) * percent) || 0;
      }
      return this.rm.pregnancyDistributionBreed1HeifersMiddleEM;
    } else {
      const percent = this.rmService.heifersMiddleBreed2Percent / 100;
      if (this.rm.maxIATFsPerHeifer == 1) {
        this.rm.pregnancyDistributionBreed2HeifersMiddleEM =  Math.round((this.rmService.heifersIATFDistributionMiddle2 * (this.rm.pregnancyRate1IATFHeifers / 100)) * percent) || 0;
      } else {
        this.rm.pregnancyDistributionBreed2HeifersMiddleEM =  Math.round((this.rmService.heifersIATFDistributionMiddle2 * (this.rm.pregnancyRate1IATFHeifers / 100) + this.rmService.heifersIATFDistributionMiddle1 * (this.rm.pregnancyRate2IATFHeifers / 100)) * percent) || 0;
      }
      return this.rm.pregnancyDistributionBreed2HeifersMiddleEM;
    }
  }
  get pregnancyDistributionHeifersEM3 () {
    if (this.breedNumber == 0) {
      const percent = this.rmService.heifersEndBreed1Percent / 100;
      if (this.rm.maxIATFsPerHeifer == 1) {
        this.rm.pregnancyDistributionBreed1HeifersEndEM = 0;
      } else if (this.rm.maxIATFsPerHeifer == 2) {
        this.rm.pregnancyDistributionBreed1HeifersEndEM = Math.round((this.rmService.heifersIATFDistributionEnd2 * (this.rm.pregnancyRate2IATFHeifers / 100)) * percent) || 0;
      } else {
        this.rm.pregnancyDistributionBreed1HeifersEndEM = Math.round((this.rmService.heifersIATFDistributionEnd2 * (this.rm.pregnancyRate2IATFHeifers / 100) + this.rmService.heifersIATFDistributionEnd1 * (this.rm.pregnancyRate3IATFHeifers / 100)) * percent) || 0;
      }
      return this.rm.pregnancyDistributionBreed1HeifersEndEM;
    } else {
      const percent = this.rmService.heifersEndBreed2Percent / 100;
      if (this.rm.maxIATFsPerHeifer == 1) {
        this.rm.pregnancyDistributionBreed2HeifersEndEM = 0;
      } else if (this.rm.maxIATFsPerHeifer == 2) {
        this.rm.pregnancyDistributionBreed2HeifersEndEM = Math.round((this.rmService.heifersIATFDistributionEnd2 * (this.rm.pregnancyRate2IATFHeifers / 100)) * percent) || 0;
      } else {
        this.rm.pregnancyDistributionBreed2HeifersEndEM = Math.round((this.rmService.heifersIATFDistributionEnd2 * (this.rm.pregnancyRate2IATFHeifers / 100) + this.rmService.heifersIATFDistributionEnd1 * (this.rm.pregnancyRate3IATFHeifers / 100)) * percent) || 0;
      }
      return this.rm.pregnancyDistributionBreed2HeifersEndEM;
    }
  }
  get pregnancyDistributionHeifersTotal () {
    if (this.breedNumber == 0) {
      this.rm.pregnancyDistributionBreed1HeifersTotal = this.pregnancyDistributionHeifersEM1 + this.pregnancyDistributionHeifersEM2 + this.pregnancyDistributionHeifersEM3;
      return this.rm.pregnancyDistributionBreed1HeifersTotal;
    } else {
      this.rm.pregnancyDistributionBreed2HeifersTotal = this.pregnancyDistributionHeifersEM1 + this.pregnancyDistributionHeifersEM2 + this.pregnancyDistributionHeifersEM3;
      return this.rm.pregnancyDistributionBreed2HeifersTotal;
    }
  }

  // COWS
  get pregnancyDistributionCowsEM1 () {
    if (this.breedNumber == 0) {
      const percent = this.rmService.cowsBeginningBreed1Percent / 100;
      this.rm.pregnancyDistributionBreed1CowsBeginningEM = Math.round((this.rmService.cowsIATFDistributionBeginning * (this.rm.pregnancyRate1IATFCows / 100)) * percent) || 0;
      return this.rm.pregnancyDistributionBreed1CowsBeginningEM;
    } else {
      const percent = this.rmService.cowsBeginningBreed2Percent / 100;
      this.rm.pregnancyDistributionBreed2CowsBeginningEM = Math.round((this.rmService.cowsIATFDistributionBeginning * (this.rm.pregnancyRate1IATFCows / 100)) * percent) || 0;
      return this.rm.pregnancyDistributionBreed2CowsBeginningEM;
    }
  }
  get pregnancyDistributionCowsEM2 () {
    if (this.breedNumber == 0) {
      const percent = this.rmService.cowsMiddleBreed1Percent / 100;
      if (this.rm.maxIATFsPerCow == 1) {
        this.rm.pregnancyDistributionBreed1CowsMiddleEM = Math.round((this.rmService.cowsIATFDistributionMiddle2 * (this.rm.pregnancyRate1IATFCows / 100)) * percent) || 0;
      } else {
        this.rm.pregnancyDistributionBreed1CowsMiddleEM = Math.round((this.rmService.cowsIATFDistributionMiddle2 * (this.rm.pregnancyRate1IATFCows / 100) + this.rmService.cowsIATFDistributionMiddle1 * (this.rm.pregnancyRate2IATFCows / 100)) * percent) || 0;
      }
      return this.rm.pregnancyDistributionBreed1CowsMiddleEM;
    } else {
      const percent = this.rmService.cowsMiddleBreed2Percent / 100;
      if (this.rm.maxIATFsPerCow == 1) {
        this.rm.pregnancyDistributionBreed2CowsMiddleEM = Math.round((this.rmService.cowsIATFDistributionMiddle2 * (this.rm.pregnancyRate1IATFCows / 100)) * percent)  || 0;
      } else {
        this.rm.pregnancyDistributionBreed2CowsMiddleEM = Math.round((this.rmService.cowsIATFDistributionMiddle2 * (this.rm.pregnancyRate1IATFCows / 100) + this.rmService.cowsIATFDistributionMiddle1 * (this.rm.pregnancyRate2IATFCows / 100)) * percent) || 0;
      }
      return this.rm.pregnancyDistributionBreed2CowsMiddleEM;
    }
  }
  get pregnancyDistributionCowsEM3 () {
    if (this.breedNumber == 0) {
      const percent = this.rmService.cowsEndBreed1Percent / 100;
      if (this.rm.maxIATFsPerCow == 1) {
        this.rm.pregnancyDistributionBreed1CowsEndEM = Math.round((this.rmService.cowsIATFDistributionEnd3 * (this.rm.pregnancyRate1IATFCows / 100)) * percent) || 0;
      } else if (this.rm.maxIATFsPerCow == 2) {
        this.rm.pregnancyDistributionBreed1CowsEndEM = Math.round(((this.rmService.cowsIATFDistributionEnd3 * (this.rm.pregnancyRate1IATFCows / 100)) + (this.rmService.cowsIATFDistributionEnd2 * (this.rm.pregnancyRate2IATFCows / 100))) * percent) || 0;
      } else {
        this.rm.pregnancyDistributionBreed1CowsEndEM = Math.round(((this.rmService.cowsIATFDistributionEnd3 * (this.rm.pregnancyRate1IATFCows / 100) + this.rmService.cowsIATFDistributionEnd2 * (this.rm.pregnancyRate2IATFCows / 100) + this.rmService.cowsIATFDistributionEnd1 * (this.rm.pregnancyRate3IATFCows / 100))) * percent) || 0;
      }
      return this.rm.pregnancyDistributionBreed1CowsEndEM;
    } else {
      const percent = this.rmService.cowsEndBreed2Percent / 100;
      if (this.rm.maxIATFsPerCow == 1) {
        this.rm.pregnancyDistributionBreed2CowsEndEM = Math.round((this.rmService.cowsIATFDistributionEnd3 * (this.rm.pregnancyRate1IATFCows / 100)) * percent) || 0;
      } else if (this.rm.maxIATFsPerCow == 2) {
        this.rm.pregnancyDistributionBreed2CowsEndEM = Math.round(((this.rmService.cowsIATFDistributionEnd3 * (this.rm.pregnancyRate1IATFCows / 100)) + (this.rmService.cowsIATFDistributionEnd2 * (this.rm.pregnancyRate2IATFCows / 100))) * percent) || 0;
      } else {
        this.rm.pregnancyDistributionBreed2CowsEndEM = Math.round(((this.rmService.cowsIATFDistributionEnd3 * (this.rm.pregnancyRate1IATFCows / 100) + this.rmService.cowsIATFDistributionEnd2 * (this.rm.pregnancyRate2IATFCows / 100) + this.rmService.cowsIATFDistributionEnd1 * (this.rm.pregnancyRate3IATFCows / 100))) * percent) || 0;
      }
      return this.rm.pregnancyDistributionBreed2CowsEndEM;
    }
  }
  get pregnancyDistributionCowsTotal () {
    if (this.breedNumber == 0) {
      this.rm.pregnancyDistributionBreed1CowsTotal = this.pregnancyDistributionCowsEM1 + this.pregnancyDistributionCowsEM2 + this.pregnancyDistributionCowsEM3;
      return this.rm.pregnancyDistributionBreed1CowsTotal;
    } else {
      this.rm.pregnancyDistributionBreed2CowsTotal = this.pregnancyDistributionCowsEM1 + this.pregnancyDistributionCowsEM2 + this.pregnancyDistributionCowsEM3;
      return this.rm.pregnancyDistributionBreed2CowsTotal;
    }
  }

  // TOTAL
  get pregnancyDistributionEM1Total () {
    if (this.breedNumber == 0) {
      this.rm.pregnancyDistributionBreed1TotalBeginningEM = this.pregnancyDistributionCowsEM1 + this.pregnancyDistributionHeifersEM1;
      return this.rm.pregnancyDistributionBreed1TotalBeginningEM;
    } else {
      this.rm.pregnancyDistributionBreed2TotalBeginningEM = this.pregnancyDistributionCowsEM1 + this.pregnancyDistributionHeifersEM1;
      return this.rm.pregnancyDistributionBreed2TotalBeginningEM;
    }
  }
  get pregnancyDistributionEM2Total () {
    if (this.breedNumber == 0) {
      this.rm.pregnancyDistributionBreed1TotalMiddleEM = this.pregnancyDistributionCowsEM2 + this.pregnancyDistributionHeifersEM2;
      return this.rm.pregnancyDistributionBreed1TotalMiddleEM;
    } else {
      this.rm.pregnancyDistributionBreed2TotalMiddleEM = this.pregnancyDistributionCowsEM2 + this.pregnancyDistributionHeifersEM2;
      return this.rm.pregnancyDistributionBreed2TotalMiddleEM;
    }
  }
  get pregnancyDistributionEM3Total () {
    if (this.breedNumber == 0) {
      this.rm.pregnancyDistributionBreed1TotalEndEM = this.pregnancyDistributionCowsEM3 + this.pregnancyDistributionHeifersEM3;
      return this.rm.pregnancyDistributionBreed1TotalEndEM;
    } else {
      this.rm.pregnancyDistributionBreed2TotalEndEM = this.pregnancyDistributionCowsEM3 + this.pregnancyDistributionHeifersEM3;
      return this.rm.pregnancyDistributionBreed2TotalEndEM;
    }
  }
  get pregnancyDistributionTotal () {
    if (this.breedNumber == 0) {
      this.rm.pregnancyDistributionBreed1Total = this.pregnancyDistributionHeifersTotal + this.pregnancyDistributionCowsTotal;
      return this.rm.pregnancyDistributionBreed1Total;
    } else {
      this.rm.pregnancyDistributionBreed2Total = this.pregnancyDistributionHeifersTotal + this.pregnancyDistributionCowsTotal;
      return this.rm.pregnancyDistributionBreed2Total;
    }
  }

}
