<h3 class="p-2 mb-2">4 - Distribuição da parição das matrizes</h3>
<div class="row">
    <div class="col-12">
        <div class="form-row">
            <div class="form-group row w-100">
                <label class="col-form-label col-12 col-sm-8 col-xl-9 p-2" for="calvingDistributionBeginning">Até o início da estação de monta</label>
                <div class="input-group col-12 col-sm-4 col-xl-3 p-1">
                    <input type="text" appOnlyDigits 
                        [(ngModel)]="reproductiveManagement.calvingDistributionBeginning" 
                        class="form-control text-right" id="calvingDistributionBeginning" 
                        placeholder="0" autocomplete="off" />
                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-row">
            <div class="form-group row w-100">
                <label class="col-form-label col-12 col-sm-8 col-xl-9 p-2" for="calvingDistributionMiddle">Meio da estação de monta</label>
                <div class="input-group col-12 col-sm-4 col-xl-3 p-1">
                    <input type="text" appOnlyDigits 
                        [(ngModel)]="reproductiveManagement.calvingDistributionMiddle" 
                        class="form-control text-right" id="calvingDistributionMiddle" 
                        placeholder="0" autocomplete="off" />
                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-row">
            <div class="form-group row w-100">
                <label class="col-form-label col-12 col-sm-8 col-xl-9 p-2" for="calvingDistributionEnd">Final da estação de monta</label>
                <div class="input-group col-12 col-sm-4 col-xl-3 p-1">
                    <input type="text" appOnlyDigits 
                        [(ngModel)]="reproductiveManagement.calvingDistributionEnd" 
                        class="form-control text-right" id="calvingDistributionEnd" 
                        placeholder="0" autocomplete="off" />
                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="form-row">
            <div class="form-group row w-100">
                <label class="col-form-label col-12 col-sm-8 col-xl-9 p-2" for="calvingDistributionTotal">Total</label>
                <div class="input-group col-12 col-sm-4 col-xl-3 p-1">
                    <input type="text" disabled 
                        [value]="calvingDistributionTotal" 
                        [ngClass]="[calvingDistributionTotal != 100 ? 'is-invalid' : 'is-valid']"  
                        class="form-control text-right font-weight-bold" id="calvingDistributionTotal" 
                        autocomplete="off" />
                    <div class="input-group-append">
                        <span class="input-group-text">%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
