<div class="container justify-content-center h-100" *ngIf="editUser?.id">
    <div class="row formArea">
        <div class="col">
        <form (ngSubmit)="OnSubmit()">
            <app-person [person]="editUser.person"></app-person>
            <div class="row px-2">
                <div class="col-12 m-0 p-2">
                    <button class="btn btn-submit" type="submit">Salvar</button>
                    <small *ngIf="error" class="alert alert-danger m-3 p-2">{{ error }}</small>
                    <small *ngIf="success" class="alert alert-success m-3 p-2">{{ success }}</small>
                </div>
            </div>
        </form>
        </div>
    </div>
</div>

<div *ngIf="!editUser?.id" class="text-center">
    <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
    <p class="text-center w-100">Carregando perfil...</p>
</div>