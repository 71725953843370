<div class="h-100">
  <div id="main" class="row h-100 align-items-center justify-content-center m-0">
    <div *ngIf="!loading" class="col-11 col-sm-9 col-md-8 col-lg-6 col-xl-4">

      <div class="row">
        <div class="col-12 border rounded">

          <div class="row">
            <div class="col-12 p-4 text-center" style="background-color: #EEE;">
              <img class="img-fluid" src="../assets/images/plano_genetico_login.png" alt="" />
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-12 col-lg-11 col-xl-10 p-4">
              <div class="form-group">
                <button 
                  (click)="login()"
                  class="btn btn-submit w-100" 
                  matTooltip="Clique para entrar no sistema">
                  Entrar
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div *ngIf="loading" class="text-center">
      <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
      <p class="text-center w-100 pt-2">Iniciando Plano Genético Corte...Aguarde...</p>
    </div>

  </div>

  <div class="footer">
      <small class="m-0">© {{ currentYear }} Alta Genetics do Brasil</small>
  </div>

</div>
