export class SyntheticSelectResultPresentation {

    public description: string;

    public percent: number;

    public dep: number;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
