<!-- <h3 class="p-2 mb-2">Quantidade de animais no rebanho</h3> -->
<div class="row ">
  <div class="col-md-6 col-12">
    <div class="form-row">
      <div class="form-group row w-100">
        <label class="col-form-label col-12 col-sm-9 p-2" for="averagePregnancyRate">Taxa média de concepção de IATF</label>
        <div class="input-group col-12 col-sm-3 p-1">
          <input type="text" class="form-control text-right font-weight-bold" [value]="averagePregnancyRate" name="averagePregnancyRate" id="averagePregnancyRate" value="0,00" disabled/>
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-12">
    <div class="form-row">
      <div class="form-group row w-100">
        <label class="col-form-label col-12 col-sm-9 p-2" for="pregnancyRateHeifers">Taxa de prenhez de IATF em novilhas</label>
        <div class="input-group col-12 col-sm-3 p-1">
          <input type="text" class="form-control text-right font-weight-bold" [value]="pregnancyRateHeifers" name="pregnancyRateHeifers" id="pregnancyRateHeifers" value="0,00" disabled/>
          <div class="input-group-append">
              <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-12">
    <div class="form-row">
      <div class="form-group row w-100">
        <label class="col-form-label col-12 col-sm-9 p-2" for="pregnancyRateCows">Taxa de prenhez de IATF em vacas</label>
        <div class="input-group col-12 col-sm-3 p-1">
          <input type="text" class="form-control text-right font-weight-bold" [value]="pregnancyRateCows" name="pregnancyRateCows" id="pregnancyRateCows" value="0,00" disabled/>
          <div class="input-group-append">
              <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-12">
    <div class="form-row">
      <div class="form-group row w-100">
        <label class="col-form-label col-12 col-sm-9 p-2" for="pregnancyRateTotal">Taxa de prenhez de IATF total</label>
        <div class="input-group col-12 col-sm-3 p-1">
          <input type="text" class="form-control text-right font-weight-bold" [value]="pregnancyRateTotal" name="pregnancyRateTotal" id="pregnancyRateTotal" value="0,00" disabled/>
          <div class="input-group-append">
              <span class="input-group-text">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
