export class Email {

    public id: number;

    public email1: string;

    public personId: number;

    public isDefault: boolean;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
