import { BreedType } from './breed-type';

export class Breed {

  public id: number;

  public name: string;

  public breedTypeId: number;

  public breedType: BreedType;

  constructor () {
    if (!this.breedType) { this.breedType = new BreedType(); }
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
