import { DatePipe } from '@angular/common';

export class UserActivity {

    public name: string;

    public email: string;

    public regionManager: string;

    public districtManager: string;

    public loginDate: string;

    public numberOfInterviews: string;


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
