<h3 class="p-2 mb-2">6 - Distribuição das IATF's</h3>
<div class="row">
  <div class="col-12">
    <div class="form-row">
      <div class="form-group">
        <table class="table borderless">
          <thead>
            <tr>
              <th></th>
              <th>
                <label class="p-2 m-0">1ª IATF</label>
              </th>
              <th>
                <label class="p-2 m-0">2ª IATF</label>
              </th>
              <th>
                <label class="p-2 m-0">3ª IATF</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <label class="p-2 m-0">Novilhas</label>
              </th>
              <td>
                <input disabled class="form-control text-right px-3" type="text" placeholder="0" [value]="iatfDistributionHeifers1IATF" name="iatfDistributionHeifers1IATF" />
              </td>
              <td>
                <input disabled class="form-control text-right px-3" type="text" placeholder="0" [value]="iatfDistributionHeifers2IATF" name="iatfDistributionHeifers2IATF" />
              </td>
              <td>
                <input disabled class="form-control text-right px-3" type="text" placeholder="0" [value]="iatfDistributionHeifers3IATF" name="iatfDistributionHeifers3IATF" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0">Vacas</label>
              </th>
              <td>
                <input disabled class="form-control text-right px-3" type="text" placeholder="0" [value]="iatfDistributionCows1IATF" name="iatfDistributionCows1IATF" />
              </td>
              <td>
                <input disabled class="form-control text-right px-3" type="text" placeholder="0" [value]="iatfDistributionCows2IATF" name="iatfDistributionCows2IATF" />
              </td>
              <td>
                <input disabled class="form-control text-right px-3" type="text" placeholder="0" [value]="iatfDistributionCows3IATF" name="iatfDistributionCows3IATF" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0">Total</label>
              </th>
              <td>
                <input disabled class="form-control text-right px-3 font-weight-bold" type="text" placeholder="0" [value]="iatfDistributionTotal1IATF" name="iatfDistributionTotal1IATF" />
              </td>
              <td>
                <input disabled class="form-control text-right px-3 font-weight-bold" type="text" placeholder="0" [value]="iatfDistributionTotal2IATF" name="iatfDistributionTotal2IATF" />
              </td>
              <td>
                <input disabled class="form-control text-right px-3 font-weight-bold" type="text" placeholder="0" [value]="iatfDistributionTotal3IATF" name="iatfDistributionTotal3IATF" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
