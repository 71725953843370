<div class="container justify-content-center h-100">
  <div class="row h-100">
    <div class="col h-100">
      <div class="panel mb-4">

        <div *ngIf="error" #errorAlert class="alert alert-danger alert-dismissible fade show" role="alert">
          <button type="button" class="close" aria-label="Close" (click)="errorCloseAlert()">
            <span aria-hidden="true">&times;</span>
          </button>
          {{ error }}
        </div>
        
        <form #interviewForm="ngForm" >

          <div *ngIf="!user?.id" class="text-center">
            <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
            <p class="text-center w-100">Carregando nova entrevista...</p>
          </div>

          <div *ngIf="user?.id">

            <!-- Interview -->
            <div *ngIf="showInterviewForm">

              <app-basic-information [interview]="interview" (breedChange)="baseBreedChange()"></app-basic-information>

              <app-data-collect [interview]="interview"
                (updateInterview)="updateBothBreedsSuggestion()"
                (updateInterviewPrimary)="updatePrimaryBreedSuggestion()"
                (updateInterviewSecondary)="updateSecondaryBreedSuggestion()">
              </app-data-collect>

              <app-breed-proof-selection [interview]="interview"></app-breed-proof-selection>

              <div class="form-row py-2">
                <div class="form-group col-12 m-0 py-2 px-0 text-right">
                  <small *ngIf="error" class="alert alert-danger m-3 p-2">{{ error }}</small>
                  <button class="btn btn-default mr-3" type="reset" (click)="clearForm()">Limpar</button>
                  <button class="btn btn-submit mr-3" type="submit" (click)="setFastPlan(false); openReproductiveManagementForm()">Próximo</button>
                  <button class="btn btn-submit" type="submit" (click)="setFastPlan(true); openWeightingForm()">Ir para ponderação</button>
                </div>
              </div>

            </div>

            <!-- Reproductive Management -->
            <div *ngIf="showReproductiveManagementForm">
              <app-reproductive-management
                  (isValidReadyToIATFTotal)="updateIsValidReadyToIATFTotal($event)"
                  (isValidCalvingDistributionTotal)="updateIsValidCalvingDistributionTotal($event)"
                  [interviewCycle]="interview.interviewCycle"
                  [reproductiveManagement]="interview.reproductiveManagement"
                  [herd]="interview.herd"
                  [dosesDistributionByBreed]="interview.dosesDistributionByBreed">
              </app-reproductive-management>
              <div class="row py-2">
                <div class="col-6 m-0 text-left">
                  <button class="btn btn-submit" type="submit" (click)="openInterviewForm()">Anterior</button>
                </div>
                <div class="col-6 m-0 text-right">
                  <small *ngIf="errorIsValidReadyToIATFTotal" class="alert alert-danger m-3 p-2">{{ errorIsValidReadyToIATFTotal }}</small>
                  <small *ngIf="errorIsValidCalvingDistributionTotal" class="alert alert-danger m-3 p-2">{{ errorIsValidCalvingDistributionTotal }}</small>
                  <button class="btn btn-submit" type="submit" (click)="openWeightingForm()">Próximo</button>
                </div>
              </div>
            </div>

            <!-- Weighting -->
            <div *ngIf="showWeightingForm">
              <app-weighting [interview]="interview" (outputWeightingValueTotal)="updateWeightingValueTotal($event)"></app-weighting>
              <div class="row py-2">
                <div class="col-6 m-0 text-left">
                  <button *ngIf="!isFastPlan" class="btn btn-submit" type="submit" (click)="openReproductiveManagementForm()">Anterior</button>
                  <button *ngIf="isFastPlan" class="btn btn-submit" type="submit" (click)="openInterviewForm()">Anterior</button>
                </div>
                <div class="col-6 m-0 text-right">
                  <small *ngIf="error" class="alert alert-danger m-3 p-2">{{ error }}</small>
                  <button class="btn btn-submit" type="submit" (click)="openFilterForm()">Próximo</button>
                </div>
              </div>
            </div>

            <!-- Filter -->
            <div *ngIf="showFilterForm">
              <app-filter [interview]="interview" (filterSignal)="updateFitlerSignal($event)"></app-filter>
              <div class="row py-2" >
                <div class="col-6 m-0 text-left">
                  <button class="btn btn-submit" type="submit" (click)="openWeightingForm()">Anterior</button>
                </div>
                <div class="col-6 m-0 text-right">
                  <small *ngIf="error" class="alert alert-danger m-3 p-2">{{ error }}</small>
                  <button class="btn btn-submit" type="submit" (click)="openBullSelectionForm()">Próximo</button>
                </div>
              </div>
            </div>

            <!-- Bull Selection -->
            <div *ngIf="showBullSelectionForm">
              <app-bull-selection [interview]="interview" (selectedBullsCount)="updateSelectedBullsCount($event)"></app-bull-selection>
              <div class="row py-2" >
                <div class="col-6 m-0 text-left">
                  <button class="btn btn-submit" type="submit" (click)="openFilterForm()">Anterior</button>
                </div>
                <div class="col-6 m-0 text-right">
                  <small *ngIf="error" class="alert alert-danger m-3 p-2">{{ error }}</small>
                  <button class="btn btn-submit" type="submit" (click)="openDosesDistributionForm()">Próximo</button>
                </div>
              </div>
            </div>

            <!-- Doses Distribution -->
            <div *ngIf="showDosesDistributionForm">
              <app-doses-distribution 
                [interview]="interview" 
                (bullsHasDoses)="updateBullsHasDoses($event)"
                (bullsHasQuantityNecessaryDoses)="updateBullsHasQuantityNecessaryDoses($event)">
              </app-doses-distribution>
              <div class="row py-2" >
                <div class="col-6 m-0 text-left">
                  <button class="btn btn-submit" type="submit" (click)="openBullSelectionForm()">Anterior</button>
                </div>
                <div class="col-6 m-0 text-right">
                  <small *ngIf="error" class="alert alert-danger m-3 p-2">{{ error }}</small>
                  <button class="btn btn-submit" type="submit" (click)="openSelectResultPresentationForm()">Próximo</button>
                </div>
              </div>
            </div>

            <!-- Select Result Presentation -->
            <div *ngIf="showSelectResultPresentationForm">
              <app-select-result-presentation [interview]="interview"></app-select-result-presentation>
              <div class="row py-2" >
                <div class="col-6 m-0 text-left">
                  <button class="btn btn-submit" type="submit" (click)="openDosesDistributionForm()">Anterior</button>
                </div>
                <div *ngIf="interview.currentInterviewCycle < InterviewCycleLength - 1" class="col-6 m-0 text-right">
                  <button class="btn btn-submit" type="submit" (click)="continueInterview()">Próximo</button>
                </div>
                <div *ngIf="interview.currentInterviewCycle == InterviewCycleLength - 1" class="col-6 m-0 text-right">
                  <button class="btn btn-submit" type="submit" (click)="finishInterview()">Concluir</button>
                </div>
              </div>
            </div>

            <div kendoDialogContainer></div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
