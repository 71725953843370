export class BullSelectionFilter {

  public signal: string;

  public value: string;

  public traitTypeId: number;

  public traitId: number;

  public proofId: number;

  constructor () { }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
