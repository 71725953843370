import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-ready-to-iatf]',
  templateUrl: './ready-to-iatf.component.html',
  styleUrls: ['./ready-to-iatf.component.scss']
})
export class ReadyToIatfComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;
  @Output() isValidReadyToIATFTotal = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  get readyToIATFTotal() {
    const beginning = Number(this.reproductiveManagement.readyToIATFBeginning || 0);
    const middle = Number(this.reproductiveManagement.readyToIATFMiddle || 0);

    if ((beginning + middle) != 100) {
      this.isValidReadyToIATFTotal.emit(false);
    } else {
      this.isValidReadyToIATFTotal.emit(true);
    }

    return beginning + middle;
  }

}
