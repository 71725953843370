import { TraitGroup } from './trait-group';
import { ProofTrait } from './proof-trait';

export class Trait {

    public id: number;

    public proofId: number;

    public acronym: string;

    public description: string;

    public positive: boolean;

    public display: string;

    public checked: boolean;

    public active: number;

    public traitGroupId?: number;

    public traitGroup: TraitGroup;

    public weightingValue: number;

    public filterTopDecaValue: number;

    public filterTopDecaSignal: string;

    public filterDepValue: number;

    public filterDepSignal: string;

    public filterAccValue: number;

    public proofTrait: Array<ProofTrait>;

    public isHighlighting: boolean;

    public sequence: number;

    deserialize(input: any): this {
      Object.assign(this, input);

      this.proofTrait =
        this.proofTrait ? input.proofTrait.map((proofTrait: ProofTrait) =>
          new ProofTrait().deserialize(proofTrait)) : this.proofTrait;

      return this;
    }
}
