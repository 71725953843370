import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { Interview } from 'src/_models/interview';
import { Customer } from 'src/_models/customer';
import { Farm } from 'src/_models/farm';
import { Herd } from 'src/_models/herd';
import { User } from 'src/_models/user';
import { ApiService } from '@app/_services/api.service';

@Component({
  selector: 'app-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss']
})
export class BasicInformationComponent implements OnInit {

  @Input() public interview: Interview;
  @Output() breedChange = new EventEmitter();

  public customerList: Array<Customer>;
  public farmList: Array<Farm>;
  public herdList: Array<Herd>;
  public user: User;

  constructor(private apiService: ApiService) { }

  ngOnInit() {

    this.farmList = new Array<Farm>();
    this.herdList = new Array<Herd>();
    this.customerList = new Array<Customer>();
    this.getUserCustomersList();
    // if (this.customerList && this.customerList.length > 0) {
    //   this.changeCustomer(this.customerList[0].id);
    // }
  }

  private getUserCustomersList() {
    this.apiService.GetUser().subscribe((data: User) => {
      this.customerList = data.customer.filter(c => c.farm.every(f => f.herd !== null && f.herd.length > 0));
      this.OrderCustomers();
      this.changeCustomer(this.customerList[0].id);
    }, (error) => console.error(error));
  }

  public changeCustomer(id: number) {
    // tslint:disable-next-line:triple-equals
    const tmpCustomer = this.customerList.find(i => i.id == id);
    if (tmpCustomer && tmpCustomer.farm) {
      this.farmList = tmpCustomer.farm;
      this.OrderFarms();
      if (this.farmList && this.farmList.length > 0) {
        this.changeFarm(this.farmList[0].id);
      }
    } else {
      this.farmList = [];
    }
  }

  public changeFarm(id: number) {
    // tslint:disable-next-line:triple-equals
    const tmpFarm = this.farmList.find(i => i.id == id);
    if (tmpFarm && tmpFarm.herd) {
      this.herdList = tmpFarm.herd;
      this.OrderHerds();
      if (this.herdList && this.herdList.length > 0) {
        this.changeHerd(this.herdList[0].id);
      }
    } else {
      this.herdList = [];
    }
  }

  public changeHerd(id: number) {
    // tslint:disable-next-line:triple-equals
    this.interview.herd = this.herdList.find(i => i.id == id);

    this.breedChange.emit();

    // console.log(this.interview);
  }

  private OrderCustomers(): void {
    if (this.customerList.length > 0) {
      this.customerList = this.customerList.sort((c1, c2) => c1.person.name.localeCompare(c2.person.name));
    }
  }

  private OrderFarms(): void {
    if (this.farmList.length > 0) {
      this.farmList = this.farmList.sort((f1, f2) => f1.name.localeCompare(f2.name));
    }
  }

  private OrderHerds(): void {
    if (this.herdList.length > 0) {
      this.herdList = this.herdList.sort((h1, h2) => h1.name.localeCompare(h2.name));
    }
  }
}
