import { InterviewService } from "./../_services/interview.service";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  GridDataResult,
  SelectableSettings,
} from "@progress/kendo-angular-grid";
import { SavedInterview } from "src/_models/saved-interview/saved-interview";
import { ApiService } from "@app/_services/api.service";
import { User } from "src/_models/user";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { DialogConfirmService } from "@app/_services/dialog-confirm.service";
import { saveAs } from "@progress/kendo-file-saver";
import { ActivatedRoute, Router } from "@angular/router";
import "rxjs/add/operator/filter";
import { Subscription } from "rxjs";
import { Interview } from "src/_models/interview";

@Component({
  selector: "app-saved-interviews",
  templateUrl: "./saved-interviews.component.html",
  styleUrls: ["./saved-interviews.component.scss"],
})
export class SavedInterviewsComponent implements OnInit {

  @ViewChild("interviewSavedSuccessAlert", { static: false })  private interviewSavedSuccessAlert: ElementRef;
  // @ViewChild("interviewSavedSuccessAlert") interviewSavedSuccessAlert: ElementRef;

  public gridView: GridDataResult;
  public gridSelection: any[] = [];
  public selectableSettings: SelectableSettings;
  public interviewSort: SortDescriptor[] = [
    { field: "interviewDate", dir: "desc" },
  ];

  public isSavedInterviewsLoaded = false;
  public savedInterviews: Array<SavedInterview>;
  public cloneError: string;
  public error: string;
  public success: string;
  public interviewSavedSuccess: string;

  public selectedCallback = (args: { dataItem: any }) => args.dataItem;

  public subscription: Subscription;

  constructor(
    private apiService: ApiService,
    private dialogConfirmService: DialogConfirmService,
    private interviewService: InterviewService,
    private router: ActivatedRoute,
    private navigationRouter: Router
  ) {}

  ngOnInit() {
    this.savedInterviews = new Array<SavedInterview>();
    this.getUserSavedInterviews();

    this.router.queryParams
      .filter((params) => params.interviewSavedSuccess)
      .subscribe((params) => {
        if (params.interviewSavedSuccess) {
          this.interviewSavedSuccess = `A entrevista foi concluída com sucesso!`;
        }
      });
  }

  public findByKeyword(e: any) {
    const keyword: string = e.target.value;
    this.findSavedInterviews(keyword.toLowerCase());
  }

  private findSavedInterviews(keyword: string): void {
    const filteredSavedInterviewsList = this.savedInterviews.filter(
      (s) => s.customerName.toLowerCase().indexOf(keyword) > -1
    );

    this.gridView = {
      data: filteredSavedInterviewsList,
      total: filteredSavedInterviewsList.length,
    };
  }

  private orderSavedInterviews(): void {
    if (this.savedInterviews.length > 0) {
      this.gridView = {
        data: orderBy(this.savedInterviews, this.interviewSort),
        total: this.savedInterviews.length,
      };
    }
  }

  public savedInterviewsSortChange(sort: SortDescriptor[]): void {
    this.interviewSort = sort;
    this.orderSavedInterviews();
  }

  private getUserSavedInterviews() {
    this.subscription = this.apiService.getSavedInterviews().subscribe(
      (data: SavedInterview[]) => {
        this.savedInterviews = data;
        this.isSavedInterviewsLoaded = true;

        this.subscription.unsubscribe();
      },
      (error) => console.error(error)
    );

    // this.apiService.GetUser().subscribe((data: User) => {
    //   // .savedInterviews = data.interview;
    //   this.mapToSavedInterview(data);
    //   this.isSavedInterviewsLoaded = true;
    // }, (error) => console.error(error));
  }

  private mapToSavedInterview(user: User) {
    user.interview.forEach((i) => {
      const savedInterview = new SavedInterview();
      savedInterview.interviewId = i.id;
      savedInterview.interviewDate = new Date(i.interviewDate);
      savedInterview.pdf = i.pdf;
      user.customer.forEach((c) => {
        c.farm.forEach((f) => {
          f.herd.forEach((h) => {
            if (h.id === i.herdId) {
              savedInterview.customerName = user.customer.find(
                (customer) => customer.id == f.customerId
              ).person.name;
              savedInterview.farmName = c.farm.find(
                (farm) => farm.id == h.farmId
              ).name;
              savedInterview.herdName = h.name;
            }
          });
        });
      });
      this.savedInterviews.push(savedInterview);
    });
  }

  public generatePdf() {
    this.error = ``;
    this.success = ``;
    if (this.gridSelection.length === 0) {
      // this.noSavedInterviewSelectedAlert();
      this.error = `Selecione uma entrevista para gerar o pdf`;
    } else {
      const savedInterview = this.gridSelection[0];

      // this.apiService.getSavedInterviews().subscribe((data: SavedInterview[]) => {
      //   this.savedInterviews = data;
      //   this.isSavedInterviewsLoaded = true;

      // }, (error) => console.error(error));

      // const interview = this.apiService.getInterview(savedInterview.interviewId);
      this.apiService
        .getInterview(savedInterview.interviewId)
        .subscribe((data: Interview) => {
          const dataURI =
            `data:application/pdf;charset=utf-8;base64,` + data.pdf;
          saveAs(
            dataURI,
            savedInterview.customerName +
              `_${new Date(savedInterview.interviewDate).toLocaleString()} ` +
              `.pdf`
          );
          this.success = `O pdf da entrevista do cliente ${this.gridSelection[0].customerName} foi gerado com sucesso.`;
        });

      // const dataURI = `data:application/pdf;charset=utf-8;base64,` + savedInterview.pdf;
      // saveAs(dataURI, savedInterview.customerName + `_${ new Date(savedInterview.interviewDate).toLocaleString() } ` + `.pdf`);
      // this.success = `O pdf da entrevista do cliente ${ this.gridSelection[0].customerName } foi gerado com sucesso.`;
      // // this.generatePdfAlert();
    }
  }

  // public noSavedInterviewSelectedAlert() {
  //   const dialogTitle = `Gerar Pdf`;
  //   const dialogContent = `Por favor selecione uma entrevista para gerar o pdf.`;
  //   this.dialogConfirmService
  //     .Alert(dialogTitle, dialogContent)
  //     .subscribe((result) => {
  //       if (!(result instanceof DialogCloseResult)) {
  //         const action = result.text;
  //       }
  //     });
  // }

  public generatePdfAlert() {
    const dialogTitle = `Gerar Pdf`;
    const dialogContent = `O pdf da entrevista do cliente ${this.gridSelection[0].customerName}
    será gerado em alguns instantes.`;
    this.dialogConfirmService
      .Alert(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
        }
      });
  }

  successCloseAlert() {
    this.interviewSavedSuccessAlert.nativeElement.classList.remove("show");
  }

  public cloneInterview() {
    // this.apiService.getInterview(this.gridSelection[0].interviewId).subscribe((result) => {
    //   console.log(result)    ;

    // });

    this.apiService
      .cloneInterview(this.gridSelection[0])
      .subscribe((result: Interview) => {

        console.log('ANTES:');
        console.log(result);


        this.interviewService.interview = this.interviewService.cloneInterview(result);


        console.log('DEPOIS:');
        console.log(result);
        console.log(this.interviewService.interview);

        this.navigationRouter.navigateByUrl("/entrevista");
      });

    this.cloneError = "Selecione uma entrevista para ser clonada";
  }
}
