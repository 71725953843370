import { Breed } from './breed';

export class Question {

  public id: number;

  public question: number;

  public value: string;

  public signal: number;

  public value2: string;

  public signal2: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
