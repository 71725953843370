import { Injectable } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import { DosesDistributionByBreed } from 'src/_models/doses-distribution-by-breed';

@Injectable({
  providedIn: 'root'
})
export class ReproductiveManagementService {

  reproductiveManagement: ReproductiveManagement;
  dosesDistributionByBreed: DosesDistributionByBreed;

  // BREED DISTRIBUITION VARIABLES
  // BREED 1
  public heifersBeginningBreed1Percent: number;
  public heifersMiddleBreed1Percent: number;
  public heifersEndBreed1Percent: number;
  public cowsBeginningBreed1Percent: number;
  public cowsMiddleBreed1Percent: number;
  public cowsEndBreed1Percent: number;
  // BREED 2
  public heifersBeginningBreed2Percent: number;
  public heifersMiddleBreed2Percent: number;
  public heifersEndBreed2Percent: number;
  public cowsBeginningBreed2Percent: number;
  public cowsMiddleBreed2Percent: number;
  public cowsEndBreed2Percent: number;

  constructor() { }

  // QUESTAO F
  get heifersIATFDistributionBeginning () {
    // P1Q1
    return Math.round(this.reproductiveManagement.nbrOfHeifersToInseminate * (this.reproductiveManagement.readyToIATFBeginning / 100) || 0);
  }
  get heifersIATFDistributionMiddle1 () {
    // P2Q1
    // tslint:disable-next-line:max-line-length
    return Math.round(this.heifersIATFDistributionBeginning - (this.heifersIATFDistributionBeginning * (this.reproductiveManagement.pregnancyRate1IATFHeifers / 100)) || 0);
  }
  get heifersIATFDistributionMiddle2 () {
    // P2Q2
    // tslint:disable-next-line:max-line-length
    return Math.round(this.reproductiveManagement.nbrOfHeifersToInseminate * (this.reproductiveManagement.readyToIATFMiddle / 100) || 0);
  }
  get heifersIATFDistributionEnd1 () {
    // P3Q1
    // tslint:disable-next-line:max-line-length
    return Math.round(this.heifersIATFDistributionMiddle1 - (this.heifersIATFDistributionMiddle1 * (this.reproductiveManagement.pregnancyRate2IATFHeifers / 100)) || 0);
  }
  get heifersIATFDistributionEnd2 () {
    // P3Q2
    // tslint:disable-next-line:max-line-length
    // return Math.round(this.heifersIATFDistributionMiddle2 - (this.heifersIATFDistributionBeginning * (this.reproductiveManagement.pregnancyRate1IATFHeifers / 100)) || 0);
    return Math.round(this.heifersIATFDistributionMiddle2 - (this.heifersIATFDistributionMiddle2 * (this.reproductiveManagement.pregnancyRate1IATFHeifers / 100)) || 0);
  }

  get cowsIATFDistributionBeginning () {
    // P1Q1
    // tslint:disable-next-line:max-line-length
    return Math.round(this.reproductiveManagement.nbrOfCowsToInseminate * (this.reproductiveManagement.calvingDistributionBeginning / 100) || 0);
  }
  get cowsIATFDistributionMiddle1 () {
    // P2Q1
    // tslint:disable-next-line:max-line-length
    return Math.round(this.cowsIATFDistributionBeginning - (this.cowsIATFDistributionBeginning * (this.reproductiveManagement.pregnancyRate1IATFCows / 100)) || 0);
  }
  get cowsIATFDistributionMiddle2 () {
    // P2Q2
    // tslint:disable-next-line:max-line-length
    return Math.round(this.reproductiveManagement.nbrOfCowsToInseminate * (this.reproductiveManagement.calvingDistributionMiddle / 100) || 0);
  }
  get cowsIATFDistributionEnd1 () {
    // P3Q1
    // tslint:disable-next-line:max-line-length
    return Math.round(this.cowsIATFDistributionMiddle1 - (this.cowsIATFDistributionMiddle1 * (this.reproductiveManagement.pregnancyRate2IATFCows / 100)) || 0);
  }
  get cowsIATFDistributionEnd2 () {
    // P3Q2
    // tslint:disable-next-line:max-line-length
    return Math.round(this.cowsIATFDistributionMiddle2 - (this.cowsIATFDistributionMiddle2 * (this.reproductiveManagement.pregnancyRate1IATFCows / 100)) || 0);
  }
  get cowsIATFDistributionEnd3 () {
    // P3Q2
    // tslint:disable-next-line:max-line-length
    return Math.round(this.reproductiveManagement.nbrOfCowsToInseminate  * (this.reproductiveManagement.calvingDistributionEnd / 100) || 0);
  }

  // QUESTAO G
  // IATF DISTRIBUTION - HEIFERS
  get iatfDistributionHeifers1IATF () {
    // tslint:disable-next-line:max-line-length
    return this.heifersIATFDistributionBeginning + this.heifersIATFDistributionMiddle2 || 0;
  }
  get iatfDistributionHeifers2IATF () {
    // tslint:disable-next-line:triple-equals
    if (this.reproductiveManagement.maxIATFsPerHeifer == 1) {
      return 0;
    } else {
      // tslint:disable-next-line:max-line-length
      return this.heifersIATFDistributionMiddle1 + this.heifersIATFDistributionEnd2 || 0;
    }
  }
  get iatfDistributionHeifers3IATF () {
    if (this.reproductiveManagement.maxIATFsPerHeifer < 3) {
      return 0;
    } else {
      return this.heifersIATFDistributionEnd1;
    }
  }

  // IATF DISTRIBUTION - COWS
  get iatfDistributionCows1IATF () {
    // tslint:disable-next-line:max-line-length
    return this.cowsIATFDistributionBeginning + this.cowsIATFDistributionMiddle2 + this.cowsIATFDistributionEnd3 || 0;
  }
  get iatfDistributionCows2IATF () {
    // tslint:disable-next-line:triple-equals
    if (this.reproductiveManagement.maxIATFsPerCow == 1) {
      return 0;
    } else {
      // tslint:disable-next-line:max-line-length
      return this.cowsIATFDistributionMiddle1 + this.cowsIATFDistributionEnd2 || 0;
    }
  }
  get iatfDistributionCows3IATF () {
    if (this.reproductiveManagement.maxIATFsPerCow < 3) {
      return 0;
    } else {
      return this.cowsIATFDistributionEnd1;
    }
  }

  // IATF DISTRIBUTION - TOTAL
  get iatfDistributionTotal1IATF() {
    return this.iatfDistributionHeifers1IATF + this.iatfDistributionCows1IATF || 0;
  }
  get iatfDistributionTotal2IATF() {
    return this.iatfDistributionHeifers2IATF + this.iatfDistributionCows2IATF || 0;
  }
  get iatfDistributionTotal3IATF() {
    return this.iatfDistributionHeifers3IATF + this.iatfDistributionCows3IATF || 0;
  }

  // QUESTAO H
  // IATF DISTRIBUTION - HEIFERS
  get iatfDistributionPerSeasonHeifersBeginningEM () {
    // tslint:disable-next-line:max-line-length
    return this.heifersIATFDistributionBeginning || 0;
  }
  get iatfDistributionPerSeasonHeifersMiddleEM () {
    if (this.reproductiveManagement.maxIATFsPerHeifer == 1) {
      return this.heifersIATFDistributionMiddle2;
    } else {
      // tslint:disable-next-line:max-line-length
      return this.heifersIATFDistributionMiddle1 + this.heifersIATFDistributionMiddle2 || 0;
    }
  }
  get iatfDistributionPerSeasonHeifersEndEM () {
    if (this.reproductiveManagement.maxIATFsPerHeifer == 1) {
      return 0;
    } else if (this.reproductiveManagement.maxIATFsPerHeifer == 2) {
      return this.heifersIATFDistributionEnd2;
    } else {
      return this.heifersIATFDistributionEnd1 + this.heifersIATFDistributionEnd2;
    }
  }

  // IATF DISTRIBUTION - COWS
  get iatfDistributionPerSeasonCowsBeginningEM () {
    // tslint:disable-next-line:max-line-length
    return this.cowsIATFDistributionBeginning || 0;
  }
  get iatfDistributionPerSeasonCowsMiddleEM () {
    if (this.reproductiveManagement.maxIATFsPerCow == 1) {
      return this.cowsIATFDistributionMiddle2;
    } else {
      // tslint:disable-next-line:max-line-length
      return this.cowsIATFDistributionMiddle1 + this.cowsIATFDistributionMiddle2 || 0;
    }
  }
  get iatfDistributionPerSeasonCowsEndEM () {
    if (this.reproductiveManagement.maxIATFsPerCow == 1) {
      return this.cowsIATFDistributionEnd3;
    } else if (this.reproductiveManagement.maxIATFsPerCow == 2) {
      return this.cowsIATFDistributionEnd2 + this.cowsIATFDistributionEnd3;
    } else {
      return this.cowsIATFDistributionEnd1 + this.cowsIATFDistributionEnd2 + this.cowsIATFDistributionEnd3;
    }
  }

  // IATF DISTRIBUTION - COL TOTAL
  get iatfDistributionPerSeasonTotalBeginningEM() {
    return this.iatfDistributionPerSeasonHeifersBeginningEM + this.iatfDistributionPerSeasonCowsBeginningEM || 0;
  }
  get iatfDistributionPerSeasonTotalMiddleEM() {
    return this.iatfDistributionPerSeasonHeifersMiddleEM + this.iatfDistributionPerSeasonCowsMiddleEM || 0;
  }
  get iatfDistributionPerSeasonTotalEndEM() {
    return this.iatfDistributionPerSeasonHeifersEndEM + this.iatfDistributionPerSeasonCowsEndEM || 0;
  }

  // IATF DISTRIBUTION - ROW TOTAL
  get iatfDistributionPerSeasonTotalHeifers() {
    return this.iatfDistributionPerSeasonHeifersBeginningEM + this.iatfDistributionPerSeasonHeifersMiddleEM + this.iatfDistributionPerSeasonHeifersEndEM || 0;
  }
  get iatfDistributionPerSeasonTotalCows() {
    return this.iatfDistributionPerSeasonCowsBeginningEM + this.iatfDistributionPerSeasonCowsMiddleEM + this.iatfDistributionPerSeasonCowsEndEM || 0;
  }
  get iatfDistributionPerSeasonTotalTotal() {
    return this.iatfDistributionPerSeasonTotalBeginningEM + this.iatfDistributionPerSeasonTotalMiddleEM + this.iatfDistributionPerSeasonTotalEndEM || 0;
  }

  // QUESTAO I
  // PREGNANCY IATF DISTRIBUTION PER SEASON - HEIFERS
  get pregnancyIatfDistributionPerSeasonHeifersBeginningEM () {
    // tslint:disable-next-line:max-line-length
    return Math.round(this.heifersIATFDistributionBeginning * (this.reproductiveManagement.pregnancyRate1IATFHeifers / 100) || 0);
  }
  get pregnancyIatfDistributionPerSeasonHeifersMiddleEM () {
    if (this.reproductiveManagement.maxIATFsPerHeifer == 1) {
      return Math.round(this.heifersIATFDistributionMiddle2 * (this.reproductiveManagement.pregnancyRate1IATFHeifers / 100) || 0);
    } else {
      // tslint:disable-next-line:max-line-length
      return Math.round(this.heifersIATFDistributionMiddle2 * (this.reproductiveManagement.pregnancyRate1IATFHeifers / 100) + this.heifersIATFDistributionMiddle1 * (this.reproductiveManagement.pregnancyRate2IATFHeifers / 100) || 0);
    }
  }
  get pregnancyIatfDistributionPerSeasonHeifersEndEM () {
    if (this.reproductiveManagement.maxIATFsPerHeifer == 1) {
      return 0;
    } else if (this.reproductiveManagement.maxIATFsPerHeifer == 2) {
      return Math.round(this.heifersIATFDistributionEnd2 * (this.reproductiveManagement.pregnancyRate2IATFHeifers / 100) || 0);
    } else {
      return Math.round(this.heifersIATFDistributionEnd2 * (this.reproductiveManagement.pregnancyRate2IATFHeifers / 100) + this.heifersIATFDistributionEnd1 * (this.reproductiveManagement.pregnancyRate3IATFHeifers / 100) || 0);
    }
  }

  // PREGNANCY IATF DISTRIBUTION PER SEASON - COWS
  get pregnancyIatfDistributionPerSeasonCowsBeginningEM () {
    // tslint:disable-next-line:max-line-length
    return Math.round(this.cowsIATFDistributionBeginning * (this.reproductiveManagement.pregnancyRate1IATFCows / 100) || 0);
  }
  get pregnancyIatfDistributionPerSeasonCowsMiddleEM () {
    if (this.reproductiveManagement.maxIATFsPerCow == 1) {
      return Math.round(this.cowsIATFDistributionMiddle2 * (this.reproductiveManagement.pregnancyRate1IATFCows / 100) || 0);
    } else {
      // tslint:disable-next-line:max-line-length
      return Math.round(this.cowsIATFDistributionMiddle2 * (this.reproductiveManagement.pregnancyRate1IATFCows / 100) + this.cowsIATFDistributionMiddle1 * (this.reproductiveManagement.pregnancyRate2IATFCows / 100) || 0);
    }
  }
  get pregnancyIatfDistributionPerSeasonCowsEndEM () {
    if (this.reproductiveManagement.maxIATFsPerCow == 1) {
      return Math.round(this.cowsIATFDistributionEnd3 * (this.reproductiveManagement.pregnancyRate1IATFCows / 100) || 0);
    } else if (this.reproductiveManagement.maxIATFsPerCow == 2) {
      return Math.round(this.cowsIATFDistributionEnd3 * (this.reproductiveManagement.pregnancyRate1IATFCows / 100) + this.cowsIATFDistributionEnd2 * (this.reproductiveManagement.pregnancyRate2IATFCows / 100) || 0);
    } else {
      return Math.round(this.cowsIATFDistributionEnd3 * (this.reproductiveManagement.pregnancyRate1IATFCows / 100) + this.cowsIATFDistributionEnd2 * (this.reproductiveManagement.pregnancyRate2IATFCows / 100) + this.cowsIATFDistributionEnd1 * (this.reproductiveManagement.pregnancyRate3IATFCows / 100) || 0);
    }
  }

  // PREGNANCY IATF DISTRIBUTION PER SEASON - COL TOTAL
  get pregnancyIatfDistributionPerSeasonTotalBeginningEM() {
    return this.pregnancyIatfDistributionPerSeasonHeifersBeginningEM + this.pregnancyIatfDistributionPerSeasonCowsBeginningEM || 0;
  }
  get pregnancyIatfDistributionPerSeasonTotalMiddleEM() {
    return this.pregnancyIatfDistributionPerSeasonHeifersMiddleEM + this.pregnancyIatfDistributionPerSeasonCowsMiddleEM || 0;
  }
  get pregnancyIatfDistributionPerSeasonTotalEndEM() {
    return this.pregnancyIatfDistributionPerSeasonHeifersEndEM + this.pregnancyIatfDistributionPerSeasonCowsEndEM || 0;
  }

  // PREGNANCY IATF DISTRIBUTION PER SEASON - ROW TOTAL
  get pregnancyIatfDistributionPerSeasonHeifersTotal() {
    return this.pregnancyIatfDistributionPerSeasonHeifersBeginningEM + this.pregnancyIatfDistributionPerSeasonHeifersMiddleEM + this.pregnancyIatfDistributionPerSeasonHeifersEndEM || 0;
  }
  get pregnancyIatfDistributionPerSeasonCowsTotal() {
    return this.pregnancyIatfDistributionPerSeasonCowsBeginningEM + this.pregnancyIatfDistributionPerSeasonCowsMiddleEM + this.pregnancyIatfDistributionPerSeasonCowsEndEM || 0;
  }
  get pregnancyIatfDistributionPerSeasonTotal() {
    return this.pregnancyIatfDistributionPerSeasonTotalBeginningEM + this.pregnancyIatfDistributionPerSeasonTotalMiddleEM + this.pregnancyIatfDistributionPerSeasonTotalEndEM || 0;
  }

  // QUESTAO K
  get breedDistributionHeifersEM1Breed1 () {
    this.reproductiveManagement.breedDistributionHeifersBeginningBreed1Percent = this.heifersBeginningBreed1Percent;
    this.dosesDistributionByBreed.heifersBeginningBreed1 = Math.round(this.iatfDistributionPerSeasonHeifersBeginningEM * (this.heifersBeginningBreed1Percent / 100)) || 0;
    return this.dosesDistributionByBreed.heifersBeginningBreed1;
  }
  get breedDistributionHeifersEM2Breed1 () {
    this.reproductiveManagement.breedDistributionHeifersMiddleBreed1Percent = this.heifersMiddleBreed1Percent;
    this.dosesDistributionByBreed.heifersMiddleBreed1 = Math.round(this.iatfDistributionPerSeasonHeifersMiddleEM * (this.heifersMiddleBreed1Percent / 100)) || 0;
    return this.dosesDistributionByBreed.heifersMiddleBreed1;
  }
  get breedDistributionHeifersEM3Breed1 () {
    this.reproductiveManagement.breedDistributionHeifersEndBreed1Percent = this.heifersEndBreed1Percent;
    this.dosesDistributionByBreed.heifersEndBreed1 = Math.round(this.iatfDistributionPerSeasonHeifersEndEM * (this.heifersEndBreed1Percent / 100)) || 0;
    return this.dosesDistributionByBreed.heifersEndBreed1;
  }
  get breedDistributionCowsEM1Breed1 () {
    this.reproductiveManagement.breedDistributionCowsBeginningBreed1Percent = this.cowsBeginningBreed1Percent;
    this.dosesDistributionByBreed.cowsBeginningBreed1 = Math.round(this.iatfDistributionPerSeasonCowsBeginningEM * (this.cowsBeginningBreed1Percent / 100)) || 0;
    return this.dosesDistributionByBreed.cowsBeginningBreed1;
  }
  get breedDistributionCowsEM2Breed1 () {
    this.reproductiveManagement.breedDistributionCowsMiddleBreed1Percent = this.cowsMiddleBreed1Percent;
    this.dosesDistributionByBreed.cowsMiddleBreed1 = Math.round(this.iatfDistributionPerSeasonCowsMiddleEM * (this.cowsMiddleBreed1Percent / 100)) || 0;
    return this.dosesDistributionByBreed.cowsMiddleBreed1;
  }
  get breedDistributionCowsEM3Breed1 () {
    this.reproductiveManagement.breedDistributionCowsEndBreed1Percent = this.cowsEndBreed1Percent;
    this.dosesDistributionByBreed.cowsEndBreed1 = Math.round(this.iatfDistributionPerSeasonCowsEndEM * (this.cowsEndBreed1Percent / 100)) || 0;
    return this.dosesDistributionByBreed.cowsEndBreed1;
  }

  get breedDistributionHeifersEM1Breed2 () {
    this.reproductiveManagement.breedDistributionHeifersBeginningBreed2Percent = this.heifersBeginningBreed2Percent;
    this.dosesDistributionByBreed.heifersBeginningBreed2 = Math.round(this.iatfDistributionPerSeasonHeifersBeginningEM * (this.heifersBeginningBreed2Percent / 100)) || 0;
    return this.dosesDistributionByBreed.heifersBeginningBreed2;
  }
  get breedDistributionHeifersEM2Breed2 () {
    this.reproductiveManagement.breedDistributionHeifersMiddleBreed2Percent = this.heifersMiddleBreed2Percent;
    this.dosesDistributionByBreed.heifersMiddleBreed2 = Math.round(this.iatfDistributionPerSeasonHeifersMiddleEM * (this.heifersMiddleBreed2Percent / 100)) || 0;
    return this.dosesDistributionByBreed.heifersMiddleBreed2;
  }
  get breedDistributionHeifersEM3Breed2 () {
    this.reproductiveManagement.breedDistributionHeifersEndBreed2Percent = this.heifersEndBreed2Percent;
    this.dosesDistributionByBreed.heifersEndBreed2 = Math.round(this.iatfDistributionPerSeasonHeifersEndEM * (this.heifersEndBreed2Percent / 100)) || 0;
    return this.dosesDistributionByBreed.heifersEndBreed2;
  }
  get breedDistributionCowsEM1Breed2 () {
    this.reproductiveManagement.breedDistributionCowsBeginningBreed2Percent = this.cowsBeginningBreed2Percent;
    this.dosesDistributionByBreed.cowsBeginningBreed2 = Math.round(this.iatfDistributionPerSeasonCowsBeginningEM * (this.cowsBeginningBreed2Percent / 100)) || 0;
    return this.dosesDistributionByBreed.cowsBeginningBreed2;
  }
  get breedDistributionCowsEM2Breed2 () {
    this.reproductiveManagement.breedDistributionCowsMiddleBreed2Percent = this.cowsMiddleBreed2Percent;
    this.dosesDistributionByBreed.cowsMiddleBreed2 = Math.round(this.iatfDistributionPerSeasonCowsMiddleEM * (this.cowsMiddleBreed2Percent / 100)) || 0;
    return this.dosesDistributionByBreed.cowsMiddleBreed2;
  }
  get breedDistributionCowsEM3Breed2 () {
    this.reproductiveManagement.breedDistributionCowsEndBreed2Percent = this.cowsEndBreed2Percent;
    this.dosesDistributionByBreed.cowsEndBreed2 = Math.round(this.iatfDistributionPerSeasonCowsEndEM * (this.cowsEndBreed2Percent / 100)) || 0;
    return this.dosesDistributionByBreed.cowsEndBreed2;
  }

  get breedDistributionTotalEM1Breed1 () {
    return this.breedDistributionHeifersEM1Breed1 + this.breedDistributionCowsEM1Breed1;
  }
  get breedDistributionTotalEM1Breed2 () {
    return this.breedDistributionHeifersEM1Breed2 + this.breedDistributionCowsEM1Breed2;
  }
  get breedDistributionTotalEM2Breed1 () {
    return this.breedDistributionHeifersEM2Breed1 + this.breedDistributionCowsEM2Breed1;
  }
  get breedDistributionTotalEM2Breed2 () {
    return this.breedDistributionHeifersEM2Breed2 + this.breedDistributionCowsEM2Breed2;
  }
  get breedDistributionTotalEM3Breed1 () {
    return this.breedDistributionHeifersEM3Breed1 + this.breedDistributionCowsEM3Breed1;
  }
  get breedDistributionTotalEM3Breed2 () {
    return this.breedDistributionHeifersEM3Breed2 + this.breedDistributionCowsEM3Breed2;
  }

  get breedDistributionTotalBreed1 () {
    return this.breedDistributionTotalEM1Breed1 + this.breedDistributionTotalEM2Breed1 + this.breedDistributionTotalEM3Breed1;
  }
  get breedDistributionTotalBreed2 () {
    return this.breedDistributionTotalEM1Breed2 + this.breedDistributionTotalEM2Breed2 + this.breedDistributionTotalEM3Breed2;
  }
}
