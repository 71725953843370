<div class="container justify-content-center h-100">
  <div #div style="position: relative; height: 600px;">
    <div
      [style.visibility]="!showForm ? 'visible': 'hidden'" 
      style="position: absolute; top: 0; left: 0; width: 100%;">
      <div class="row h-100 listArea">
        <div class="col h-100">
          <div class="panel">
            <h2 class="text-center mb-4">Clientes cadastrados</h2>
            <div class="d-flex form-group">
              <div class="w-100 pr-2">
                <input class="form-control" type="search" placeholder="Pesquisar por nome ou documento" (input)="findByKeyword($event)" />
              </div>
              <div class="flex-shrink-1">
                <button class="btn btn-submit form-control" (click)="openForm(null)" >
                  <fa-icon [icon]="addCustomer" title="Adicionar cliente"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <ng-template [ngIf]="customerList.length > 0" [ngIfElse]="listEmpty">
              <kendo-grid
                (remove)="ChangeCustomerStatus($event)"
                [kendoGridBinding]="customerList"
                [data]="gridView"
                [sortable]="{allowUnsort: 'allowUnsort', mode: 'single'}"
                [sort]="customerSort"
                (sortChange)="customersSortChange($event)"
                [selectable]="true"
                (selectedKeysChange)="clickTableRow()"
                [kendoGridSelectBy]="selectedCallback"
                [selectedKeys]="gridSelection"
                style="max-height: calc(100% - 90px);"
                [height]="600">
                  <kendo-grid-column media="xs" title="Clientes">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          <dl (click)="openForm(dataItem)">
                              <dt>Cliente</dt>
                              <dd>{{ dataItem.person.name }}</dd>

                              <dt>Documento</dt>
                              <dd>{{ dataItem.person.docNumber }}</dd>

                              <dt>Email</dt>
                              <dd>{{ GetDefaultEmail(dataItem) }}</dd>

                              <dt>Data Cadastro</dt>
                              <dd>{{ dataItem.person.registerDate | date:'dd/MM/yyyy' }}</dd>

                              <dt>Ativo</dt>
                              <dd>{{ isActive(dataItem.active) }}</dd>
                          </dl>
                      </ng-template>
                  </kendo-grid-column>

                  <!-- columns for mid-size devices -->
                  <kendo-grid-column
                      media="sm"
                      field="person.name"
                      title="Cliente"
                      [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                      [footerStyle]="{'font-weight': 'bold','color': '#000', 'line-height': '1em'}">
                      <ng-template kendoGridFooterTemplate>Total: {{ customerList.length }}</ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                      media="sm"
                      field="person.docNumber"
                      title="Documento"
                      width="150"
                      [headerStyle]="{'background-color': '#035737','color': '#fff'}">
                  </kendo-grid-column>
                  <kendo-grid-column
                      media="md"
                      title="Email"
                      [headerStyle]="{'background-color': '#035737','color': '#fff'}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        {{ GetDefaultEmail(dataItem) }}
                      </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                      media="sm"
                      title="Data Cadastro"
                      field="person.registerDate"
                      width="150"
                      [headerStyle]="{'background-color': '#035737','color': '#fff'}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          <div *ngIf="dataItem.person.registerDate!=null">{{ dataItem.person.registerDate | date:'dd/MM/yyyy' }}</div>
                      </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                      media="md"
                      title="Ativo"
                      width="100"
                      field="active"
                      class="text-center"
                      [headerStyle]="{'background-color': '#035737','color': '#fff'}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <fa-icon [icon]="isActive(dataItem.active)"></fa-icon>
                      </ng-template>
                  </kendo-grid-column>

                  <!-- command columns -->
                  <kendo-grid-command-column
                      width="120"
                      media="md"
                      [headerStyle]="{'background-color': '#035737','color': '#fff'}">
                      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                          <button kendoGridRemoveCommand *ngIf="dataItem.active">Inativar</button>
                          <button kendoGridRemoveCommand *ngIf="!dataItem.active">Ativar</button>
                      </ng-template>
                  </kendo-grid-command-column>

              </kendo-grid>

              <div kendoDialogContainer></div>

          </ng-template>

          <div *ngIf="!isCustomersLoaded" class="text-center">
            <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
            <p class="text-center w-100">Buscando clientes...</p>
          </div>

          <ng-template #listEmpty>
            <div *ngIf="isCustomersLoaded" class="d-flex align-items-center" style="height: calc(100% - 90px);">
              <p class="text-center w-100">Nenhum cliente cadastrado.</p>
            </div>
          </ng-template>

        </div>
      </div>
    </div>

    <div
      [style.visibility]="showForm ? 'visible' : 'hidden'"
      style="position: absolute; top: 0; left: 0; width: 100%;">
      <div class="row formArea" [style.visibility]="showForm ? 'visible' : 'hidden'">
        <div class="col">
          <form (ngSubmit)="onSubmit()">
            <!-- INFORMAÇÕES DO CLIENTE -->
            <h2 class="text-center">Informações do cliente {{ GetCustomerName(gridSelection[0]) }}</h2>
            <app-person [person]="editCustomer.person"></app-person>

            <h2 class="text-center mt-4">Fazendas do cliente</h2>

            <div class="row px-2">
              <!-- CONTROLE DE FAZENDAS -->
              <div class="col-12 m-0 p-2">
                <app-farm [customer]="editCustomer"></app-farm>
                <small class="text-danger" *ngIf="!editCustomer.farm && isSubmitted">Informe ao menos uma fazenda</small>
              </div>
            </div>

            <div class="row px-2">
              <div class="col-12 m-0 p-2">
                <button class="btn btn-delete mr-3" type="button" (click)="closeForm()">Cancelar</button>
                <button class="btn btn-submit" type="submit">Salvar</button>
                <small *ngIf="error" class="alert alert-danger m-3 p-2">{{ error }}</small>
                <small *ngIf="success" class="alert alert-success m-3 p-2">{{ success }}</small>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>              
  </div>
</div>
