import { Component, OnInit, Input } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-quantity-to-inseminate]',
  templateUrl: './quantity-to-inseminate.component.html',
  styleUrls: ['./quantity-to-inseminate.component.scss']
})
export class QuantityToInseminateComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;

  constructor() { }

  ngOnInit() {
    this.reproductiveManagement.maxIATFsPerHeifer = 1;
    this.reproductiveManagement.maxIATFsPerCow = 1;
  }

  get nbrTotalToInsaminate() {
    const heifers = Number(this.reproductiveManagement.nbrOfHeifersToInseminate || 0);
    const cows = Number(this.reproductiveManagement.nbrOfCowsToInseminate || 0);
    return heifers + cows;
  }

}
