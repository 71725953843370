import { ManagersComponent } from '@app/managers/managers.component';
import { Component, OnInit, ViewChild, OnChanges, SimpleChanges, Input, ElementRef, AfterViewInit } from '@angular/core';
import 'rxjs/add/operator/map';
import { Person } from 'src/_models/person';
import { AddressComponent } from '@app/address/address.component';
import { EmailComponent } from '@app/email/email.component';
import { PhoneComponent } from '@app/phone/phone.component';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(AddressComponent, { static: false }) addresses: AddressComponent;
  @ViewChild(EmailComponent, { static: false }) emails: EmailComponent;
  @ViewChild(PhoneComponent, { static: false }) phones: PhoneComponent;
  @ViewChild(ManagersComponent, { static: false }) managers: ManagersComponent;

  @ViewChild('radioCpf', { static: false }) radioCpf: ElementRef;
  @ViewChild('radioCnpj', { static: false }) radioCnpj: ElementRef;

  // @ViewChild(AddressComponent) addresses;
  // @ViewChild(EmailComponent) emails;
  // @ViewChild(PhoneComponent) phones;
  // @ViewChild(ManagersComponent) managers;

  // @ViewChild('radioCpf') radioCpf: ElementRef;
  // @ViewChild('radioCnpj') radioCnpj: ElementRef;

  @Input() person: Person;

  public editPerson: Person;
  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public isCpfChecked = false;
  public isCnpjChecked = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.CopyPerson();
  }

  ngOnInit() {
    this.CopyPerson();
    this.showCpfOrCnpj();
  }

  ngAfterViewInit() {
    this.checkCpfOrCnpjRadio();
  }

  private CopyPerson() {
    this.editPerson = Object.assign({}, this.person);
  }

  public Validate(errors: any) {
    if (errors !== null) {
      // this.editPerson.docNumber = ``;
    }
  }

 public changeDocType(radio: string) {
   this.editPerson.docNumber = ``;
   if (radio === `radioCpf`) {
     this.isCpfChecked = true;
     this.isCnpjChecked = false;
     this.radioCnpj.nativeElement.checked = false;
   } else {
     this.isCnpjChecked = true;
     this.isCpfChecked = false;
     this.radioCpf.nativeElement.checked = false;
   }
 }

 public showCpfOrCnpj() {
    if (this.editPerson && this.editPerson.docNumber) {
      if (this.editPerson.docNumber.length === 11) {
        this.isCpfChecked = true;
        this.isCnpjChecked = false;
      } else {
        this.isCnpjChecked = true;
        this.isCpfChecked = false;
      }
    } else {
      this.isCpfChecked = true;
      this.isCnpjChecked = false;
    }
 }

  public checkCpfOrCnpjRadio() {
    if (this.editPerson && this.editPerson.docNumber) {
      if (this.editPerson.docNumber.length === 11) {
        this.radioCpf.nativeElement.checked = true;
        this.radioCnpj.nativeElement.checked = false;
      } else {
        this.radioCnpj.nativeElement.checked = true;
        this.radioCpf.nativeElement.checked = false;
      }
    } else {
      this.radioCpf.nativeElement.checked = true;
    }
  }

}
