export class SelectedBull {

    public id: number;

    public shortName: string;

    public bullId: number;

    public interviewCycleId: number;

    public cowsDoses: number;

    public heifersDoses: number;

    public pricePerDose: number;

    public indexValue: number;

    public total: number;

    constructor () { }

    deserialize(input: any): this {
      return Object.assign(this, input);
    }
}
