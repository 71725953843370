import { Component, OnInit, Input, ViewEncapsulation, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Interview } from 'src/_models/interview';
import { InterviewCycle } from 'src/_models/interview-cycle';
import { GridDataResult, SelectableSettings, RowClassArgs } from '@progress/kendo-angular-grid';
import { ApiService } from '@app/_services/api.service';
import { AxisLabelsPosition, SeriesLabels, ChartComponent } from '@progress/kendo-angular-charts';
import { SyntheticSelectResultPresentation } from 'src/_models/select-result-presentation/synthetic-select-result-presentation';
import { AnalyticSelectResultPresentation } from 'src/_models/select-result-presentation/analytic-select-result-presentation';
import { ProofTypeEnum } from 'src/_enums/proof-type-enum';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-select-result-presentation',
  encapsulation: ViewEncapsulation.None,
  styles: [
  `
    .whole-cell {
      display: block;
      padding: 8px 12px; /* depends on theme */
    }
    .traitGroup { background-color: #ccc; font-weight: bold; }
    .trait { background-color: #fff; }
  `
  ],
  templateUrl: './select-result-presentation.component.html',
  styleUrls: ['./select-result-presentation.component.scss']
})
export class SelectResultPresentationComponent implements OnInit {

  @Input() public interview: Interview;

  @ViewChild('chart', { static: false }) public chart: ChartComponent;
  // @ViewChild('chart') private chart: ChartComponent;

  public gridView: GridDataResult;
  public gridSelection: any[] = [];
  public selectableSettings: SelectableSettings;

  public analyticResults: Array<AnalyticSelectResultPresentation>;
  public syntheticResults: Array<SyntheticSelectResultPresentation>;
  public interviewCycle: InterviewCycle;
  public isResultsLoaded = false;
  public isChartLoaded = false;
  public valuesLabelsPosition: AxisLabelsPosition = 'end';
  public categoriesLabelsPosition: AxisLabelsPosition = 'onAxis';
  ProofTypeEnum: typeof ProofTypeEnum = ProofTypeEnum;

  public seriesLabels: SeriesLabels = {
    visible: true, // Note that visible defaults to false
    padding: 1,
    font: '11px Arial, sans-serif'
  };

  public selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(
    public apiService: ApiService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.analyticResults = new Array<AnalyticSelectResultPresentation>();
    this.syntheticResults = new Array<SyntheticSelectResultPresentation>();
    this.interviewCycle = this.interview.interviewCycle[this.interview.currentInterviewCycle];
    this.interviewCycle.analyticResultPresentation = new Array<AnalyticSelectResultPresentation>();
    this.getAnalyticSelectResultPresentation();
  }

  private getAnalyticSelectResultPresentation() {
    this.apiService.getAnalyticSelectResultPresentation(this.interviewCycle).subscribe((data: AnalyticSelectResultPresentation[]) => {
      this.interviewCycle.analyticResultPresentation.push(...data);
      this.configureAnalyticSelectResultPresentation(data);
    }, (error) => console.error(error));
  }

  private configureAnalyticSelectResultPresentation(analyticResults: AnalyticSelectResultPresentation[]) {
    this.createResults(analyticResults);
    this.setSelectedWeightingValues();
    this.setSelectedFiltersValues();
    this.isResultsLoaded = true;
    this.isChartLoaded = true;
  }

  private createResults(data: AnalyticSelectResultPresentation[]) {
    data.forEach((result) => {
        if (result.isTraitGroup) {
          this.analyticResults.push(result);
        } else {
            result.filterPercentSignal = result.filterPercentSignal ? result.filterPercentSignal : `<=`;
            result.filterPercentValue = result.filterPercentValue ? result.filterPercentValue : 0;
            result.filterDepSignal = result.filterDepSignal ? result.filterDepSignal : `<=`;
            result.filterDepValue = result.filterDepValue ? result.filterDepValue : 0;
            result.filterAccValue = result.filterAccValue ? result.filterAccValue : 0;
            result.weighting = result.weighting ? result.weighting : 0;
            this.analyticResults.push(result);

            const syntheticResult = new SyntheticSelectResultPresentation();
            syntheticResult.description = result.traitName;
            this.setPercent(syntheticResult, result);
            if (syntheticResult.percent > 0
                && syntheticResult != null) {
              this.syntheticResults.push(syntheticResult);
            }
          }
    });
  }

  public getDescription(dataItem: AnalyticSelectResultPresentation) {
    if (dataItem.isTraitGroup) {
      return dataItem.traitGroupName;
    }
    return dataItem.traitName;
  }

  // Use an arrow function to capture the 'this' execution context of the class.
  public rowCallback = (context: RowClassArgs) => {
    const isTraitGroup = context.dataItem.isTraitGroup;
    return {
      traitGroup: isTraitGroup,
      trait: !isTraitGroup,
    };
   }

   private setSelectedWeightingValues() {
     if (this.hasSelectedWeightingsValues()) {
        for (const tg of this.interviewCycle.selectedWeightingTraitsGroups) {
          for (const t of tg.trait) {
            const result = this.analyticResults.find(r => r.traitId === t.id);
            if (result != null) {
               result.weighting = t.weightingValue ? t.weightingValue : 0;
            }
          }
        }
      }
   }

   private setSelectedFiltersValues() {
    if (this.hasSelectedFilterValues()) {
        for (const tg of this.interviewCycle.selectedFilterTraitsGroups) {
          for (const t of tg.trait) {
            const result = this.analyticResults.find(r => r.traitId === t.id);
            if (result != null ) {
              result.filterPercentSignal = t.filterTopDecaSignal ? t.filterTopDecaSignal : `<=`;
              result.filterPercentValue = t.filterTopDecaValue ? t.filterTopDecaValue : 0;
              result.filterDepSignal = t.filterDepSignal ? t.filterDepSignal : `<=`;
              result.filterDepValue = t.filterDepValue ? t.filterDepValue : 0;
              result.filterAccValue = t.filterAccValue ? t.filterAccValue : 0;
            }
          }
        }
    }
  }

  private hasSelectedWeightingsValues(): boolean {
    return  (this.interviewCycle.selectedWeightingTraitsGroups != null &&
      this.interviewCycle.selectedWeightingTraitsGroups.length > 0);
  }

  private hasSelectedFilterValues(): boolean {
    return (this.interviewCycle.selectedFilterTraitsGroups != null &&
      this.interviewCycle.selectedFilterTraitsGroups.length > 0);
  }

  public chartMaxValue(): number {
    if (this.isDeca()) {
      return 10;
    }
    return 100;
  }

  public chartMajorUnit(): number {
    if (this.isDeca()) {
      return 1;
    }
    return 10;
  }

  public chartMinValue(): number {
    if (this.isDeca()) {
      return 1;
    }
    return 0;
  }

  public chartAxisCrossingValue(): number {
    if (this.isDeca()) {
      return 10;
    }
    return 100;
  }

  public isDeca(): boolean {
    const proofId = this.interviewCycle.proofId;
    return (proofId === this.ProofTypeEnum.ALIANCA ||
            proofId === this.ProofTypeEnum.SUMARIO_NATURA ||
            proofId === this.ProofTypeEnum.CONEXAO_DELTA_G ||
            proofId === this.ProofTypeEnum.PMGZ);
  }

  public chartBarColor(): string {
    return 'green';
  }

  public gridCellBackGroundColor(value: number): SafeStyle {
    let result = '';
    if (value > 0) {
      result = '#298461';
    }
    // else {
    //   result = 'transparent';
    // }
    return this.sanitizer.bypassSecurityTrustStyle(result);
  }

  public gridCellColor(value: number): SafeStyle {
    let result = '';
    if (value > 0) {
      result = '#fff';
    }
    // else {
    //   result = 'transparent';
    // }
    return this.sanitizer.bypassSecurityTrustStyle(result);
  }

  private setPercent(syntheticResult: SyntheticSelectResultPresentation, result: AnalyticSelectResultPresentation) {
    const percent = result.percent;
    if (this.isDeca()) {
      if (percent === 0
          || percent === 10) {
        syntheticResult.percent = null;
      } else {
        syntheticResult.percent = percent;
      }
    } else {
      if (percent === 100) {
        syntheticResult.percent = null;
      } else {
        syntheticResult.percent = percent;
      }
    }
  }
}
