import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Constants } from '@app/_utils/constants';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Person } from 'src/_models/person';
import { Phone } from 'src/_models/phone';
import { DialogConfirmService } from '@app/_services/dialog-confirm.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit, OnChanges {

  @Input() person: any;

  public editPerson: Person;
  public phoneList: Array<Phone>;
  public mask: Constants;
  // public phoneMask = ['(',/\d/, /\d/,') ', /[0-9{4,5}]/, '-', /\d/,/\d/, /\d/, /\d/];
  public phonePattern = '/\(\d{2}\) \d{4,5}\-\d{4}/g';

  public iconTrash = faTrashAlt;
  public iconAdd = faPlus;

  constructor(private dialogConfirmService: DialogConfirmService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.CopyPerson();
  }

  ngOnInit() {
    this.CopyPerson();
    this.CreateNewPerson();
    this.phoneList = [];
  }

  private CopyPerson() {
    this.editPerson = Object.assign({}, this.person);
  }

  private CreateNewPerson() {
    if (!this.editPerson || !this.editPerson.id) {
      this.editPerson = new Person();
      this.editPerson.phone = new Array<Phone>();
    }
  }

  public AddPhone() {
    if (!this.editPerson.phone) {
      this.editPerson.phone = new Array<Phone>();
    }
    this.editPerson.phone.push(new Phone());
    this.SetDefaultPhone();
  }

  public RemovePhone(index: number) {
    const dialogTitle = `Remover telefone`;
    const dialogContent =  `Deseja remover este telefone ?`;
    this.dialogConfirmService.Confirm(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
          if (action === `Sim`) {
            this.editPerson.phone.reverse().splice(index, 1);
            this.editPerson.phone.reverse();
            this.SetDefaultPhone();
          }
        }
    });
  }

  public ChangeDefaultPhone(index: number) {
    if (this.editPerson.phone.length > 1) {
      this.editPerson.phone.reverse();
      for (let i = 0; i < this.editPerson.phone.length; i++) {
        this.editPerson.phone[i].isDefault = false;
      }
      this.editPerson.phone[index].isDefault = true;
      this.editPerson.phone.reverse();
  } else {
    this.SetDefaultPhone();
  }
}

public SetDefaultPhone() {
  if (this.editPerson.phone.length === 1) {
    this.editPerson.phone[0].isDefault = true;
  }
}

  public PhoneMask(e: any) {
    //console.log(e);
    //const input = e.target;
    // input.mask = input.value.replace(/\D+/g, '').length <= 10 ? Constants.PHONE_FIXE : Constants.PHONE_MOBILE;
    // tslint:disable-next-line:max-line-length
    //input.parentNode.attributes['ng-reflect-mask'].value = input.value.replace(/\D+/g, '').length <= 10 ? Constants.PHONE_FIXE : Constants.PHONE_MOBILE;
    //console.log(input.mask);
    // let numbers = userInput.match(/\d/g);
    // let numberLength = 0;
    // if (numbers) {
    //   numberLength = numbers.join("").length;
    // }

    // if (numberLength > 10) {
    // return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    // } else {
    //   return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    // }
  }

}

