import { Address } from './address';
import { Email } from './email';
import { Phone } from './phone';

export class Person {

  public id: number;

  public name: string;

  public docNumber: string;

  public registerDate: Date;

  public lastAccess: Date;

  public address: Array<Address>;

  public email: Array<Email>;

  public phone: Array<Phone>;

  deserialize(input: any): this {
    Object.assign(this, input);

    this.address = this.address ? input.address.map((a: Address) => new Address().deserialize(a)) : this.address;
    this.email = this.email ? input.email.map((e: Email) => new Email().deserialize(e)) : this.email;
    this.phone = this.phone ? input.phone.map((p: Phone) => new Phone().deserialize(p)) : this.phone;

    return this;
  }
}
