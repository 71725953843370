import { Component, OnInit, OnChanges, DoCheck, IterableDiffers } from '@angular/core';
import { ApiService } from '@app/_services/api.service';
import { User } from 'src/_models/user';
import { ModalBonusProgramComponent } from '@app/interview/modal-bonus-program/modal-bonus-program.component';
import { BonusProgramm } from 'src/_models/bonus-program';
import { ModalHerdProblemComponent } from '@app/interview/modal-herd-problem/modal-herd-problem.component';
import { HerdProblem } from 'src/_models/herd-problem';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { BreedTypeSelection } from 'src/_models/breed-type-selection';
import { BreedSelection } from 'src/_models/breed-selection';
import { ProofSelection } from 'src/_models/proof-selection';
import { Question } from 'src/_models/question';
import { Suggestion } from 'src/_models/suggestion';
import { Breed } from 'src/_models/breed';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.scss']
})
export class ParameterComponent implements OnInit {

  public iconAdd = faPlus;

  // public user: User;
  public suggestion: Suggestion;

  public bonusProgram: Array<BonusProgramm>;
  public herdProblem: Array<HerdProblem>;

  public baseBreedsList: Array<Breed>;
  public suggestedBreedsList: Array<BreedSelection>;

  // OPCOES INICIALIZADAS COM 0, QUE REPRESENTA 'DESCONSIDERAR QUESTAO'
  public form = {
    // ID: 0
    origin: { id: 0, value: 0 },
    // ID: 1
    breedOptionsMatting: { id: 1, value: 0 },
    // ID: 2
    maxZebuine: { id: 2, value: 0, signal: 0 },
    // ID: 3
    maxTaurine: { id: 3, value: 0, signal: 0 },
    // ID: 4
    herdGoalMale: { id: 4, value: 0 },
    // ID: 5
    confinementTypeMale: { id: 5, value: 0 },
    // ID: 6
    usesCastration: { id: 6, value: 0 },
    // ID: 7
    herdGoalFemale: { id: 7, value: 0 },
    // ID: 8
    confinementTypeFemale: { id: 8, value: 0 }
  };

  constructor(private apiService: ApiService, private modalService: NgbModal) { }

  ngOnInit() {

    // this.user = new User();
    this.suggestion = new Suggestion();
    this.bonusProgram = new Array<BonusProgramm>();
    this.herdProblem = new Array<HerdProblem>();

    this.suggestedBreedsList = new Array<BreedSelection>();

    this.apiService.getBreeds().subscribe((data: Array<Breed>) => {
      this.baseBreedsList = data;
    }, (error: any) => console.error(error));

    // this.apiService.GetUser().subscribe((data: User) => {
    //   this.user = data;
    // }, (error) => console.error(error));

    this.loadBreeds();
  }

  // QUESTAO 4: PROGRAMAS DE BONIFICACAO
  public openBonusProgram() {
    const modalRef = this.modalService.open(ModalBonusProgramComponent, { centered: true });
    modalRef.componentInstance.bonus = this.bonusProgram;
    modalRef.result.then((result) => {
      this.bonusProgram = new Array<BonusProgramm>();
      this.bonusProgram.push(...result);
    }, (reason) => {
      console.log(`Dispensou ${this.getDismissReason(reason)}`);
    });
  }

  public removeBonusProgram(idx: number) {
    console.log(idx);
    this.bonusProgram.splice(idx, 1);
  }

  // QUESTAO 5: PROBLEMAS DO REBANHO
  public openHerdProblem() {
    const modalRef = this.modalService.open(ModalHerdProblemComponent, { centered: true });
    modalRef.componentInstance.list = this.herdProblem;
    modalRef.result.then((result) => {
      this.herdProblem = new Array<HerdProblem>();
      this.herdProblem.push(...result);
    }, (reason) => {
      console.log(`Dispensou ${this.getDismissReason(reason)}`);
    });
  }

  public removeHerdProblem(idx: number) {
    console.log(idx);
    this.herdProblem.splice(idx, 1);
  }

  public changeBaseBreed(baseBreed: any) {
    this.suggestion.baseBreeds = new Array<Breed>();
    this.suggestion.baseBreeds.push(...baseBreed);
  }

  public changeSuggestedBreed(suggestedBreeds: any) {
    this.suggestion.suggestedBreeds = new Array<BreedSelection>();
    this.suggestion.suggestedBreeds.push(...suggestedBreeds);
    console.log('this.suggestion.suggestedBreeds', this.suggestion.suggestedBreeds);
  }

  public saveSuggestion() {

    // VALIDAR DADOS BASICOS PARA CRIAR UMA SUGESTAO
    const descriptionOk = this.suggestion.description && this.suggestion.description.length > 0;
    console.log('descriptionOk ', descriptionOk);

    const breedsOk = this.suggestion.suggestedBreeds && this.suggestion.suggestedBreeds.length > 0;
    console.log('breedsOk ', breedsOk);

    const baseBreedOk = this.suggestion.baseBreeds && this.suggestion.baseBreeds.length > 0;

    if (descriptionOk && baseBreedOk && breedsOk) {
      this.suggestion.questions = new Array<Question>();
      // CRIAR OBJETO QUE REPRESENTE AS QUESTOES
      Object.keys(this.form).forEach((q, i) => {
        const itemId = (this.form[q].id ? this.form[q].id : null);
        const itemValue = (this.form[q].value ? this.form[q].value : this.form[q]);
        const itemSignal = (this.form[q].signal ? this.form[q].signal : 0);
        console.log('itemId : ', itemId);
        if (itemId && itemValue > 0) {
          this.suggestion.questions.push(<Question> {
            id: itemId,
            question: i,
            value: itemValue,
            signal: itemSignal
          });
        }
      });

      if (this.suggestion.questions.length > 0) {
        // VERIFICAR SE JA EXISTE UMA SUGESTAO IDENTICA
        console.log(this.suggestion);
      } else {
        console.log('Condições não informadas');
      }

    } else {
      if (!descriptionOk) { console.log('Informe uma descrição para a sugestão'); }
      if (!baseBreedOk) { console.log('Informe a raça base para a sugestão'); }
      if (!breedsOk) { console.log('A sugestão deve exibir raças de acordo com as condições'); }
    }

  }

  // ======================================================================================================
  // METODOS PRIVADOS
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'apertando ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'clicando fora do modal';
    } else {
      return  `com: ${reason}`;
    }
  }

  private loadBreeds() {
    this.apiService.getBreedTypesSelection().subscribe((data: ProofSelection[]) => {
      let breedSelection = new BreedSelection();
      data.map(proof => {
          if (!this.suggestedBreedsList.find(b => b.id === proof.breedId)) {
            breedSelection = <BreedSelection>
            {
              id: proof.breedId,
              name: proof.breedName
            };
            this.suggestedBreedsList.push(breedSelection);
          }
        }
      );
    }, (error) => console.error(error));
  }

}
