import { Person } from './person';
import { Farm } from './farm';

export class Customer {

  public id: number;

  public active: boolean;

  public isFromAltaOne: boolean;

  public salesRepAn8: bigint;

  public userId: string;

  public farm: Array<Farm>;

  public person: Person;

  public personId: number;

  constructor () {
    this.person = this.person ? this.person : new Person();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    this.farm = this.farm ? input.farm.map((farm: Farm) => new Farm().deserialize(farm)) : this.farm;

    return this;
  }
}
