import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor() {}

  public exportToExcel(items: any[], args:{tabName: string, filename: string}) {

    console.log(items);

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(items, {
      skipHeader: true,
    });

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, args.tabName);
    XLSX.writeFile(wb, args.filename);
  }
}
