import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "@app/_services/api.service";
import { Interview } from "src/_models/interview";
import { BreedSelection } from "src/_models/breed-selection";
import { BreedTypeSelection } from "src/_models/breed-type-selection";
import { ProofSelection } from "src/_models/proof-selection";
import { InterviewCycle } from "src/_models/interview-cycle";
import { Breed } from "src/_models/breed";
import { BreedEnum } from "src/_enums/breed-enum";

@Component({
  selector: "app-breed-proof-selection",
  templateUrl: "./breed-proof-selection.component.html",
  styleUrls: ["./breed-proof-selection.component.scss"],
})
export class BreedProofSelectionComponent implements OnInit {
  @Input() public interview: Interview;

  public proofsSelection: Array<ProofSelection>;

  public breedTypeList: Array<BreedTypeSelection>;

  public primaryBreedsList: Array<BreedSelection>;
  public secondaryBreedsList: Array<BreedSelection>;

  public primaryProofList: Array<ProofSelection>;
  public secondaryProofList: Array<ProofSelection>;

  public primaryBreedSuggestion: string[];
  public secondaryBreedSuggestion: string[];

  BreedEnum: typeof BreedEnum = BreedEnum;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.breedTypeList = new Array<BreedTypeSelection>();

    this.primaryBreedsList = new Array<BreedSelection>();
    this.secondaryBreedsList = new Array<BreedSelection>();

    this.primaryProofList = new Array<ProofSelection>();
    this.secondaryProofList = new Array<ProofSelection>();

    this.primaryBreedSuggestion = [];
    this.secondaryBreedSuggestion = [];

    // if (this.interview.interviewCycle === null) {
    //   this.interview.interviewCycle = new Array<InterviewCycle>();
    // }

    this.loadProofsSelection();
  }

  private loadProofsSelection() {
    this.apiService.getBreedTypesSelection().subscribe(
      (data: ProofSelection[]) => {
        this.proofsSelection = data;
        this.loadBreedTypes();
      },
      (error) => console.error(error)
    );
  }

  private loadBreedTypes() {
    this.proofsSelection.map((proof) =>
      // tslint:disable-next-line:triple-equals
      !this.breedTypeList.find((b) => b.breedTypeId == proof.breedTypeId)
        ? this.breedTypeList.push(<BreedTypeSelection>{
            breedTypeId: proof.breedTypeId,
            breedTypeName: proof.breedTypeName,
          })
        : []
    );
  }

  public changePrimaryBreedType(breedTypeId: number) {
    this.primaryBreedsList = [];
    this.primaryProofList = [];
    // tslint:disable-next-line:triple-equals
    this.proofsSelection
      .filter((proof) => proof.breedTypeId == breedTypeId)
      .map((proof) =>
        !this.primaryBreedsList.find((b) => b.id === proof.breedId)
          ? this.primaryBreedsList.push(<BreedSelection>{
              id: proof.breedId,
              name: proof.breedName,
            })
          : []
      );
  }

  public changeSecondaryBreedType(breedTypeId: number) {
    this.secondaryBreedsList = [];
    this.secondaryProofList = [];
    // tslint:disable-next-line:triple-equals
    this.proofsSelection
      .filter((proof) => proof.breedTypeId == breedTypeId)
      .map((proof) =>
        !this.secondaryBreedsList.find((b) => b.id === proof.breedId)
          ? this.secondaryBreedsList.push(<BreedSelection>{
              id: proof.breedId,
              name: proof.breedName,
            })
          : []
      );
  }

  public changePrimaryBreed(breedId: number) {
    this.primaryProofList = [];
    // tslint:disable-next-line:triple-equals
    this.proofsSelection
      .filter((proof) => proof.breedId == breedId)
      .map((proof) =>
        !this.primaryProofList.find((b) => b.proofId === proof.proofId)
          ? this.primaryProofList.push(proof)
          : []
      );
  }

  public changeSecondaryBreed(breedId: number) {
    this.secondaryProofList = [];
    // tslint:disable-next-line:triple-equals
    this.proofsSelection
      .filter((proof) => proof.breedId == breedId)
      .map((proof) =>
        !this.secondaryProofList.find((b) => b.proofId === proof.proofId)
          ? this.secondaryProofList.push(proof)
          : []
      );
  }

  public changeBreedProof(p: number, idx: number) {
    if (!this.interview.interviewCycle[idx]) {
      this.interview.interviewCycle[idx] = new InterviewCycle();
    }

    const tmpProofList =
      idx === 0 ? this.primaryProofList : this.secondaryProofList;

    console.log("primaryProofList: ", tmpProofList);

    // tslint:disable-next-line:triple-equals
    const selection = tmpProofList.find((b) => b.proofId == p);
    console.log("selection: ", selection);

    this.interview.interviewCycle[idx].interviewId = this.interview.id;
    this.interview.interviewCycle[idx].breedId = selection.breedId;
    this.interview.interviewCycle[idx].proofId = selection.proofId;
    this.interview.interviewCycle[idx].proofName = selection.proofName;
    this.interview.interviewCycle[idx].breedName = selection.breedName;

    // RESETAR TODOS OS OBJETOS DAS ETAPAS A FRENTE DO FORMULARIO
  }

  public isCrossedBaseBreed(): boolean {
    return (
      this.interview.herd.breedId === BreedEnum.ZEBU_MESTICA ||
      this.interview.herd.breedId === BreedEnum.CRUZA_ANGUS ||
      this.interview.herd.breedId === BreedEnum.CRUZA_HEREFORD ||
      this.interview.herd.breedId === BreedEnum.CRUZA_SINTETICA
    );
  }
}
