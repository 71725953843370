import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-max-iatf]',
  templateUrl: './max-iatf.component.html',
  styleUrls: ['./max-iatf.component.scss']
})
export class MaxIatfComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;

  @Output() pregnancyRate1IATFHeifersEmitter = new EventEmitter();
  @Output() pregnancyRate2IATFHeifersEmitter = new EventEmitter();
  @Output() pregnancyRate1IATFCowsEmitter = new EventEmitter();
  @Output() pregnancyRate2IATFCowsEmitter = new EventEmitter();

  private maxCow: number;
  private maxHeifer: number;

  constructor() { }

  ngOnInit() {
    this.maxHeifer = this.reproductiveManagement.maxIATFsPerHeifer;
    this.maxCow = this.reproductiveManagement.maxIATFsPerCow;
  }

  public maxIATFsPerHeifer() {
    // tslint:disable-next-line:triple-equals
    if (this.reproductiveManagement.maxIATFsPerHeifer == 1) {
      this.reproductiveManagement.pregnancyRate2IATFHeifers = 0;
      this.reproductiveManagement.pregnancyRate3IATFHeifers = 0;
    // tslint:disable-next-line:triple-equals
    } else if (this.reproductiveManagement.maxIATFsPerHeifer == 2) {
      this.reproductiveManagement.pregnancyRate3IATFHeifers = 0;
      if (this.maxHeifer < this.reproductiveManagement.maxIATFsPerHeifer) {
        // recalcula p/ 2
        this.pregnancyRate1IATFHeifersEmitter.emit();
      }
    } else {
      if (this.maxHeifer < this.reproductiveManagement.maxIATFsPerHeifer) {
        // recalcula p/ 3
        this.pregnancyRate2IATFHeifersEmitter.emit();
      }
    }
    this.maxHeifer = this.reproductiveManagement.maxIATFsPerHeifer;
  }

  public maxIATFsPerCowChange() {
    // tslint:disable-next-line:triple-equals
    if (this.reproductiveManagement.maxIATFsPerCow == 1) {
      this.reproductiveManagement.pregnancyRate2IATFCows = 0;
      this.reproductiveManagement.pregnancyRate3IATFCows = 0;
    // tslint:disable-next-line:triple-equals
    } else if (this.reproductiveManagement.maxIATFsPerCow == 2) {
      this.reproductiveManagement.pregnancyRate3IATFCows = 0;
      if (this.maxCow < this.reproductiveManagement.maxIATFsPerCow) {
        // recalcula p/ 2
        this.pregnancyRate1IATFCowsEmitter.emit();
      }
    } else {
      if (this.maxCow < this.reproductiveManagement.maxIATFsPerCow) {
        // recalcula p/ 3
        this.pregnancyRate2IATFCowsEmitter.emit();
      }
    }
    this.maxCow = this.reproductiveManagement.maxIATFsPerCow;
  }

}
