import { Uf } from './uf';

export class City {

  public id: number;

  public name: string;

  public ibge: string;

  public ufId: string;

  public uf: Uf;

  constructor () {
    if (!this.uf) { this.uf = new Uf(); }
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
