export class FilterQuestion {

    public suggestionId: number;

    public questionId: number;

    public value: string;

    public name: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
