import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, ViewChildren, QueryList, ElementRef, AfterViewChecked } from '@angular/core';
import { faCheck, faTimes, faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Customer } from 'src/_models/customer';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import 'rxjs/add/operator/map';
import { ApiService } from '@app/_services/api.service';
import { User } from 'src/_models/user';
import { Person } from 'src/_models/person';
import { FarmComponent } from '@app/farm/farm.component';
import { Farm } from 'src/_models/farm';
import { DialogConfirmService } from '@app/_services/dialog-confirm.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { PersonComponent } from '@app/person/person.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit, OnChanges {

  @ViewChild(PersonComponent, { static: false }) personReference: PersonComponent;
  @ViewChild(FarmComponent, { static: false }) farmReference: FarmComponent;
  @ViewChild('div', { static: false }) public div: ElementRef<any>;

  // @ViewChild(PersonComponent) personReference;
  // @ViewChild(FarmComponent) farmReference;

  // @ViewChild('div') public div: ElementRef<any>;

  constructor(
    private apiService: ApiService,
    private dialogConfirmService: DialogConfirmService) { }

  public currentUser: User;

  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public customer: Customer;
  public editCustomer: Customer;
  public customerList: Array<Customer>;
  public gridView: GridDataResult;
  public addCustomer = faPlus;
  public customerSort: SortDescriptor[] = [{ field: 'name', dir: 'asc' }];
  public gridSelection: any[] = [];
  public showForm = false;
  public isSubmitted = false;
  public isCustomersLoaded = false;
  public error: string;
  public success: string;

  public selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  ngOnChanges(changes: SimpleChanges) {
    this.CopyCustomer();
  }

  ngOnInit() {
    this.gridView = {
      data: [],
      total: 0
    };
    this.CopyCustomer();
    this.GetCustomers();
    this.CreateCustomer();

    this.customerList = [];
  }

  // ngAfterViewChecked() {
  //   this.scrollTop();
  // }

  private CopyCustomer() {
    this.editCustomer = Object.assign({}, this.customer);
  }

  /* #region LISTA DE CLIENTES */
  // VERIFICA SE O CLIENTE ESTÁ ATIVO
  public isActive(active: boolean): IconDefinition {
    return active ? faCheck : faTimes;
  }

  // EVENTO DE MUDANÇA NA ORDENAÇÃO DA LISTA DE CLIENTES
  public customersSortChange(sort: SortDescriptor[]): void {
    this.customerSort = sort;
    this.OrderCustomers();
  }

  // FILTRA A LISTA DE CLIENTES A PARTIR DO VALOR INSERIDO NO INPUT DE BUSCA
  public findByKeyword(e: any) {
    const keyword: string = e.target.value;
    this.FindCustomers(keyword.toLowerCase());
  }

  // ABRE O CADASTRO DE CLIENTE
  public openForm(c: any) {
    if (c) {
      this.editCustomer = c;
    } else {
      this.CreateCustomer();
    }
    this.showForm = true;
    this.success = ``;
    this.error = ``;
  }

  // CLICK DA LINHA DA TABELA
  public clickTableRow() {
    this.openForm(this.gridSelection[0]);
  }

  // FECHA O CADASTRO DE CLIENTE E VOLTA PRA LISTA
  public closeForm() {
    // PRECISA FAZER AS VALIDAÇÕES PARA NÃO PERDER DADOS ALTERADOS
    this.gridSelection = [];
    this.showForm = false;
    this.isSubmitted = false;
    this.scrollTop();
    this.GetCustomers();
  }

  public scrollTop() {
    this.div.nativeElement.scrollIntoView();
  }

  // ATUALIZA GRID DO KENDO BASEADO NA ORDENACAO
  private OrderCustomers(): void {
    if (this.customerList.length > 0) {
      this.gridView = {
          data: orderBy(this.customerList, this.customerSort),
          total: this.customerList.length
      };
    }
  }

  // ATUALIZA GRID DO KENDO BASEADO NA BUSCA TEXTUAL
  private FindCustomers(keyword: string): void {
    const filteredCustomerList = this.customerList
      .filter(c => c.person.name.toLowerCase().indexOf(keyword) > -1 || c.person.docNumber.indexOf(keyword) > -1);
    this.gridView = {
        data: filteredCustomerList,
        total: filteredCustomerList.length
    };
  }

  private GetCustomers() {
    this.apiService.GetUser().subscribe((data: User) => {
      this.currentUser = data;
      this.customerList = this.currentUser.customer;
      this.OrderCustomers();
      this.isCustomersLoaded = true;
    }, (error) => console.error(error));
  }

  private CreateCustomer() {
      this.customer = new Customer();
      this.customer.id = 0;
      this.customer.active = true;
      this.customer.userId = this.currentUser ? this.currentUser.id : '';
      this.customer.person = new Person();
      this.customer.farm = new Array<Farm>();

      this.editCustomer = new Customer();
      this.editCustomer.id = 0;
      this.editCustomer.active = true;
      this.editCustomer.userId = this.currentUser ? this.currentUser.id : '';
      this.editCustomer.person = new Person();
      this.editCustomer.farm = new Array<Farm>();

      this.gridSelection = [];
  }

  public GetCustomerName(customer: any): string {
    if (customer != null) {
      return customer.person.name;
    }
    return '';
  }

  private MapCustomerToSubmit() {
    this.customer.id = this.editCustomer.id;
    this.customer.active = this.editCustomer.active;
    this.customer.userId = this.currentUser.id;
    this.customer.personId = this.personReference.editPerson.id;
    this.customer.person = this.personReference.editPerson;
    this.customer.person.address = this.personReference.addresses.editPerson.address;
    this.customer.person.email = this.personReference.emails.editPerson.email;
    this.customer.person.phone = this.personReference.phones.editPerson.phone;
    this.customer.farm = new Array<Farm>();

    const herds = this.farmReference.herds.toArray();

    for (const herd of herds) {
      const farm = new Farm();
      farm.customerId = this.customer.id;
      farm.id = herd.farm.id;
      farm.name = herd.farm.name;
      farm.herd = herd.editFarm.herd;

      this.customer.farm.push(farm);
    }

    // const herds = this.farmReference.herds.toArray();

    // for (let h = 0; h < herds.length; h++) {
    //   const farm = new Farm();
    //   farm.customerId = this.customer.id;
    //   farm.id = herds[h].farm.id;
    //   farm.name = herds[h].farm.name;
    //   farm.herd = herds[h].editFarm.herd;

    //   this.customer.farm.push(farm);
    // }
  }

  public onSubmit() {
    this.MapCustomerToSubmit();
    this.isSubmitted = true;
    if (this.Validate()) {
      if (this.customer.id === 0 || this.customer.id === undefined) {
        this.InsertCustomer();
      } else {
        this.UpdateCustomer();
      }
    }
  }

  private ValidateCustomer(): boolean {
    if (!this.customer.person.name) {
      return false;
    }
    if (!this.customer.person.docNumber) {
      return false;
    }
    return true;
  }

  private ValidateAddress(): boolean {
    if (this.customer.person.address.length === 0) {
      return false;
    } else {
       for (let i = 0; i < this.customer.person.address.length; i++) {
        if (!this.customer.person.address[i].postalCode) {
          return false;
        }
        if (!this.customer.person.address[i].street) {
          return false;
        }
        if (!this.customer.person.address[i].number) {
          return false;
        }
        if (!this.customer.person.address[i].cityId) {
          return false;
        }
      }
    }
    return true;
  }

  private ValidateEmail(): boolean {
    if (this.customer.person.email.length === 0) {
      return false;
    } else {
      for (let i = 0; i < this.customer.person.email.length; i++) {
        if (!this.customer.person.email[i].email1) {
          return false;
        }
      }
    }
    return true;
  }

  private ValidatePhone(): boolean {
    if (this.customer.person.phone.length === 0) {
      return false;
    } else {
      for (let i = 0; i < this.customer.person.phone.length; i++) {
        if (!this.customer.person.phone[i].phone1) {
          return false;
        }
      }
    }
    return true;
  }

  private ValidateFarm(): boolean {
    if (this.customer.farm.length === 0) {
      return false;
    } else {
      for (let i = 0; i < this.customer.farm.length; i++) {
        if (!this.customer.farm[i].name) {
          return false;
        }
      }
    }
    return true;
  }

  private ValidateHerd(): boolean {
    for (let f = 0; f < this.customer.farm.length; f++) {
      if (!this.customer.farm[f].herd) {
        return false;
      }
      for (let h = 0; h < this.customer.farm[f].herd.length; h++) {
        if (!this.customer.farm[f].herd[h].name) {
          return false;
        }
        if (!this.customer.farm[f].herd[h].numberOfCows &&
            !this.customer.farm[f].herd[h].numberOfHeifers) {
          return false;
        }
        const totalAnimals = this.customer.farm[f].herd[h].numberOfCows + this.customer.farm[f].herd[h].numberOfHeifers;
        if (totalAnimals <= 0) {
          return false;
        }
        // if (!this.customer.farm[f].herd[h].numberOfHeifers) {
        //   return false;
        // }
        if (!this.customer.farm[f].herd[h].breedId) {
          return false;
        }
      }
    }
    return true;
  }

  private Validate(): boolean {
    if (this.ValidateCustomer()
      && this.ValidateAddress()
      && this.ValidateEmail()
      && this.ValidatePhone()
      && this.ValidateFarm()
      && this.ValidateHerd()) {
      return true;
    }
    return false;
  }

  private showMessage(dialogTitle: string, dialogContent: string) {
    this.dialogConfirmService.Alert(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
        }
    });
  }

  private InsertCustomer() {
    this.apiService.CreateCustomer(this.customer).subscribe((data: Customer) => {
        this.customerList.push(data);
        this.gridView.data = this.customerList;
        this.success = `O cliente ` + this.customer.person.name + ` foi criado com sucesso`;
        this.error = ``;
        this.CreateCustomer();
        this.GetCustomers();
        this.isSubmitted = false;
        this.personReference.addresses.canAddAddress = true;
    }, (error) => this.error = JSON.stringify(error.error.errors));
  }

  private UpdateCustomer() {
    this.apiService.UdpateCustomer(this.customer).subscribe((data: Customer) => {
      let customer = this.customerList.filter((c: Customer) => c.id === this.customer.id)[0];
      customer = data;
      this.success = `O cliente ` + this.customer.person.name + ` foi atualizado com sucesso`;
      this.error = ``;
      this.CreateCustomer();
      this.GetCustomers();
      this.isSubmitted = false;
      this.personReference.addresses.canAddAddress = true;
    }, (error) => this.error = JSON.stringify(error.error.errors));
  }

  public RemoveCustomer(e: any) {
    // this.dialogconfirmService.confirm('Deseja excluir o cliente ' + e.dataItem.person.name + ' ?')
    // .then((isToDelete: boolean) => {
    //     if (isToDelete) {
    //         this.apiService
    //         .DeleteCustomer(e.dataItem.id)
    //         .then(() => {
    //              this.customerList = this.customerList.filter((c: Customer) => c.id !== e.dataItem.id);
    //         }).catch(error => {
    //           console.error(error);
    //         });
    //     }
    // });
  }

  public ChangeCustomerStatus(e: any) {
    const dialogTitle = `Alteração de status`;
    const dialogContent =  `Deseja ` +  this.TranslateStatus(e.dataItem.active) + ` o cliente ` + e.dataItem.person.name + ` ?`;
    this.dialogConfirmService.Confirm(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
          if (action === `Sim`) {
            this.apiService
            .ChangeCustomerStatus(e.dataItem.id)
            .then(() => {
                const customer = this.customerList.filter((c: Customer) => c.id === e.dataItem.id)[0];
                customer.active = !e.dataItem.active;
            }).catch(error => {
                console.error(error);
            });
          }
        }
    });
  }

  private TranslateStatus(active: boolean): string {
    if (!active) {
      return 'ativar';
    }
    return 'inativar';
  }

  public GetDefaultEmail(customer: Customer): string {
    let email = ``;
    for (let i = 0; i < customer.person.email.length; i++) {
      if (customer.person.email[i].isDefault) {
        email = customer.person.email[i].email1;
      }
    }
    return email;
  }
}
