export class CowsHeifersQuantity {

    public id: number;

    public breedingSeasonStartHeifers?: number;

    public breedingSeasonStartCows?: number;

    public breedingSeasonMiddleHeifers?: number;

    public breedingSeasonMiddleCows?: number;

    public breedingSeasonEndHeifers?: number;

    public breedingSeasonEndCows?: number;

    deserialize(input: any): this {
      return Object.assign(this, input);
    }
  }
  