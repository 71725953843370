<div>
  <h2 class="text-center my-4">Manejo reprodutivo</h2>
  <div class="form-row border rounded p-4 bg-white col-12">

    <h3 class="p-2 mb-2">Quantidade de animais no rebanho</h3>
    <div class="col-12 row w-100 m-0">
      <div class="col-6 px-1">
        <div class="form-row">
          <div class="form-group row w-100">
            <label class="col-form-label col-12 col-sm-9 p-2" for="nbrOfCowsInHerd">Vacas</label>
            <div class="col-12 col-sm-3 py-0 px-1">
              <input type="text" [(ngModel)]="herd.numberOfCows" class="form-control text-right font-weight-bold" id="nbrOfCowsInHerd" disabled/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 px-1">
        <div class="form-row">
          <div class="form-group row w-100">
            <label class="col-form-label col-12 col-sm-9 p-2" for="nbrOfHeiferssInHerd">Novilhas</label>
            <div class="col-12 col-sm-3 py-0 px-1">
              <input type="text" [(ngModel)]="herd.numberOfHeifers" class="form-control text-right font-weight-bold" id="nbrOfHeiferssInHerd" disabled/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="w-100 divider mb-4" />

    <div app-quantity-to-inseminate class="col-12 col-lg-6" [reproductiveManagement]="reproductiveManagement"></div>

    <hr class="d-block d-lg-none w-100 divider mb-4" />

    <div app-max-iatf class="col-12 col-lg-6"
      [reproductiveManagement]="reproductiveManagement"
      (pregnancyRate1IATFHeifersEmitter)="pregnancyRate1IATFHeifersChange()"
      (pregnancyRate2IATFHeifersEmitter)="pregnancyRate2IATFHeifersChange()"
      (pregnancyRate1IATFCowsEmitter)="pregnancyRate1IATFCowsChange()"
      (pregnancyRate2IATFCowsEmitter)="pregnancyRate2IATFCowsChange()"></div>

    <hr class="w-100 divider mb-4" />

    <div app-ready-to-iatf 
      class="col-12 col-lg-6" 
      (isValidReadyToIATFTotal)="updateIsValidReadyToIATFTotal($event)" 
      [reproductiveManagement]="reproductiveManagement">
    </div>

    <hr class="d-block d-lg-none w-100 divider mb-4" />

    <div app-calving-distribution 
      class="col-12 col-lg-6"
      (isValidCalvingDistributionTotal)="updateIsValidCalvingDistributionTotal($event)"
      [reproductiveManagement]="reproductiveManagement">
    </div>

    <hr class="w-100 divider mb-4" />

    <div app-pregnancy-rate class="col-12 col-lg-6"
      [reproductiveManagement]="reproductiveManagement"
      (pregnancyRate1IATFHeifersEmitter)="pregnancyRate1IATFHeifersChange()"
      (pregnancyRate2IATFHeifersEmitter)="pregnancyRate2IATFHeifersChange()"
      (pregnancyRate1IATFCowsEmitter)="pregnancyRate1IATFCowsChange()"
      (pregnancyRate2IATFCowsEmitter)="pregnancyRate2IATFCowsChange()"></div>

    <hr class="d-block d-lg-none w-100 divider mb-4" />

    <div app-iatf-distribution class="col-12 col-lg-6" [reproductiveManagement]="reproductiveManagement"></div>

    <hr class="w-100 divider mb-4" />

    <div app-iatf-distribution-per-season class="col-12 col-lg-6" [reproductiveManagement]="reproductiveManagement"></div>

    <hr class="d-block d-lg-none w-100 divider mb-4" />

    <div app-pregnancy-iatf-distribution-per-season class="col-12 col-lg-6" [rm]="reproductiveManagement"></div>

    <div app-average-pregnancy-rate class="col-12" [reproductiveManagement]="reproductiveManagement"></div>

    <hr *ngIf="interviewCycle.length > 1" class="w-100 divider mb-4" />

    <div *ngIf="interviewCycle.length > 1" app-breed-distribution
        class="col-12"
        [interviewCycle]="interviewCycle"
        [reproductiveManagement]="reproductiveManagement"
        [dosesDistributionByBreed]="dosesDistributionByBreed">
    </div>

    <hr *ngIf="interviewCycle.length > 1" class="w-100 divider mb-4" />

    <div *ngIf="interviewCycle.length > 1"
        app-pregnancy-distribution-per-breed class="col-12 col-lg-6"
        [breed]="interviewCycle[0].breedName"
        [breedNumber] = "0"
        [rm]="reproductiveManagement">
    </div>

    <div *ngIf="interviewCycle.length > 1"
        app-pregnancy-distribution-per-breed class="col-12 col-lg-6"
         [breed]="interviewCycle[1].breedName"
         [breedNumber] = "1"
         [rm]="reproductiveManagement">
    </div>

    <div *ngIf="interviewCycle.length > 1" class="row ">
      <div class="col-md-6 col-12">
        <div class="form-row">
          <div class="form-group row w-100">
            <label class="col-form-label col-12 col-sm-9 p-2" for="averagePregnancyRate">Taxa de prenhez de IATF de {{ interviewCycle[0].breedName }}</label>
            <div class="input-group col-12 col-sm-3 p-1">
              <input type="text" class="form-control text-right font-weight-bold" [value]="iatfPregnancyRateBreed1()" name="iatfPregnancyRateBreed1" id="iatfPregnancyRateBreed1" value="0,00" disabled/>
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-row">
          <div class="form-group row w-100">
            <label class="col-form-label col-12 col-sm-9 p-2" for="pregnancyRateHeifers">Taxa de prenhez de IATF de {{ interviewCycle[1].breedName }}</label>
            <div class="input-group col-12 col-sm-3 p-1">
              <input type="text" class="form-control text-right font-weight-bold" [value]="iatfPregnancyRateBreed2()" name="iatfPregnancyRateBreed2" id="iatfPregnancyRateBreed2" value="0,00" disabled/>
              <div class="input-group-append">
                  <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
