import { Component, OnInit, Input } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import { ReproductiveManagementService } from '@app/_services/reproductive-management.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-iatf-distribution]',
  templateUrl: './iatf-distribution.component.html',
  styleUrls: ['./iatf-distribution.component.scss']
})
export class IatfDistributionComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;

  constructor(public reproductiveManagementService: ReproductiveManagementService) { }

  ngOnInit() {
  }

  // IATF DISTRIBUTION - HEIFERS
  get iatfDistributionHeifers1IATF () {
    this.reproductiveManagement.iatfDistributionHeifers1IATF = this.reproductiveManagementService.iatfDistributionHeifers1IATF;
    return this.reproductiveManagement.iatfDistributionHeifers1IATF;
  }
  get iatfDistributionHeifers2IATF () {
    this.reproductiveManagement.iatfDistributionHeifers2IATF = this.reproductiveManagementService.iatfDistributionHeifers2IATF;
    return this.reproductiveManagement.iatfDistributionHeifers2IATF;
  }
  get iatfDistributionHeifers3IATF () {
    this.reproductiveManagement.iatfDistributionHeifers3IATF = this.reproductiveManagementService.iatfDistributionHeifers3IATF;
    return this.reproductiveManagement.iatfDistributionHeifers3IATF;
  }

  // IATF DISTRIBUTION - COWS
  get iatfDistributionCows1IATF () {
    this.reproductiveManagement.iatfDistributionCows1IATF = this.reproductiveManagementService.iatfDistributionCows1IATF;
    return this.reproductiveManagement.iatfDistributionCows1IATF;
  }
  get iatfDistributionCows2IATF () {
    this.reproductiveManagement.iatfDistributionCows2IATF = this.reproductiveManagementService.iatfDistributionCows2IATF;
    return this.reproductiveManagement.iatfDistributionCows2IATF;
  }
  get iatfDistributionCows3IATF () {
    this.reproductiveManagement.iatfDistributionCows3IATF = this.reproductiveManagementService.iatfDistributionCows3IATF;
    return this.reproductiveManagement.iatfDistributionCows3IATF;
  }

  // IATF DISTRIBUTION - TOTAL
  get iatfDistributionTotal1IATF() {
    this.reproductiveManagement.iatfDistributionTotal1IATF = this.reproductiveManagementService.iatfDistributionTotal1IATF;
    return this.reproductiveManagement.iatfDistributionTotal1IATF;
  }
  get iatfDistributionTotal2IATF() {
    this.reproductiveManagement.iatfDistributionTotal2IATF = this.reproductiveManagementService.iatfDistributionTotal2IATF;
    return this.reproductiveManagement.iatfDistributionTotal2IATF;
  }
  get iatfDistributionTotal3IATF() {
    this.reproductiveManagement.iatfDistributionTotal3IATF = this.reproductiveManagementService.iatfDistributionTotal3IATF;
    return this.reproductiveManagement.iatfDistributionTotal3IATF;
  }

}
