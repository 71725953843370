import { Trait } from '../trait';

export class FilterSuggestionHasTrait {

    public suggestionId: number;

    public traitId: number;

    public breedId: number;

    public proofId: number;

    public value: string;

    public trait: Trait;

    public signal: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
