<div class="modal-header">
  <h2 class="modal-title">Recuperação de acesso</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="p-3" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="userEmail">E-mail de acesso</label>
      <input type="email" formControlName="email" class="form-control" id="userEmail" placeholder="Digite seu e-mail" autocomplete="false">
      <div *ngIf="submitted && !!f.email.errors" class="text-danger">
        <small *ngIf="!!f.email.errors.required">Informe seu e-mail</small>
        <small *ngIf="!!f.email.errors.email">Informe um e-mail válido</small>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="activeModal.close('Close click')">Cancelar</button>
  <button type="button" class="btn btn-submit" (click)="onSubmit()" >Enviar</button>
</div>
