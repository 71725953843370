export enum ProofTypeEnum {
  AAA = 12,
  ERA = 13,
  PROMEBO = 14,
  ALIANCA = 11,
  ANCP = 7,
  GENEPLUS = 8,
  PMGZ = 6,
  QUALITAS = 10,
  ASOSIACION_ARGENTINA_BRANGUS = 15,
  SUMARIO_NATURA = 16,
  ASOSIACION_ARGENTINA_HEREFORD = 17,
  CONEXAO_DELTA_G = 18,
  ASOSIACION_ARGENTINA_BRAFORD = 19,
  RED_ANGUS_ASSOCIATION_OF_AMERICA = 20
}
