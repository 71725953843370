import { Component, OnInit, ViewChild } from '@angular/core';
import 'rxjs/add/operator/map';
import { User } from 'src/_models/user';
import { ApiService } from '@app/_services/api.service';
import { PersonComponent } from '@app/person/person.component';
import { Person } from 'src/_models/person';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @ViewChild(PersonComponent, { static: false }) personReference: PersonComponent;
  // @ViewChild(PersonComponent) personReference;

  public cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  public currentUser: User;
  public editUser: User;
  public passwordConfirm: string;
  public isSubmitted = false;
  public error: string;
  public success: string;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.GetCurrentUser();
    this.currentUser = new User();
    this.currentUser.person = new Person();
  }

  private GetCurrentUser() {
    this.apiService.GetCurrentUser().subscribe((data: User) => {
      this.editUser = data;
    }, (error) => console.error(error));
  }

  private MapUserToSubmit() {
    this.currentUser.id = this.editUser.id;
    this.currentUser.password = this.editUser.password;
    this.currentUser.status = this.editUser.status;
    this.currentUser.personId = this.editUser.personId;
    this.currentUser.person.id = this.personReference.editPerson.id;
    this.currentUser.person.name = this.personReference.editPerson.name;
    this.currentUser.person.docNumber = this.personReference.editPerson.docNumber;
    this.currentUser.person.address = this.personReference.editPerson.address;
    this.currentUser.person.email = this.personReference.editPerson.email;
    this.currentUser.person.phone = this.personReference.editPerson.phone;

    for (let i = 0; i < this.currentUser.person.email.length; i++) {
      if (this.currentUser.person.email[i].isDefault) {
        this.currentUser.email = this.currentUser.person.email[i].email1;
        break;
      }
    }
  }

  public OnSubmit() {
    this.success = ``;
    this.error = ``;
    this.isSubmitted = true;
    this.MapUserToSubmit();
    if (this.Validate()) {
      this.UpdateUser();
    }
  }

  private ValidateUser(): boolean {
    if (!this.currentUser.person.name) {
      return false;
    }
    if (!this.currentUser.person.docNumber) {
      return false;
    }
    // if (!this.currentUser.password) {
    //   return false;
    // }
    return true;
  }

  private ValidateAddress(): boolean {
    if (this.currentUser.person.address.length === 0) {
      return false;
    } else {
       for (let i = 0; i < this.currentUser.person.address.length; i++) {
        if (!this.currentUser.person.address[i].postalCode) {
          return false;
        }
        if (!this.currentUser.person.address[i].street) {
          return false;
        }
        if (!this.currentUser.person.address[i].number) {
          return false;
        }
        if (!this.currentUser.person.address[i].cityId) {
          return false;
        }
      }
    }
    return true;
  }

  private ValidateEmail(): boolean {
    if (this.currentUser.person.email.length === 0) {
      return false;
    } else {
      for (let i = 0; i < this.currentUser.person.email.length; i++) {
        if (!this.currentUser.person.email[i].email1) {
          return false;
        }
      }
    }
    return true;
  }

  private ValidatePhone(): boolean {
    if (this.currentUser.person.phone.length === 0) {
      return false;
    } else {
      for (let i = 0; i < this.currentUser.person.phone.length; i++) {
        if (!this.currentUser.person.phone[i].phone1) {
          return false;
        }
      }
    }
    return true;
  }

  private Validate(): boolean {
    if (this.ValidateUser()
      && this.ValidateAddress()
      && this.ValidateEmail()
      && this.ValidatePhone()
    ) {
      return true;
    }
    return false;
  }

  private UpdateUser() {
    this.apiService.UdpateUser(this.currentUser).subscribe((data: User) => {
      this.success = `Seus dados foram atualizados com sucesso`;
      this.error = ``;
      this.isSubmitted = false;
    }, (error) =>  this.error = JSON.stringify(error.error.errors));
  }
}
