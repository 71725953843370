import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Uf } from 'src/_models/uf';
import { City } from 'src/_models/city';
import { Person } from 'src/_models/person';
import { Address } from 'src/_models/address';
import { DialogConfirmService } from '@app/_services/dialog-confirm.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { ApiService } from '@app/_services/api.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnChanges {

  @Input() person: any;

  public editPerson: Person;
  public ufList: Array<Uf>;
  public citiesList: Array<City>;
  public cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  public iconTrash = faTrashAlt;
  public iconAdd = faPlus;
  public canAddAddress = true;

  constructor(
      private apiService: ApiService,
      private dialogConfirmService: DialogConfirmService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.CopyPerson();
  }

  ngOnInit() {
    this.CopyPerson();
    this.CreateNewPerson();
    this.citiesList = [];
    this.ufList = [];
    this.loadStates();
    this.loadAllCities();
    // this.AddAddress();
    if (this.editPerson.address && this.editPerson.address.length === 1) {
      this.canAddAddress = false;
    }
  }

  private CopyPerson() {
    this.editPerson = Object.assign({}, this.person);
  }

  private CreateNewPerson() {
    if (!this.editPerson || !this.editPerson.id) {
      this.editPerson = new Person();
      this.editPerson.address = new Array<Address>();
    }
  }

  private loadStates() {
    this.apiService.getUfs().subscribe((data: Uf[]) => {
      this.ufList = data;
    }, (error) => console.error(error));
  }

  private loadAllCities() {
    this.apiService.getCities().subscribe((data: City[]) => {
      this.citiesList = data;

      if (this.person && this.person.address) {
        this.person.address.forEach(a => {
          if (!a.city) {
            a.city = new City();
            a.city.uf = new Uf();
          }
        });
      }
    }, (error) => console.error(error));
  }

  public AddAddress() {
    if (!this.editPerson.address) {
      this.editPerson.address = new Array<Address>();
    }
    if (this.editPerson.address.length < 1) {
      this.editPerson.address.push(new Address());
      this.SetDefaultAddress();
      this.canAddAddress = false;
    }
  }

  public ChangeCity(e: any, i: number) {
    this.editPerson.address[i].cityId = e.target.value;
    this.editPerson.address[i].city.id = e.target.value;
  }

  public ChangeUf(e: any, i: number) {
    this.editPerson.address[i].city.ufId = e.target.value;
  }

  public RemoveAddress(index: number) {
    const dialogTitle = `Remover endereço`;
    const dialogContent =  `Deseja remover este endereço ?`;
    this.dialogConfirmService.Confirm(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
          if (action === `Sim`) {
            this.editPerson.address.reverse().splice(index, 1);
            this.editPerson.address.reverse();
            this.SetDefaultAddress();
          }
        }
    });
  }

  public GetCities(ufId: any) {
    // tslint:disable-next-line:triple-equals
    return this.citiesList.filter((uf) => uf.ufId == ufId);
  }

  public ChangeDefaultAddress(index: number) {
    if (this.editPerson.address.length > 1) {
      this.editPerson.address.reverse();
      for (let i = 0; i < this.editPerson.address.length; i++) {
        this.editPerson.address[i].isDefault = false;
      }
      this.editPerson.address[index].isDefault = true;
      this.editPerson.address.reverse();
    } else {
      this.SetDefaultAddress();
    }
  }

  public SetDefaultAddress() {
    if (this.editPerson.address.length === 1) {
      this.editPerson.address[0].isDefault = true;
    }
  }
}
