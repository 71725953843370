<div>
  <h2 class="text-center mb-4">Informações básicas</h2>
  <div class="form-row border rounded p-4 bg-white">
    <div class="form-group col-12 col-lg-4">
      <label for="customer" class="font-weight-bold">Cliente</label>
      <select id="customer" class="form-control" (change)="changeCustomer($event.target.value)">
        <option *ngFor="let c of customerList" [value]="c.id" >{{c.person.name}}</option>
      </select>
    </div>
    <div class="form-group col-12 col-lg-4">
      <label for="farm" class="font-weight-bold">Fazenda</label>
      <select id="farm" class="form-control" (change)="changeFarm($event.target.value)">
        <option *ngFor="let f of farmList" [value]="f.id" >{{f.name}}</option>
      </select>
    </div>
    <div class="form-group col-12 col-lg-4">
      <label for="herd" class="font-weight-bold">Rebanho</label>
      <select id="herd" class="form-control"  (change)="changeHerd($event.target.value)">
        <option *ngFor="let h of herdList" [value]="h.id" >{{h.name}}</option>
      </select>
    </div>
    <div class="form-group col-12">
      <label for="herd" class="font-weight-bold">Objetivo do rebanho</label>
      <textarea id="goal" name="goal" class="form-control" rows="3" [(ngModel)]="interview.herd.goals"></textarea>
    </div>
  </div>
</div>
