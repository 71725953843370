import { Injectable } from '@angular/core';
import { DialogService, DialogAction, DialogResult } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogConfirmService {

  constructor(private dialogService: DialogService) {}

  public Confirm(title: string, content: string): Observable<DialogResult> {
    const actions: DialogAction[] = [
      { text: 'Não' },
      { text: 'Sim', primary: true }
    ];
    const dialogResult: Observable<DialogResult> = this.dialogService.open({
        title: title,
        content: content,
        actions: actions,
        width: 450,
        height: 200,
        minWidth: 250
    }).result;

    return dialogResult;
  }

  public Alert(title: string, content: string): Observable<DialogResult> {
    const actions: DialogAction[] = [
      { text: 'Ok' }
    ];
    const dialogResult: Observable<DialogResult> = this.dialogService.open({
        title: title,
        content: content,
        actions: actions,
        width: 450,
        height: 200,
        minWidth: 250
    }).result;

    return dialogResult;
  }
}
