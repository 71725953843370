<div class="modal-header">
  <h2 class="modal-title">Programas de bonificação</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngFor="let bonusProgram of listBonusPrograms">
    <div *ngIf="bonusProgram.active" class="form-group col-md-12 col-xs-6">
      <div class="custom-control custom-checkbox">
        <input class="custom-control-input" type="checkbox" value="" id="bonusProgram_{{bonusProgram.id}}" [(ngModel)]="bonusProgram.checked" [ngModelOptions]="{standalone: true}">
        <label class="custom-control-label" for="bonusProgram_{{bonusProgram.id}}">{{bonusProgram.description}}</label>
      </div>
      <div class="custom-control" *ngIf="bonusProgram.id == 11 && bonusProgram.checked">
        <input type="text" value="" [(ngModel)]="bonusProgram.otherProgram" maxlength="50">
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="cancel()">Cancelar</button>
  <button type="button" class="btn btn-submit" (click)="addPrograms()" >Enviar</button>
</div>
