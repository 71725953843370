<div class="modal-header">
  <h2 class="modal-title">Solicitação de acesso</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form id="formRequestAccess" class="p-3" [formGroup]="accessRequestForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label>Nome</label>
      <input type="text" formControlName="name" class="form-control" placeholder="Digite seu nome completo">
      <div *ngIf="submitted && !!f.name?.errors" class="text-danger">
        <small *ngIf="!!f.name.errors.required">Informe seu nome</small>
      </div>
    </div>
    <div class="form-group">
      <label>E-mail</label>
      <input type="email" formControlName="email" class="form-control" placeholder="Digite seu e-mail" autocomplete="false">
      <div *ngIf="submitted && !!f.email?.errors" class="text-danger">
        <small *ngIf="!!f.email.errors.required">Informe seu e-mail</small>
        <small *ngIf="!!f.email.errors.email">Informe um e-mail válido</small>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-md-6">
        <label>Senha</label>
        <input type="password" formControlName="password" class="form-control" placeholder="Defina uma senha de acesso" autocomplete="false">
        <div *ngIf="submitted && !!f.password?.errors" class="text-danger">
          <small *ngIf="!!f.password.errors.required">Informe uma senha [6 - 8 dígitos]</small>
          <small *ngIf="!!f.password.errors.minlength || !!f.password.errors.maxlength">A senha deve ser de 6 a 8 caracteres</small>
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <label>Confirmação de senha</label>
        <input type="password" formControlName="passwordConfirm" class="form-control" placeholder="Digite a senha novamente" autocomplete="false">
        <div *ngIf="submitted && !!f.passwordConfirm?.errors" class="text-danger">
          <small *ngIf="!!f.passwordConfirm.errors.required">Informe a confirmação de senha</small>
          <small *ngIf="!!f.passwordConfirm.errors.mustMatch">A confirmação deve ser igual à senha</small>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <small *ngIf="error.length > 0">{{ error }}</small>
  <small *ngIf="userCreated">Solicitação enviada com sucesso.</small>
  <button type="button" class="btn btn-cancel" (click)="activeModal.close('Close click')">Cancelar</button>
  <button type="button" class="btn btn-submit" (click)="onSubmit()" >Enviar</button>
</div>
