<h2 class="text-center my-4">Filtros e limites genéticos - {{interviewCycle.breedName}} ({{interviewCycle.proofName}})</h2>
<div class="border rounded p-4 bg-white col-12">
    <!-- <div class="mb-2">
        <h3 class="p-2 mr-5">Características</h3>
        <fa-icon (click)="OpenModalTraits()" class="btn btn-submit m-4" [icon]="iconAdd" title="Adicionar características"></fa-icon>
    </div> -->
    <div *ngIf="traitsGroups.length > 0 && isTraitsLoaded; else listEmpty">
        <div *ngFor="let traitGroup of traitsGroups; let tg = index;" class="col-12">
            <table class="borderless">
                <thead>
                  <tr>
                    <th>
                        <div class="row">
                            <div class="col-5 m-0 py-1 px-3 text-left">
                                <h3 class="p-2 m-0">{{traitGroup.name}}</h3>
                            </div>
                            <div class="col-2 m-0 py-1 px-1 text-center"> 
                                <label class="p-2 m-0">Top/Deca</label>
                            </div>
                            <div class="col-2 m-0 py-1 px-1 text-center">
                                <label class="p-2 m-0">Dep</label>
                            </div>
                            <div class="col-1 m-0 py-1 px-1 text-center">
                                <label class="p-2 m-0">M. Touro</label>
                            </div>
                            <div class="col-1 m-0 py-1 px-1 text-center">
                                <label class="p-2 m-0">Acc</label>
                            </div>
                            <div class="col-1 m-0 py-1 px-1"></div>
                        </div>                 
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <th> -->
                        <div *ngFor="let trait of traitGroup.trait; let t = index;">
                            <div class="row bg-light border p-1 rounded m-1">
                                <div class="col-5 m-0 py-1 px-0 text-left">
                                    <label *ngIf="trait.isHighlighting"
                                        class="p-1 m-0 font-weight-bold" 
                                        for="trait_{{trait.id}}">{{trait.description}} ({{trait.acronym}})
                                    </label>
                                    <label *ngIf="!trait.isHighlighting"
                                        class="p-1 m-0" 
                                        for="trait_{{trait.id}}">{{trait.description}} ({{trait.acronym}})
                                    </label>
                                </div>
                                <div class="col-1 m-0 py-1 px-1 text-left">
                                    <select 
                                        id="trait_top_signal_{{trait.id}}" 
                                        class="form-control" 
                                        [(ngModel)]="trait.filterTopDecaSignal" 
                                        (change)="updateSignalFilters(trait, 10, trait.filterTopDecaSignal)">
                                        <option></option>
                                        <option>>=</option>
                                        <option><=</option>
                                    </select>
                                </div>
                                <div class="col-1 m-0 py-1 px-1 text-left">
                                    <input 
                                        type="text" 
                                        class="form-control text-right"
                                        id="trait_top_value_{{trait.id}}"
                                        name="trait_top_name_{{trait.acronym}}"
                                        mask="000" 
                                        placeholder="000"
                                        [(ngModel)]="trait.filterTopDecaValue"
                                        (change)="updateValueFilters(trait, 10, trait.filterTopDecaValue)" />
                                </div>
                                <div class="col-1 m-0 py-1 px-1 text-left">
                                    <select 
                                        id="trait_dep_signal_{{trait.id}}" 
                                        class="form-control" 
                                        [(ngModel)]="trait.filterDepSignal" 
                                        (change)="updateSignalFilters(trait, 8, trait.filterDepSignal)">
                                        <option></option>
                                        <option>>=</option>
                                        <option><=</option>
                                    </select>
                                </div>
                                <div class="col-1 m-0 py-1 px-1 text-left">
                                    <input 
                                        type="number" 
                                        class="form-control text-right"
                                        id="trait_dep_value_{{trait.id}}"
                                        name="trait_dep_name_{{trait.acronym}}"
                                        placeholder="0.0"
                                        editor="numeric"
                                        maxlength="6"
                                        [(ngModel)]="trait.filterDepValue" 
                                        (change)="updateValueFilters(trait, 8, trait.filterDepValue)" />
                                </div>
                                <div *ngFor="let proofTrait of trait.proofTrait; let pt = index;" class="col-1 m-0 py-1 px-2 text-left">
                                    <span *ngIf="proofTrait.traitId === trait.id">
                                        <label id="standard_deviation_{{trait.id}}" class="p-1 m-1">
                                            {{ proofTrait.standardDeviation | number : '1.1-3' }}
                                        </label>
                                    </span>
                                </div>
                                <div class="col-1 m-0 py-1 px-1 text-left">
                                    <input 
                                        type="text" 
                                        class="form-control text-right"
                                        id="trait_acc_value_{{trait.id}}"
                                        name="trait_acc_name_{{trait.acronym}}"
                                        mask="000" 
                                        placeholder="000"
                                        [(ngModel)]="trait.filterAccValue"
                                        (change)="updateValueFilters(trait, 6, trait.filterAccValue)" />
                                </div>
                                <!-- <div class="col-1 m-0 py-1 px-0 text-left">
                                    <button class="btn-danger badge badge-secondary rounded border m-1 p-2" (click)="RemoveTrait(tg, t)">X</button>
                                </div> -->
                            </div>
                        </div>
                    <!-- </th> -->
                  </tr>
                </tbody>
            </table>          
        </div>
    </div>

    <div *ngIf="!isTraitsLoaded" class="text-center">
        <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
        <p class="text-center w-100">Buscando características...</p>
    </div>

    <ng-template #listEmpty>
        <div *ngIf="isTraitsLoaded" class="d-flex align-items-center" style="height: calc(100% - 90px);">
            <p class="text-center w-100">Nenhuma característica encontrada.</p>
        </div>
    </ng-template>

    <!-- <ng-template #noSelectedTraitsGroups>
        <div class="form-row">
        <div class="col-12">
            <p class="w-100">Nenhuma característica selecionada</p>
        </div>
        </div>
    </ng-template> -->
</div>
