import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Interview } from 'src/_models/interview';
import { BonusProgramm } from 'src/_models/bonus-program';

@Component({
  selector: 'app-modal-bonus-program',
  templateUrl: './modal-bonus-program.component.html',
  styleUrls: ['./modal-bonus-program.component.scss']
})
export class ModalBonusProgramComponent implements OnInit {

  @Input() public bonus: Array<BonusProgramm>;

  public listBonusPrograms: Array<BonusProgramm>;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.listBonusPrograms = <Array<BonusProgramm>> BonusProgramm.getPrograms();
    this.bonus.map(b => {
      console.log(b);
      // tslint:disable-next-line:triple-equals
      this.listBonusPrograms.find(p => p.id == b.id).checked = true;
    });
  }

  public addPrograms() {
    this.activeModal.close(this.listBonusPrograms.filter(b => b.checked));
  }

  public cancel() {
    this.activeModal.close(this.bonus);
  }

}
