<h3 class="p-2 mb-2">9 - Distribuição por raça</h3>
<div class="row">
  <div class="col-12">
    <div class="form-row">
      <div class="form-group">
        <table class="table borderless">
          <thead>
            <tr>
                <th></th>
                <th colspan="4">
                  <label class="p-2 m-0">Início EM</label>
                </th>
                <th colspan="4">
                  <label class="p-2 m-0">Meio EM</label>
                </th>
                <th colspan="4">
                  <label class="p-2 m-0">Final EM</label>
                </th>
            </tr>
            <tr>
              <th></th>
              <th colspan="2" class="py-0 px-1">
                <label class="p-2 m-0 w-100 bg-light rounded">{{breed1}}</label>
              </th>
              <th colspan="2" class="py-0 px-1">
                <label class="p-2 m-0 w-100 bg-light rounded">{{breed2}}</label>
              </th>
              <th colspan="2" class="py-0 px-1">
                <label class="p-2 m-0 w-100 bg-light rounded">{{breed1}}</label>
              </th>
              <th colspan="2" class="py-0 px-1">
                <label class="p-2 m-0 w-100 bg-light rounded">{{breed2}}</label>
              </th>
              <th colspan="2" class="py-0 px-1">
                <label class="p-2 m-0 w-100 bg-light rounded">{{breed1}}</label>
              </th>
              <th colspan="2" class="py-0 px-1">
                <label class="p-2 m-0 w-100 bg-light rounded">{{breed2}}</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <label class="p-2 m-0">Novilhas</label>
              </th>
              <td>
                <input name="heifersBeginningBreed1Percent" id="heifersBeginningBreed1Percent"
                  [(ngModel)]="reproductiveManagementService.heifersBeginningBreed1Percent" appOnlyDigits
                  (keyup)="reproductiveManagementService.heifersBeginningBreed2Percent = 100 - reproductiveManagementService.heifersBeginningBreed1Percent"
                  class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionHeifersEM1Breed1" id="breedDistributionHeifersEM1Breed1"
                  [value]="breedDistributionHeifersEM1Breed1" appOnlyDigits
                  class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="heifersBeginningBreed2Percent" id="heifersBeginningBreed2Percent"
                  [(ngModel)]="reproductiveManagementService.heifersBeginningBreed2Percent" appOnlyDigits
                  (keyup)="reproductiveManagementService.heifersBeginningBreed1Percent = 100 - reproductiveManagementService.heifersBeginningBreed2Percent"
                  class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionHeifersEM1Breed2" id="breedDistributionHeifersEM1Breed2"
                  [value]="breedDistributionHeifersEM1Breed2" appOnlyDigits
                  class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="heifersMiddleBreed1Percent" id="heifersMiddleBreed1Percent"
                  [(ngModel)]="reproductiveManagementService.heifersMiddleBreed1Percent" appOnlyDigits
                  (keyup)="reproductiveManagementService.heifersMiddleBreed2Percent = 100- reproductiveManagementService.heifersMiddleBreed1Percent"
                  class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionHeifersEM2Breed1" id="breedDistributionHeifersEM2Breed1"
                  [value]="breedDistributionHeifersEM2Breed1" appOnlyDigits
                  class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="heifersMiddleBreed2Percent" id="heifersMiddleBreed2Percent"
                  [(ngModel)]="reproductiveManagementService.heifersMiddleBreed2Percent" appOnlyDigits
                  (keyup)="reproductiveManagementService.heifersMiddleBreed1Percent = 100- reproductiveManagementService.heifersMiddleBreed2Percent"
                  class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionHeifersEM2Breed2" id="breedDistributionHeifersEM2Breed2"
                  [value]="breedDistributionHeifersEM2Breed2" appOnlyDigits
                  class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="heifersEndBreed1Percent" id="heifersEndBreed1Percent"
                  [(ngModel)]="reproductiveManagementService.heifersEndBreed1Percent" appOnlyDigits
                  (keyup)="reproductiveManagementService.heifersEndBreed2Percent = 100 - reproductiveManagementService.heifersEndBreed1Percent"
                  class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionHeifersEM3Breed1" id="breedDistributionHeifersEM3Breed1"
                  [value]="breedDistributionHeifersEM3Breed1" appOnlyDigits
                  class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="heifersEndBreed2Percent" id="heifersEndBreed2Percent"
                  [(ngModel)]="reproductiveManagementService.heifersEndBreed2Percent" appOnlyDigits
                  (keyup)="reproductiveManagementService.heifersEndBreed1Percent = 100 - reproductiveManagementService.heifersEndBreed2Percent"
                  class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionHeifersEM3Breed2" id="breedDistributionHeifersEM3Breed2"
                  [value]="breedDistributionHeifersEM3Breed2" appOnlyDigits
                  class="form-control text-right" type="text" value="0" disabled />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0">Vacas</label>
              </th>
              <td>
                <input name="cowsBeginningBreed1Percent" id="cowsBeginningBreed1Percent"
                [(ngModel)]="reproductiveManagementService.cowsBeginningBreed1Percent" appOnlyDigits
                (keyup)="reproductiveManagementService.cowsBeginningBreed2Percent = 100 - reproductiveManagementService.cowsBeginningBreed1Percent"
                class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionCowsEM1Breed1" id="breedDistributionCowsEM1Breed1"
                [value]="breedDistributionCowsEM1Breed1" appOnlyDigits
                class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="cowsBeginningBreed2Percent" id="cowsBeginningBreed2Percent"
                [(ngModel)]="reproductiveManagementService.cowsBeginningBreed2Percent" appOnlyDigits
                (keyup)="reproductiveManagementService.cowsBeginningBreed1Percent = 100 - reproductiveManagementService.cowsBeginningBreed2Percent"
                class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionCowsEM1Breed2" id="breedDistributionCowsEM1Breed2"
                [value]="breedDistributionCowsEM1Breed2" appOnlyDigits
                class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="cowsMiddleBreed1Percent" id="cowsMiddleBreed1Percent"
                [(ngModel)]="reproductiveManagementService.cowsMiddleBreed1Percent" appOnlyDigits
                (keyup)="reproductiveManagementService.cowsMiddleBreed2Percent = 100 - reproductiveManagementService.cowsMiddleBreed1Percent"
                class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionCowsEM2Breed1" id="breedDistributionCowsEM2Breed1"
                [value]="breedDistributionCowsEM2Breed1" appOnlyDigits
                class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="cowsMiddleBreed2Percent" id="cowsMiddleBreed2Percent"
                [(ngModel)]="reproductiveManagementService.cowsMiddleBreed2Percent" appOnlyDigits
                (keyup)="reproductiveManagementService.cowsMiddleBreed1Percent = 100 - reproductiveManagementService.cowsMiddleBreed2Percent"
                class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionCowsEM2Breed2" id="breedDistributionCowsEM2Breed2"
                [value]="breedDistributionCowsEM2Breed2" appOnlyDigits
                class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="cowsEndBreed1Percent" id="cowsEndBreed1Percent"
                [(ngModel)]="reproductiveManagementService.cowsEndBreed1Percent" appOnlyDigits
                (keyup)="reproductiveManagementService.cowsEndBreed2Percent = 100 - reproductiveManagementService.cowsEndBreed1Percent"
                class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionCowsEM3Breed1" id="breedDistributionCowsEM3Breed1"
                [value]="breedDistributionCowsEM3Breed1" appOnlyDigits
                class="form-control text-right" type="text" value="0" disabled />
              </td>
              <td>
                <input name="cowsEndBreed2Percent" id="cowsEndBreed2Percent"
                [(ngModel)]="reproductiveManagementService.cowsEndBreed2Percent" appOnlyDigits
                (keyup)="reproductiveManagementService.cowsEndBreed1Percent = 100 - reproductiveManagementService.cowsEndBreed2Percent"
                class="form-control text-right" type="text" />
              </td>
              <td>
                <input name="breedDistributionCowsEM3Breed2" id="breedDistributionCowsEM3Breed2"
                [value]="breedDistributionCowsEM3Breed2" appOnlyDigits
                class="form-control text-right" type="text" value="0" disabled />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0"></label>
              </th>
              <td></td>
              <td>
                <input name="breedDistributionTotalEM1Breed1" id="breedDistributionTotalEM1Breed1" [value]="breedDistributionTotalEM1Breed1" class="form-control text-right font-weight-bold" type="text" disabled />
              </td>
              <td></td>
              <td>
                <input name="breedDistributionTotalEM1Breed2" id="breedDistributionTotalEM1Breed2" [value]="breedDistributionTotalEM1Breed2" class="form-control text-right font-weight-bold" type="text" disabled />
              </td>
              <td></td>
              <td>
                <input name="breedDistributionTotalEM2Breed1" id="breedDistributionTotalEM2Breed1" [value]="breedDistributionTotalEM2Breed1" class="form-control text-right font-weight-bold" type="text" disabled />
              </td>
              <td></td>
              <td>
                <input name="breedDistributionTotalEM2Breed2" id="breedDistributionTotalEM2Breed2" [value]="breedDistributionTotalEM2Breed2" class="form-control text-right font-weight-bold" type="text" disabled />
              </td>
              <td></td>
              <td>
                <input name="breedDistributionTotalEM3Breed1" id="breedDistributionTotalEM3Breed1" [value]="breedDistributionTotalEM3Breed1" class="form-control text-right font-weight-bold" type="text" disabled />
              </td>
              <td></td>
              <td>
                <input name="breedDistributionTotalEM3Breed2" id="breedDistributionTotalEM3Breed2" [value]="breedDistributionTotalEM3Breed2" class="form-control text-right font-weight-bold" type="text" disabled />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<h3 class="p-2 mb-2">Total de doses por raça</h3>
<div class="row w-100 m-0">
    <div class="col-6 px-1">
    <div class="form-row">
        <div class="form-group row w-100">
        <label class="col-form-label col-12 col-sm-9 p-2" for="nbrOfDosesBreed1">{{breed1}}</label>
        <div class="col-12 col-sm-3 py-0 px-1">
            <input type="text" class="form-control text-right font-weight-bold"
            id="breedDistributionTotalBreed1"
            name="breedDistributionTotalBreed1"
            disabled
            [value]="breedDistributionTotalBreed1"/>
        </div>
        </div>
    </div>
    </div>
    <div class="col-6 px-1">
    <div class="form-row">
        <div class="form-group row w-100">
        <label class="col-form-label col-12 col-sm-9 p-2" for="nbrOfDosesBreed2">{{breed2}}</label>
        <div class="col-12 col-sm-3 py-0 px-1">
            <input type="text" class="form-control text-right font-weight-bold"
            id="breedDistributionTotalBreed2"
            name="breedDistributionTotalBreed2"
            disabled
            [value]="breedDistributionTotalBreed2"/>
        </div>
        </div>
    </div>
    </div>
</div>
