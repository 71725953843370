export class InterviewBreedGoal {

  public id: number;

  public sellingGoalsMale: number;

  public sellingGoalsFemale: number;

  public confinementTypeMale: number;

  public confinementTypeFemale: number;

  public usesCastration: number;

  public maxZebu: number;

  public maxTaurean: number;

  public interviewId: number;

  constructor () {
    this.sellingGoalsMale = 0;
    this.sellingGoalsFemale = 0;
    this.confinementTypeMale = 0;
    this.confinementTypeFemale = 0;
    this.usesCastration = 0;

    this.maxZebu = 100;
    this.maxTaurean = 100;
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

}
