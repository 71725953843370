<div>
  <h2 class="text-center my-4">Coleta de dados</h2>
  <div class="form-row border rounded p-4 bg-white">

    <div class="col-12">
      <h3 class="p-2 mb-2">Origem das fêmeas para reprodução</h3>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" value="1" id="originReprodution" (change)="changeFemaleOrigin($event)">
                <label class="custom-control-label w-100" for="originReprodution">Manutenção com fêmeas do próprio rebanho</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" value="2" id="originBuy" (change)="changeFemaleOrigin($event)">
                <label class="custom-control-label w-100" for="originBuy">Manutenção com compra</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="w-100 divider mb-4" />

    <div class="col-12">
      <h3 class="p-2 mb-2">Tipo da raça utilizada na inseminação</h3>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="inseminationtype" [value]="1" id="baseBreedInsemination" [(ngModel)]="interview.breedOptions" (change)="updateAllBreedsSuggestions()">
                <label class="custom-control-label w-100" for="baseBreedInsemination">Apenas raça base</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="inseminationtype" [value]="2" id="breedingInsemination" [(ngModel)]="interview.breedOptions" (change)="updateAllBreedsSuggestions()">
                <label class="custom-control-label w-100" for="breedingInsemination">Aberto para cruzamento</label>
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="interview.breedOptions == 2" >
            <div class="form-group col-12">
              <label for="breedOptionsMatting">Objetivo do cruzamento</label>
              <select id="breedOptionsMatting" name="breedOptionsMatting" class="form-control" [(ngModel)]="interview.breedOptionsMatting" (change)="updateAllBreedsSuggestions()">
                <option [ngValue]="0">Selecione...</option>
                <option [ngValue]="1" >Terminal</option>
                <option [ngValue]="2" >Utilização dos produtos como matrizes</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="w-100 divider mb-4" />

    <div class="col-12">
      <h3 class="p-2 mb-2">Foco do rebanho</h3>

      <p class="p-2 m-0 font-weight-bold">Raça primária</p>

      <div class="row" *ngIf="interview.breedOptions == 2">
        <div class="col-12 col-lg-6">
          <div class="form-row">
            <div class="form-group col-12">
              <label>Grau máximo de sangue <b>Zebuíno</b> desejado no produto (0 a 100%)</label>
              <input type="range" class="custom-range w-75 align-middle" id="primaryMaxZebuine" name="primaryMaxZebuine" min="0" max="100" [(ngModel)]="interview.interviewBreedGoal[0].maxZebu" (change)="updatePrimaryBreedSuggestion()">
              <span class="w-25 align-middle d-inline-block px-2">{{interview.interviewBreedGoal[0].maxZebu}}%</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-row">
            <div class="form-group col-12">
              <label>Grau máximo de sangue <b>Tauríno</b> desejado no produto (0 a 100%)</label>
              <input type="range" class="custom-range w-75 align-middle" id="primaryMaxTaurean" name="primaryMaxTaurean" min="0" max="100" [(ngModel)]="interview.interviewBreedGoal[0].maxTaurean" (change)="updatePrimaryBreedSuggestion()">
              <span class="w-25 align-middle d-inline-block px-2">{{interview.interviewBreedGoal[0].maxTaurean}}%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-row">
            <div class="form-group col-12">
              <label for="herdGoalPrimmaryMale">Macho</label>
              <select id="herdGoalPrimmaryMale" name="herdGoalPrimmaryMale" class="form-control" [(ngModel)]="interview.interviewBreedGoal[0].sellingGoalsMale" (change)="updatePrimaryBreedSuggestion()">
                <option [value]="0">Selecione...</option>
                <option *ngFor="let f of herdGoalMaleList" [value]="f.id">{{f.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-row"  *ngIf="interview.interviewBreedGoal[0].sellingGoalsMale == 1">
            <div class="col-12">
              <div class="row border rounded px-2 py-0 bg-light m-0">
                <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="confinementTypeMalePrimary" id="confinementTypeMalePrimary1" [(ngModel)]="interview.interviewBreedGoal[0].confinementTypeMale" [value]="1" (change)="updatePrimaryBreedSuggestion()">
                    <label class="custom-control-label w-100" for="confinementTypeMalePrimary1">A pasto</label>
                  </div>
                </div>
                <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="confinemenTypPrimaryMale" id="confinementTypeMalePrimary2" [(ngModel)]="interview.interviewBreedGoal[0].confinementTypeMale" [value]="2" (change)="updatePrimaryBreedSuggestion()">
                    <label class="custom-control-label w-100" for="confinementTypeMalePrimary2">Semi-confinado</label>
                  </div>
                </div>
                <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="confinementTypeMalePrimary" id="confinementTypeMalePrimary3" [(ngModel)]="interview.interviewBreedGoal[0].confinementTypeMale" [value]="3" (change)="updatePrimaryBreedSuggestion()">
                    <label class="custom-control-label w-100" for="confinementTypeMalePrimary3">Confinado</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="interview.interviewBreedGoal[0].sellingGoalsMale == 1">
            <div class="col-12">
              <div class="row border rounded px-2 py-0 bg-light m-0">
                <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="usesCastrationPrimmary" id="usesCastrationPrimmary1" [(ngModel)]="interview.interviewBreedGoal[0].usesCastration" [value]="1" (change)="updatePrimaryBreedSuggestion()">
                    <label class="custom-control-label w-100" for="usesCastrationPrimmary1">Castrado</label>
                  </div>
                </div>
                <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="usesCastrationPrimmary" id="usesCastrationPrimmary2" [(ngModel)]="interview.interviewBreedGoal[0].usesCastration" [value]="2" (change)="updatePrimaryBreedSuggestion()">
                    <label class="custom-control-label w-100" for="usesCastrationPrimmary2">Inteiro</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-row">
            <div class="form-group col-12">
              <label for="herdGoalPrimmaryFemale">Fêmea</label>
              <select id="herdGoalPrimmaryFemale" name="herdGoalPrimmaryFemale" class="form-control" [(ngModel)]="interview.interviewBreedGoal[0].sellingGoalsFemale" (change)="updatePrimaryBreedSuggestion()">
                <option [value]="0">Selecione...</option>
                <option *ngFor="let f of herdGoalFemaleList" [value]="f.id" >{{f.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-row" *ngIf="interview.interviewBreedGoal[0].sellingGoalsFemale == 1">
            <div class="col-12">
              <div class="row border rounded px-2 py-0 bg-light m-0">
                <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="confinementTypeFemalePrimary" id="confinementTypeFemalePrimary1" [(ngModel)]="interview.interviewBreedGoal[0].confinementTypeFemale" [value]="1" (change)="updatePrimaryBreedSuggestion()">
                    <label class="custom-control-label w-100" for="confinementTypeFemalePrimary1">A pasto</label>
                  </div>
                </div>
                <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="confinementTypeFemalePrimary" id="confinementTypeFemalePrimary2" [(ngModel)]="interview.interviewBreedGoal[0].confinementTypeFemale" [value]="2" (change)="updatePrimaryBreedSuggestion()">
                    <label class="custom-control-label w-100" for="confinementTypeFemalePrimary2">Semi-confinado</label>
                  </div>
                </div>
                <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                  <div class="custom-control custom-radio">
                    <input class="custom-control-input" type="radio" name="confinementTypeFemalePrimary" id="confinementTypeFemalePrimary3" [(ngModel)]="interview.interviewBreedGoal[0].confinementTypeFemale" [value]="3" (change)="updatePrimaryBreedSuggestion()">
                    <label class="custom-control-label w-100" for="confinementTypeFemalePrimary3">Confinado</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="interview.breedOptions === 2" class="mt-2">
        <p class="p-2 m-0 font-weight-bold">Raça secundária</p>

        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-row">
              <div class="form-group col-12">
                <label>Grau máximo de sangue <b>Zebuíno</b> desejado no produto (0 a 100%)</label>
                <input type="range" class="w-75 align-middle" id="secondaryMaxZebuine" name="secondaryMaxZebuine" min="0" max="100" [(ngModel)]="interview.interviewBreedGoal[1].maxZebu" (change)="updateSecondaryBreedSuggestion()">
                <span class="w-25 align-middle d-inline-block px-2">{{interview.interviewBreedGoal[1].maxZebu}}%</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="form-row">
              <div class="form-group col-12">
                <label>Grau máximo de sangue <b>Tauríno</b> desejado no produto (0 a 100%)</label>
                <input type="range" class="custom-range w-75 align-middle" id="secondaryMaxTaurean" name="secondaryMaxTaurean" min="0" max="100" [(ngModel)]="interview.interviewBreedGoal[1].maxTaurean" (change)="updateSecondaryBreedSuggestion()">
                <span class="w-25 align-middle d-inline-block px-2">{{interview.interviewBreedGoal[1].maxTaurean}}%</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-row">
              <div class="col-12">
                <label for="herdGoalSecondaryMale">Macho</label>
                <select id="herdGoalSecondaryMale" name="herdGoalSecondaryMale" class="form-control" [(ngModel)]="interview.interviewBreedGoal[1].sellingGoalsMale" (change)="updateSecondaryBreedSuggestion()">
                  <option [value]="0">Selecione...</option>
                  <option *ngFor="let f of herdGoalMaleList" [value]="f.id" >{{f.name}}</option>
                </select>
              </div>
            </div>
            <div class="form-row" *ngIf="interview.interviewBreedGoal[1].sellingGoalsMale == 1">
              <div class="col-12">
                <div class="row border rounded px-2 py-0 bg-light m-0">
                  <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="confinementTypeMaleSecondary" id="confinementTypeMaleSecondary1" [(ngModel)]="interview.interviewBreedGoal[1].confinementTypeMale" [value]="1" (change)="updateSecondaryBreedSuggestion()">
                      <label class="custom-control-label w-100" for="confinementTypeMaleSecondary1">A pasto</label>
                    </div>
                  </div>
                  <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="confinementTypeMaleSecondary" id="confinementTypeMaleSecondary2" [(ngModel)]="interview.interviewBreedGoal[1].confinementTypeMale" [value]="2" (change)="updateSecondaryBreedSuggestion()">
                      <label class="custom-control-label w-100" for="confinementTypeMaleSecondary2">Semi-confinado</label>
                    </div>
                  </div>
                  <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="confinementTypeMaleSecondary" id="confinementTypeMaleSecondary3" [(ngModel)]="interview.interviewBreedGoal[1].confinementTypeMale" [value]="3" (change)="updateSecondaryBreedSuggestion()">
                      <label class="custom-control-label w-100" for="confinementTypeMaleSecondary3">Confinado</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="interview.interviewBreedGoal[1].sellingGoalsMale == 1">
              <div class="col-12">
                <div class="row border rounded px-2 py-0 bg-light m-0">
                  <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="usesCastrationSecondary" id="usesCastrationSecondary1" [(ngModel)]="interview.interviewBreedGoal[1].usesCastration" [value]="1" (change)="updateSecondaryBreedSuggestion()">
                      <label class="custom-control-label w-100" for="usesCastrationSecondary1">Castrado</label>
                    </div>
                  </div>
                  <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="usesCastrationSecondary" id="usesCastrationSecondary2" [(ngModel)]="interview.interviewBreedGoal[1].usesCastration" [value]="2" (change)="updateSecondaryBreedSuggestion()">
                      <label class="custom-control-label w-100" for="usesCastrationSecondary2">Inteiro</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <div class="form-row">
              <div class="col-12">
                <label for="herdGoalSecondaryFemale">Fêmea</label>
                <select id="herdGoalSecondaryFemale" name="herdGoalSecondaryFemale" class="form-control" [(ngModel)]="interview.interviewBreedGoal[1].sellingGoalsFemale" (change)="updateSecondaryBreedSuggestion()">
                  <option [value]="0">Selecione...</option>
                  <option *ngFor="let f of herdGoalFemaleList" [value]="f.id" >{{f.name}}</option>
                </select>
              </div>
            </div>
            <div class="form-row" *ngIf="interview.interviewBreedGoal[1].sellingGoalsFemale == 1">
              <div class="col-12">
                <div class="row border rounded px-2 py-0 bg-light m-0">
                  <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="confinementTypeFemaleSecondary" value="" id="confinementTypeFemaleSecondary1" [(ngModel)]="interview.interviewBreedGoal[1].confinementTypeFemale" [value]="1" (change)="updateSecondaryBreedSuggestion()">
                      <label class="custom-control-label w-100" for="confinementTypeFemaleSecondary1">A pasto</label>
                    </div>
                  </div>
                  <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="confinementTypeFemaleSecondary" value="" id="confinementTypeFemaleSecondary2" [(ngModel)]="interview.interviewBreedGoal[1].confinementTypeFemale" [value]="2" (change)="updateSecondaryBreedSuggestion()">
                      <label class="custom-control-label w-100" for="confinementTypeFemaleSecondary2">Semi-confinado</label>
                    </div>
                  </div>
                  <div class="form-group p-1 col-12 col-md-6 col-lg-4">
                    <div class="custom-control custom-radio">
                      <input class="custom-control-input" type="radio" name="confinementTypeFemaleSecondary" value="" id="confinementTypeFemaleSecondary3" [(ngModel)]="interview.interviewBreedGoal[1].confinementTypeFemale" [value]="3" (change)="updateSecondaryBreedSuggestion()">
                      <label class="custom-control-label w-100" for="confinementTypeFemaleSecondary3">Confinado</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="w-100 divider mb-4" />

    <div class="col-12">
      <div class="mb-2">
        <h3 class="p-2 mr-5">Programas de bonificação </h3>
        <fa-icon (click)="openBonusProgram()" class="btn btn-submit float-right" [icon]="iconAdd" title="Adicionar programa de bonificação"></fa-icon>
      </div>
      <div class="form-row" *ngIf="interview?.bonusProgram.length > 0; else noBonusPrograms">
        <div *ngFor="let bonusProgram of interview.bonusProgram; let i = index;" class="col-12 col-md-6 col-xl-4 mb-2">
          <div class="bg-light border p-1 rounded list-programs">
            <label class="p-1 m-0">{{bonusProgram.description}}</label>
            <button class="badge badge-secondary rounded border m-0 p-2" (click)="removeBonusProgram(i)">X</button>
          </div>
        </div>
      </div>
      <ng-template #noBonusPrograms>
        <div class="form-row">
          <div class="col-12">
            <p class="w-100">Nenhum programa selecionado</p>
          </div>
        </div>
      </ng-template>
    </div>

    <hr class="w-100 divider mb-4" />

    <div class="col-12">
      <div class="mb-2">
        <h3 class="p-2 mr-5">Principais problemas observados no rebanho</h3>
        <fa-icon (click)="openHerdProblem()" class="btn btn-submit" [icon]="iconAdd" title="Adicionar problema de rebanho"></fa-icon>
      </div>
      <div class="form-row" *ngIf="interview.herdProblem.length > 0; else noHerdProblems">
        <div *ngFor="let herdProblem of interview.herdProblem; let i = index;" class="col-12 col-md-6 col-xl-4 mb-2">
          <div class="bg-light border p-1 rounded list-problems">
            <label class="p-1 m-0">{{herdProblem.description}}</label>
            <button class="badge badge-secondary rounded border m-0 p-2" (click)="removeHerdProblem(i)">X</button>
          </div>
        </div>
      </div>
      <ng-template #noHerdProblems>
        <div class="form-row">
          <div class="col-12">
            <p class="w-100">Nenhum problema selecionado</p>
          </div>
        </div>
      </ng-template>
    </div>

  </div>
</div>
