<div class="modal-header">
    <h2 class="modal-title">Selecionar Características</h2>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-container *ngFor="let traitGroup of traitsGroups">
        <div class="custom-control custom-checkbox">
            <input 
                class="custom-control-input" 
                type="checkbox" 
                id="traitGroup_{{traitGroup.id}}" 
                [(ngModel)]="traitGroup.checked" 
                (click)="selectAllTraits(traitGroup.id, $event.target.checked)" 
                [ngModelOptions]="{standalone: true}">
            <label class="custom-control-label font-weight-bold large" for="traitGroup_{{traitGroup.id}}">{{traitGroup.name}}</label>   
        </div>
        <ng-container *ngFor="let trait of traitGroup.trait">
            <div class="custom-control custom-checkbox">
                <input 
                    class="custom-control-input" 
                    type="checkbox" 
                    id="trait_{{trait.id}}" 
                    [(ngModel)]="trait.checked" 
                    [ngModelOptions]="{standalone: true}"
                    (click)="selectTrait(traitGroup.id, trait.id, $event.target.checked)">
                <label *ngIf="trait.isHighlighting" class="custom-control-label font-italic" for="trait_{{trait.id}}">{{trait.description}} ({{trait.acronym}})</label>
                <label *ngIf="!trait.isHighlighting" class="custom-control-label" for="trait_{{trait.id}}">{{trait.description}} ({{trait.acronym}})</label>
            </div>
        </ng-container>
    </ng-container>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-cancel" (click)="cancel()">Cancelar</button>
    <button type="button" class="btn btn-submit" (click)="addTraits()" >Enviar</button>
</div>