import { Component, OnInit } from '@angular/core';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { apiConfig } from '@app/_authorization/auth.config';
import { Router } from '@angular/router';
import { InteractionRequiredAuthError } from 'msal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  currentYear: string;
  loading = true;

  constructor(private msalService: MsalService, private router: Router, private broadcastService: BroadcastService) { }

  ngOnInit() {
    this.acquireTokenSilent();
  }

  acquireTokenSilent() {
    const activeAccount = this.msalService.getAccount();
    if (!activeAccount) {
      this.login();
    } else {
      this.msalService.acquireTokenSilent({ account: activeAccount, scopes: apiConfig.b2cScopes })
        .then((tokenResponse) => {
          this.router.navigate(['']);
        })
        .catch((error) => {
          // este método é um paleativo para o problema de login no Azure 
          // ClientAuthError: Token renewal operation failed due to timeout.
          this.acquireTokenPopup();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  acquireTokenPopup() {
    this.msalService.acquireTokenPopup({ scopes: apiConfig.b2cScopes })
      .then((tokenResponse) => {
        this.router.navigate(['']);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return this.msalService.acquireTokenRedirect({
            scopes: apiConfig.b2cScopes
          });
        }
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  login() {
    this.msalService.loginRedirect();
  }
}
