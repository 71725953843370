<div class="container justify-content-center h-100">
  <div class="row h-100">
    <div class="col h-100 p-0">
      <div class="panel mb-4">
        <form #interviewForm="ngForm"  >
          <!-- *ngIf="user?.id" -->
          <h2 class="text-center mb-4">Características para sugestão de raças</h2>
          <div class="border rounded p-4 m-2 bg-white">
            <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" for="description">Descrição da sugestão</label>
              <input type="text" [(ngModel)]="suggestion.description" class="form-control col-7" id="description" name="description" placeholder="Nome para identificar sugestão"/>
            </div>
            <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" for="breedSecondary">Raça base</label>
              <div class="col-7 p-0">
                <kendo-multiselect id="breedSecondary" name="breedSecondary"
                    class="form-control"
                    [data]="baseBreedsList"
                    [autoClose]="false"
                    (valueChange)="changeBaseBreed($event)"
                    [textField]="'name'"
                    [valueField]="'id'"
                    placeholder="Selecione a(s) raça(s) base...">
                    <ng-template kendoMultiSelectNoDataTemplate>
                        <p>Carregando...</p>
                    </ng-template>
                </kendo-multiselect>
              </div>
            </div>
          <!-- </div>

          <div class="border rounded p-4 m-2 bg-white"> -->
            <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" for="origin">Origem das fêmeas para reprodução</label>
              <select id="origin" name="origin" class="form-control col-7" [(ngModel)]="form.origin.value">
                <option [ngValue]="0" >Desconsiderar questão</option>
                <option [ngValue]="1" >Manutenção com fêmeas do próprio rebanho</option>
                <option [ngValue]="2" >Manutenção com compra</option>
                <option [ngValue]="3" >Manutenção com fêmeas do próprio rebanho e com compra</option>
              </select>
            </div>
            <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" >Tipo da raça utilizada na inseminação</label>
              <input readonly type="text" class="form-control col-7" value="Aberto para cruzamento"/>
            </div>
            <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" for="breedOptionsMatting">Objetivo do cruzamento</label>
              <select id="breedOption" name="breedOptionsMatting" class="form-control col-7" [(ngModel)]="form.breedOptionsMatting.value">
                <option [ngValue]="0" >Desconsiderar questão</option>
                <option [ngValue]="1" >Terminal</option>
                <option [ngValue]="2" >Utilização dos produtos como matrizes</option>
              </select>
            </div>
            <!-- <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" for="breedInsemination">Tipo da raça utilizada na inseminação</label>
              <select id="breedInsemination" name="breedInsemination" class="form-control col-7" [(ngModel)]="form.breedInsemination.value">
                <option [ngValue]="0" >Desconsiderar questão</option>
                <option [ngValue]="1" >Apenas raça base</option>
                <option [ngValue]="2" >Aberto para cruzamento</option>
              </select>
            </div> -->
          </div>

          <div class="border rounded p-4 m-2 bg-white">
            <div class="mb-2">
              <h3 class="p-2">Foco do rebanho</h3>
            </div>
            <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" for="maxZebuine">Grau de sangue <b>Zebuíno</b> desejado no produto</label>
              <div class="col-7 p-0 m-0">
                <div class="row">
                  <div class="col-6">
                    <select id="maxZebuineSignal" name="maxZebuineSignal" class="form-control w-100" [(ngModel)]="form.maxZebuine.signal">
                      <option [ngValue]="0" selected>Desconsiderar questão</option>
                      <option [ngValue]="1" >Igual a</option>
                      <option [ngValue]="2" >Menor que</option>
                      <option [ngValue]="3" >Maior que</option>
                      <option [ngValue]="4" >Menor ou igual a</option>
                      <option [ngValue]="5" >Maior ou igual a</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <input type="number" [(ngModel)]="form.maxZebuine.value"  class="form-control text-right" id="maxZebuine" name="maxZebuine" autocomplete="off" />
                      <div class="input-group-append">
                        <span class="input-group-text">%</span>
                      </div>
                    </div>
                    <!-- <input id="maxZebuine" name="maxZebuine" type="number" class="form-control w-100" [(ngModel)]="form.maxZebuine" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" for="maxTaurine">Grau de sangue <b>Taurino</b> desejado no produto</label>
              <div class="col-7 p-0 m-0">
                <div class="row">
                  <div class="col-6">
                    <select id="maxTaurineSignal" name="maxTaurineSignal" class="form-control w-100" [(ngModel)]="form.maxTaurine.signal">
                      <option [ngValue]="0" selected>Desconsiderar questão</option>
                      <option [ngValue]="1" >Igual a</option>
                      <option [ngValue]="2" >Menor que</option>
                      <option [ngValue]="3" >Maior que</option>
                      <option [ngValue]="4" >Menor ou igual a</option>
                      <option [ngValue]="5" >Maior ou igual a</option>
                    </select>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <input type="number" [(ngModel)]="form.maxTaurine.value"  class="form-control text-right" id="maxTaurine" name="maxTaurine" autocomplete="off" />
                      <div class="input-group-append">
                        <span class="input-group-text">%</span>
                      </div>
                    </div>
                    <!-- <input id="maxTaurine" name="maxTaurine" type="number" class="form-control w-100" [(ngModel)]="form.maxTaurine" /> -->
                  </div>
                </div>
              </div>
            </div>
            <fieldset class="border rounded bg-light p-2 m-2">
              <legend class="py-2 px-4 m-0 border rounded">MACHO</legend>
              <div class="form-group row m-0 p-2">
                <label class="col-form-label col-5" for="herdGoalMale">Objetivo do rebanho</label>
                <select id="herdGoalMale" name="herdGoalMale" class="form-control col-7" [(ngModel)]="form.herdGoalMale.value">
                  <option value="0" selected>Desconsiderar questão</option>
                  <option value="1" >Abate</option>
                  <option value="2" >Venda ao desmame</option>
                  <option value="3" >Produção de touros</option>
                </select>
              </div>
              <div class="form-group row m-0 p-2">
                <label class="col-form-label col-5" for="confinementTypeMale">Tipo do confinamento</label>
                <select id="confinementTypeMale" name="confinementTypeMale" class="form-control col-7" [(ngModel)]="form.confinementTypeMale.value">
                  <option value="0" selected>Desconsiderar questão</option>
                  <option value="1" >A pasto</option>
                  <option value="2" >Semi-confinado</option>
                  <option value="3" >Confinado</option>
                  <option value="4" >A pasto ou semi-confinado</option>
                  <option value="5" >A pasto ou confinado</option>
                  <option value="6" >Semi-confinado ou confinado</option>
                </select>
              </div>
              <div class="form-group row m-0 p-2" *ngIf="form.herdGoalMale.value == 1">
                <label class="col-form-label col-5" for="usesCastration">Castração</label>
                <select id="usesCastration" name="usesCastration" class="form-control col-7" [(ngModel)]="form.usesCastration.value">
                  <option value="0" selected>Desconsiderar questão</option>
                  <option value="1" >Castrado</option>
                  <option value="2" >Inteiro</option>
                </select>
              </div>
            </fieldset>
            <fieldset class="border rounded bg-light p-2 m-2">
              <legend class="py-2 px-4 m-0 border rounded">FÊMEA</legend>
              <div class="form-group row m-0 p-2">
                <label class="col-form-label col-5" for="herdGoalFemale">Objetivo do rebanho</label>
                <select id="herdGoalFemale" name="herdGoalFemale" class="form-control col-7" [(ngModel)]="form.herdGoalFemale.value">
                  <option value="0" selected>Desconsiderar questão</option>
                  <option value="1" >Abate</option>
                  <option value="2" >Venda ao desmame</option>
                  <option value="3" >Produção de matrizes</option>
                </select>
              </div>
              <div class="form-group row m-0 p-2">
                <label class="col-form-label col-5" for="confinementTypeFemale">Tipo do confinamento</label>
                <select id="confinementTypeFemale" name="confinementTypeFemale" class="form-control col-7" [(ngModel)]="form.confinementTypeFemale.value">
                  <option value="0" selected>Desconsiderar questão</option>
                  <option value="1" >A pasto</option>
                  <option value="2" >Semi-confinado</option>
                  <option value="3" >Confinado</option>
                  <option value="4" >A pasto ou semi-confinado</option>
                  <option value="5" >A pasto ou confinado</option>
                  <option value="6" >Semi-confinado ou confinado</option>
                </select>
              </div>
            </fieldset>
          </div>

          <h2 class="text-center my-4">Sugestão de raças</h2>

          <div class="border rounded p-4 m-2 bg-white">
            <div class="form-group row m-0 p-2">
              <label class="col-form-label col-5" for="breed">Raça(s) sugerida(s)</label>
              <div class="col-7">
                <kendo-multiselect id="breed" name="breed"
                    class="form-control"
                    [data]="suggestedBreedsList"
                    [autoClose]="false"
                    (valueChange)="changeSuggestedBreed($event)"
                    [textField]="'name'"
                    [valueField]="'id'"
                    placeholder="Selecione...">
                    <ng-template kendoMultiSelectNoDataTemplate>
                        <p>Carregando...</p>
                    </ng-template>
                </kendo-multiselect>
              </div>
            </div>
          </div>

          <div class="form-row py-2">
            <div class="form-group col-12 m-0 py-2 px-0 text-right">
              <button class="btn btn-cancel mr-3">Cancelar</button>
              <button class="btn btn-submit" type="submit" (click)="saveSuggestion()" >Salvar</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
