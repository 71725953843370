import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { retry, catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from 'src/_models/user';
import { Customer } from 'src/_models/customer';
import { UserInfo } from 'src/_models/user-info';
import { UserId } from 'src/_models/user-token';
import { City } from 'src/_models/city';
import { Uf } from 'src/_models/uf';
import { Breed } from 'src/_models/breed';
import { BreedType } from 'src/_models/breed-type';
import { ProofSelection } from 'src/_models/proof-selection';
import { TraitGroup } from 'src/_models/trait-group';
import { WeightingSuggestion } from 'src/_models/weighting-suggestion/weighting-suggestion';
import { Interview } from 'src/_models/interview';
import { FilterSuggestion } from 'src/_models/filter-suggestion/filter-suggestion';
import { FeatureHighLighting } from 'src/_models/feature-highlighting';
import { Bull } from 'src/_models/bull';
import { InterviewCycle } from 'src/_models/interview-cycle';
import { Suggestion } from 'src/_models/suggestion';
import { AnalyticSelectResultPresentation } from 'src/_models/select-result-presentation/analytic-select-result-presentation';
import { SavedInterview } from 'src/_models/saved-interview/saved-interview';
import { UserActivity } from 'src/_models/user-activity';
import { LoginInfo } from 'src/_models/login-info';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public userToken: UserId;
  public currentUser: Observable<User>;
  private apiBaseUrl = environment.apiBaseUrl;
  private RETRY_NUMBER = 3;

  constructor(
    private http: HttpClient,
    private router: Router,
    private msalService: MsalService) {
      this.userToken = JSON.parse(localStorage.getItem('currentUser'));
  }

  // Users

  public RequestAccess(userInfo: UserInfo): Observable<UserInfo> {
    return this.http
      .post<any>(`${this.apiBaseUrl}/users/requestaccess`, userInfo)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'RequestAccess')));
  }

  public CreateUser(user: User) {
    return this.http
      .post<any>(`${this.apiBaseUrl}/users`, user)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'CreateUser')));
  }

  public UdpateUser(user: User) {
    return this.http
      .put<any>(`${this.apiBaseUrl}/users`, user)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'UdpateUser')));
  }

  public ChangeUserStatus(userId: any) {
    return this.http
      .put<any>(`${this.apiBaseUrl}/users/${userId}/changestatus`, null)
      .toPromise()
      .catch((error) => this.handleError(error, 'ChangeUserStatus'));
  }

  public DeleteUser(userId: any) {
    return this.http
      .delete<any>(`${this.apiBaseUrl}/users/${userId}`)
      .toPromise()
      .catch((error) => this.handleError(error, 'DeleteUser'));
  }

  public GetUser(): Observable<User> {
    if (this.currentUser) {
      return this.currentUser;
    } else if (this.userToken && this.userToken.userId) {
      return this.currentUser = this.http
        .get<User>(`${this.apiBaseUrl}/users/${this.userToken.userId}`)
        .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetUser')))
        .map(data => new User().deserialize(data));
    } else {
      this.msalService.logout();
      this.router.navigate(['/acesso']);
      return new Observable<User>();
    }
  }

  public GetCurrentUser(): Observable<User> {
    if (this.userToken && this.userToken.userId) {
      return this.http
        .get<User>(`${this.apiBaseUrl}/users/${this.userToken.userId}`)
        .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetCurrentUser')))
        .map(data => new User().deserialize(data));
    } else {
      this.msalService.logout();
      this.router.navigate(['/acesso']);
      return new Observable<User>();
    }
  }

  public GetUsers(): Observable<User[]> {
      return this.http
        .get<User[]>(`${this.apiBaseUrl}/users`)
        .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetUsers')));
  }

  public GetAllUsersActivity(): Observable<UserActivity[]> {
    return this.http
        .get<UserActivity[]>(`${this.apiBaseUrl}/users/activity`)
        .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetUsersActivity')));
  }

  // Cities

  public getCities(): Observable<City[]> {
    return this.http
      .get<City[]>(`${this.apiBaseUrl}/address/cities`)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getCities')));
  }

  // Ufs

  public getUfs(): Observable<Uf[]> {
    return this.http
      .get<Uf[]>(`${this.apiBaseUrl}/address/ufs`)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getUfs')));
  }

  // Breeds

  public getBreeds(): Observable<Breed[]> {
    return this.http
      .get<Breed[]>(`${this.apiBaseUrl}/breeds`)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getBreeds')));
  }

  // BreedTypes

  public getBreedTypes(): Observable<BreedType[]> {
    return this.http
      .get<BreedType[]>(`${this.apiBaseUrl}/breedTypes`)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getBreedTypes')));
  }

  public getBreedTypesSelection(): Observable<ProofSelection[]> {
    return this.http
      .get<ProofSelection[]>(`${this.apiBaseUrl}/breedTypes/proofselection`)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getBreedTypesSelection')));
  }

  // Customers

  public CreateCustomer(customer: Customer) {
    return this.http
      .post<any>(`${this.apiBaseUrl}/customers`, customer)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'CreateCustomer')));
  }

  public UdpateCustomer(customer: Customer) {
    return this.http
      .put<any>(`${this.apiBaseUrl}/customers`, customer)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'UdpateCustomer')));
  }

  public ChangeCustomerStatus(customerId: any) {
    return this.http
      .put<any>(`${this.apiBaseUrl}/customers/${customerId}/changestatus`, null)
      .toPromise()
      .catch((error) => this.handleError(error, 'ChangeCustomerStatus'));
  }

  public DeleteCustomer(customerId: any) {
    return this.http
      .delete<any>(`${this.apiBaseUrl}/customers/${customerId}`)
      .toPromise()
      .catch((error) => this.handleError(error, 'DeleteCustomer'));
  }

  // Weighting Traits Groups

  public GetWeightingTraitsGroups(proofId: number, breedId: number): Observable<TraitGroup[]> {
    return this.http
        .get<TraitGroup[]>(`${this.apiBaseUrl}/weighting/traitsgroups/${proofId}/${breedId}`)
        .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetWeightingTraitsGroups')));
  }

  // Filter Traits Groups

  public GetFilterTraitsGroups(proofId: number, breedId: number): Observable<TraitGroup[]> {
    return this.http
        .get<TraitGroup[]>(`${this.apiBaseUrl}/filter/traitsgroups/${proofId}/${breedId}`)
        .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetFilterTraitsGroups')));
  }

  // Weighting Suggestion

  public getWeightingSuggestions(interview: Interview): Observable<WeightingSuggestion> {
    return this.http
      .post<any>(`${this.apiBaseUrl}/weighting/suggestions`, interview)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getWeightingSuggestions')));
  }

  // Filter Suggestion

  public getFilterSuggestions(interview: Interview): Observable<FilterSuggestion> {
    return this.http
      .post<any>(`${this.apiBaseUrl}/filter/suggestions`, interview)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getFilterSuggestions')));
  }

  // Feature HighLighting

  public getWeightingFeatureHighLighting(interview: Interview): Observable<FeatureHighLighting[]> {
    return this.http
      .post<any>(`${this.apiBaseUrl}/weighting/featurehighlighting`, interview)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getWeightingFeatureHighLighting')));
  }

  public getFilterFeatureHighLighting(interview: Interview): Observable<FeatureHighLighting[]> {
    return this.http
      .post<any>(`${this.apiBaseUrl}/filter/featurehighlighting`, interview)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getFilterFeatureHighLighting')));
  }

  public getBulls(interviewCycle: InterviewCycle): Observable<Array<Bull>> {
    return this.http
      .post<any>(`${this.apiBaseUrl}/bullselection`, interviewCycle)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getBulls')));
  }

  // Breed Suggestions
  public getBreedSuggestions(): Observable<Suggestion[]> {
    return this.http
      .get<Suggestion[]>(`${this.apiBaseUrl}/interviewsuggestion`)
      .pipe(retry(3), catchError((error) => this.handleError(error, 'getBreedSuggestions')));
  }

  // Select Result Presentation

  public getAnalyticSelectResultPresentation(interviewCycle: InterviewCycle): Observable<AnalyticSelectResultPresentation[]> {
    return this.http
        .post<any>(`${this.apiBaseUrl}/selectresultpresentation`, interviewCycle)
        .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'getAnalyticSelectResultPresentation')));
  }

  // Interview

  public getSavedInterviews(): Observable<SavedInterview[]> {
    return this.http
        .get<SavedInterview[]>(`${this.apiBaseUrl}/interviews/saved/${this.userToken.userId}`)
        .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetSavedInterviews')));
  }

  public getInterview(id: number): Observable<Interview> {
    return this.http
    .get<Interview>(`${this.apiBaseUrl}/interviews/${id}`)
    .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetInterview')));


  }

  public saveInterview(interview: Interview) {
    return this.http
      .post<any>(`${this.apiBaseUrl}/interviews`, interview)
      .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'saveInterview')));
  }

  public cloneInterview(interview: SavedInterview) {
    return this.http
    .get<Interview>(`${this.apiBaseUrl}/interviews/clone/${interview.interviewId}`)
    .pipe(retry(this.RETRY_NUMBER), catchError((error) => this.handleError(error, 'GetInterview')));
  }

  // Error

  private handleError(error: HttpErrorResponse, method: string) {
    console.error(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
        } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error('Backend returned an error:');
      console.error(error);
      console.error(`Method: ${method}`);
      if (error.status === 404) {
          this.router.navigateByUrl('/404', { skipLocationChange: true });
      } else if (error.status === 401) {
            // não autorizado
            alert(`A sessão expirou. É necessário acessar o sistema novamente.`);
            this.msalService.logout();
            this.router.navigate(['/acesso']);
          }
        }
    return Observable.throwError(error);
  }

  login(loginInfo: LoginInfo) {
    // console.log("loginInfo: ", loginInfo);
    return this.http.post<any>(`${environment.apiBaseUrl}/login`, loginInfo)
      .pipe(map(userToken => { return userToken; }));
  }
}
