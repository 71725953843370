import { Input } from '@angular/core';

export class UserInfo {

  public email: string;

  public password: string;

  public name: string;

}
