import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Farm } from 'src/_models/farm';
import { Customer } from 'src/_models/customer';
import { Person } from 'src/_models/person';
import { HerdComponent } from '@app/herd/herd.component';
import { DialogConfirmService } from '@app/_services/dialog-confirm.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-farm',
  templateUrl: './farm.component.html',
  styleUrls: ['./farm.component.scss']
})
export class FarmComponent implements OnInit, OnChanges {

  @ViewChildren(HerdComponent) herds: QueryList<HerdComponent>;
  // @ViewChild(HerdComponent, {static: false}) herds;

  @Input() customer: any;

  public editCustomer: Customer;

  public iconTrash = faTrashAlt;
  public iconAdd = faPlus;

  constructor(private dialogConfirmService: DialogConfirmService) { }

  ngOnInit() {
    this.CopyCustomer();
    this.CreateNewCustomer();
    // this.AddFarm();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.CopyCustomer();
  }

  private CopyCustomer() {
    this.editCustomer = Object.assign({}, this.customer);
  }

  private CreateNewCustomer() {
    if (!this.editCustomer || !this.editCustomer.id) {
      this.editCustomer = new Customer();
      this.editCustomer.farm = new Array<Farm>();
      this.editCustomer.person = new Person();
    }
  }

  public AddFarm() {
    this.editCustomer.farm.push(new Farm());
  }

  public RemoveFarm(index: number) {
    const dialogTitle = `Remover fazenda`;
    const dialogContent =  `Deseja remover esta fazenda ?`;
    this.dialogConfirmService.Confirm(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
          if (action === `Sim`) {
            this.editCustomer.farm.reverse().splice(index, 1);
            this.editCustomer.farm.reverse();
          }
        }
    });
  }
}
