<div class="modal-header">
  <h2 class="modal-title">Pricipais problemas observados no rebanho</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngFor="let herdProblem of listHerdProblems">
    <div *ngIf="herdProblem.active" class="form-group col-md-12 col-xs-6">
      <div class="custom-control custom-checkbox">
        <input class="custom-control-input" type="checkbox" id="herdProblem_{{herdProblem.id}}" [(ngModel)]="herdProblem.checked" [ngModelOptions]="{standalone: true}">
        <label class="custom-control-label" for="herdProblem_{{herdProblem.id}}">{{herdProblem.description}}</label>
      </div>
      <div class="custom-control" *ngIf="herdProblem.id == 24 && herdProblem.checked">
        <input type="text" value="" [(ngModel)]="herdProblem.otherProblem" maxlength="50">
      </div>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="cancel()">Cancelar</button>
  <button type="button" class="btn btn-submit" (click)="addProblems()" >Enviar</button>
</div>
