import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '@app/_pipes/must-match';
import { ApiService } from '@app/_services/api.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserInfo } from 'src/_models/user-info';

@Component({
  selector: 'app-modal-access-request',
  templateUrl: './modal-access-request.component.html',
  styleUrls: ['./modal-access-request.component.scss']
})
export class ModalAccessRequestComponent implements OnInit {

  public accessRequestForm: FormGroup;
  public loading = false;
  public submitted = false;
  public error = '';
  public userCreated = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.accessRequestForm = this.CreateRequestForm();
  }

  get f() { return this.accessRequestForm.controls; }

  private CreateRequestForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(8)]],
      passwordConfirm: ['', Validators.required]
      }, {
        validator: MustMatch('password', 'passwordConfirm')
      });
  }

  public onSubmit() {
    this.submitted = true;

    if (this.accessRequestForm.invalid) {
      return;
    } else {
        // console.group('Requisição de acesso');
        // console.log('Nome:', this.f.name.value);
        // console.log('E-mail:', this.f.email.value);
        // console.log('Senha:', this.f.password.value);
        // console.groupEnd();
        // console.log(this.CreateUserInfo());

        this.RequestAccess(this.CreateUserInfo());
      }
  }

  private CreateUserInfo(): UserInfo {
    const userInfo = new UserInfo();
    userInfo.name = this.f.name.value;
    userInfo.email = this.f.email.value;
    userInfo.password = this.f.password.value;
    return userInfo;
  }

  private RequestAccess(userInfo: UserInfo) {
    this.apiService.RequestAccess(userInfo)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.userCreated = true;
          this.error = '';
        },
        msg => {
          console.log(msg);
            this.error = msg.error;
            this.loading = false;
            this.userCreated = false;
        }
      );
      //this.accessRequestForm.reset();
  }
}
