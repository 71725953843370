<div class="row px-2">
  <div kendoDialogContainer></div>
  <div class="col-12 m-0 p-2">
    <div class="border rounded p-4 bg-white">
      <h3 class="my-2">Gerentes</h3>
      <div class="form-row">
        <div class="form-group col-6">
          <label for="regionManager">Regional</label>
          <input
            type="text"
            [(ngModel)]="user.regionManager"
            class="form-control"
            id="regionManager"
            name="regionManager"
            placeholder="Regional"
            autocomplete="off"
            tabindex="0"
            required
          />
          <small class="text-danger" *ngIf="!user.regionManager"
            >Informe o Regional responsável</small
          >
        </div>

        <div class="form-group col-6">
          <label for="districtManager">Distrital</label>
          <input
            type="text"
            [(ngModel)]="user.districtManager"
            class="form-control"
            id="districtManager"
            name="districtManager"
            placeholder="Distrital"
            autocomplete="off"
            tabindex="0"
            required
          />
          <small class="text-danger" *ngIf="!user.districtManager"
            >Informe o Distrital responsável</small
          >
        </div>
      </div>
    </div>
  </div>
</div>
