import { WeightingQuestion } from './weighting-question';
import { WeightingSuggestionHasTrait } from './weighting-suggestion-has-trait';

export class WeightingSuggestion {

    public id: number;

    public description: string;

    public weightingSuggestionHasTrait: Array<WeightingSuggestionHasTrait>;

    public weightingQuestion: Array<WeightingQuestion>;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.weightingQuestion =
            this.weightingQuestion ?
                input.weightingQuestion
                .map((w: WeightingQuestion) => new WeightingQuestion()
                .deserialize(w)) : this.weightingQuestion;

        this.weightingSuggestionHasTrait =
            this.weightingSuggestionHasTrait ?
                input.weightingSuggestionHasTrait
                .map((w: WeightingSuggestionHasTrait) => new WeightingSuggestionHasTrait()
                .deserialize(w)) : this.weightingSuggestionHasTrait;

        return this;
    }
}
