<!-- <nav>
  <a routerLink="/acesso" routerLinkActive="active">Acesso</a>
  <a routerLink="/cadastro" routerLinkActive="active">Cadastro</a>
</nav> -->
<nav id="header" class="navbar navbar-expand-lg fixed-top navbar-light bg-white p-0">
  <button class="navbar-toggler" id="logoutButton" (click)="showMenu = !showMenu" >
    <span  class="fa-sm navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand h-100 m-0 p-2" href="/">
    <img class="d-block h-100 py-0 px-2 m-0" alt="Plano Genético Corte" src="assets/images/plano_genetico_topo.png" />
  </a>
  <div id="navbarUser">
    <div class="row p-0  m-0">
      <div class="col mt-1 mb-0 py-2 px-4">
        <div class="row m-0 p-0">
          <label>{{currentUser.person.name}}</label>
        </div>
        <div class="row m-0 p-0">
          <small>{{currentUser.email}}</small>
        </div>
      </div>
      <div class="col m-0 p-0 border-left">
        <a id="logoutButton" (click)="logout()">
          <fa-icon [icon]="iconSignOut" class="fa-sm"></fa-icon>
        </a>
      </div>
    </div>
  </div>

  <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="d-inline-block py-1 px-3 align-top">
        <p class="m-0 small text-right px-1">Responsável</p>
      </div>
  </div> -->
  <!-- <svg class="material-icon menu-icon align-middle d-inline-block d-md-none pr-1" viewBox="0 0 24 24"
    (click)="showMenu = !showMenu">
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg> -->
</nav>
<div id="page" class="row h-100 m-0" style="padding-top: 56px;">
  <aside id="navbar" class="h-100" style="background-color: #2C2C2C;" [ngClass]="{'collapsed': showMenu}">
    <nav role="navigation">
      <ul>
        <li [routerLinkActive]="'active'">
          <a routerLink="/inicio">
            <fa-icon [icon]="faHome" class="fa-sm hidden-lg hidden-md" aria-hidden="true"></fa-icon>
            Página Inicial
          </a>
        </li>
        <li [routerLinkActive]="'active'">
          <a routerLink="/cadastros">
            <fa-icon [icon]="faUserPlus" class="fa-sm"></fa-icon>
            Cadastros
          </a>
        </li>
        <li [routerLinkActive]="'active'">
          <a routerLink="/entrevista">
            <fa-icon [icon]="faEdit" class="fa-sm"></fa-icon>
            Nova Entrevista
          </a>
        </li>
        <li [routerLinkActive]="'active'">
          <a routerLink="/historico">
            <fa-icon [icon]="faFileAlt" class="fa-sm"></fa-icon>
            Entrevistas Salvas
          </a>
        </li>
        <li [routerLinkActive]="'active'">
          <a routerLink="/perfil">
            <fa-icon [icon]="faUser" class="fa-sm"></fa-icon>
            Meu Perfil
          </a>
        </li>
        <li [routerLinkActive]="'active'" *ngIf="isAdminUser()">
          <a routerLink="/usuarios">
            <fa-icon [icon]="faUsers" class="fa-sm"></fa-icon>
            Usuários
          </a>
        </li>
        <!-- <li [routerLinkActive]="'active'">
          <a routerLink="/parametros">
            Parâmetros
          </a>
        </li> -->
      </ul>
    </nav>
  </aside>
  <section id="content" class="col p-4 h-100 bg-light" [scrollTop]="0">
    <router-outlet></router-outlet>
  </section>
</div>
