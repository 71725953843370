<div class="container-fluid h-100">
  <div #div style="position: relative; height: 600px">
    <div
      class="row justify-content-center h-100 listArea"
      [style.visibility]="!showForm ? 'visible' : 'hidden'"
      style="position: absolute; top: 0; left: 0; width: 100%">

      <div class="col-10 h-100">
        <div class="panel">
          <h2 class="text-center mb-4">Usuários cadastrados</h2>
          <div class="d-flex form-group">
            <div class="w-100 pr-2">
              <input
                class="form-control"
                type="search"
                placeholder="Pesquisar por nome ou e-mail"
                (input)="FindByKeyword($event)"
              />
            </div>
            <div class="flex-shrink-1">
              <button
                class="btn btn-submit form-control"
                (click)="OpenForm(null)"
              >
                <fa-icon [icon]="addUser" title="Adicionar usuário"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <ng-template [ngIf]="usersList.length > 0" [ngIfElse]="listEmpty">
          <kendo-grid
            (remove)="ChangeUserStatus($event)"
            [kendoGridBinding]="usersList"
            [data]="gridView"
            [sortable]="{ allowUnsort: 'allowUnsort', mode: 'single' }"
            [sort]="userSort"
            (sortChange)="UsersSortChange($event)"
            [selectable]="true"
            (selectedKeysChange)="ClickTableRow()"
            [kendoGridSelectBy]="selectedCallback"
            [selectedKeys]="gridSelection"
            style="max-height: calc(100% - 90px)">
            
            <kendo-grid-column media="xs" title="Usuários">
              <ng-template kendoGridCellTemplate let-dataItem>
                <dl (click)="OpenForm(dataItem)">
                  <dt>Usuário</dt>
                  <dd>{{ dataItem.person.name }}</dd>

                  <dt>Documento</dt>
                  <dd>{{ dataItem.person.docNumber }}</dd>

                  <dt>Email</dt>
                  <dd>{{ dataItem.email }}</dd>

                  <dt>Regional</dt>
                  <dd>{{ dataItem.RegionManager }}</dd>

                  <dt>Distrital</dt>
                  <dd>{{ dataItem.DistrictManager }}</dd>

                  <dt>Data Cadastro</dt>
                  <dd>{{ dataItem.registerDate | date: "dd/MM/yyyy" }}</dd>

                  <dt>Último Acesso</dt>
                  <dd>{{ dataItem.lastAccess | date: "dd/MM/yyyy" }}</dd>

                  <dt>Ativo</dt>
                  <dd>{{ IsActive(dataItem.status) }}</dd>
                </dl>
              </ng-template>
            </kendo-grid-column>

            <!-- columns for mid-size devices -->
            <kendo-grid-column
              media="sm"
              field="person.name"
              title="Usuário"
              width="200"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
              [footerStyle]="{
                'font-weight': 'bold',
                color: '#000',
                'line-height': '1em'
              }"
            >
              <ng-template kendoGridFooterTemplate
                >Total: {{ usersList.length }}</ng-template
              >
            </kendo-grid-column>
            <kendo-grid-column
              media="sm"
              field="person.docNumber"
              title="Documento"
              width="130"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
            >
            </kendo-grid-column>
            <kendo-grid-column
              media="sm"
              field="email"
              title="Email"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
            >
            </kendo-grid-column>

            <kendo-grid-column
              media="sm"
              field="regionManager"
              title="Regional"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
            >
            </kendo-grid-column>

            <kendo-grid-column
              media="sm"
              field="districtManager"
              title="Distrital"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
            >
            </kendo-grid-column>

            <kendo-grid-column
              media="sm"
              title="Data Cadastro"
              field="registerDate"
              width="150"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div *ngIf="dataItem.registerDate != null">
                  {{ dataItem.registerDate | date: "dd/MM/yyyy" }}
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              media="sm"
              field="lastAccess"
              title="Data Últ. Acesso"
              width="150"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div *ngIf="dataItem.lastAccess != null">
                  {{ dataItem.lastAccess | date: "dd/MM/yyyy" }}
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              media="md"
              title="Ativo"
              field="status"
              width="70"
              class="text-center"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <fa-icon
                  *ngIf="dataItem.status !== 1"
                  [icon]="IsActive(dataItem.status)"
                ></fa-icon>
                <div *ngIf="dataItem.status === 1">Aguardando</div>
              </ng-template>
            </kendo-grid-column>

            <!-- command columns -->
            <kendo-grid-command-column
              width="120"
              media="md"
              [headerStyle]="{ 'background-color': '#035737', color: '#fff' }"
            >
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <button kendoGridRemoveCommand *ngIf="dataItem.status === 2">
                  Desativar
                </button>
                <button kendoGridRemoveCommand *ngIf="dataItem.status !== 2">
                  Ativar
                </button>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>

          <div kendoDialogContainer></div>
        </ng-template>

        <div *ngIf="!isUsersLoaded" class="text-center">
          <span
            class="k-icon k-i-loading"
            style="font-size: 64px; color: #035737"
          ></span>
          <p class="text-center w-100">Buscando usuários...</p>
        </div>

        <ng-template #listEmpty>
          <div
            *ngIf="isUsersLoaded"
            class="d-flex align-items-center"
            style="height: calc(100% - 90px)"
          >
            <p class="text-center w-100">Nenhum usuário cadastrado.</p>
          </div>
        </ng-template>

        <div *ngIf="usersList.length > 0" class="row px-2">
          <div class="col-12 m-0 p-2 text-right">
            <small *ngIf="error" class="alert alert-danger m-3 p-2">
              {{ error }}
            </small>
            <small *ngIf="success" class="alert alert-success m-3 p-2">
              {{ success }}
            </small>
            <button
              class="btn btn-submit"
              type="submit"
              (click)="exportUserActivity()"
            >
              Exportar atividade
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row formArea"
      [style.visibility]="showForm ? 'visible' : 'hidden'"
      style="position: absolute; top: 0; left: 0; width: 100%"
    >
      <div class="col">
        <form (ngSubmit)="OnSubmit()">
          <h2 class="text-center">
            Informações do usuário {{ GetUserName(gridSelection[0]) }}
          </h2>

          <app-managers [user]="editUser"></app-managers>
          <app-person [person]="editUser.person"></app-person>

          <div class="row px-2">
            <div class="col-12 m-0 p-2">
              <button
                class="btn btn-delete mr-3"
                type="button"
                (click)="CloseForm()"
              >
                Cancelar
              </button>
              <button class="btn btn-submit" type="submit">Salvar</button>
              <small *ngIf="error" class="alert alert-danger m-3 p-2">{{
                error
              }}</small>
              <small *ngIf="success" class="alert alert-success m-3 p-2">{{
                success
              }}</small>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
