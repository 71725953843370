export class BreedType {

  public id: number;

  public name: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
