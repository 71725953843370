<h2 class="text-center my-4">Resultado da seleção - {{interviewCycle.breedName}} ({{interviewCycle.proofName}})</h2>
<div class="border rounded p-4 bg-white col-12">
    <kendo-tabstrip [keepTabContent]="true">
        <kendo-tabstrip-tab [title]="'Sintético'" [selected]="true">
            <ng-template kendoTabContent>
                <ng-template [ngIf]="syntheticResults.length > 0 && isChartLoaded">
                    <kendo-chart #chart id="chart">
                        <kendo-chart-area height="500" [margin]="20"></kendo-chart-area>

                        <kendo-chart-series>
                            <kendo-chart-series-item 
                                type="bar" 
                                [data]="syntheticResults"
                                field="percent" 
                                categoryField="description"
                                [labels]="seriesLabels"
                                [color]="chartBarColor()">
                            </kendo-chart-series-item>
                        </kendo-chart-series>

                        <kendo-chart-category-axis>
                            <kendo-chart-category-axis-item>
                                <kendo-chart-category-axis-item-labels 
                                    [position]="categoriesLabelsPosition">
                                </kendo-chart-category-axis-item-labels>
                            </kendo-chart-category-axis-item>
                        </kendo-chart-category-axis>

                        <kendo-chart-value-axis>
                            <kendo-chart-value-axis-item
                                [min]="chartMinValue()" 
                                [max]="chartMaxValue()"
                                [majorUnit]="chartMajorUnit()"
                                [reverse]="true"
                                [axisCrossingValue]="chartAxisCrossingValue()">
                                <kendo-chart-value-axis-item-labels 
                                    [position]="valuesLabelsPosition">
                                </kendo-chart-value-axis-item-labels>
                            </kendo-chart-value-axis-item>
                        </kendo-chart-value-axis>
                    </kendo-chart>
                </ng-template>

                
                <div *ngIf="!isChartLoaded" class="text-center">
                    <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
                    <p class="text-center w-100">Gerando gráfico...</p>
                </div>

            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Analítico'">
            <ng-template kendoTabContent>
                <ng-template [ngIf]="analyticResults.length > 0 && isResultsLoaded">
                    <kendo-grid
                        [kendoGridBinding]="analyticResults"
                        [data]="gridView"
                        [selectable]="selectableSettings"
                        [kendoGridSelectBy]="selectedCallback"
                        [selectedKeys]="gridSelection"
                        style="max-height: calc(100% - 90px);"
                        [height]="500"
                        [rowClass]="rowCallback">
                        <kendo-grid-column 
                            media="xs" 
                            title="Analítico" 
                            width="100">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <input type="checkbox"/>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <dl>
                                    <dt>Característica</dt>
                                    <dd>{{ getDescription(dataItem) </dd>
            
                                    <dt>Ponderação</dt>
                                    <dd>{{ dataItem.weighting }}</dd>
            
                                    <dt>Vetor (%)</dt>
                                    <dd>{{ dataItem.filterPercentSignal }}</dd>
            
                                    <dt>%</dt>
                                    <dd>{{ dataItem.filterPercentValue }}</dd>
            
                                    <dt>Vetor (Filtro)</dt>
                                    <dd>{{ dataItem.filterDepSignal }}</dd>
            
                                    <dt>DEP (Filtro)</dt>
                                    <dd>{{ dataItem.filterDepValue }}</dd>
            
                                    <dt>ACC (Filtro)</dt>
                                    <dd>{{ dataItem.filterAccValue }}</dd>
            
                                    <dt>M. Touros</dt>
                                    <dd>{{ dataItem.bullAverage }}</dd>
            
                                    <dt>M. Plano</dt>
                                    <dd>{{ dataItem.schemeAverage }}</dd>
            
                                    <dt>ACC</dt>
                                    <dd>{{ dataItem.acc }}</dd>
            
                                    <dt>%</dt>
                                    <dd>{{ dataItem.percent }}</dd>
                                </dl>
                            </ng-template>
                        </kendo-grid-column>
            
                        <!-- columns for mid-size devices -->
                        <kendo-grid-column
                              media="md"
                              title="Característica"
                              width="500"
                              class="text-left"
                              [locked]="true"
                              [headerStyle]="{'background-color': '#035737','color': '#fff'}">
                              <ng-template kendoGridCellTemplate let-dataItem>
                                    {{ getDescription(dataItem) }}
                              </ng-template>
                          </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="weighting"
                            title="Pond."
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="whole-cell" 
                                    [style.backgroundColor]="gridCellBackGroundColor(dataItem.weighting)"
                                    [style.color]="gridCellColor(dataItem.weighting)"
                                    *ngIf="!dataItem.isTraitGroup">
                                    {{ dataItem.weighting }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="filterPercentSignal"
                            title="Vetor(%)"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="90">
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="filterPercentValue"
                            title="%"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="60">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="whole-cell" 
                                    [style.backgroundColor]="gridCellBackGroundColor(dataItem.filterPercentValue)"
                                    [style.color]="gridCellColor(dataItem.filterPercentValue)"
                                    *ngIf="!dataItem.isTraitGroup">
                                    {{ dataItem.filterPercentValue }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="filterDepSignal"
                            title="Vetor(Filtro)"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="110">
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="filterDepValue"
                            title="DEP(Filtro)"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="100">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span 
                                    class="whole-cell" 
                                    [style.backgroundColor]="gridCellBackGroundColor(dataItem.filterDepValue)"
                                    [style.color]="gridCellColor(dataItem.filterDepValue)"
                                    *ngIf="!dataItem.isTraitGroup">
                                    {{ dataItem.filterDepValue }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="filterAccValue"
                            title="ACC(Filtro)"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="100">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span 
                                    class="whole-cell" 
                                    [style.backgroundColor]="gridCellBackGroundColor(dataItem.filterAccValue)" 
                                    [style.color]="gridCellColor(dataItem.filterAccValue)"
                                    *ngIf="!dataItem.isTraitGroup">
                                    {{ dataItem.filterAccValue }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="bullAverage"
                            title="M. Touros"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="90">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="!dataItem.isTraitGroup">
                                    {{ dataItem.bullAverage }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="schemeAverage"
                            title="M. Plano"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="90">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="!dataItem.isTraitGroup && dataItem.schemeAverage">
                                    {{ dataItem.schemeAverage }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="acc"
                            title="ACC"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="90">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="!dataItem.isTraitGroup && dataItem.accAverage">
                                    {{ dataItem.accAverage }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="percent"
                            title="%"
                            *ngIf="!isDeca()"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="90">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="!dataItem.isTraitGroup && dataItem.percent > 0">
                                    {{ dataItem.percent }}%
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            media="sm"
                            field="percent"
                            title="DECA"
                            *ngIf="isDeca()"
                            class="text-left"
                            [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                            width="90">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span *ngIf="!dataItem.isTraitGroup && dataItem.percent > 0">
                                    {{ dataItem.percent }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </ng-template>
            
                <!-- <div *ngIf="!isResultsLoaded" class="text-center">
                    <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
                    <p class="text-center w-100">Calculando resultados...</p>
                </div> -->

            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>
