import { User } from './user';
import { Herd } from './herd';
import { InterviewBreedGoal } from './interview-breed-goal';
import { BonusProgramm } from './bonus-program';
import { HerdProblem } from './herd-problem';
import { InterviewCycle } from './interview-cycle';
import { ReproductiveManagement } from './reproductive-management';
import { CowsHeifersQuantity } from './cows-heifers-quantity';
import { DosesDistributionByBreed } from './doses-distribution-by-breed';

export class Interview {

    public id: number;

    public userId: string;

    public herd: Herd;

    public herdId: number;

    public cowsHeifersQuantityId: number;

    public dosesDistributionByBreedId: number;

    public reproductiveManagementId: number;

    public interviewDate: Date;

    public femaleOrigin: number;

    public breedOptions: number;

    public breedOptionsMatting: number;

    public currentInterviewCycle: number;

    public reproductiveManagement: ReproductiveManagement;

    public cowsHeifersQuantity: CowsHeifersQuantity;

    public dosesDistributionByBreed: DosesDistributionByBreed;

    public herdProblem: Array<HerdProblem>;

    public interviewBreedGoal: Array<InterviewBreedGoal>;

    public interviewCycle: Array<InterviewCycle>;

    public bonusProgram: Array<BonusProgramm>;

    public pdf: any;

    public isFastPlan: boolean;


    constructor () {
      this.interviewDate = this.interviewDate ? this.interviewDate : new Date();
      this.femaleOrigin = this.femaleOrigin ? this.femaleOrigin : 0;
      this.breedOptions = this.breedOptions ? this.breedOptions : 1;
      this.breedOptionsMatting = this.breedOptionsMatting ? this.breedOptionsMatting : 0;
      this.currentInterviewCycle = this.currentInterviewCycle ? this.currentInterviewCycle : 0;

      this.herd = this.herd ? this.herd : new Herd();
      this.reproductiveManagement = this.reproductiveManagement ? this.reproductiveManagement : new ReproductiveManagement();

      if (!this.interviewBreedGoal) {
        this.interviewBreedGoal = new Array<InterviewBreedGoal>();
        this.interviewBreedGoal.push(new InterviewBreedGoal());
        this.interviewBreedGoal.push(new InterviewBreedGoal());
      }

      this.interviewCycle = this.interviewCycle ? this.interviewCycle : new Array<InterviewCycle>();

      this.bonusProgram = this.bonusProgram ? this.bonusProgram : new Array<BonusProgramm>();

      this.herdProblem = this.herdProblem ? this.herdProblem : new Array<HerdProblem>();
    }

    deserialize(input: any): this {
      Object.assign(this, input);

      this.interviewBreedGoal = this.interviewBreedGoal ? input.interviewBreedGoal
      .map((c: InterviewBreedGoal) => new InterviewBreedGoal().deserialize(c)) : this.interviewBreedGoal;

      this.interviewCycle = this.interviewCycle ? input.interviewCycle
      .map((i: InterviewCycle) => new InterviewCycle().deserialize(i)) : this.interviewCycle;

      this.reproductiveManagement = this.reproductiveManagement ? input.reproductiveManagement
      .map((r: ReproductiveManagement) => new ReproductiveManagement().deserialize(r)) : this.reproductiveManagement;

      this.cowsHeifersQuantity = this.cowsHeifersQuantity ? input.cowsHeifersQuantity
      .map((c: CowsHeifersQuantity) => new CowsHeifersQuantity().deserialize(c)) : this.cowsHeifersQuantity;

      this.dosesDistributionByBreed = this.dosesDistributionByBreed ? input.dosesDistributionByBreed
      .map((d: DosesDistributionByBreed) => new DosesDistributionByBreed().deserialize(d)) : this.dosesDistributionByBreed;

      this.bonusProgram = this.bonusProgram ? input.bonusProgram
      .map((b: BonusProgramm) => new BonusProgramm().deserialize(b)) : this.bonusProgram;

      this.herdProblem = this.herdProblem ? input.herdProblem
      .map((c: HerdProblem) => new HerdProblem().deserialize(c)) : this.herdProblem;

      return this;
    }
}
