import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-calving-distribution]',
  templateUrl: './calving-distribution.component.html',
  styleUrls: ['./calving-distribution.component.scss']
})
export class CalvingDistributionComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;
  @Output() isValidCalvingDistributionTotal = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  get calvingDistributionTotal() {
    const beginning = Number(this.reproductiveManagement.calvingDistributionBeginning || 0);
    const middle = Number(this.reproductiveManagement.calvingDistributionMiddle || 0);
    const end = Number(this.reproductiveManagement.calvingDistributionEnd || 0);

    if ((beginning + middle + end) != 100) {
      this.isValidCalvingDistributionTotal.emit(false);
    } else {
      this.isValidCalvingDistributionTotal.emit(true);
    }

    return beginning + middle + end;
  }

}
