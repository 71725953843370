export class Uf {

  public uf1: string;

  public name: string;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
