import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { apiConfig } from '@app/_authorization/auth.config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardHelper implements CanActivate {

  constructor(private msalService: MsalService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve) => {
      this.msalService.acquireTokenSilent({ scopes: apiConfig.b2cScopes })
        .then((tokenResponse) => {
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          this.router.navigate(['/acesso']);
          resolve(false);
        });
    });
  }
}
