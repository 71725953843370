import { TraitGroup } from './trait-group';
import { BullSelectionFilter } from './bull-selection-filter';
import { SelectedBull } from './selected-bull';
import { AnalyticSelectResultPresentation } from './select-result-presentation/analytic-select-result-presentation';

export class InterviewCycle {

    public id: number;

    public interviewId: number;

    public breedId: number;

    public proofId: number;

    public proofName: string;

    public breedName: string;

    public selectedWeightingTraitsGroups: Array<TraitGroup>;

    public selectedFilterTraitsGroups: Array<TraitGroup>;

    public selectedBull: Array<SelectedBull>;

    public bullSelectionFilter: Array<BullSelectionFilter>;

    public analyticResultPresentation: Array<AnalyticSelectResultPresentation>;

    public chart: string;

    deserialize(input: any): this {
        Object.assign(this, input);

        this.selectedWeightingTraitsGroups = this.selectedWeightingTraitsGroups ? input.weightingTraitGroup
            .map((tg: TraitGroup) => new TraitGroup()
            .deserialize(tg)) : this.selectedWeightingTraitsGroups;

        this.selectedFilterTraitsGroups = this.selectedFilterTraitsGroups ? input.filterTraitGroup
            .map((tg: TraitGroup) => new TraitGroup()
            .deserialize(tg)) : this.selectedFilterTraitsGroups;

        this.bullSelectionFilter = this.bullSelectionFilter ? input.bullSelectionFilter
            .map((b: BullSelectionFilter) => new BullSelectionFilter()
            .deserialize(b)) : this.bullSelectionFilter;

        this.selectedBull = this.selectedBull ? input.selectedBulls
            .map((b: SelectedBull) => new SelectedBull()
            .deserialize(b)) : this.selectedBull;

        this.analyticResultPresentation = this.analyticResultPresentation ? input.analyticResultPresentation
            .map((a: AnalyticSelectResultPresentation) => new AnalyticSelectResultPresentation()
            .deserialize(a)) : this.analyticResultPresentation;

        return this;
    }
}
