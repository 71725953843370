import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Interview } from 'src/_models/interview';
import { CalvingDistributionComponent } from './calving-distribution/calving-distribution.component';
import { MaxIatfComponent } from './max-iatf/max-iatf.component';
import { PregnancyRateComponent } from './pregnancy-rate/pregnancy-rate.component';
import { QuantityToInseminateComponent } from './quantity-to-inseminate/quantity-to-inseminate.component';
import { ReadyToIatfComponent } from './ready-to-iatf/ready-to-iatf.component';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import { AveragePregnancyRateComponent } from './average-pregnancy-rate/average-pregnancy-rate.component';
import { BreedDistributionComponent } from './breed-distribution/breed-distribution.component';
import { IatfDistributionComponent } from './iatf-distribution/iatf-distribution.component';
import { IatfDistributionPerSeasonComponent } from './iatf-distribution-per-season/iatf-distribution-per-season.component';
import { PregnancyDistributionPerBreedComponent } from './pregnancy-distribution-per-breed/pregnancy-distribution-per-breed.component';
// tslint:disable-next-line:max-line-length
import { PregnancyIatfDistributionPerSeasonComponent } from './pregnancy-iatf-distribution-per-season/pregnancy-iatf-distribution-per-season.component';
import { InterviewCycle } from 'src/_models/interview-cycle';
import { Herd } from 'src/_models/herd';
import { DosesDistributionByBreed } from 'src/_models/doses-distribution-by-breed';
import { ReproductiveManagementService } from '@app/_services/reproductive-management.service';

@Component({
  selector: 'app-reproductive-management',
  templateUrl: './reproductive-management.component.html',
  styleUrls: ['./reproductive-management.component.scss']
})
export class ReproductiveManagementComponent implements OnInit {

  @ViewChild(CalvingDistributionComponent, { static: false }) calvingDistribution: CalvingDistributionComponent;
  @ViewChild(MaxIatfComponent, { static: false }) maxIatf: MaxIatfComponent;
  @ViewChild(PregnancyRateComponent, { static: false }) pregnancyRate: PregnancyRateComponent;
  @ViewChild(QuantityToInseminateComponent, { static: false }) quantityToInseminate: QuantityToInseminateComponent;
  @ViewChild(ReadyToIatfComponent, { static: false }) readyToIatf: ReadyToIatfComponent;

  @ViewChild(AveragePregnancyRateComponent, { static: false }) averagePregnancyRate: AveragePregnancyRateComponent;
  @ViewChild(BreedDistributionComponent, { static: false }) breedDistribution: BreedDistributionComponent;
  @ViewChild(IatfDistributionComponent, { static: false }) iatfDistribution: IatfDistributionComponent;
  @ViewChild(IatfDistributionPerSeasonComponent, { static: false }) iatfDistributionPerSeason: IatfDistributionPerSeasonComponent;
  @ViewChild(PregnancyDistributionPerBreedComponent, { static: false }) pregnancyDistributionPerBreed: PregnancyDistributionPerBreedComponent;
  @ViewChild(PregnancyIatfDistributionPerSeasonComponent, { static: false }) pregnancyIatfDistributionPerSeason: PregnancyIatfDistributionPerSeasonComponent;

  // @ViewChild(CalvingDistributionComponent) calvingDistribution: any;
  // @ViewChild(MaxIatfComponent) maxIatf: any;
  // @ViewChild(PregnancyRateComponent) pregnancyRate: any;
  // @ViewChild(QuantityToInseminateComponent) quantityToInseminate: any;
  // @ViewChild(ReadyToIatfComponent) readyToIatf: any;

  // @ViewChild(AveragePregnancyRateComponent) averagePregnancyRate: any;
  // @ViewChild(BreedDistributionComponent) breedDistribution: any;
  // @ViewChild(IatfDistributionComponent) iatfDistribution: any;
  // @ViewChild(IatfDistributionPerSeasonComponent) iatfDistributionPerSeason: any;
  // @ViewChild(PregnancyDistributionPerBreedComponent) pregnancyDistributionPerBreed: any;
  // @ViewChild(PregnancyIatfDistributionPerSeasonComponent) pregnancyIatfDistributionPerSeason: any;

  @Input() herd: Herd;
  @Input() interviewCycle: InterviewCycle[];
  @Input() reproductiveManagement: ReproductiveManagement;
  @Input() dosesDistributionByBreed: DosesDistributionByBreed;

  @Output() isValidReadyToIATFTotal = new EventEmitter<boolean>();
  @Output() isValidCalvingDistributionTotal = new EventEmitter<boolean>();

  constructor(public reproductiveManagementService: ReproductiveManagementService) {
   }

  ngOnInit() {
    console.clear();
    window.scrollTo(0, 0);

    this.reproductiveManagementService.reproductiveManagement = this.reproductiveManagement;

    this.reproductiveManagement.nbrOfHeifersToInseminate = Number(this.herd.numberOfHeifers || 0);
    this.reproductiveManagement.nbrOfCowsToInseminate = Number(this.herd.numberOfCows || 0);
  }

  // PREGNANCY RATE EVENTS
  public pregnancyRate1IATFHeifersChange() {
    if (this.reproductiveManagement.pregnancyRate1IATFHeifers && this.reproductiveManagement.pregnancyRate1IATFHeifers > 0) {
      // tslint:disable-next-line:triple-equals
      if (this.reproductiveManagement.maxIATFsPerHeifer == 3) {
        this.reproductiveManagement.pregnancyRate2IATFHeifers =  Math.round(Number(this.reproductiveManagement.pregnancyRate1IATFHeifers) * 0.9);
        this.reproductiveManagement.pregnancyRate3IATFHeifers =  Math.round(Number(this.reproductiveManagement.pregnancyRate2IATFHeifers) * 0.9);
      // tslint:disable-next-line:triple-equals
      } else if (this.reproductiveManagement.maxIATFsPerHeifer == 2) {
        this.reproductiveManagement.pregnancyRate2IATFHeifers =  Math.round(Number(this.reproductiveManagement.pregnancyRate1IATFHeifers) * 0.9);
      }
    } else {
      this.reproductiveManagement.pregnancyRate2IATFHeifers = 0;
      this.reproductiveManagement.pregnancyRate3IATFHeifers = 0;
    }
  }

  public pregnancyRate1IATFCowsChange() {
    if (this.reproductiveManagement.pregnancyRate1IATFCows && this.reproductiveManagement.pregnancyRate1IATFCows > 0) {
      // tslint:disable-next-line:triple-equals
      if (this.reproductiveManagement.maxIATFsPerCow == 3) {
        this.reproductiveManagement.pregnancyRate2IATFCows =  Math.round(Number(this.reproductiveManagement.pregnancyRate1IATFCows) * 0.9);
        this.reproductiveManagement.pregnancyRate3IATFCows =  Math.round(Number(this.reproductiveManagement.pregnancyRate2IATFCows) * 0.9);
      // tslint:disable-next-line:triple-equals
      } else if (this.reproductiveManagement.maxIATFsPerCow == 2) {
        this.reproductiveManagement.pregnancyRate2IATFCows =  Math.round(Number(this.reproductiveManagement.pregnancyRate1IATFCows) * 0.9);
      }
    } else {
      this.reproductiveManagement.pregnancyRate2IATFCows = 0;
      this.reproductiveManagement.pregnancyRate3IATFCows = 0;
    }
  }

  public pregnancyRate2IATFHeifersChange() {
    if (this.reproductiveManagement.pregnancyRate2IATFHeifers && this.reproductiveManagement.pregnancyRate2IATFHeifers > 0) {
      // tslint:disable-next-line:triple-equals
      if (this.reproductiveManagement.maxIATFsPerHeifer == 3) {
        // tslint:disable-next-line:max-line-length
        this.reproductiveManagement.pregnancyRate3IATFHeifers =  Math.round(Number(this.reproductiveManagement.pregnancyRate2IATFHeifers) * 0.9);
      }
    } else {
      this.reproductiveManagement.pregnancyRate3IATFHeifers = 0;
    }
  }

  public pregnancyRate2IATFCowsChange() {
    if (this.reproductiveManagement.pregnancyRate2IATFCows && this.reproductiveManagement.pregnancyRate2IATFCows > 0) {
      // tslint:disable-next-line:triple-equals
      if (this.reproductiveManagement.maxIATFsPerCow == 3) {
        this.reproductiveManagement.pregnancyRate3IATFCows =  Math.round(Number(this.reproductiveManagement.pregnancyRate2IATFCows) * 0.9);
      }
    } else {
      this.reproductiveManagement.pregnancyRate3IATFCows = 0;
    }
  }

  public updateIsValidReadyToIATFTotal($event: boolean) {
    if ($event) {
      this.isValidReadyToIATFTotal.emit(true);
    } else {
      this.isValidReadyToIATFTotal.emit(false);
    }
  }

  public updateIsValidCalvingDistributionTotal($event: boolean) {
    if ($event) {
      this.isValidCalvingDistributionTotal.emit(true);
    } else {
      this.isValidCalvingDistributionTotal.emit(false);
    }
  }

  public iatfPregnancyRateBreed1() {
    const totalToInseminate = Number(this.reproductiveManagement.nbrOfHeifersToInseminate || 0) + Number(this.reproductiveManagement.nbrOfCowsToInseminate || 0);
    return ((this.reproductiveManagement.pregnancyDistributionBreed1Total / totalToInseminate) * 100).toFixed(2);
  }

  public iatfPregnancyRateBreed2() {
    const totalToInseminate = Number(this.reproductiveManagement.nbrOfHeifersToInseminate || 0) + Number(this.reproductiveManagement.nbrOfCowsToInseminate || 0);
    return ((this.reproductiveManagement.pregnancyDistributionBreed2Total / totalToInseminate) * 100).toFixed(2);
  }
}
