export class SavedInterview {

    public interviewId: number;

    public interviewDate: Date;

    public customerName: string;

    public farmName: string;

    public herdName: string;

    public status: string;

    public pdf: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
