export class DosesDistributionByBreed {

    public id: number;

    public interviewId: number;

    public cowsBeginningBreed1?: number;

    public cowsBeginningBreed2?: number;

    public cowsMiddleBreed1?: number;

    public cowsMiddleBreed2?: number;

    public cowsEndBreed1?: number;

    public cowsEndBreed2?: number;

    public heifersBeginningBreed1?: number;

    public heifersBeginningBreed2?: number;

    public heifersMiddleBreed1?: number;

    public heifersMiddleBreed2?: number;

    public heifersEndBreed1?: number;

    public heifersEndBreed2?: number;

    constructor () {
      this.cowsBeginningBreed1 = 0;
      this.cowsBeginningBreed2 = 0;
      this.cowsMiddleBreed1 = 0;
      this.cowsMiddleBreed2 = 0;
      this.cowsEndBreed1 = 0;
      this.cowsEndBreed2 = 0;
      this.heifersBeginningBreed1 = 0;
      this.heifersBeginningBreed2 = 0;
      this.heifersMiddleBreed1 = 0;
      this.heifersMiddleBreed2 = 0;
      this.heifersEndBreed1 = 0;
      this.heifersEndBreed2 = 0;
    }

    deserialize(input: any): this {
      return Object.assign(this, input);
    }
}
