import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { Interview } from 'src/_models/interview';
import { SelectedBull } from 'src/_models/selected-bull';
import { InterviewCycle } from 'src/_models/interview-cycle';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReproductiveManagement } from 'src/_models/reproductive-management';

@Component({
  selector: 'app-doses-distribution',
  templateUrl: './doses-distribution.component.html',
  styleUrls: ['./doses-distribution.component.scss']
})
export class DosesDistributionComponent implements OnInit {

  @Input() public interview: Interview;
  @Output() bullsHasDoses = new EventEmitter<boolean>();
  @Output() bullsHasQuantityNecessaryDoses = new EventEmitter<boolean>();

  public gridView: GridDataResult;
  public gridSelection: any[] = [];
  public selectableSettings: SelectableSettings;

  public selectedBulls: Array<SelectedBull>;
  public interviewCycle: InterviewCycle;
  private updatedItems: SelectedBull[] = [];
  public totalCowDoses = 0;
  public totalHeifersDoses = 0;
  public totalPrice = 0;
  public totalPricePerDose = 0;
  private reproductiveManagement: ReproductiveManagement;

  public selectedCallback = (args: { dataItem: any; }) => args.dataItem;

  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.selectedBulls = new Array<SelectedBull>();
    this.interviewCycle = this.interview.interviewCycle[this.interview.currentInterviewCycle];
    this.selectedBulls = this.interviewCycle.selectedBull;
    this.reproductiveManagement = this.interview.reproductiveManagement;
  }

  public cellClickHandler({ sender, rowIndex, column, columnIndex, dataItem, isEdited }) {
    if (!isEdited && !this.isReadOnly(column.field)) {
        sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;

    if (!formGroup.valid) {
         // prevent closing the edited cell if there are invalid values.
        args.preventDefault();
    } else if (formGroup.dirty) {
        this.assignValues(dataItem, formGroup.value);
        this.update(dataItem);
        this.updateTotals();
        this.interviewCycle.selectedBull = this.updatedItems;
    }
  }

  public createFormGroup(dataItem: any): FormGroup {
    return this.formBuilder.group({
        'shortName': dataItem.shortName,
        'cowsDoses': [dataItem.cowsDoses, Validators.pattern('^[0-9]{1,8}')],
        'heifersDoses': [dataItem.heifersDoses, Validators.pattern('^[0-9]{1,8}')],
        'pricePerDose': [dataItem.pricePerDose, Validators.pattern('\\d+([.]\\d+)?')],
        'total': dataItem.total
    });
  }

  public update(item: any): void {
    const index = this.getBullIndex(item, this.updatedItems);
    if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
    } else {
        this.updatedItems.push(item);
    }
  }

  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
  }

  private getBullIndex(item: any, data: any[]): number {
    for (let idx = 0; idx < data.length; idx++) {
        if (data[idx].bullId === item.bullId) {
            return idx;
        }
    }
    return -1;
  }

  private isReadOnly(field: string): boolean {
    const readOnlyColumns = ['total', 'shortName'];
    return readOnlyColumns.indexOf(field) > -1;
  }

  private updateTotals() {
    this.totalCowDoses = 0;
    this.totalHeifersDoses = 0;
    this.totalPrice = 0;
    this.totalPricePerDose = 0;

    for (const item of this.updatedItems) {
      this.totalCowDoses += item.cowsDoses;
      this.totalHeifersDoses += item.heifersDoses;
      item.total = (item.cowsDoses + item.heifersDoses) * item.pricePerDose;
      this.totalPrice += item.total;
    }

    const totalBullDoses = this.totalCowDoses + this.totalHeifersDoses;
    if (totalBullDoses <= 0) {
      this.bullsHasDoses.emit(false);
    } else {
      this.bullsHasDoses.emit(true);
    }

    if (totalBullDoses < this.quantityNecessaryDosesTotal) {
      this.bullsHasQuantityNecessaryDoses.emit(false);
    } else {
      this.bullsHasQuantityNecessaryDoses.emit(true);
    }

    if (totalBullDoses > 0  && this.totalPrice > 0) {
      this.totalPricePerDose = (this.totalPrice / totalBullDoses);
    }
  }

  get quantityNecessaryDosesCows() {
    if (this.interview.interviewCycle.length == 1) {
      return this.reproductiveManagement.iatfDistributionPerSeasonCowsTotal;
    } else {
      if (this.interview.currentInterviewCycle == 0) {
        return this.reproductiveManagement.breedDistributionCowsBeginningBreed1 + this.reproductiveManagement.breedDistributionCowsMiddleBreed1 + this.reproductiveManagement.breedDistributionCowsEndBreed1;
      } else {
        return this.reproductiveManagement.breedDistributionCowsBeginningBreed2 + this.reproductiveManagement.breedDistributionCowsMiddleBreed2 + this.reproductiveManagement.breedDistributionCowsEndBreed2;
      }
    }
  }

  get quantityNecessaryDosesHeifers() {
    if (this.interview.interviewCycle.length == 1) {
      return this.reproductiveManagement.iatfDistributionPerSeasonHeifersTotal;
    } else {
      if (this.interview.currentInterviewCycle == 0) {
        return this.reproductiveManagement.breedDistributionHeifersBeginningBreed1 + this.reproductiveManagement.breedDistributionHeifersMiddleBreed1 + this.reproductiveManagement.breedDistributionHeifersEndBreed1;
      } else {
        return this.reproductiveManagement.breedDistributionHeifersBeginningBreed2 + this.reproductiveManagement.breedDistributionHeifersMiddleBreed2 + this.reproductiveManagement.breedDistributionHeifersEndBreed2;
      }
    }
  }

  get quantityNecessaryDosesTotal() {
    if (this.interview.interviewCycle.length == 1) {
      return this.reproductiveManagement.iatfDistributionPerSeasonTotal;
    } else {
      if (this.interview.currentInterviewCycle == 0) {
        return this.reproductiveManagement.totalDosesByBreedBreed1;
      } else {
        return this.reproductiveManagement.totalDosesByBreedBreed2;
      }
    }
  }
}
