import { ExportService } from "./../_services/export.service";
import {
  Component,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ElementRef,
} from "@angular/core";
import {
  faCheck,
  faTimes,
  faPlus,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import "rxjs/add/operator/map";
import { User } from "src/_models/user";
import { ApiService } from "@app/_services/api.service";
import { Person } from "src/_models/person";
import { DialogConfirmService } from "@app/_services/dialog-confirm.service";
import {
  DialogCloseResult,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { PersonComponent } from "@app/person/person.component";
import { Alert } from "selenium-webdriver";
import * as XLSX from "xlsx";
import { UserActivity } from "src/_models/user-activity";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit, OnChanges {

  @ViewChild(PersonComponent, { static: true }) personReference: PersonComponent;
  @ViewChild("div", { static: true }) public div: ElementRef<any>;

  // @ViewChild(PersonComponent) personReference;
  
  // @ViewChild("div") public div: ElementRef<any>;

  public cpfMask = [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  public usersList: Array<User>;
  public gridView: GridDataResult;
  public user: User;
  public editUser: User;

  public addUser = faPlus;
  public userSort: SortDescriptor[] = [{ field: "person.name", dir: "asc" }];
  public gridSelection: any[] = [];

  public showForm = false;
  public passwordConfirm: string;
  public isSubmitted = false;
  public isUsersLoaded = false;
  public error: string;
  public success: string;

  public selectedCallback = (args: { dataItem: any }) => args.dataItem;

  constructor(
    private exportService: ExportService,
    private apiService: ApiService,
    private dialogConfirmService: DialogConfirmService,
    private dialogService: DialogService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.CopyUser();
  }

  ngOnInit() {
    this.CopyUser();
    this.CreateUser();
    this.usersList = [];
    this.GetUsers();
  }

  /* #region LISTA DE CLIENTES */
  // VERIFICA SE O CLIENTE ESTÁ ATIVO
  // public IsActive(active: boolean): IconDefinition {
  //   return active ? faCheck : faTimes;
  // }

  public IsActive(status: number): IconDefinition {
    return status === 2 ? faCheck : faTimes;
  }

  // EVENTO DE MUDANÇA NA ORDENAÇÃO DA LISTA DE CLIENTES
  public UsersSortChange(sort: SortDescriptor[]): void {
    this.userSort = sort;
    this.OrderUsers();
  }

  // FILTRA A LISTA DE CLIENTES A PARTIR DO VALOR INSERIDO NO INPUT DE BUSCA
  public FindByKeyword(e: any) {
    const keyword: string = e.target.value;
    this.FindUsers(keyword.toLowerCase());
  }

  // ABRE O CADASTRO DE CLIENTE
  public OpenForm(u: any) {
    if (u) {
      this.editUser = u;
    } else {
      this.CreateUser();
    }
    this.showForm = true;
    this.success = ``;
    this.error = ``;
  }

  // CLICK DA LINHA DA TABELA
  public ClickTableRow() {
    this.OpenForm(this.gridSelection[0]);
  }

  public exportUserActivity() {
    this.apiService.GetAllUsersActivity().subscribe(
      (data: UserActivity[]) => {
        const activity: UserActivity[] = [];
        activity.push(this.createHeaderRowForExcelFile());
        activity.push(...data);

        this.exportService.exportToExcel(activity, {
          tabName: "Acessos-Entrevistas",
          filename: "Plano Genético - Acessos de usuários.xlsx",
        });

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(activity, {skipHeader: true});
        // const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Acessos-Entrevistas');
        // XLSX.writeFile(wb, 'Plano Genético - Acessos de usuários.xlsx');
      },
      (error) => console.error(error)
    );
  }

  private createHeaderRowForExcelFile(): UserActivity {
    const result: UserActivity = new UserActivity();
    result.name = "Nome";
    result.email = "E-mail";
    result.regionManager = "Regional";
    result.districtManager = "Distrital";
    result.loginDate = "Data de Acesso";
    result.numberOfInterviews = "Entrevistas";

    return result;
  }

  // FECHA O CADASTRO DE CLIENTE E VOLTA PRA LISTA
  public CloseForm() {
    // PRECISA FAZER AS VALIDAÇÕES PARA NÃO PERDER DADOS ALTERADOS
    this.gridSelection = [];
    this.showForm = false;
    this.isSubmitted = false;
    this.scrollTop();
  }

  public scrollTop() {
    this.div.nativeElement.scrollIntoView();
  }

  // ATUALIZA GRID DO KENDO BASEADO NA ORDENACAO
  private OrderUsers(): void {
    if (this.usersList.length > 0) {
      this.gridView = {
        data: orderBy(this.usersList, this.userSort),
        total: this.usersList.length,
      };
    }
  }

  // ATUALIZA GRID DO KENDO BASEADO NA BUSCA TEXTUAL
  private FindUsers(keyword: string): void {
    // tslint:disable-next-line:max-line-length
    // const filteredUserList = this.usersList.filter(u => u.person.name.toLowerCase().indexOf(keyword) > -1 || u.person.docNumber.indexOf(keyword) > -1);
    const filteredUserList = this.usersList.filter(
      (u) =>
        u.person.name.toLowerCase().indexOf(keyword) > -1 ||
        u.email.toLowerCase().indexOf(keyword) > -1
    );

    this.gridView = {
      data: filteredUserList,
      total: filteredUserList.length,
    };
  }

  private GetUsers() {
    this.apiService.GetUsers().subscribe(
      (data: User[]) => {
        this.usersList = data;
        this.OrderUsers();
        this.isUsersLoaded = true;
      },
      (error) => console.error(error)
    );
  }

  private CreateUser() {
    this.user = new User();
    this.user.id = "";
    this.user.regionManager = "";
    this.user.districtManager = "";
    this.user.email = "";
    this.user.password = "";
    this.user.person = new Person();

    this.editUser = new User();
    this.editUser.id = "";
    this.editUser.email = "";
    this.editUser.password = "";
    this.editUser.person = new Person();

    this.passwordConfirm = "";
  }

  public GetUserName(user: any): string {
    if (user != null) {
      return user.person.name;
    }
    return "";
  }

  private CopyUser() {
    this.editUser = Object.assign({}, this.user);
  }

  private MapUserToSubmit() {
    this.user.id = this.editUser.id;
    this.user.password = this.editUser.password;
    this.user.status = this.editUser.status;
    this.user.regionManager = this.editUser.regionManager;
    this.user.districtManager = this.editUser.districtManager;
    this.user.personId = this.editUser.personId;
    this.user.person.id = this.personReference.editPerson.id;
    this.user.person.name = this.personReference.editPerson.name;
    this.user.person.docNumber = this.personReference.editPerson.docNumber;
    this.user.person.address = this.personReference.addresses.editPerson.address;
    this.user.person.email = this.personReference.emails.editPerson.email;
    this.user.person.phone = this.personReference.phones.editPerson.phone;

    if (this.user.person.email) {
      for (let i = 0; i < this.user.person.email.length; i++) {
        if (this.user.person.email[i].isDefault) {
          this.user.email = this.user.person.email[i].email1;
          break;
        }
      }
    }
  }

  public OnSubmit() {
    this.isSubmitted = true;
    this.MapUserToSubmit();
    if (this.Validate()) {
      if (this.user.id.length === 0) {
        console.log(`insert`);
        this.InsertUser();
      } else {
        console.log(`update`);
        this.UpdateUser();
      }
    }
  }

  private ValidateUser(): boolean {
    if (!this.user.person.name) {
      return false;
    }
    if (!this.user.person.docNumber) {
      return false;
    }
    return true;
  }

  private ValidateAddress(): boolean {
    if (this.user.person.address.length === 0) {
      return false;
    } else {
      for (let i = 0; i < this.user.person.address.length; i++) {
        if (!this.user.person.address[i].postalCode) {
          return false;
        }
        if (!this.user.person.address[i].street) {
          return false;
        }
        if (!this.user.person.address[i].number) {
          return false;
        }
        if (!this.user.person.address[i].cityId) {
          return false;
        }
      }
    }
    return true;
  }

  private ValidateEmail(): boolean {
    if (this.user.person.email.length === 0) {
      return false;
    } else {
      for (let i = 0; i < this.user.person.email.length; i++) {
        if (!this.user.person.email[i].email1) {
          return false;
        }
      }
    }
    return true;
  }

  private ValidatePhone(): boolean {
    if (this.user.person.phone.length === 0) {
      return false;
    } else {
      for (let i = 0; i < this.user.person.phone.length; i++) {
        if (!this.user.person.phone[i].phone1) {
          return false;
        }
      }
    }
    return true;
  }

  private Validate(): boolean {
    if (
      this.ValidateUser() &&
      this.ValidateAddress() &&
      this.ValidateEmail() &&
      this.ValidatePhone()
    ) {
      return true;
    }
    return false;
  }

  private InsertUser() {
    this.apiService.CreateUser(this.user).subscribe(
      (data: User) => {
        this.usersList.push(this.user);
        this.gridView.data = this.usersList;
        this.success =
          `O usuário ` + this.user.person.name + ` foi criado com sucesso`;
        this.error = ``;
        this.CreateUser();
        this.GetUsers();
        this.isSubmitted = false;
        this.personReference.addresses.canAddAddress = true;
      },
      (error) => (this.error = JSON.stringify(error.error.errors))
    );
  }

  private UpdateUser() {
    this.apiService.UdpateUser(this.user).subscribe(
      (data: User) => {
        let user = this.usersList.filter((u: User) => u.id === this.user.id)[0];
        user = data;
        this.success =
          `O usuário ` + this.user.person.name + ` foi atualizado com sucesso`;
        this.error = ``;
        this.CreateUser();
        this.GetUsers();
        this.isSubmitted = false;
        this.personReference.addresses.canAddAddress = true;
      },
      (error) => (this.error = JSON.stringify(error.error.errors))
    );
  }

  // public RemoveUser(e: any) {
  //   this.dialogconfirmService.confirm('Deseja excluir o usuário ' + e.dataItem.person.name + ' ?')
  //   .then((isToDelete: boolean) => {
  //       if (isToDelete) {
  //           this.apiService
  //           .DeleteUser(e.dataItem.id)
  //           .then(() => {
  //                this.usersList = this.usersList.filter((u: User) => u.id !== e.dataItem.id);
  //           }).catch(error => {
  //             console.error(error);
  //           });
  //       }
  //   });
  // }

  public ChangeUserStatus(e: any) {
    const dialogTitle = `Alteração de status`;
    const dialogContent =
      `Deseja ` +
      this.TranslateStatus(e.dataItem.status) +
      ` o usuário ` +
      e.dataItem.person.name +
      ` ?`;

    this.dialogConfirmService
      .Confirm(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
          if (action === `Sim`) {
            this.apiService
              .ChangeUserStatus(e.dataItem.id)
              .then(() => {
                if (e.dataItem.status <= 1) {
                  e.dataItem.status = 2;
                } else {
                  e.dataItem.status = 0;
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      });

    // this.dialogconfirmService
    // .confirm('Deseja alterar o status do usuário ' + e.dataItem.person.name + ' para ' + this.TranslateStatus(e.dataItem.status) + ' ?')
    // .then((isToUpdate: boolean) => {
    //     if (isToUpdate) {
    //         this.apiService
    //         .ChangeUserStatus(e.dataItem.id)
    //         .then(() => {
    //               if (e.dataItem.status === 0 || e.dataItem.status === 1) {
    //                 e.dataItem.status = 2;
    //               } else {
    //                 e.dataItem.status = 0;
    //               }
    //               // const user = this.usersList.filter((u: User) => u.id === e.dataItem.id)[0];
    //               // user.status = e.dataItem.status;
    //               //this.usersList = this.usersList.filter((u: User) => u.id !== e.dataItem.id)
    //         }).catch(error => {
    //           console.error(error);
    //         });
    //     }
    // });
  }

  private TranslateStatus(status: number): string {
    if (status <= 1) {
      return "ativar";
    }
    return "desativar";
  }
}
