export class ReproductiveManagement {

    public id: number;

    public nbrOfHeifersToInseminate?: number;

    public nbrOfCowsToInseminate?: number;

    public maxIATFsPerHeifer?: number;

    public maxIATFsPerCow?: number;

    public readyToIATFBeginning?: number;

    public readyToIATFMiddle?: number;

    public calvingDistributionBeginning?: number;

    public calvingDistributionMiddle?: number;

    public calvingDistributionEnd?: number;

    public pregnancyRate1IATFHeifers?: number;

    public pregnancyRate2IATFHeifers?: number;

    public pregnancyRate3IATFHeifers?: number;

    public pregnancyRate1IATFCows?: number;

    public pregnancyRate2IATFCows?: number;

    public pregnancyRate3IATFCows?: number;

    /* CAMPOS CALCULADOS */

    // Iatf Distribution
    public iatfDistributionHeifers1IATF?: number;
    public iatfDistributionHeifers2IATF?: number;
    public iatfDistributionHeifers3IATF?: number;

    public iatfDistributionCows1IATF?: number;
    public iatfDistributionCows2IATF?: number;
    public iatfDistributionCows3IATF?: number;

    public iatfDistributionTotal1IATF?: number;
    public iatfDistributionTotal2IATF?: number;
    public iatfDistributionTotal3IATF?: number;

    // Iatf Distribution Per Season
    public iatfDistributionPerSeasonHeifersBeginningEM?: number;
    public iatfDistributionPerSeasonHeifersMiddleEM?: number;
    public iatfDistributionPerSeasonHeifersEndEM?: number;
    public iatfDistributionPerSeasonHeifersTotal?: number;

    public iatfDistributionPerSeasonCowsBeginningEM?: number;
    public iatfDistributionPerSeasonCowsMiddleEM?: number;
    public iatfDistributionPerSeasonCowsEndEM?: number;
    public iatfDistributionPerSeasonCowsTotal?: number;

    public iatfDistributionPerSeasonTotalBeginningEM?: number;
    public iatfDistributionPerSeasonTotalMiddleEM?: number;
    public iatfDistributionPerSeasonTotalEndEM?: number;
    public iatfDistributionPerSeasonTotal?: number;

    // Average Pregnancy Rate
    public averagePregnancyRateHeifers?: number;
    public averagePregnancyRateCows?: number;
    public averagePregnancyRateAverage?: number;
    public averagePregnancyRateTotal?: number;

    // Pregnancy Iatf Distribution Per Season
    public pregnancyIatfDistributionPerSeasonHeifersBeginningEM?: number;
    public pregnancyIatfDistributionPerSeasonHeifersMiddleEM?: number;
    public pregnancyIatfDistributionPerSeasonHeifersEndEM?: number;
    public pregnancyIatfDistributionPerSeasonHeifersTotal?: number;

    public pregnancyIatfDistributionPerSeasonCowsBeginningEM?: number;
    public pregnancyIatfDistributionPerSeasonCowsMiddleEM?: number;
    public pregnancyIatfDistributionPerSeasonCowsEndEM?: number;
    public pregnancyIatfDistributionPerSeasonCowsTotal?: number;

    public pregnancyIatfDistributionPerSeasonTotalBeginningEM?: number;
    public pregnancyIatfDistributionPerSeasonTotalMiddleEM?: number;
    public pregnancyIatfDistributionPerSeasonTotalEndEM?: number;
    public pregnancyIatfDistributionPerSeasonTotal?: number;

    // Breed Distribution

    /* PERCENTS */
    public breedDistributionHeifersBeginningBreed1Percent?: number;
    public breedDistributionHeifersMiddleBreed1Percent?: number;
    public breedDistributionHeifersEndBreed1Percent?: number;

    public breedDistributionCowsBeginningBreed1Percent?: number;
    public breedDistributionCowsMiddleBreed1Percent?: number;
    public breedDistributionCowsEndBreed1Percent?: number;

    public breedDistributionHeifersBeginningBreed2Percent?: number;
    public breedDistributionHeifersMiddleBreed2Percent?: number;
    public breedDistributionHeifersEndBreed2Percent?: number;

    public breedDistributionCowsBeginningBreed2Percent?: number;
    public breedDistributionCowsMiddleBreed2Percent?: number;
    public breedDistributionCowsEndBreed2Percent?: number;

    /* VALUES */
    public breedDistributionHeifersBeginningBreed1?: number;
    public breedDistributionHeifersMiddleBreed1?: number;
    public breedDistributionHeifersEndBreed1?: number;

    public breedDistributionCowsBeginningBreed1?: number;
    public breedDistributionCowsMiddleBreed1?: number;
    public breedDistributionCowsEndBreed1?: number;

    public breedDistributionHeifersBeginningBreed2?: number;
    public breedDistributionHeifersMiddleBreed2?: number;
    public breedDistributionHeifersEndBreed2?: number;

    public breedDistributionCowsBeginningBreed2?: number;
    public breedDistributionCowsMiddleBreed2?: number;
    public breedDistributionCowsEndBreed2?: number;

    public breedDistributionTotalBeginningBreed1?: number;
    public breedDistributionTotalMiddleBreed1?: number;
    public breedDistributionTotalEndBreed1?: number;

    public breedDistributionTotalBeginningBreed2?: number;
    public breedDistributionTotalMiddleBreed2?: number;
    public breedDistributionTotalEndBreed2?: number;

    // Total Doses By Breed
    public totalDosesByBreedBreed1?: number;
    public totalDosesByBreedBreed2?: number;
    public totalDosesByBreedTotal?: number;

    // Preganancy Distribution Breed1
    public pregnancyDistributionBreed1HeifersBeginningEM?: number;
    public pregnancyDistributionBreed1HeifersMiddleEM?: number;
    public pregnancyDistributionBreed1HeifersEndEM?: number;
    public pregnancyDistributionBreed1HeifersTotal?: number;

    public pregnancyDistributionBreed1CowsBeginningEM?: number;
    public pregnancyDistributionBreed1CowsMiddleEM?: number;
    public pregnancyDistributionBreed1CowsEndEM?: number;
    public pregnancyDistributionBreed1CowsTotal?: number;

    public pregnancyDistributionBreed1TotalBeginningEM?: number;
    public pregnancyDistributionBreed1TotalMiddleEM?: number;
    public pregnancyDistributionBreed1TotalEndEM?: number;
    public pregnancyDistributionBreed1Total?: number;

    // Pregnancy Distribution Breed2
    public pregnancyDistributionBreed2HeifersBeginningEM?: number;
    public pregnancyDistributionBreed2HeifersMiddleEM?: number;
    public pregnancyDistributionBreed2HeifersEndEM?: number;
    public pregnancyDistributionBreed2HeifersTotal?: number;

    public pregnancyDistributionBreed2CowsBeginningEM?: number;
    public pregnancyDistributionBreed2CowsMiddleEM?: number;
    public pregnancyDistributionBreed2CowsEndEM?: number;
    public pregnancyDistributionBreed2CowsTotal?: number;

    public pregnancyDistributionBreed2TotalBeginningEM?: number;
    public pregnancyDistributionBreed2TotalMiddleEM?: number;
    public pregnancyDistributionBreed2TotalEndEM?: number;
    public pregnancyDistributionBreed2Total?: number;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
