import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BreedType } from 'src/_models/breed-type';
import { Breed } from 'src/_models/breed';
import { ApiService } from '@app/_services/api.service';
import { Herd } from 'src/_models/herd';
import { Farm } from 'src/_models/farm';
import { DialogConfirmService } from '@app/_services/dialog-confirm.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-herd',
  templateUrl: './herd.component.html',
  styleUrls: ['./herd.component.scss']
})
export class HerdComponent implements OnInit, OnChanges {

  @Input() farm: any;

  public editFarm: Farm;
  public breedTypeList: Array<BreedType>;
  public breedList: Array<Breed>;
  public cacheBreedList: Array<Breed>;

  public iconTrash = faTrashAlt;
  public iconAdd = faPlus;

  constructor(
    private apiService: ApiService,
    private dialogConfirmService: DialogConfirmService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.CopyFarm();
  }

  ngOnInit() {
    this.CopyFarm();
    this.CreateNewFarm();
    this.breedList = [];
    this.breedTypeList = [];
    this.loadBreedTypes();
    this.loadBreeds();
    // this.AddHerd();
  }

  private CopyFarm() {
    this.editFarm = Object.assign({}, this.farm);
  }

  private CreateNewFarm() {
    if (!this.editFarm || !this.editFarm.id) {
      this.editFarm = new Farm();
      this.editFarm.herd = new Array<Herd>();
    }
  }

  public AddHerd() {
    const herd = new Herd();
    herd.breed = new Breed();
    this.editFarm.herd.push(herd);
  }

  public RemoveHerd(index: number) {
    const dialogTitle = `Remover rebanho`;
    const dialogContent =  `Deseja remover este rebanho ?`;
    this.dialogConfirmService.Confirm(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
          if (action === `Sim`) {
            this.editFarm.herd.reverse().splice(index, 1);
            this.editFarm.herd.reverse();
          }
        }
    });
  }

  // public ChangeBreedType(breedTypeId: number, i: number) {
  //   // this.breedList = this.cacheBreedList.filter((breed) => breed.breedTypeId == breedTypeId);
  //   // this.editFarm.herd[i].breedId = this.breedList[0].id;
  // }

  public ChangeBreed(breedId: number, i: number) {
    this.editFarm.herd.reverse()[i].breedId = breedId;
    this.editFarm.herd.reverse();
  }

  private loadBreeds() {
    this.apiService.getBreeds().subscribe((breeds: Breed[]) => {
      this.cacheBreedList = breeds;
      this.breedList = breeds;

      if (this.farm && this.farm.herd) {
        this.farm.herd.forEach(h => {
          if (!h.breed) {
            h.breed = new Breed();
          }
        });
      }
    }, (error) => console.error(error));
  }

  private loadBreedTypes() {
    this.apiService.getBreedTypes().subscribe((data: BreedType[]) => {
      this.breedTypeList = data;
    }, (error) => console.error(error));
  }
}



