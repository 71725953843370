import { Component, OnInit, Input } from '@angular/core';
import { HerdProblem } from 'src/_models/herd-problem';
import { BonusProgramm } from 'src/_models/bonus-program';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-herd-problem',
  templateUrl: './modal-herd-problem.component.html',
  styleUrls: ['./modal-herd-problem.component.scss']
})
export class ModalHerdProblemComponent implements OnInit {

  @Input() public list: Array<HerdProblem>;

  public listHerdProblems: Array<HerdProblem>;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.listHerdProblems =  <Array<HerdProblem>> HerdProblem.getProblems();
    this.list.map(b => {
      console.log(b);
      // tslint:disable-next-line:triple-equals
      this.listHerdProblems.find(p => p.id == b.id).checked = true;
    });
  }

  public addProblems() {
    this.activeModal.close(this.listHerdProblems.filter(b => b.checked));
  }

  public cancel() {
    this.activeModal.close(this.list);
  }

}
