import { Component, OnInit, Input } from '@angular/core';
import { ReproductiveManagement } from 'src/_models/reproductive-management';
import { ReproductiveManagementService } from '@app/_services/reproductive-management.service';
import { InterviewCycle } from 'src/_models/interview-cycle';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-iatf-distribution-per-season]',
  templateUrl: './iatf-distribution-per-season.component.html',
  styleUrls: ['./iatf-distribution-per-season.component.scss']
})
export class IatfDistributionPerSeasonComponent implements OnInit {

  @Input() reproductiveManagement: ReproductiveManagement;

  constructor(public reproductiveManagementService: ReproductiveManagementService) { }

  ngOnInit() {
  }

  // IATF DISTRIBUTION - HEIFERS
  get iatfDistributionPerSeasonHeifersBeginningEM () {
    this.reproductiveManagement.iatfDistributionPerSeasonHeifersBeginningEM = this.reproductiveManagementService.iatfDistributionPerSeasonHeifersBeginningEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonHeifersBeginningEM;
  }
  get iatfDistributionPerSeasonHeifersMiddleEM () {
    this.reproductiveManagement.iatfDistributionPerSeasonHeifersMiddleEM = this.reproductiveManagementService.iatfDistributionPerSeasonHeifersMiddleEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonHeifersMiddleEM;
  }
  get iatfDistributionPerSeasonHeifersEndEM () {
    this.reproductiveManagement.iatfDistributionPerSeasonHeifersEndEM = this.reproductiveManagementService.iatfDistributionPerSeasonHeifersEndEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonHeifersEndEM;
  }

  // IATF DISTRIBUTION - COWS
  get iatfDistributionPerSeasonCowsBeginningEM () {
    this.reproductiveManagement.iatfDistributionPerSeasonCowsBeginningEM = this.reproductiveManagementService.iatfDistributionPerSeasonCowsBeginningEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonCowsBeginningEM;
  }
  get iatfDistributionPerSeasonCowsMiddleEM () {
    this.reproductiveManagement.iatfDistributionPerSeasonCowsMiddleEM = this.reproductiveManagementService.iatfDistributionPerSeasonCowsMiddleEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonCowsMiddleEM;
  }
  get iatfDistributionPerSeasonCowsEndEM () {
    this.reproductiveManagement.iatfDistributionPerSeasonCowsEndEM = this.reproductiveManagementService.iatfDistributionPerSeasonCowsEndEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonCowsEndEM;
  }

  // IATF DISTRIBUTION - COL TOTAL
  get iatfDistributionPerSeasonTotalBeginningEM() {
    this.reproductiveManagement.iatfDistributionPerSeasonTotalBeginningEM = this.reproductiveManagementService.iatfDistributionPerSeasonTotalBeginningEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonTotalBeginningEM;
  }
  get iatfDistributionPerSeasonTotalMiddleEM() {
    this.reproductiveManagement.iatfDistributionPerSeasonTotalMiddleEM = this.reproductiveManagementService.iatfDistributionPerSeasonTotalMiddleEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonTotalMiddleEM;
  }
  get iatfDistributionPerSeasonTotalEndEM() {
    this.reproductiveManagement.iatfDistributionPerSeasonTotalEndEM = this.reproductiveManagementService.iatfDistributionPerSeasonTotalEndEM;
    return this.reproductiveManagement.iatfDistributionPerSeasonTotalEndEM;
  }

  // IATF DISTRIBUTION - ROW TOTAL
  get iatfDistributionPerSeasonHeifersTotal() {
    this.reproductiveManagement.iatfDistributionPerSeasonHeifersTotal = this.reproductiveManagementService.iatfDistributionPerSeasonTotalHeifers;
    return this.reproductiveManagement.iatfDistributionPerSeasonHeifersTotal;
  }
  get iatfDistributionPerSeasonCowsTotal() {
    this.reproductiveManagement.iatfDistributionPerSeasonCowsTotal = this.reproductiveManagementService.iatfDistributionPerSeasonTotalCows;
    return this.reproductiveManagement.iatfDistributionPerSeasonCowsTotal;
  }
  get iatfDistributionPerSeasonTotal() {
    this.reproductiveManagement.iatfDistributionPerSeasonTotal = this.reproductiveManagementService.iatfDistributionPerSeasonTotalTotal;
    return this.reproductiveManagement.iatfDistributionPerSeasonTotal;
  }
}
