import { InterviewService } from './../_services/interview.service';
import { Component, OnInit, DoCheck, IterableDiffers, ViewChild, ElementRef } from '@angular/core';
import { Interview } from 'src/_models/interview';
import { ApiService } from '@app/_services/api.service';
import { User } from 'src/_models/user';
import { Suggestion } from 'src/_models/suggestion';
import { BreedProofSelectionComponent } from './breed-proof-selection/breed-proof-selection.component';
import { InterviewSuggestions } from '@app/_utils/interview-suggestions';
import { ReproductiveManagementComponent } from './reproductive-management/reproductive-management.component';
import { SelectResultPresentationComponent } from './select-result-presentation/select-result-presentation.component';
import { ProofTypeEnum } from 'src/_enums/proof-type-enum';
import { Router } from '@angular/router';
import { BreedEnum } from 'src/_enums/breed-enum';

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.scss']
})
export class InterviewComponent implements OnInit, DoCheck {

  @ViewChild(BreedProofSelectionComponent, { static: false })  private breedProofReference: BreedProofSelectionComponent;
  @ViewChild(ReproductiveManagementComponent, { static: false })  private reproductiveManagementReference: ReproductiveManagementComponent;
  @ViewChild(SelectResultPresentationComponent, { static: false })  private selectResultPresentationReference: SelectResultPresentationComponent;

  @ViewChild('errorAlert', { static: false }) errorAlert: ElementRef;

  // @ViewChild(BreedProofSelectionComponent) breedProofReference: any;
  // @ViewChild(ReproductiveManagementComponent) reproductiveManagementReference: any;
  // @ViewChild(SelectResultPresentationComponent) selectResultPresentationReference: any;

  // @ViewChild('errorAlert') errorAlert: ElementRef;

  private differ: any;

  public showInterviewForm = true;
  public showReproductiveManagementForm = false;
  public showWeightingForm = false;
  public showFilterForm = false;
  public showBullSelectionForm = false;
  public showDosesDistributionForm = false;
  public showSelectResultPresentationForm = false;

  public suggestions: Array<Suggestion>;
  public interviewSuggestions: InterviewSuggestions;
  public interview: Interview;
  public bonusProgramSuggestions: Array<string>;
  public primaryBreedSuggestion: Array<string>;
  public secondaryBreedSuggestion: Array<string>;
  public weightingValueTotal: string;
  public selectedBullsCount = 0;
  public bullsHasDoses = false;
  public bullsHasQuantityNecessaryDoses = false;
  public isValidReadyToIATFTotal = false;
  public isValidCalvingDistributionTotal = false;
  public user: User;
  ProofTypeEnum: typeof ProofTypeEnum = ProofTypeEnum;
  public error: string;
  public errorIsValidReadyToIATFTotal: string;
  public errorIsValidCalvingDistributionTotal: string;
  public success: string;
  public isValidFilterSignal = true;

  public isFastPlan = false;

  constructor(
      private apiService: ApiService,
      private interviewService: InterviewService,
      private differs: IterableDiffers,
      private router: Router) {
    this.differ = differs.find([]).create(null);
  }

  ngOnInit() {

    this.interview = new Interview();

    this.interviewSuggestions = new InterviewSuggestions();
    this.bonusProgramSuggestions = new Array<string>();

    this.apiService.GetUser().subscribe((u: User) => {
      this.user = u;
      // CONSULTA SUGESTOES DE RACAS PELO API SERVICE
      this.apiService.getBreedSuggestions().subscribe((s: Suggestion[]) => {
        this.suggestions = s;
        console.log(this.suggestions);
        setTimeout(() => {
          this.updatePrimaryBreedSuggestion();
        }, 1000);
      });
    }, (error) => console.error(error));

    // if (this.interviewService.interview !== null) {

    //   this.interview = this.interviewService.interview;
    //   this.interviewService.interview = null;

    //   // console.log('entrevista');
    //   // console.log(this.interview);
    // }
  }

  ngDoCheck() {
    const change = this.differ.diff(this.interview.bonusProgram);
    if (change) {
      this.bonusProgramSuggestions = new Array<string>();
      this.interview.bonusProgram.forEach(program => {
        console.log(program.description);
        switch (program.id) {
          case 1: // 1	Angus / Brangus
              this.bonusProgramSuggestions.push('Angus');
              if ((this.interview.herd.breed.id === 11) || (this.interview.herd.breed.id === 116)) {
                // 11 Brangus
                // 116 Cruza Sintética
                this.bonusProgramSuggestions.push('Brangus');
              }
              break;
          case 3: // 3	Bonsmara
              this.bonusProgramSuggestions.push('Bonsmara');
              break;
          case 4: // 4	Britânicas
              this.bonusProgramSuggestions.push('Angus');
              this.bonusProgramSuggestions.push('Hereford');
              break;
          case 5: // 5	Charolês Beef
              this.bonusProgramSuggestions.push('Charolês');
              break;
          case 7: // 7	Hereford / Braford
              this.bonusProgramSuggestions.push('Hereford');
              if ((this.interview.herd.breed.id === 11) || (this.interview.herd.breed.id === 116)) {
                // 11 Brangus
                // 116 Cruza Sintética
                this.bonusProgramSuggestions.push('Braford');
              }
              break;
          case 10: // 10	Wagyu
              this.bonusProgramSuggestions.push('Wagyu');
              break;
        }
      });
      this.updateBothBreedsSuggestion();
    }
  }

  get InterviewCycleLength () {
    return this.interview.interviewCycle.length;
  }

  private getUser() {
    this.apiService.GetUser().subscribe((user: User) => {
      this.interview.userId = user.id;
      setTimeout(() => {
        this.updateBothBreedsSuggestion();
      }, 1000);
      // console.log(this.interview.user);
    }, (error) => console.error(error));
  }

  public isFormReady() {
    return this.user.customer.map(c => c.farm.map(f => f.herd.map(h => h))).length;
  }

  public clearForm() {
    // LIMPAR TODOS OS MODELS
  }

  public openInterviewForm() {
    if (this.validateInterview()) {
      this.showInterviewForm = true;
      this.showReproductiveManagementForm = false;
      this.showWeightingForm = false;
      this.showFilterForm = false;
      this.showBullSelectionForm = false;
      this.showDosesDistributionForm = false;
      this.showSelectResultPresentationForm = false;
    }
  }

  public openReproductiveManagementForm() {
    if (this.validateInterview()) {
      this.success = ``;
      this.showInterviewForm = false;
      this.showReproductiveManagementForm = true;
      this.showWeightingForm = false;
      this.showFilterForm = false;
      this.showBullSelectionForm = false;
      this.showDosesDistributionForm = false;
      this.showSelectResultPresentationForm = false;
      this.interview.currentInterviewCycle = 0;
    }
  }


  public openWeightingForm() {
    if (this.validateInterview() &&
        (this.isFastPlan || (this.validateReadyToIATFTotal() && this.validateCalvingDistributionTotal()))) {
      if (this.reproductiveManagementReference) {
        this.interview.reproductiveManagement = this.reproductiveManagementReference.reproductiveManagement;
      }
      if (!this.isProofTypeAngusOrNelore()) {
        this.openBullSelectionForm();
      } else {
          this.showInterviewForm = false;
          this.showReproductiveManagementForm = false;
          this.showWeightingForm = true;
          this.showFilterForm = false;
          this.showBullSelectionForm = false;
          this.showDosesDistributionForm = false;
          this.showSelectResultPresentationForm = false;
      }
    // para voltar da tela de filtros para ponderação com o plano fast
    } else if (this.validateInterview() && this.isFastPlan) {
      this.showInterviewForm = false;
      this.showReproductiveManagementForm = false;
      this.showWeightingForm = true;
      this.showFilterForm = false;
      this.showBullSelectionForm = false;
      this.showDosesDistributionForm = false;
      this.showSelectResultPresentationForm = false;
    }
  }

  public openFilterForm() {
    if (this.validateInterview() &&
        this.validateWeightingValueTotal()) {
      if (this.isProofTypeAngusOrNelore()) {
        this.showInterviewForm = false;
        this.showReproductiveManagementForm = false;
        this.showWeightingForm = false;
        this.showFilterForm = true;
        this.showBullSelectionForm = false;
        this.showDosesDistributionForm = false;
        this.showSelectResultPresentationForm = false;
      } else {
        this.openReproductiveManagementForm();
      }
    }
  }

  public openBullSelectionForm() {
    if (this.validateInterview()
        && this.validateFilterSignal()) {
      this.showInterviewForm = false;
      this.showReproductiveManagementForm = false;
      this.showWeightingForm = false;
      this.showFilterForm = false;
      this.showBullSelectionForm = true;
      this.showDosesDistributionForm = false;
      this.showSelectResultPresentationForm = false;
    }
  }

  public openDosesDistributionForm() {
    if (this.validateInterview() &&
        this.validateSelectedBullsCount()) {
      this.showInterviewForm = false;
      this.showReproductiveManagementForm = false;
      this.showWeightingForm = false;
      this.showFilterForm = false;
      this.showBullSelectionForm = false;
      this.showDosesDistributionForm = true;
      this.showSelectResultPresentationForm = false;
    }
  }

  public openSelectResultPresentationForm() {
    if (this.validateInterview() &&
      this.validateBullsHasDoses() &&
      this.validateBullsHasQuantityNecessaryDoses()) {
      this.showInterviewForm = false;
      this.showReproductiveManagementForm = false;
      this.showWeightingForm = false;
      this.showFilterForm = false;
      this.showBullSelectionForm = false;
      this.showDosesDistributionForm = false;
      this.showSelectResultPresentationForm = true;
    }
  }

  public continueInterview() {
    this.selectResultPresentationReference.chart.exportImage().then((dataURI) => {
      this.interview.interviewCycle[this.interview.currentInterviewCycle].chart = dataURI;
        if (this.incrementInterviewCycle()) {
          if (this.isProofTypeAngusOrNelore()) {
            this.openWeightingForm();
          } else {
            this.openBullSelectionForm();
          }
        }
    });
  }

  public finishInterview() {
    this.selectResultPresentationReference.chart.exportImage().then((dataURI) => {
      this.interview.interviewCycle[this.interview.currentInterviewCycle].chart = dataURI;
        if (!this.incrementInterviewCycle()) {
          this.saveInterview();
          this.clearForm();
        }
    });
  }

  public validateInterview() {
    if (!this.validateInterviewHerd()) {
      return false;
    }
    if (!this.validateInterviewCycle()) {
      return false;
    }
    if (!this.validateInterviewBreedOptions()) {
      return false;
    }
    if (!this.validateInterviewBreedProof()) {
      return false;
    }
    if (!this.validateInterviewBreedGoals()) {
      return false;
    }
    return true;
  }

  public baseBreedChange() {
    this.updateBothBreedsSuggestion();
  }

  public updateBothBreedsSuggestion() {
    this.updatePrimaryBreedSuggestion();
    this.updateSecondaryBreedSuggestion();
  }

  public updatePrimaryBreedSuggestion() {

    this.primaryBreedSuggestion = new Array<string>();
    this.checkBreedSuggestions(this.primaryBreedSuggestion, 0);

    this.primaryBreedSuggestion = [...new Set([...this.primaryBreedSuggestion, ...this.bonusProgramSuggestions])];

    if (this.breedProofReference) {
      this.breedProofReference.primaryBreedSuggestion = this.primaryBreedSuggestion;
    }
  }

  public updateSecondaryBreedSuggestion() {

    this.secondaryBreedSuggestion = [];
    this.checkBreedSuggestions(this.secondaryBreedSuggestion, 1);

    this.secondaryBreedSuggestion = [...new Set([...this.secondaryBreedSuggestion, ...this.bonusProgramSuggestions])];

    if (this.breedProofReference) {
      this.breedProofReference.secondaryBreedSuggestion = this.secondaryBreedSuggestion;
    }
  }

  private checkBreedSuggestions(suggestionsArr: Array<string>, breedGoalIndex: number) {
    // console.log('breedGoalIndex: ', breedGoalIndex);
    if (this.suggestions && this.interview.herd.breedId) {
      const tmpArr = new Array<Suggestion>();
      // base breed option
      if (this.interview.breedOptions === 1) {
        // check if is crossed base breed
        if (this.isCrossedBaseBreed()) {
          this.suggestCrossedBreeds(suggestionsArr);
        } else {
          this.suggestOtherBreeds(tmpArr, suggestionsArr, breedGoalIndex);
        }
      } else {
        // suggest only base breeds
        this.suggestOnlyBaseBreed(suggestionsArr);
        // for primary breed
        if (breedGoalIndex === 0) {
          // check if is crossed base breed
          if (this.isCrossedBaseBreed()) {
            this.suggestCrossedBreeds(suggestionsArr);
          } else {
            this.suggestOtherBreeds(tmpArr, suggestionsArr, breedGoalIndex);
          }
        } else { // for second breed
          this.suggestOtherBreeds(tmpArr, suggestionsArr, breedGoalIndex);
        }
      }
      // console.log('tmpArr: ', tmpArr);dex));
      console.log('result : ', suggestionsArr.join(', '));
    }
  }

  private suggestOnlyBaseBreed(suggestionsArr: Array<string>) {
    // breeds suggestion for crossed animals - Cruza Zebu | Cruza Angus | Cruza Hereford | Cruza Sintética
    let baseBreed: string;
    if (this.interview.herd.breedId === BreedEnum.BONSMARA) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.BRAUNVIEH) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.CANCHIM) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.CARACU) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.CARACU_MOCHO) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.CHAROLES) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.CHAROLES_MOCHO) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.LIMOUSIN) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.MARCHIGIANA) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.PIEMONTES) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.SENEPOL) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.SIMBRASIL) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.SIMENTAL) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.SIMENTAL_MOCHO) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.SIMENTAL_LEITEIRO) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (this.interview.herd.breedId === BreedEnum.WAGYU) {
      baseBreed = this.interview.herd.breed.name;
    }
    if (baseBreed !== null && baseBreed !== undefined) {
      suggestionsArr.push(baseBreed);
    }
  }

  private suggestCrossedBreeds(suggestionsArr: Array<string>) {
    // breeds suggestion for crossed animals - Cruza Zebu | Cruza Angus | Cruza Hereford | Cruza Sintética
    let suggestion: Suggestion;
    if (this.interview.herd.breedId === BreedEnum.ZEBU_MESTICA) {
      suggestion = this.suggestions.find(s => s.id === 14);
    }
    if (this.interview.herd.breedId === BreedEnum.CRUZA_ANGUS) {
      suggestion = this.suggestions.find(s => s.id === 15);
    }
    if (this.interview.herd.breedId === BreedEnum.CRUZA_HEREFORD) {
      suggestion = this.suggestions.find(s => s.id === 16);
    }
    if (this.interview.herd.breedId === BreedEnum.CRUZA_SINTETICA) {
      suggestion = this.suggestions.find(s => s.id === 17);
    }
    if (suggestion !== null && suggestion !== undefined) {
      suggestionsArr.push(...suggestion.suggestedBreeds.map(s => s.name));
    }
  }

  private suggestOtherBreeds(tmpArr: Array<Suggestion>, suggestionsArr: Array<string>, breedGoalIndex: number) {
    this.suggestions.forEach(s => {
      for (let x = 0; x < s.baseBreeds.length; x++) {
        if (s.baseBreeds[x].id === this.interview.herd.breed.id) {
          // console.log('s.baseBreeds[x]: ', s.baseBreeds[x]);
          tmpArr.push(s);
          break;
        }
      }
    });
    suggestionsArr.push(...this.interviewSuggestions.validateSuggestions(tmpArr, this.interview, breedGoalIndex));
  }

  public isCrossedBaseBreed(): boolean {
    return (this.interview.herd.breedId === BreedEnum.ZEBU_MESTICA
      || this.interview.herd.breedId === BreedEnum.CRUZA_ANGUS
      || this.interview.herd.breedId === BreedEnum.CRUZA_HEREFORD
      || this.interview.herd.breedId === BreedEnum.CRUZA_SINTETICA);
  }

  private incrementInterviewCycle() {
    if (this.interview.currentInterviewCycle < this.interview.interviewCycle.length - 1) {
      this.interview.currentInterviewCycle++;
      return true;
    }
    return false;
  }

  updateIsValidCalvingDistributionTotal($event: boolean) {
    this.isValidCalvingDistributionTotal = $event;
  }

  updateIsValidReadyToIATFTotal($event: boolean) {
    this.isValidReadyToIATFTotal = $event;
  }

  updateWeightingValueTotal($event: string) {
    this.weightingValueTotal = $event;
  }

  updateSelectedBullsCount($event: number) {
    this.selectedBullsCount = $event;
  }

  updateBullsHasDoses($event: boolean) {
    this.bullsHasDoses = $event;
  }

  updateBullsHasQuantityNecessaryDoses($event: boolean) {
    this.bullsHasQuantityNecessaryDoses = $event;
  }

  updateFitlerSignal($event: boolean) {
    this.isValidFilterSignal = $event;
  }

  private validateInterviewBreedGoals(): boolean {
    if (this.interview.interviewBreedGoal && this.interview.interviewBreedGoal.length > 0) {
      if (this.interview.interviewBreedGoal[0].sellingGoalsMale === 0 ||
          this.interview.interviewBreedGoal[0].sellingGoalsFemale === 0) {
          this.error = `Informe os focos da raça primária`;
          return false;
      }
      if (this.interview.interviewBreedGoal.length > 1 && this.interview.breedOptions === 2) {
        if (this.interview.interviewBreedGoal[1].sellingGoalsMale === 0 ||
            this.interview.interviewBreedGoal[1].sellingGoalsFemale === 0) {
            this.error = `Informe os focos da raça secundária`;
            return false;
        }
      }
      this.error = ``;
      return true;
    }
  }

  private validateInterviewBreedOptions(): boolean {
    if (this.interview.breedOptions === 2) {
      if (this.interview.breedOptionsMatting === 0) {
        this.error = `Informe o objetivo do cruzamento`;
        return false;
      }
      this.error = ``;
      return true;
    }
    this.error = ``;
    return true;
  }

  private validateInterviewCycle(): boolean {
    if (this.interview.interviewCycle === undefined ||
      !this.interview.interviewCycle ||
      this.interview.interviewCycle.length === 0) {
      return false;
    }
    return true;
  }

  private validateReadyToIATFTotal(): boolean {
    if (this.isValidReadyToIATFTotal === undefined || !this.isValidReadyToIATFTotal) {
      this.errorIsValidReadyToIATFTotal = `A estações de monta do item 3 devem resultar em 100%`;
      return false;
    }
    this.errorIsValidReadyToIATFTotal = ``;
    return true;
  }

  private validateCalvingDistributionTotal(): boolean {
    if (this.isValidCalvingDistributionTotal === undefined || !this.isValidCalvingDistributionTotal) {
      this.errorIsValidCalvingDistributionTotal = `A distribuição da parição de matrizes do item 4 devem resultar em 100%`;
      return false;
    }
    this.errorIsValidCalvingDistributionTotal = ``;
    return true;
  }

  private validateWeightingValueTotal(): boolean {
    if (this.isProofTypeAngusOrNelore()) {
      // tslint:disable-next-line: radix
      if (this.weightingValueTotal === undefined || parseInt(this.weightingValueTotal) !== 100) {
        this.error = `O valor total das ponderações devem somar 100%`;
        return false;
      }
      this.error = ``;
      return true;
    }
    return true;
  }

  private validateSelectedBullsCount(): boolean {
    if (this.selectedBullsCount === undefined || this.selectedBullsCount <= 0) {
      this.error = `Selecione ao menos um touro para continuar`;
      return false;
    }
    this.error = ``;
    return true;
  }

  private validateBullsHasDoses(): boolean {
    if (this.bullsHasDoses === undefined || !this.bullsHasDoses) {
      this.error = `Existem touros com a quantidade de doses zeradas`;
      return false;
    }
    this.error = ``;
    return true;
  }

  private validateFilterSignal(): boolean {
    if (!this.isValidFilterSignal || this.isValidFilterSignal === undefined) {
      this.error = `Existem valores de filtros informados sem o sinal '>=' ou '<='`;
      return false;
    }
    this.error = ``;
    return true;
  }

  private validateBullsHasQuantityNecessaryDoses(): boolean {
    if (this.bullsHasQuantityNecessaryDoses === undefined || !this.bullsHasQuantityNecessaryDoses) {
      // const dialogTitle = `Quantidade necessária de doses`;
      // const dialogContent = `O total de doses lançadas é menor que o necessário.
      //    Deseja continuar mesmo assim ?`;
      // this.dialogConfirmService.Confirm(dialogTitle, dialogContent)
      //   .subscribe((result) => {
      //     if (!(result instanceof DialogCloseResult)) {
      //       const action = result.text;
      //       if (action === `Sim`) {
      //         return true;
      //       }
      //       return false;
      //     }
      //   });
      if (confirm(`O total de doses lançadas é menor que o necessário. Deseja continuar mesmo assim ?`)) {
          return true;
      }
      return false;
    }
    return true;
  }

  private validateInterviewHerd(): boolean {
    if (!this.interview.herd || this.interview.herd.id === 0 || this.interview.herd.id === undefined) {
      this.error = `Informe o rebanho da entrevista`;
      return false;
    }
    this.error = ``;
    return true;
  }

  private validateInterviewBreedProof(): boolean {
    if (this.interview.breedOptions === 2) {
      if (!(this.interview.interviewCycle.length === 2)) {
        this.error = `Informe a(s) prova(s) da raça primária/secundária da entrevista`;
        return false;
      }
    }
    this.error = ``;
    return true;
  }

  private saveInterview() {
    this.interview.userId = this.user.id;
    this.interview.herdId = this.interview.herd.id;

    console.log(this.interview.reproductiveManagement);

    this.apiService
      .saveInterview(this.interview)
      .subscribe((data: Interview) => {
        this.router.navigate(['/historico'], { queryParams: { interviewSavedSuccess: true } });
        window.scroll(0, 0);
    }, (error) => this.error = JSON.stringify(error.error.errors));
  }

  public isProofTypeAngusOrNelore(): boolean {
    const proofId = this.interview.interviewCycle[this.interview.currentInterviewCycle].proofId;
    const breedId = this.interview.interviewCycle[this.interview.currentInterviewCycle].breedId;
    if (breedId === BreedEnum.NELORE
      || breedId === BreedEnum.ANGUS
      || breedId === BreedEnum.NELORE_MOCHO) {
      return (proofId === this.ProofTypeEnum.AAA ||
        proofId === this.ProofTypeEnum.ERA ||
        proofId === this.ProofTypeEnum.PROMEBO ||
        proofId === this.ProofTypeEnum.ALIANCA ||
        proofId === this.ProofTypeEnum.ANCP ||
        proofId === this.ProofTypeEnum.GENEPLUS ||
        proofId === this.ProofTypeEnum.PMGZ ||
        proofId === this.ProofTypeEnum.QUALITAS);
    }
    return false;
  }

  errorCloseAlert() {
    this.errorAlert.nativeElement.classList.remove('show');
  }

  setFastPlan(isFastPlan: boolean) {
    this.isFastPlan = isFastPlan;
    this.interview.isFastPlan = isFastPlan;
  }
}

