export class HerdProblem {

  public id: number;

  public description: string;

  public checked: boolean;

  public active: boolean;

  public otherProblem: string;

  public static getProblems (): Array<HerdProblem> {
    return listProblems(); // BUSCAR NO SERVICE
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

function listProblems(): Array<HerdProblem> {
  const programs = <Array<HerdProblem>> [
    { id :  1, description: 'Caracterização racial', checked: false, active:	true },
    { id :  2, description: 'Pelagem / pigmentação', checked: false, active:	true },
    { id :  3, description: 'Reprodutivo', checked: false, active:	true },
    { id :  4, description: 'Saúde', checked: false, active:	true },
    { id :  5, description: 'Adaptação', checked: false, active:	true },
    { id :  6, description: 'Temperamento', checked: false, active:	true },
    { id :  7, description: 'Pouca espessura de gordura nas carcaças', checked: false, active:	true },
    { id :  8, description: 'Problema de parto', checked: false, active:	true },
    { id :  9, description: 'Baixo peso ao desmame', checked: false, active:	true },
    { id : 10, description: 'Baixo rendimento de carcaça', checked: false, active:	true },
    { id : 11, description: 'Alta proporção de guaxos', checked: false, active:	true },
    { id : 12, description: 'Dificuldade de liquidez', checked: false, active:	true },
    { id : 13, description: 'Baixo índice de concepção', checked: false, active:	true },
    { id : 14, description: 'Alto índice de perdas embrionárias / aborto', checked: false, active:	true },
    { id : 15, description: 'Alto índice de mortes neonatais', checked: false, active:	true },
    { id : 16, description: 'Pouca precocidade sexual', checked: false, active:	true },
    { id : 17, description: 'Baixo peso de carcaça', checked: false, active:	true },
    { id : 18, description: 'Alto peso adulto / tamanho das matrizes', checked: false, active:	true },
    { id : 19, description: 'Estrutura (visual)', checked: false, active:	true },
    { id : 20, description: 'Precocidade (visual)', checked: false, active:	true },
    { id : 21, description: 'Musculatura (visual)', checked: false, active:	true },
    { id : 22, description: 'Umbigo', checked: false, active:	true },
    { id : 23, description: 'Aprumos', checked: false, active:	true },
    { id : 24, description: 'Outro', checked: false, active:	true }
  ];
  return programs;
}
