<div class="border rounded p-4 bg-white">
  <div>
    <h4 class="d-inline-block mb-2">Endereço</h4>
    <fa-icon *ngIf="canAddAddress" (click)="AddAddress()" class="btn btn-submit float-right" [icon]="iconAdd" title="Adicionar endereço"></fa-icon>
  </div>
  <div>
    <div class="my-2" *ngFor="let address of editPerson.address?.slice()?.reverse(); let i = index;" >
      <hr *ngIf="i > 0" class="w-100 divider mb-4" />
      <div>
        <div class="form-row">
          <div class="form-group col-3">
            <label for="postalCode_{{i}}">CEP</label>
            <input type="text" mask="00.000-000" [(ngModel)]="address.postalCode" class="form-control" id="postalCode_{{i}}" name="postalCode_{{i}}" placeholder="00000-000" autocomplete="off" tabindex="2" required/>
            <small class="text-danger" *ngIf="!address.postalCode">Informe o CEP</small>
          </div>
          <div class="form-group col-9">
            <label for="street_{{i}}">Logradouro</label>
            <input type="text" [(ngModel)]="address.street" class="form-control" id="street_{{i}}" name="street_{{i}}" placeholder="Rua, avenida, quadra..." autocomplete="off" tabindex="3" required/>
            <small class="text-danger" *ngIf="!address.street">Informe o logradouro</small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-3">
            <label for="number_{{i}}">Número</label>
            <input type="text" [(ngModel)]="address.number" class="form-control" id="number_{{i}}" name="number_{{i}}" placeholder="000" autocomplete="off" tabindex="4" required/>
            <small class="text-danger" *ngIf="!address.number">Informe o número</small>
          </div>
          <div class="form-group col-9">
            <label for="complement_{{i}}">Complemento</label>
            <input type="text" [(ngModel)]="address.complement" class="form-control" id="complement_{{i}}" name="complement_{{i}}" placeholder="Apto 0" autocomplete="off" tabindex="5" required/>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-6">
            <label for="state_{{i}}">Estado</label>
            <select id="state_{{i}}" name="state_{{i}}" class="form-control" [(ngModel)]="address.city.ufId" (change)="ChangeUf($event, i)" required>
              <option value="" disabled>Selecione...</option>
              <option *ngFor="let u of ufList" [value]="u.uf1">{{u.name}}</option>
            </select>
            <small class="text-danger" *ngIf="!address.city.ufId">Informe o Estado</small>
          </div>
          <div class="form-group col-6">
            <label for="city_{{i}}">Cidade</label>
            <select id="city_{{i}}" name="city_{{i}}" class="form-control" (change)="ChangeCity($event, i)" [(ngModel)]="address.city.id" required>
              <option value="" disabled>Selecione...</option>
              <option *ngFor="let c of GetCities(address.city.ufId, i)" [value]="c.id" >{{c.name}}</option>
              <!-- <option *ngFor="let c of GetCities(address.city.ufId, i) | byProperty:'ufId':address.city.ufId" [value]="c.id" >{{c.name}}</option> -->
            </select>
            <small class="text-danger" *ngIf="!address.city.id">Informe a Cidade</small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 m-0">
            <div class="form-check d-inline-block">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="mainAddress_{{i}}"
                name="mainAddress_{{i}}"
                [(ngModel)]="address.isDefault"
                (change)="ChangeDefaultAddress(i)">
              <label class="form-check-label" for="mainAddress_{{i}}">Definir como padrão</label>
            </div>
            <fa-icon *ngIf="canAddAddress" (click)="RemoveAddress(i)" class="btn btn-delete float-right" [icon]="iconTrash" title="Remover endereço"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
