import { User } from "src/_models/user";
import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
} from "@angular/core";

@Component({
  selector: "app-managers",
  templateUrl: "./managers.component.html",
  styleUrls: ["./managers.component.scss"],
})
export class ManagersComponent implements OnInit, OnChanges {
  @Input() user: User;
  //public editUser: User;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    //this.CopyEntity();
  }

  ngOnInit() {
    //this.CopyEntity();
  }

  private CopyEntity() {
    //this.editUser = Object.assign({}, this.user);
  }


}
