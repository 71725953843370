import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TraitGroup } from 'src/_models/trait-group';

@Component({
  selector: 'app-modal-traits',
  templateUrl: './modal-traits.component.html',
  styleUrls: ['./modal-traits.component.scss']
})
export class ModalTraitsComponent implements OnInit {

  @Input() public traitsGroups: Array<TraitGroup>;

  public selectedTraitsGroups: Array<TraitGroup>;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.selectedTraitsGroups = new Array<TraitGroup>();
    this.copyTraitsGroups();
  }

  private copyTraitsGroups() {
    for (let tg = 0; tg < this.traitsGroups.length; tg++) {
      this.selectedTraitsGroups[tg] = Object.assign({}, this.traitsGroups[tg]);
      for (let t = 0; t < this.traitsGroups[tg].trait.length; t++) {
        this.selectedTraitsGroups[tg].trait[t] =  Object.assign({}, this.traitsGroups[tg].trait[t]);
      }
    }
  }

  public addTraits() {
    this.addSelectedTraits();
    this.activeModal.close(this.selectedTraitsGroups);
  }

  public cancel() {
    this.addSelectedTraits();
    this.activeModal.close(this.selectedTraitsGroups);
  }

  private addSelectedTraits() {
    // sets only checked traits for each group
    for (let tg = 0; tg < this.selectedTraitsGroups.length; tg++) {
      this.selectedTraitsGroups[tg].trait = this.selectedTraitsGroups[tg].trait.filter(t => t.checked === true);
      if (this.selectedTraitsGroups[tg].trait.length > 0) {
        this.selectedTraitsGroups[tg].checked = true;
      }
    }
    // retuns only groups that have some trait
    this.selectedTraitsGroups = this.selectedTraitsGroups.filter(tg => tg.trait.length > 0);
  }

  public selectAllTraits(traitGroupId: number, checked: any) {
    this.selectedTraitsGroups
      .find(tg => tg.id === traitGroupId)
      .trait
      .forEach(t => t.checked = checked);
  }

  public selectTrait(traitGroupId: number, traitId: number, checked: any) {
    const selectedTraitGroup = this.selectedTraitsGroups.find(tg => tg.id === traitGroupId);
    const traitGroup = this.traitsGroups.find(tg => tg.id === traitGroupId);
    if (checked) {
      traitGroup.trait.find(t => t.id === traitId).checked = checked;
      const allTraitsSelected = traitGroup.trait.filter(t => t.checked === checked).length;
      if (allTraitsSelected === traitGroup.trait.length) {
        traitGroup.checked = checked;
      }
    } else {
      selectedTraitGroup.checked = checked;
      traitGroup.checked = checked;
    }
  }
}
