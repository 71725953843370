import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Interview } from 'src/_models/interview';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TraitGroup } from 'src/_models/trait-group';
import { ApiService } from '@app/_services/api.service';
import { InterviewCycle } from 'src/_models/interview-cycle';
import { WeightingSuggestion } from 'src/_models/weighting-suggestion/weighting-suggestion';
import { FeatureHighLighting } from 'src/_models/feature-highlighting';
import { WeightingSuggestionHasTrait } from 'src/_models/weighting-suggestion/weighting-suggestion-has-trait';

@Component({
  selector: 'app-weighting',
  templateUrl: './weighting.component.html',
  styleUrls: ['./weighting.component.scss']
})
export class WeightingComponent implements OnInit {

  @Input() public interview: Interview;
  @Output() outputWeightingValueTotal = new EventEmitter<string>();

  // public selectedTraitsGroups: Array<TraitGroup>;
  public iconAdd = faPlus;

  public traitsGroups: Array<TraitGroup>;
  public interviewCycle: InterviewCycle;
  public weightingValueTotal: number;
  public invalidWeightingValueTotal = false;
  private weightingSuggestion: WeightingSuggestion;
  private featureHighLighting: Array<FeatureHighLighting>;
  public isTraitsLoaded = false;
  private proofId: number;
  private breedId: number;

  constructor(
    private modalService: NgbModal,
    public apiService: ApiService) { }

  ngOnInit() {
    this.weightingValueTotal = 0;
    this.traitsGroups = new Array<TraitGroup>();
    // this.selectedTraitsGroups = new Array<TraitGroup>();
    this.interviewCycle = this.interview.interviewCycle[this.interview.currentInterviewCycle];
    this.proofId = this.interviewCycle.proofId;
    this.breedId = this.interviewCycle.breedId;
    this.weightingSuggestion = new WeightingSuggestion();
    this.weightingSuggestion.weightingSuggestionHasTrait = new Array<WeightingSuggestionHasTrait>();
    this.featureHighLighting = new Array<FeatureHighLighting>();

    // this.getWeightingTraitsGroups();
    // this.getSelectedWeightingTraitsGroups();

    this.getWeightingsTraitsSuggestions();

    // if (this.interview.herdProblem
    //   && this.interview.herdProblem.length > 0) {
    //     this.getFeatureHighLighting();
    // }
  }

  private getWeightingsTraitsSuggestions() {
    if (this.interviewCycle.selectedWeightingTraitsGroups &&
      this.interviewCycle.selectedWeightingTraitsGroups.length > 0) {
        this.getSelectedWeightingTraitsGroups();
    } else {
      this.getWeightingTraitsGroups();
    }
  }

  private getWeightingTraitsGroups() {
    this.apiService.GetWeightingTraitsGroups(this.proofId, this.breedId).subscribe((data: TraitGroup[]) => {
      this.traitsGroups = data;
      this.getWeightingSuggestions();
      this.getFeatureHighLighting();
    }, (error) => console.error(error));
  }

  // public OpenModalTraits() {
  //   if (this.interview.herdProblem
  //     && this.interview.herdProblem.length > 0) {
  //       this.setFeatureHighLighting();
  //   }
  //   this.setTraitsWeightingSuggestions();
  //   this.CheckSelectedWeightingTraitsGroups();
  //   const modalRef = this.modalService.open(ModalTraitsComponent, { centered: true });
  //   modalRef.componentInstance.traitsGroups = this.traitsGroups;
  //   modalRef.result.then((result) => {
  //     this.selectedTraitsGroups = new Array<TraitGroup>();
  //     this.selectedTraitsGroups.push(...result);
  //     this.interviewCycle.selectedWeightingTraitsGroups = new Array<TraitGroup>();
  //     this.interviewCycle.selectedWeightingTraitsGroups.push(...this.selectedTraitsGroups);
  //     this.updatWeightingTotalValue();
  //   }, (reason) => {
  //     console.log(`Dispensou ${this.getDismissReason(reason)}`);
  //   });
  // }

  // public removeTrait(traitGroupIdx: number, traitIdx: number) {
  //   // gets the selected group
  //   const selectedTraitGroup = this.selectedTraitsGroups[traitGroupIdx];
  //   // gets the selected trait from the selected group
  //   const selectedTrait = selectedTraitGroup.trait[traitIdx];
  //   // if selected group doesn´t have traits anymore, removes it
  //   this.traitsGroups
  //     .find(tg => tg.id === selectedTraitGroup.id).trait
  //     .find(t => t.id === selectedTrait.id).checked = false;
  //   // checks trait group to false
  //   this.traitsGroups
  //     .find(tg => tg.id === selectedTraitGroup.id).checked = false;
  //   // checks selected trait group to false
  //   selectedTraitGroup.checked = false;
  //   // checks selected trait to remove to false on traitsGroups list
  //   selectedTraitGroup.trait.splice(traitIdx, 1);
  //   // removes selected trait from selectedTraitsGroup list
  //   if (selectedTraitGroup.trait.length === 0) {
  //     // removes selected trait group from selectedTraitsGroups list
  //     this.selectedTraitsGroups.splice(traitGroupIdx, 1);
  //   }
  //   this.updatWeightingTotalValue();
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'apertando ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'clicando fora do modal';
  //   } else {
  //     return  `com: ${reason}`;
  //   }
  // }

  private getSelectedWeightingTraitsGroups() {
    // if (this.interviewCycle.selectedWeightingTraitsGroups &&
      // this.interviewCycle.selectedWeightingTraitsGroups.length > 0) {
      // this.selectedTraitsGroups = this.interviewCycle.selectedWeightingTraitsGroups;
      this.traitsGroups = this.interviewCycle.selectedWeightingTraitsGroups;
      this.updatWeightingTotalValue();
      this.getFeatureHighLighting();
      this.isTraitsLoaded = true;
    // }
  }

  // private CheckSelectedWeightingTraitsGroups() {
  //   for (let stg = 0; stg < this.selectedTraitsGroups.length; stg++) {
  //     for  (let tg = 0; tg < this.traitsGroups.length; tg++) {
  //       if (this.selectedTraitsGroups[stg].id === this.traitsGroups[tg].id) {
  //         this.traitsGroups[tg].checked = this.selectedTraitsGroups[stg].checked;
  //         for (let st = 0; st < this.selectedTraitsGroups[stg].trait.length; st++) {
  //           for (let t = 0; t < this.traitsGroups[tg].trait.length; t++) {
  //             if (this.selectedTraitsGroups[stg].trait[st].id ===  this.traitsGroups[tg].trait[t].id) {
  //               this.traitsGroups[tg].trait[t].checked = this.selectedTraitsGroups[stg].trait[st].checked;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  public updatWeightingTotalValue() {
    this.weightingValueTotal = 0;
    for (let stg = 0; stg < this.traitsGroups.length; stg++) {
      for (let st = 0; st < this.traitsGroups[stg].trait.length; st++) {
        const weightingValue = this.traitsGroups[stg].trait[st].weightingValue;
        if (weightingValue) {
          // tslint:disable-next-line: radix
          this.weightingValueTotal += parseInt(weightingValue.toString());
        }
      }
    }
    this.invalidWeightingValueTotal = this.weightingValueTotal !== 100 ? true : false;
    this.outputWeightingValueTotal.emit(this.weightingValueTotal.toString());
  }

  private getWeightingSuggestions() {
    this.apiService.getWeightingSuggestions(this.interview).subscribe((data: WeightingSuggestion) => {
        this.weightingSuggestion = data;
        this.setTraitsWeightingSuggestions();
        this.updatWeightingTotalValue();
        this.interviewCycle.selectedWeightingTraitsGroups = new Array<TraitGroup>();
        this.interviewCycle.selectedWeightingTraitsGroups.push(...this.traitsGroups);
        this.isTraitsLoaded = true;
    }, (error) => console.log(error));
  }

  private setTraitsWeightingSuggestions() {
    this.weightingSuggestion.weightingSuggestionHasTrait.forEach((ws) => {
      this.traitsGroups.forEach((tg) => {
        tg.trait.forEach((t) => {
          if (t.id === ws.traitId) {
            // tslint:disable-next-line: radix
            t.weightingValue = parseInt(ws.value);
          }
        });
      });
    });
  }

  private getFeatureHighLighting() {
    if (this.interview.herdProblem
      && this.interview.herdProblem.length > 0) {
      this.apiService.getWeightingFeatureHighLighting(this.interview).subscribe((data: FeatureHighLighting[]) => {
          this.featureHighLighting = data;
          this.setFeatureHighLighting();
      }, (error) => console.log(error));
    }
  }

  private setFeatureHighLighting() {
    this.featureHighLighting.forEach((f) => {
      this.traitsGroups.forEach((tg) => {
        tg.trait.forEach((t) => {
          if (f.traitId === t.id) {
            t.isHighlighting = true;
          }
        });
      });
    });
  }
}
