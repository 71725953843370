<div class="border rounded p-4 bg-white">
  <div>
    <h4 class="d-inline-block mb-2">E-mail</h4>
    <fa-icon (click)="AddEmail()" class="btn btn-submit float-right" [icon]="iconAdd" title="Adicionar email"></fa-icon>
  </div>
  <div>
    <div class="my-2" *ngFor="let email of editPerson.email?.slice()?.reverse(); let i = index;">
      <hr *ngIf="i > 0" class="w-100 divider mb-4" />
      <div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="email_{{i}}">Endereço de e-mail</label>
            <input type="text" [(ngModel)]="email.email1" class="form-control" id="email_{{i}}" placeholder="exemplo@altagenetics.com.br" autocomplete="off" required/>
            <small class="text-danger" *ngIf="!email.email1">Informe um e-mail válido</small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 m-0">
            <div class="form-check d-inline-block">
              <input class="form-check-input" type="checkbox" value="" [(ngModel)]="email.isDefault" id="mainEmail_{{i}}"(change)="ChangeDefaultEmail(i)">
              <label class="form-check-label" for="mainEmail_{{i}}">Definir como padrão</label>
            </div>
            <fa-icon (click)="RemoveEmail(i)" class="btn btn-delete float-right" [icon]="iconTrash" title="Remover email"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
