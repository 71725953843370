import { Person } from './person';
import { Customer } from './customer';
import { Interview } from './interview';

export class User  {

  public id: string;

  public email: string;

  public regionManager: string;

  public districtManager: string;

  public registerDate: Date;

  public lastAccess: Date;

  public active: boolean;

  public person: Person;

  public customer: Array<Customer>;

  public password: string;

  public status: number;

  public personId: number;

  public interview: Array<Interview>;

  constructor () {
    // this.person = this.person ? this.person : new Person();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    this.customer = this.customer ? input.customer.map((c: Customer) => new Customer().deserialize(c)) : this.customer;

    this.interview = this.interview ? input.interview
      .map((i: Interview) => new Interview().deserialize(i)) : this.interview;

    return this;
  }

}
