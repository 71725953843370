<div class="row px-2">
    <div kendoDialogContainer></div>
    <div class="col-12 m-0 p-2">
        <div class="border rounded p-4 bg-white">
        <h3 class="my-2">Identificação</h3>
        <div class="form-row">
            <div class="form-group col-6">
                <label for="name">Nome</label>
                <input
                    type="text"
                    [(ngModel)]="editPerson.name"
                    class="form-control"
                    id="name"
                    name="name"
                    placeholder="nome completo"
                    autocomplete="off"
                    tabindex="0"
                    required/>
                <small class="text-danger" *ngIf="!editPerson.name">Informe o nome</small>
            </div>
            <div class="form-group col-6">
                <div class="custom-control custom-radio custom-control-inline mt-1">
                    <input #radioCpf type="radio" class="custom-control-input" id="radioCpf" name="radioCpf" (change)="changeDocType('radioCpf')" checked mdbInput>
                    <label class="custom-control-label" for="radioCpf">Cpf</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline mt-1">
                    <input #radioCnpj type="radio" class="custom-control-input" id="radioCnpj" name="radioCnpj" (change)="changeDocType('radioCnpj')">
                    <label class="custom-control-label" for="radioCnpj">Cnpj</label>
                </div>
                <div class="m-1" *ngIf="isCpfChecked">
                    <input
                        type="text"
                        [(ngModel)]="editPerson.docNumber"
                        class="form-control"
                        id="docNumberCpf"
                        name="docNumberCpf"
                        mask="000.000.000-00"
                        placeholder="000.000.000-00"
                        autocomplete="off"
                        tabindex="1"
                        #cpfInput="ngModel"
                        (change)="Validate(cpfInput?.errors)"
                        required/>
                    <!-- <input
                        type="text"
                        [(ngModel)]="editPerson.docNumber"
                        class="form-control"
                        id="docNumberCpf"
                        name="docNumberCpf"
                        mask="000.000.000-00"
                        placeholder="000.000.000-00"
                        autocomplete="off"
                        tabindex="1"
                        [cpf]="editPerson.docNumber"
                        #cpfInput="ngModel"
                        (change)="Validate(cpfInput?.errors)"
                        required/> -->
                    <small class="text-danger" *ngIf="cpfInput?.errors?.cpf">Informe um cpf válido</small>
                    <small class="text-danger" *ngIf="cpfInput?.errors?.required">Informe o cpf</small>
                </div>
                <div class="m-1" *ngIf="isCnpjChecked">
                    <input
                        type="text"
                        [(ngModel)]="editPerson.docNumber"
                        class="form-control"
                        id="docNumberCnpj"
                        name="docNumberCnpj"
                        mask="00.000.000/0000-00"
                        placeholder="00.000.000/0000-00"
                        autocomplete="off"
                        tabindex="1"
                        #cnpjInput="ngModel"
                        (change)="Validate(cnpjInput?.errors)"
                        required/>
                    <!-- <input
                        type="text"
                        [(ngModel)]="editPerson.docNumber"
                        class="form-control"
                        id="docNumberCnpj"
                        name="docNumberCnpj"
                        mask="00.000.000/0000-00"
                        placeholder="00.000.000/0000-00"
                        autocomplete="off"
                        tabindex="1"
                        [cnpj]="editPerson.docNumber"
                        #cnpjInput="ngModel"
                        (change)="Validate(cnpjInput?.errors)"
                        required/> -->
                    <small class="text-danger" *ngIf="cnpjInput?.errors?.cnpj">Informe um cnpj válido</small>
                    <small class="text-danger" *ngIf="cnpjInput?.errors?.required">Informe o cnpj</small>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>

<div class="row px-2">
    <div class="col-12 m-0 p-2">
        <app-address [person]="editPerson"></app-address>
        <small class="text-danger" *ngIf="!editPerson.address">Informe ao menos um endereço válido</small>
    </div>
</div>

<div class="row px-2">
    <div class="col-7 m-0 p-2">
        <app-email [person]="editPerson"></app-email>
        <small class="text-danger" *ngIf="!editPerson.email">Informe ao menos um e-mail válido</small>
    </div>

    <div class="col-5 m-0 p-2">
        <app-phone [person]="editPerson"></app-phone>
        <small class="text-danger" *ngIf="!editPerson.phone">Informe ao menos um telefone válido</small>
    </div>
</div>
