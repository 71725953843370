import { Breed } from "src/_models/breed";
import { ExportService } from "./../../_services/export.service";
import { Alert } from "selenium-webdriver";
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { Interview } from "src/_models/interview";
import { ApiService } from "@app/_services/api.service";
import { InterviewCycle } from "src/_models/interview-cycle";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import {
  CellClickEvent,
  GridDataResult,
  RowArgs,
  SelectableSettings,
} from "@progress/kendo-angular-grid";
import { Bull } from "src/_models/bull";
import { DialogConfirmService } from "@app/_services/dialog-confirm.service";
import { DialogCloseResult } from "@progress/kendo-angular-dialog";
import { SelectedBull } from "src/_models/selected-bull";
import { BreedEnum } from "src/_enums/breed-enum";
import { SafeStyle, DomSanitizer } from "@angular/platform-browser";
import { Proof } from "src/_models/proof";
import { BullAttendedFilter } from "src/_models/bull-attended-filter";
import { FocusOnDomReadyDirective } from "@progress/kendo-angular-inputs";

interface ColumnSetting {
  field: string;
  title: string;
  locked?: boolean;
  width: string;
  // format?: string;
  // type: 'text' | 'numeric' | 'boolean' | 'date';
}

@Component({
  selector: "app-bull-selection",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .whole-cell {
        display: block;
        padding: 6px 8px; /* depends on theme */
      }
    `,
  ],
  templateUrl: "./bull-selection.component.html",
  styleUrls: ["./bull-selection.component.scss"],
})
export class BullSelectionComponent implements OnInit {
  @Input() public interview: Interview;
  @Output() selectedBullsCount = new EventEmitter<number>();

  @ViewChild("removeBullsWithoutInventory", { static: false })  private removeBullsWithoutInventory: ElementRef;
  @ViewChild("removeBullsNotAttendedFilters", { static: false })  private removeBullsNotAttendedFilters: ElementRef;

  // @ViewChild("removeBullsWithoutInventory") removeBullsWithoutInventory: ElementRef;
  // @ViewChild("removeBullsNotAttendedFilters") removeBullsNotAttendedFilters: ElementRef;

  public bulls: Array<any>;
  public bullsCached: Array<any>;
  public interviewCycle: InterviewCycle;
  public bullSort: SortDescriptor[] = [{ field: "index", dir: "desc" }];
  public gridView: GridDataResult;
  public gridSelection: any[] = [];
  public checkboxOnly = false;
  public selectableSettings: SelectableSettings;
  public columns: ColumnSetting[] = [];
  public isBullsLoaded = false;
  BreedEnum: typeof BreedEnum = BreedEnum;
  private filtersColumns = [];
  private bullHasAttendedFilters: Array<BullAttendedFilter>;

  public selectedCallback = (args: { dataItem: any }) => args.dataItem;

  public selectedItems: any[] = [];

  constructor(
    public exportService: ExportService,
    public apiService: ApiService,
    public dialogConfirmService: DialogConfirmService,
    private sanitizer: DomSanitizer
  ) {
    this.setSelectableSettings();
  }

  ngOnInit() {
    this.filtersColumns = [];
    this.bullHasAttendedFilters = new Array<BullAttendedFilter>();
    this.gridView = {
      data: [],
      total: 0,
    };
    this.bulls = new Array<Bull>();
    this.bullsCached = new Array<Bull>();
    this.interviewCycle =
      this.interview.interviewCycle[this.interview.currentInterviewCycle];
    this.getBulls();
  }

  public isRowSelected = (e: RowArgs): boolean =>
    this.gridSelection.indexOf(e.dataItem) >= 0;

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    const itemIndex: number = this.selectedItems.indexOf(dataItem);

    if (itemIndex >= 0) {
      this.selectedItems.splice(itemIndex, 1);
    } else {
      this.selectedItems.push(dataItem);
    }

    this.gridSelection = [];
    this.gridSelection.push(...this.selectedItems);

    this.setSelectedBulls();

  }

  public bullSortChange(sort: SortDescriptor[]): void {
    this.bullSort = sort;
    this.orderBulls();
  }

  private orderBulls(): void {
    if (this.bulls.length > 0) {
      this.gridView = {
        data: orderBy(this.bulls, this.bullSort),
        total: this.bulls.length,
      };
    }
  }

  private getBulls() {
    this.apiService.getBulls(this.interviewCycle).subscribe(
      (data: Bull[]) => {
        this.configureBulls(data);
      },
      (error) => console.log(error)
    );
  }

  private configureBulls(bulls: Bull[]) {
    this.columns = this.createDynamicGridColumns(bulls);
    this.createDynamicBullsList(bulls);
    this.orderBulls();
    this.removeBulls(true, `removeBullsWithoutInventory`);
    this.removeBulls(true, `removeBullsNotAttendedFilters`);
    this.isBullsLoaded = true;
  }

  public setSelectableSettings() {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: "multiple",
    };
  }

  public removeBulls(checked: boolean, id: string) {
    this.bulls = [];
    const removeBullsNotAttendedFilters =
      this.removeBullsNotAttendedFilters !== undefined
        ? this.removeBullsNotAttendedFilters.nativeElement.checked
        : false;
    const removeBullsWithoutInventory =
      this.removeBullsWithoutInventory !== undefined
        ? this.removeBullsWithoutInventory.nativeElement.checked
        : false;

    if (removeBullsNotAttendedFilters && removeBullsWithoutInventory) {
      this.bulls.push(
        ...this.bullsCached.filter(
          (b) => b.doses === "Sim" && b.filters === "Sim"
        )
      );
    } else if (removeBullsNotAttendedFilters) {
      this.bulls.push(...this.bullsCached.filter((b) => b.filters === "Sim"));
    } else if (removeBullsWithoutInventory) {
      this.bulls.push(...this.bullsCached.filter((b) => b.doses === "Sim"));
    } else if (checked) {
      // for first time, to load with bulls that have inventory
      this.bulls.push(...this.bullsCached.filter((b) => b.doses === "Sim"));
    } else {
      this.bulls = this.bullsCached;
    }
  }

  public compareBulls(e: any) {
    if (e.target.innerText === `Comparar Touros`) {
      if (this.gridSelection.length > 1) {
        this.bulls = this.gridSelection;
        e.target.innerText = `Limpar`;
        e.target.className = `btn btn-danger`;
      } else {
        this.alert();
      }
    } else {
      this.bulls = this.bullsCached;
      e.target.innerText = `Comparar Touros`;
      e.target.className = `btn btn-submit`;
    }
  }

  public setSelectedBulls() {
    const selectedBulls = new Array<SelectedBull>();
    for (const bull of this.gridSelection) {
      const selectedBull = new SelectedBull();
      selectedBull.bullId = bull.id;
      selectedBull.shortName = bull.shortName;
      selectedBull.interviewCycleId = this.interviewCycle.id;
      selectedBull.indexValue = bull.index;
      selectedBulls.push(selectedBull);
    }
    this.interview.interviewCycle[
      this.interview.currentInterviewCycle
    ].selectedBull = selectedBulls;
    this.selectedBullsCount.emit(selectedBulls.length);
  }

  public alert() {
    const dialogTitle = `Comparar Touros`;
    const dialogContent = `É necessário selecionar mais de 1 touro para realizar a comparação.`;
    this.dialogConfirmService
      .Alert(dialogTitle, dialogContent)
      .subscribe((result) => {
        if (!(result instanceof DialogCloseResult)) {
          const action = result.text;
        }
      });
  }

  public onSelectedKeysChange() {
    this.setSelectedBulls();
  }

  private createDynamicGridColumns(bulls: Bull[]): ColumnSetting[] {
    let columns = `[
      {
        "field": "breed.name",
        "title": "Raça"
      },
      {
        "field": "calvingEase",
        "title": "Selo (PN)"
      }`;

    if (bulls.length > 0) {
      for (const bull of bulls) {
        for (const proof of bull.proof) {
          const column =
            proof.trait.acronym + "_" + proof.traitType.description;
          columns += `,
              {
                "field":"${column}",
                "title":"${column}",
                "width": 100
              }`;
        }
        columns += `]`;
        break;
      }
    } else {
      columns += `]`;
    }
    return JSON.parse(columns);
  }

  private createDynamicBullsList(data: Bull[]) {
    data.forEach((b) => {
      const bull = {};
      bull["id"] = b.id;
      bull["shortName"] = b.shortName;
      bull["regNumber"] = b.regNumber;
      bull["index"] = b.index;
      bull["doses"] = Number(b.doses || 0) > 1000 ? "Sim" : "Não";
      bull["conceptPlus"] = b.conceptPlus === 1 ? "Sim" : "Não";
      bull["breed"] = b.breed;
      bull["calvingEase"] = b.calvingEase > 0 ? b.calvingEase + ` meses` : `-`;

      b.proof.forEach((p) => {
        const column = p.trait.acronym + "_" + p.traitType.description;
        bull[column] = p.value;
        this.resolveFilters(column, p, b.id);
      });

      if (
        this.interviewCycle.bullSelectionFilter &&
        this.interviewCycle.bullSelectionFilter.length > 0
      ) {
        this.setBullFilter(b);
        bull["filters"] = b.filters;
      } else {
        bull["filters"] = "Sim";
      }

      this.bullsCached.push(bull);
    });
    this.bulls.push(...this.bullsCached);
  }

  isColumnHidden(): boolean {
    if (
      this.interviewCycle.breedId !== BreedEnum.NELORE &&
      this.interviewCycle.breedId !== BreedEnum.NELORE_MOCHO &&
      this.interviewCycle.breedId !== BreedEnum.ANGUS &&
      this.interviewCycle.breedId !== BreedEnum.RED_ANGUS
    ) {
      return false;
    }
    return true;
  }

  hideIdColumn(column: string): boolean {
    if (column === `Id`) {
      return false;
    }
    return true;
  }

  private resolveFilters(column: string, proof: Proof, bullId: number) {
    const hasBullFilters =
      this.interviewCycle.bullSelectionFilter &&
      this.interviewCycle.bullSelectionFilter.length > 0;

    if (hasBullFilters) {
      const filter = this.interviewCycle.bullSelectionFilter.find(
        (f) =>
          f.traitId === proof.traitId && f.traitTypeId === proof.traitTypeId
      );

      const hasFilter = filter !== null && filter !== undefined;

      if (hasFilter) {
        this.filtersColumns.push(column);

        const proofValue = Number(proof.value || 0);
        const filterValue = Number(filter.value || 0);

        if (filter.signal === `>=`) {
          if (proofValue >= filterValue) {
            const bullAttendedFilter = new BullAttendedFilter();
            bullAttendedFilter.bullId = bullId;
            bullAttendedFilter.column = column;
            this.bullHasAttendedFilters.push(bullAttendedFilter);
          }
        } else {
          if (proofValue <= filterValue) {
            const bullAttendedFilter = new BullAttendedFilter();
            bullAttendedFilter.bullId = bullId;
            bullAttendedFilter.column = column;
            this.bullHasAttendedFilters.push(bullAttendedFilter);
          }
        }
      }
    }
  }

  private setBullFilter(bull: Bull) {
    let containsAllColumns = false;
    const bullHasAttendedFilters = this.bullHasAttendedFilters.filter(
      (b) => b.bullId === bull.id
    );
    if (bullHasAttendedFilters !== null && bullHasAttendedFilters.length > 0) {
      this.filtersColumns.every((column) => {
        containsAllColumns = bullHasAttendedFilters.some(
          (b) => b.column === column
        );
        return containsAllColumns;
      });
      if (containsAllColumns) {
        bull.filters = `Sim`;
      }
    }
  }

  public highlightGridCell(
    column: string,
    bullId: number,
    value: number
  ): SafeStyle {
    let result = "";
    if (this.filtersColumns.includes(column)) {
      const bullHasAttendedFilters = this.bullHasAttendedFilters.filter(
        (b) => b.bullId === bullId
      );
      if (
        bullHasAttendedFilters.some((b) => b.column === column) &&
        value !== null &&
        value !== undefined
      ) {
        result = "#298461";
      } else if (
        !bullHasAttendedFilters.some((b) => b.column === column) &&
        value !== null &&
        value !== undefined
      ) {
        result = `#CD5C5C`;
      }
    }
    return this.sanitizer.bypassSecurityTrustStyle(result);
  }

  public gridTextCellColor(): SafeStyle {
    const result = "#fff";
    return this.sanitizer.bypassSecurityTrustStyle(result);
  }

  public isFilterColumn(column: string): boolean {
    if (this.filtersColumns.includes(column)) {
      return true;
    }
    return false;
  }

  public exportSelectedBulls() {
    this.exportBulls(this.gridSelection.sort(cond => cond.index));
  }

  public exportAllBulls() {

    this.exportBulls(this.bulls.sort(function(a,b) {
      if (a.index < b.index) {
          return 1;
      }

      if (a.index > b.index) {
          return -1;
      }
      return 0;
  }));

    //this.exportBulls(this.bulls.sort(cond => cond.index));
  }

  private exportBulls(bulls: any[]) {
    let exportItems: any[] = [];
    exportItems.push(this.createHeaderForFile());

    const items = bulls.map((bull) => {
      const newObj = Object.assign({}, bull);
      newObj.breed = newObj.breed.name;

      delete newObj.id;
      delete newObj.filters;

      return newObj;
    });

    exportItems.push(...items);

    this.exportService.exportToExcel(exportItems, {
      tabName: "Touros - índice",
      filename: "Plano Genético - Sumário de touros.xlsx",
    });
  }

  private createHeaderForFile(): any {
    let result: any = {};

    result.shortName = "Touro";
    result.regNumber = "Registro";
    result.index = "Índice";
    result.doses = "Estoque";
    result.conceptPlus = "Concept PLus";
    // result.breed = 'Raça';
    // result.calvingEase = 'Selo (PN)';

    this.columns.forEach((element) => {

      if (element.title === 'Raça') {
        result['breed'] = element.title;
      }
      else if (element.title === 'Selo (PN)') {
        result['calvingEase'] = element.title;
      }
      else {
        result[element.title] = element.title;
      }
    });

    return result;
  }
}
