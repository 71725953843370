export class ProofTrait {

    public id: number;

    public traitId: number;

    public proofId: number;

    public breedId: number;

    public standardDeviation: string;

    public averageIndex: string;

    public averageBulls: string;

    public sequence: number;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
