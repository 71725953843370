import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTraitsComponent } from '../modal-traits/modal-traits.component';
import { TraitGroup } from 'src/_models/trait-group';
import { Interview } from 'src/_models/interview';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '@app/_services/api.service';
import { InterviewCycle } from 'src/_models/interview-cycle';
import { FilterSuggestion } from 'src/_models/filter-suggestion/filter-suggestion';
import { FeatureHighLighting } from 'src/_models/feature-highlighting';
import { FilterSuggestionHasTrait } from 'src/_models/filter-suggestion/filter-suggestion-has-trait';
import { BullSelectionFilter } from 'src/_models/bull-selection-filter';
import { Trait } from 'src/_models/trait';
import { ProofTypeEnum } from 'src/_enums/proof-type-enum';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Input() public interview: Interview;
  @Output() filterSignal = new EventEmitter<boolean>();

  // public selectedTraitsGroups: Array<TraitGroup>;
  public interviewCycle: InterviewCycle;
  public iconAdd = faPlus;

  public traitsGroups: Array<TraitGroup>;
  private filterSuggestion: FilterSuggestion;
  private featureHighLighting: Array<FeatureHighLighting>;
  private bullSelectionFilters: Array<BullSelectionFilter>;
  private proofId: number;
  private breedId: number;
  ProofTypeEnum: typeof ProofTypeEnum = ProofTypeEnum;
  public isTraitsLoaded = false;

  constructor(
    private modalService: NgbModal,
    public apiService: ApiService) { }

  ngOnInit() {
    this.traitsGroups = new Array<TraitGroup>();
    // this.selectedTraitsGroups = new Array<TraitGroup>();
    this.interviewCycle = this.interview.interviewCycle[this.interview.currentInterviewCycle];
    this.proofId = this.interviewCycle.proofId;
    this.breedId = this.interviewCycle.breedId;
    this.filterSuggestion = new FilterSuggestion();
    this.filterSuggestion.filterSuggestionHasTrait = new Array<FilterSuggestionHasTrait>();
    this.featureHighLighting = new Array<FeatureHighLighting>();
    this.bullSelectionFilters = new Array<BullSelectionFilter>();

    // this.GetFilterTraitsGroups();
    // this.GetSelectedFilterTraitsGroups();
    // this.getFilterSuggestions();
    // this.setTraitsFilterSuggestions();

    this.getFiltersTraitsSuggestions();

    // if (this.interview.herdProblem
    //   && this.interview.herdProblem.length > 0) {
    //     this.getFeatureHighLighting();
    // }
  }

  private getFiltersTraitsSuggestions() {
    if (this.interviewCycle.selectedFilterTraitsGroups &&
      this.interviewCycle.selectedFilterTraitsGroups.length > 0) {
        this.getSelectedFilterTraitsGroups();
    } else {
      this.getFilterTraitsGroups();
    }
  }

  private getFilterTraitsGroups() {
    this.apiService.GetFilterTraitsGroups(this.proofId, this.breedId).subscribe((data: TraitGroup[]) => {
      this.traitsGroups = data;
      this.getFilterSuggestions();
      this.getFeatureHighLighting();
    }, (error) => console.error(error));
  }

  // public OpenModalTraits() {
  //   if (this.interview.herdProblem
  //     && this.interview.herdProblem.length > 0) {
  //       this.setFeatureHighLighting();
  //   }
  //   // this.setTraitsFilterSuggestions();
  //   this.CheckSelectedFilterTraitsGroups();
  //   const modalRef = this.modalService.open(ModalTraitsComponent, { centered: true });
  //   modalRef.componentInstance.traitsGroups = this.traitsGroups;
  //   modalRef.result.then((result) => {
  //     this.setTraitsFilterSuggestions(result);
  //     this.selectedTraitsGroups = new Array<TraitGroup>();
  //     this.selectedTraitsGroups.push(...result);
  //     this.interviewCycle.selectedFilterTraitsGroups = new Array<TraitGroup>();
  //     this.interviewCycle.selectedFilterTraitsGroups.push(...result);
  //   }, (reason) => {
  //     console.log(`Dispensou ${this.getDismissReason(reason)}`);
  //   });
  // }

  // public RemoveTrait(traitGroupIdx: number, traitIdx: number) {
  //   // gets the selected group
  //   const selectedTraitGroup = this.selectedTraitsGroups[traitGroupIdx];
  //   // gets the selected trait from the selected group
  //   const selectedTrait = selectedTraitGroup.trait[traitIdx];
  //   // checks selected trait to remove to false on traitsGroups list
  //   this.traitsGroups
  //     .find(tg => tg.id === selectedTraitGroup.id).trait
  //     .find(t => t.id === selectedTrait.id).checked = false;
  //   // checks trait group to false
  //   this.traitsGroups
  //     .find(tg => tg.id === selectedTraitGroup.id).checked = false;
  //   // set selected trait group checked to false
  //   selectedTraitGroup.checked = false;
  //   // removes selected trait from selectedTraitsGroup list
  //   selectedTraitGroup.trait.splice(traitIdx, 1);
  //   // if selected group doesn´t have traits anymore, removes it
  //   if (selectedTraitGroup.trait.length === 0) {
  //     // removes selected trait group from selectedTraitsGroups list
  //     this.selectedTraitsGroups.splice(traitGroupIdx, 1);
  //   }
  //   // removes from bull selection filters list
  //   if (this.bullSelectionFilters
  //     && this.bullSelectionFilters.length > 0) {
  //       // tslint:disable-next-line: no-shadowed-variable
  //       this.bullSelectionFilters = this.bullSelectionFilters.filter(f => f.traitId !== selectedTrait.id);
  //       this.interviewCycle.bullSelectionFilter = this.bullSelectionFilters;
  //     } else if (this.interviewCycle.bullSelectionFilter
  //     && this.interviewCycle.bullSelectionFilter.length > 0) {
  //       // tslint:disable-next-line: no-shadowed-variable
  //       this.interviewCycle.bullSelectionFilter = this.interviewCycle.bullSelectionFilter.filter(f => f.traitId !== selectedTrait.id);
  //       this.bullSelectionFilters = this.interviewCycle.bullSelectionFilter;
  //     }
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'apertando ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'clicando fora do modal';
  //   } else {
  //     return  `com: ${reason}`;
  //   }
  // }

  private getSelectedFilterTraitsGroups() {
    // if (this.interviewCycle.selectedFilterTraitsGroups &&
      // this.interviewCycle.selectedFilterTraitsGroups.length > 0) {
      this.traitsGroups = this.interviewCycle.selectedFilterTraitsGroups;
      this.isTraitsLoaded = true;
    // }
  }

  // private CheckSelectedFilterTraitsGroups() {
  //   // gets selected traits and checks on the traits groups list to be showed on modal traits
  //   for (let stg = 0; stg < this.selectedTraitsGroups.length; stg++) {
  //     for  (let tg = 0; tg < this.traitsGroups.length; tg++) {
  //       if (this.selectedTraitsGroups[stg].id === this.traitsGroups[tg].id) {
  //         this.traitsGroups[tg].checked = this.selectedTraitsGroups[stg].checked;
  //         for (let st = 0; st < this.selectedTraitsGroups[stg].trait.length; st++) {
  //           for (let t = 0; t < this.traitsGroups[tg].trait.length; t++) {
  //             if (this.selectedTraitsGroups[stg].trait[st].id ===  this.traitsGroups[tg].trait[t].id) {
  //               this.traitsGroups[tg].trait[t].checked = this.selectedTraitsGroups[stg].trait[st].checked;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  private getFilterSuggestions() {
    this.apiService.getFilterSuggestions(this.interview).subscribe((data: FilterSuggestion) => {
        this.filterSuggestion = data;
        this.setTraitsFilterSuggestions();
        this.interviewCycle.selectedFilterTraitsGroups = new Array<TraitGroup>();
        this.interviewCycle.selectedFilterTraitsGroups.push(...this.traitsGroups);
        this.isTraitsLoaded = true;
    }, (error) => console.log(error));
  }

  private setTraitsFilterSuggestions() {
    this.filterSuggestion.filterSuggestionHasTrait.forEach((fs) => {
      this.traitsGroups.forEach((tg) => {
        tg.trait.forEach((t) => {
          if (t.id === fs.traitId) {
            // tslint:disable-next-line: radix
            t.filterTopDecaValue = parseInt(fs.value);
            t.filterTopDecaSignal = fs.signal;
            this.updateSignalFilters(t, 10, fs.signal);
            this.updateValueFilters(t, 10, fs.value);
          }
        });
      });
      // this.traitsGroups.forEach((tg) => {
      //   tg.trait.forEach((t) => {
      //     if (t.id === fs.traitId) {
      //       // tslint:disable-next-line: radix
      //       t.filterTopDecaValue = parseInt(fs.value);
      //       t.filterTopDecaSignal = fs.signal;
      //     }
      //   });
      // });
    });
  }

  private getFeatureHighLighting() {
    if (this.interview.herdProblem
      && this.interview.herdProblem.length > 0) {
      this.apiService.getFilterFeatureHighLighting(this.interview).subscribe((data: FeatureHighLighting[]) => {
          this.featureHighLighting = data;
          this.setFeatureHighLighting();
      }, (error) => console.log(error));
    }
  }

  private setFeatureHighLighting() {
    this.featureHighLighting.forEach((f) => {
      this.traitsGroups.forEach((tg) => {
        tg.trait.forEach((t) => {
          if (f.traitId === t.id) {
            t.isHighlighting = true;
          }
        });
      });
    });
  }

  // tslint:disable-next-line: no-shadowed-variable
  private updateSignalFilters(trait: Trait, traitTypeId: number, signal: string) {
    traitTypeId = this.setTraitTypeToDeca(traitTypeId);

    const hasInterviewCycleFilters = this.interviewCycle.bullSelectionFilter
      && this.interviewCycle.bullSelectionFilter.length > 0;
    if (hasInterviewCycleFilters) {
      this.bullSelectionFilters = this.interviewCycle.bullSelectionFilter;
    }

    const hasSelectionFilters =
      this.bullSelectionFilters.some(f => f.traitId === trait.id && f.traitTypeId === traitTypeId);
    if (hasSelectionFilters) {
      this.bullSelectionFilters.find(f => f.traitId === trait.id && f.traitTypeId === traitTypeId).signal = signal;
    } else {
      const bullSelectionFilter = this.createBullSelectionFilter(traitTypeId, trait.id, trait.proofId);
      bullSelectionFilter.signal = signal;
      this.bullSelectionFilters.push(bullSelectionFilter);
    }

    this.setBullSelectionFilters();
  }

  private updateValueFilters(trait: Trait, traitTypeId: number, value: string) {
    traitTypeId = this.setTraitTypeToDeca(traitTypeId);

    const hasInterviewCycleFilters = this.interviewCycle.bullSelectionFilter
      && this.interviewCycle.bullSelectionFilter.length > 0;
    if (hasInterviewCycleFilters) {
      this.bullSelectionFilters = this.interviewCycle.bullSelectionFilter;
    }

    const hasSelectionFilters =
      this.bullSelectionFilters.some(f => f.traitId === trait.id && f.traitTypeId === traitTypeId);
    if (hasSelectionFilters) {
      this.bullSelectionFilters.find(f => f.traitId === trait.id && f.traitTypeId === traitTypeId).value = value;
    } else {
      const bullSelectionFilter = this.createBullSelectionFilter(traitTypeId, trait.id, trait.proofId);
      bullSelectionFilter.value = value;
      this.bullSelectionFilters.push(bullSelectionFilter);
    }

    this.setAccSignal(traitTypeId, trait.id);

    this.setBullSelectionFilters();
  }

  setAccSignal(traitTypeId: number, traitId: number) {
    if (traitTypeId === 6) {
      this.bullSelectionFilters.find(f => f.traitId === traitId && f.traitTypeId === traitTypeId).signal = '>=';
    }
  }

  setTraitTypeToDeca(traitTypeId: number): number{
    if (this.isDecaProof() && traitTypeId === 10) {
      return 2;
    }
    return traitTypeId;
  }

  private createBullSelectionFilter(traitTypeId: number, traitId: number, proofId: number): BullSelectionFilter {
    // tslint:disable-next-line: no-shadowed-variable
     const bullSelectionFilter: BullSelectionFilter = new BullSelectionFilter();
     bullSelectionFilter.traitTypeId = traitTypeId;
     bullSelectionFilter.traitId = traitId;
     bullSelectionFilter.proofId = proofId;

     return bullSelectionFilter;
  }

  public setBullSelectionFilters() {
    this.interviewCycle.bullSelectionFilter =
      this.bullSelectionFilters.filter(f => (f.value !== null && f.value !== '' && f.value !== undefined)
      || (f.signal !== null && f.signal !== '' && f.signal !== undefined));

    const hasFilter = this.interviewCycle.bullSelectionFilter.length > 0;

    if (hasFilter) {
      const hasInvalidFilter =
        this.interviewCycle.bullSelectionFilter.some(filter => filter.signal === null || filter.signal === undefined || filter.signal === '');
      if (hasInvalidFilter) {
        this.filterSignal.emit(false);
      } else {
        this.filterSignal.emit(true);
      }
    } else {
      this.filterSignal.emit(true);
    }
  }

  private isDecaProof(): boolean {
    const proofId = this.interviewCycle.proofId;
    return (proofId === this.ProofTypeEnum.ALIANCA ||
            proofId === this.ProofTypeEnum.SUMARIO_NATURA ||
            proofId === this.ProofTypeEnum.CONEXAO_DELTA_G ||
            proofId === this.ProofTypeEnum.PMGZ);
  }
}
