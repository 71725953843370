<div class="border rounded p-4 bg-white">
  <div>
    <h4 class="d-inline-block mb-2">Telefone</h4>
    <fa-icon (click)="AddPhone()" class="btn btn-submit float-right" [icon]="iconAdd" title="Adicionar telefone"></fa-icon>
  </div>
  <div>
    <div class="my-2" *ngFor="let phone of editPerson.phone?.slice()?.reverse(); let i = index;">
      <hr *ngIf="i > 0" class="w-100 divider mb-4" />
      <div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="phone_{{i}}">Número do telefone</label>
            <input
              type="text"
              mask="(00) 0000-00009"
              (input)="PhoneMask($event)"
              [(ngModel)]="phone.phone1"
              class="form-control"
              id="phone_{{i}}"
              placeholder="(00) 00000-0000"
              autocomplete="off" required/>
            <small class="text-danger" *ngIf="!phone.phone1">Informe um telefone válido</small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 m-0">
            <div class="form-check d-inline-block">
              <input class="form-check-input" type="checkbox" value="" id="mainPhone_{{i}}" [(ngModel)]="phone.isDefault" (change)="ChangeDefaultPhone(i)">
              <label class="form-check-label" for="mainPhone_{{i}}">Definir como padrão</label>
            </div>
            <fa-icon (click)="RemovePhone(i)" class="btn btn-delete float-right" [icon]="iconTrash" title="Remover telefone"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
