<div class="border rounded p-4 bg-white">
  <div>
    <h4 class="d-inline-block mb-2">Fazenda</h4>
    <fa-icon (click)="AddFarm()" class="btn btn-submit float-right" [icon]="iconAdd" title="Adicionar fazenda"></fa-icon>
  </div>
  <div>
    <div class="my-2" *ngFor="let farm of editCustomer.farm?.slice()?.reverse(); let i = index;" >
      <hr *ngIf="i > 0" class="w-100 divider mb-4" />
      <div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="name_{{i}}">Nome</label>
            <input type="text" [(ngModel)]="farm.name" class="form-control" id="name_{{farm.id}}" placeholder="Nome da fazenda" autocomplete="off" />
            <small class="text-danger" *ngIf="!farm.name">Informe o nome da fazenda</small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 m-0">
            <app-herd [farm]="farm"></app-herd>
            <small class="text-danger" *ngIf="!farm.herd">Informe ao menos um rebanho</small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 m-0">
            <fa-icon (click)="RemoveFarm(i)" class="btn btn-delete float-right" [icon]="iconTrash" title="Remover fazenda"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
