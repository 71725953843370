<h3 class="p-2 mb-2">7 - Distribuição das IATF's por época</h3>
<div class="row">
  <div class="col-12">
    <div class="form-row">
      <div class="form-group">
        <table class="table borderless">
          <thead>
            <tr>
              <th></th>
              <th>
                <label class="p-2 m-0">Início EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Meio EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Final EM</label>
              </th>
              <th>
                <label class="p-2 m-0">Total</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <label class="p-2 m-0">Novilhas</label>
              </th>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonHeifersBeginningEM" 
                  name="iatfDistributionPerSeasonHeifersBeginningEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonHeifersMiddleEM" 
                  name="iatfDistributionPerSeasonHeifersMiddleEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonHeifersEndEM" 
                  name="iatfDistributionPerSeasonHeifersEndEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonHeifersTotal" 
                  name="iatfDistributionPerSeasonHeifersTotal" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0">Vacas</label>
              </th>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonCowsBeginningEM" 
                  name="iatfDistributionPerSeasonCowsBeginningEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonCowsMiddleEM" 
                  name="iatfDistributionPerSeasonCowsMiddleEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonCowsEndEM" 
                  name="iatfDistributionPerSeasonCowsEndEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonCowsTotal" 
                  name="iatfDistributionPerSeasonCowsTotal" />
              </td>
            </tr>
            <tr>
              <th>
                <label class="p-2 m-0"></label>
              </th>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonTotalBeginningEM" 
                  name="iatfDistributionPerSeasonTotalBeginningEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonTotalMiddleEM" 
                  name="iatfDistributionPerSeasonTotalMiddleEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonTotalEndEM" 
                  name="iatfDistributionPerSeasonTotalEndEM" />
              </td>
              <td>
                <input 
                  class="form-control text-right font-weight-bold" 
                  type="text" 
                  placeholder="0" 
                  disabled 
                  [value]="iatfDistributionPerSeasonTotal" 
                  name="iatfDistributionPerSeasonTotal" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
