<div class="container justify-content-center h-100">
    <div class="row h-100 listArea">
      <div class="col h-100">
            <div *ngIf="interviewSavedSuccess" #interviewSavedSuccessAlert class="alert alert-success alert-dismissible fade show" role="alert">
                <button type="button" class="close" aria-label="Close" (click)="successCloseAlert()">
                <span aria-hidden="true">&times;</span>
                </button>
                {{ interviewSavedSuccess }}
            </div>
            <div class="panel">
                <h2 class="text-center my-4">Entrevistas realizadas</h2>
                <div class="d-flex form-group">
                    <div class="w-100 pr-2">
                        <input
                            class="form-control"
                            type="search"
                            placeholder="Pesquisar por cliente"
                            (input)="findByKeyword($event)" />
                    </div>
                </div>
                <div class="border rounded p-4 bg-white col-12">
                    <ng-template [ngIf]="savedInterviews.length > 0" [ngIfElse]="listEmpty">
                        <kendo-grid
                            [kendoGridBinding]="savedInterviews"
                            [data]="gridView"
                            [selectable]="true"
                            [kendoGridSelectBy]="selectedCallback"
                            [selectedKeys]="gridSelection"
                            [sortable]="{allowUnsort: 'allowUnsort', mode: 'single'}"
                            [sort]="interviewSort"
                            (sortChange)="savedInterviewsSortChange($event)"
                            style="max-height: calc(100% - 90px);"
                            [height]="600">
                            <kendo-grid-column
                                media="xs"
                                width="100">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <dl>
                                        <dt>Data Entrevista</dt>
                                        <dd>{{ dataItem.interviewDate | date:'dd/MM/yy hh:mm' }}</dd>

                                        <dt>Cliente</dt>
                                        <dd>{{ dataItem.customerName }}</dd>

                                        <dt>Fazenda</dt>
                                        <dd>{{ dataItem.farmName }}</dd> -->

                                        <dt>Rebanho</dt>
                                        <dd>{{ dataItem.herdName }}</dd>

                                        <!-- <dt>Situação</dt>
                                        <dd>{{ dataItem.status }}</dd> -->
                                    </dl>
                                </ng-template>
                            </kendo-grid-column>

                            <!-- columns for mid-size devices -->
                            <kendo-grid-column
                                media="md"
                                title="Data Entrevista"
                                field="interviewDate"
                                width="150"
                                class="text-left"
                                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                                [footerStyle]="{'font-weight': 'bold','color': '#000', 'line-height': '1em'}">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div *ngIf="dataItem.interviewDate != null">
                                        {{ dataItem.interviewDate | date:'dd/MM/yy H:mm' }}
                                    </div>
                                </ng-template>
                                <ng-template kendoGridFooterTemplate>Total: {{ savedInterviews.length }}</ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column
                                media="sm"
                                field="customerName"
                                title="Cliente"
                                class="text-left"
                                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                                width="250">
                            </kendo-grid-column>
                            <kendo-grid-column
                                media="sm"
                                field="farmName"
                                title="Fazenda"
                                class="text-left"
                                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                                width="250">
                            </kendo-grid-column>
                            <kendo-grid-column
                                media="sm"
                                field="herdName"
                                title="Rebanho"
                                class="text-left"
                                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                                width="250">
                            </kendo-grid-column>
                            <!-- <kendo-grid-column
                                media="sm"
                                field="status"
                                title="Situação"
                                class="text-left"
                                [headerStyle]="{'background-color': '#035737','color': '#fff'}"
                                width="100">
                            </kendo-grid-column> -->
                        </kendo-grid>

                    </ng-template>

                    <div *ngIf="!isSavedInterviewsLoaded" class="text-center">
                        <span class="k-icon k-i-loading" style="font-size: 64px; color: #035737;"></span>
                        <p class="text-center w-100">Buscando entrevistas realizadas...</p>
                    </div>

                    <ng-template #listEmpty>
                        <div *ngIf="isSavedInterviewsLoaded" class="d-flex align-items-center" style="height: calc(100% - 90px);">
                            <p class="text-center w-100">Nenhuma entrevista realizada.</p>
                        </div>
                    </ng-template>

                    <div *ngIf="savedInterviews.length > 0" class="row px-2">
                      <div class="col-6 m-0 p-2 text-left">

                          <button class="btn btn-submit d-none" type="submit" (click)="cloneInterview()">Clonar entrevista</button>

                          <small *ngIf="cloneError" class="alert alert-danger m-3 p-2 d-none">
                              {{ cloneError }}
                          </small>

                      </div>

                        <div class="col-6 m-0 p-2 text-right">
                            <small *ngIf="error" class="alert alert-danger m-3 p-2">
                                {{ error }}
                            </small>
                            <small *ngIf="success" class="alert alert-success m-3 p-2">
                                {{ success }}
                            </small>
                            <button class="btn btn-submit" type="submit" (click)="generatePdf()">Gerar PDF</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div kendoDialogContainer></div>
    </div>
</div>
